import { Identity } from './identity';
import { Offers } from './transaction';

export class TenderDashboard extends Identity {
  offers: number = 0;
  acceptedOffers: number = 0;
  rejectedOffers: number = 0;
  metReserveStocks: number = 0;
  stocks: number = 0;
  members: number = 0;
  stocksWithoutOffers: number = 0;
  participants: number = 0;
  grossAboveReserve: number = 0;
  tendersPerStock: number = 0;
  pendingOffers: Offers[] = [];
}
