import { Injectable } from '@angular/core';
import { AppLogger } from '../../../shared/src/lib/common/logger';
import { NotificationService } from './services/notification.service';
import { NotificationHistorySummary } from '@app/model';
import { StateService } from '@app/core';
import { ToastrService } from 'ngx-toastr';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor(
    public appLogger: AppLogger,
    public notificationService: NotificationService,
    public state: StateService,
    public toastr: ToastrService,
  ) {
    this.initFirebase();
  }

  public initFirebase() {
    document.addEventListener("deviceready", this.onDeviceReady.bind(this), false);
  }

  private onDeviceReady() {
    const firebaseMessaging = (((window.cordova || {}).plugins || {}).firebase || {}).messaging;
    if (firebaseMessaging == null) {
      return;
    }

    firebaseMessaging.requestPermission().then(() => {
      console.log('Notifications permission is allowed');
    }, (error: any) => {
      this.appLogger.error('Notifications permission is not allowed', error);
    });

    firebaseMessaging.onMessage(this.handleNotification.bind(this, false));

    firebaseMessaging.onBackgroundMessage(this.handleNotification.bind(this, true));
  }

  public handleNotification(isInBackground: boolean, payload: any): void {
    payload = payload || {};
    const metadataRaw = payload.MetaData || '{}';
    let metadata: any = metadataRaw;
    try {
      metadata = JSON.parse(metadataRaw);
    } catch (error) {
      this.appLogger.warning('Error while parsing notification metadata', error);
    }

    const notification: NotificationHistorySummary = new NotificationHistorySummary();
    notification.metaData = {
      EventId: metadata.EventId,
      EntityId: metadata.EntityId,
      Target: metadata.Target,
      Action: metadata.Action
    };

    const url = this.notificationService.getDetailsUrl(notification);
    if (!isInBackground) {
      const gcm = payload.gcm || {};
      const aps = payload.aps || {};
      const alert = aps.alert || {};
      const title = payload.Title || gcm.title || alert.title || '';
      const body = payload.Content || gcm.body || alert.body || '';
      this.toastr.success(body, title, {
        positionClass: 'toast-top-center',
      }).onTap.subscribe(() => {
        this.state.navigateByUrl(url);
      });

      return;
    }

    this.state.navigateByUrl(url);
  }

  public async login(): Promise<void> {
    const firebaseMessaging = (((window.cordova || {}).plugins || {}).firebase || {}).messaging;
    if (firebaseMessaging == null) {
      return;
    }

    try {
      await firebaseMessaging.subscribe(`userId--${this.state.currentUserId}`);
    } catch (error) {
      this.appLogger.error('Error while subscribing to notification ', error);
    }
  }

  public async logout(): Promise<void> {
    const firebaseMessaging = (((window.cordova || {}).plugins || {}).firebase || {}).messaging;
    if (firebaseMessaging == null) {
      return;
    }

    try {
      if (this.state.currentUserId) {
        await firebaseMessaging.unsubscribe(`userId--${this.state.currentUserId}`);
      }
      
      await firebaseMessaging.deleteToken();
      await firebaseMessaging.getToken('');
    } catch (error) {
      this.appLogger.error('Error while unsubscribing from notification or renewing firebase token ', error);
    }
  }
}