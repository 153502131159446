import { Injectable } from '@angular/core';

import { User } from '@app/model';
import { Logger } from '@app/shared';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { StateService, config } from '@app/core';

export class ProfileCollection extends DataScalar<User> {}

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends ItemService<User> {
  protected serviceName = 'ProfileService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
    super.configure('identity/v1/users');
  }

  public get collection(): ProfileCollection {
    return new ProfileCollection(this.internalItem);
  }

  public async getProfileById(userId: string, forceGet: boolean = false): Promise<any> {

    await super.getData('/internal/'+userId+"?requestToken=true",null, forceGet);

     let user : any = this._item; 
    return user;
  }

  public async getProfile(forceGet: boolean = false): Promise<void> {
    this.state.setInitialDataState('Loading Profile...');
    await super.getData('/internal/'+this.state.currentUserId+"?requestAccessToken=true",null, forceGet);
if(!this.state.isProfilePictureUploaded)
        this.state.setIsProfilePictureUploaded(this._item.fileUrl.url ? true : false);
      this.state.setSecondaryAccessToken(this._item.accessToken);  
      this.state.setInitialDataState(`Profile ${config.loadedDataIcon}`);
      
  }
}
