import { Injectable } from '@angular/core';

import { StockModel } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class StockModelCollection extends DataCollection<StockModel> {}

@Injectable({
  providedIn: 'root'
})
export class StockModelService extends ItemsService<StockModel> {
  protected serviceName = 'StockModelService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('catalog/v2/models');
  }

  public get collection(): StockModelCollection {
    return new StockModelCollection(this.internalData);
  }
}
