import { Injectable } from '@angular/core';

import { InsightsEventList, EventActivityTimeline } from '@app/model';
import { Logger } from '@app/shared';
import { DataAccessService } from '../data-access.service';

import { StateService } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class DashboardAnalyticsService {
  protected serviceName = 'DashboardAnalyticsService';

  public constructor(
    public dataAccessService: DataAccessService,
    public stateService: StateService,
    public logger: Logger
  ) {
  }

  public async getInsightsTenderList(dayNum: string, eventType: string = 'Tender'): Promise<InsightsEventList[]> {
    const params = {
      type: 'InsightsEventList',
      dayNum: dayNum,
      eventType: eventType,
      tenantCode: this.stateService.tenantCode,
      organizationId: this.stateService.organizationId,
    };
    const data = await this.dataAccessService.getData<InsightsEventList[]>(`seller/dashboard/v1/analytics`, undefined, params);
    return data;
  }

  public async getTenderActivityTimeline(tenderId: string, dateType: string): Promise<EventActivityTimeline> {
    const params = {
      type: 'EventActivityTimeline',
      dayNum: 180,
      tenantCode: this.stateService.tenantCode,
      organizationId: this.stateService.organizationId,
      eventId: tenderId,
      dateType: dateType,
    };
    const data = await this.dataAccessService.getData<EventActivityTimeline>(`seller/dashboard/v1/analytics`, undefined, params);
    return data;
  }
}
