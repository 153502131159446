import { Injectable } from '@angular/core';

import { Tender, TenderStock, Stock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

import { StateService } from '@app/core';
import { TransactionsCountService } from './transactions-count.service';
import { HttpHeaders } from '@angular/common/http';

export class TendersCollection extends DataCollection<Tender> { }

@Injectable({
  providedIn: 'root'
})
export class TendersDetailsService extends ItemsService<Tender> {
  protected serviceName = 'TenderDetailssService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService,
    private transactionsCountService: TransactionsCountService
  ) {
    super(dataAccessService, logger);
    super.configure('supervisor/tender/v2/tenders');
  }

  public get collection(): TendersCollection {
    return new TendersCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    const staticFilters = ['type$Public$0'];

    params.filters =
      params.filters && params.filters instanceof Array
        ? params.filters.some((x: string) => x.indexOf('type') > -1)
          ? params.filters
          : staticFilters.concat(params.filters)
        : staticFilters;

    await super.getData(urlPostfix, params, forceGet, options);
  }

  public async getDataById(
    id: string,
    urlPostfix: string = '',
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<Tender> {
    return await super
      .getDataById(id, urlPostfix, forceGet, options)
      .then(async (tender: Tender) => {
        if (tender) {
          // stock ids
          const stockIds = tender.stocks.map((item: TenderStock) => {
            return item.stockId;
          });

          if (stockIds.length > 0) {
            // get view count
            await this.transactionsCountService
              .getBatchViewsCount(stockIds)
              .then(() => {

                const viewCounts = this.transactionsCountService.collection.item as any;

                // replace view count
                tender.stocks = tender.stocks.map(
                  (item: TenderStock) => {
                    const details = item.details as Stock;

                    if (Object.keys(details).length > 0) {
                      const count = viewCounts.find(vcount => vcount.id == details.id)
                      details.viewCount = count ? count.count as number : 0
                      item.details = details;
                    }
                    return item;
                  }
                );
              });
          }
        }

        return tender;
      });
  }

  public async join(id: string): Promise<void> {
    const item = { id: id } as Tender;
    await this.saveItem(item, `join/${id}`, true, false, false, true);
  }
}
