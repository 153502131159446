import { Injectable } from '@angular/core';

import { AppInsights } from 'applicationinsights-js';

import { StateService } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class InsightService {
  private config: any;

  constructor(private state: StateService) {
    this.state.on(this.state.instrumentationKey$, (instrumentationKey: any) => {
      if (instrumentationKey && !AppInsights.config) {
        this.config = {
          instrumentationKey: instrumentationKey
        };

        AppInsights.downloadAndSetup(this.config);
      }
    });
  }

  public setAuthenticated(userName: string): void {
    AppInsights.setAuthenticatedUserContext(userName);
  }

  public logPageView(
    name?: string,
    url?: string,
    properties?: any,
    measurements?: any,
    duration?: number
  ) {
    properties = Object.assign({}, this.defaultProperties, properties);
    properties = JSON.stringify(properties);
    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }

  public logEvent(name: string, properties?: any, measurements?: any) {
    properties = Object.assign({}, this.defaultProperties, properties);
    properties = JSON.stringify(properties);
    AppInsights.trackEvent(name, properties, measurements);
  }

  public logException(
    exception: Error,
    handledAt?: string,
    properties?: any,
    measurements?: any
  ) {
    properties = Object.assign({}, this.defaultProperties, properties);
    properties = JSON.stringify(properties);
    AppInsights.trackException(exception, handledAt, properties, measurements);
  }

  public logTrace(message: string, properties?: any, severityLevel?: any) {
    properties = Object.assign({}, this.defaultProperties, properties);
    properties = JSON.stringify(properties);
    AppInsights.trackTrace(message, properties, severityLevel);
  }

  public logRequest(message: string, properties?: any, severityLevel?: any) {
    properties = Object.assign({}, this.defaultProperties, properties);
    properties = JSON.stringify(properties);
    AppInsights.trackRequest(message, properties, severityLevel);
  }

  // getters
  public get defaultProperties(): any {
    return {
      tenantCode: this.state.tenantCode,
      organizationId: this.state.organizationId
        ? this.state.organizationId
        : 'Unknown',
      userId: this.state.currentUserId ? this.state.currentUserId : 'Unknown'
    };
  }
}
