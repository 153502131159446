import { Identity } from './identity';
import { Tender, TenderStock } from './tender';
import { Location } from './location';
import { Offers } from './transaction';
import { Stock } from './stock';

export interface TenantAuctionConfig {
  IsEnabled: boolean;
  SignalRService: string;
}

export class AuctionBid {
  id: string;
  userId: string;
  organizationId: string;
  tenantCode: string;
  value: number;
  entityId: string;
  deletedBy: string;
  auctionId: string;
  dateTime: Date;
}

export class Auction extends Tender {
  name: string = '';
  location: Location = null;
  start: string = '';
  end: string = '';
  type: number = 0;
  isMember: boolean = false;
  status: string = '0';
  stocks: AuctionStock[] = [];
  membersIds: any[] = [];
  inspections: any[] = [];
  description: string = '';
  createdBy?: string;
  organizationId?: string;

  constructor() {
    super();
  }
}

export class AuctionStock extends TenderStock {
  minimumBidPrice: number = 0;

  constructor() {
    super();
  }
}

export class ActionModel extends Identity {
  type: string;
  dateTime: string;
}

export class AuctionBidInfo extends ActionModel {
  auctionId: string;
  winningBid: AuctionBid;
  userBid: AuctionBid;
  bidCount: number;
}

export class AuctionStatusActionModel extends ActionModel {
  auctionId: string;
  status: string;
}

export class AuctionEndTimeActionModel extends ActionModel {
  auctionId: string;
  endTime: string;
}

export class AuctionStocksStatusActionModel extends ActionModel {
  auctionId: string;
  entityIds: string[];
  status: string;
}

export class AuctionUpdateActionModel extends ActionModel {
  auctionId: string;
}

export class AuctionStocksUpdateActionModel extends ActionModel {
  auctionId: string;
  auctionStock: {
    EntityId: string;
    ReservePrice: number;
    MinimumBid: number;
    StockDetails: object;
    Status: string;
    HighestBid: AuctionBid;
  };
}

export class InvalidBidActionModel extends ActionModel {
  auctionBid: AuctionBid;
  reason: string;
}

export class CounterOfferActionModel extends ActionModel {
  auctionId: string;
  entityId: string;
  counterOffer: {
    Id: string;
    Amount: number;
    DateTime: string;
    ResponseDateTime: string;
    TargetUserId: string;
  }
}

export class CounterOfferResponseActionModel extends ActionModel {
  auctionId: string;
  entityId: string;
  decision: string;
  counterOffer: {
    Id: string;
    Amount: number;
    DateTime: string;
    ResponseDateTime: string;
    TargetUserId: string;
  }
}

export class AuctionInfo extends Identity {
  auctionId: string;
  userId: string;
  organizationId: string;
  name: string;
  startTime: Date;
  endTime: Date;
  status: string;
  auctionStocks: AuctionInfoStock[];
  webHookUrl: string;
  hideReserveMet: boolean;
}

export const AuctionInfoStatus = {
  PENDING: 'Pending',
  COUNTERED: 'Countered',
}

export class AuctionStockOffers extends Offers {
  auctionStatus: string;
  counterAmount?: number;

  constructor() {
    super();
  }
}

export const StockOffersStatus = {
  ACCEPT: 'Accept',
  REJECT: 'Reject',
  PENDING: 'Pending',
  COUNTER_OFFER: 'CounterOffer',
  COUNTER_CANCEL: 'CounterCancel',
  COUNTERED: 'Countered',
}

export class AuctionInfoStock extends Identity {
  bidCount: number;
  entityId: string;
  minimumBid: number;
  stockDetails?: AuctionStock;
  highestBid: AuctionBid;
  reservePrice: number;
  status: string;
  pendingOffer: AuctionStockOffers;
  counterOffer?: {
    id: string,
    dateTime: string,
    amount: number,
    responseDateTime: string,
    targetUserId: string,
  };
}

export const AuctionInfoStockStatus = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
  WITHDRAWN: 'Withdrawn',
  COUNTERED: 'Countered',
  ACCEPTED: 'Accepted',
  TO_BE_COUNTER_ACCEPTED: 'ToBeCounterAccepted',
  COUNTER_ACCEPTED: 'CounterAccepted',
  TO_BE_COUNTER_REJECTED: 'ToBeCounterRejected',
  COUNTER_REJECTED: 'CounterRejected',
  SOLD: 'Sold',
}

export interface AuctionStockAction {
  auctionId: string;
  actions: StockAction[];
}

export interface StockAction {
  entityId: string;
  decision: string;
  counterId?: string;
  counterAmount?: number;
}

export interface AuctionDetailItem {
  stock: Stock;
  tenderStock: TenderStock;
  auctionInfoStock: AuctionInfoStock;
}
