import { Injectable } from '@angular/core';

import { Offers, Stock, User } from '@app/model';
import { Logger } from '@app/shared';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { HttpHeaders } from '@angular/common/http';

import { SellerStocksSummaryService } from './seller-stocks-summary.service';
import { UsersService } from './users.service';

export class SellerPendingActionsCollection extends DataCollection<
  Stock
> { }

@Injectable({
  providedIn: 'root'
})
export class SellerPendingActionsService extends ItemsService<Stock> {
  protected serviceName = 'SellerPendingActionsService';
  public stocksItems: Stock[] = null;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private sellerStocksSummaryService: SellerStocksSummaryService,
    private userServices: UsersService
  ) {
    super(dataAccessService, logger);
    super.configure('seller/tender/v1/transactions');
  }

  public get collection(): SellerPendingActionsCollection {
    return new SellerPendingActionsCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {

    await super.getData('pending', params, true, options);

    let items = this.collection.items as unknown as Offers[];
    if (!(items && items.length > 0))
      return;

    // get stock ids
    const stockIds = items.map((offer: Offers) => offer.stock.stockId);

    // get distinct stocks ids
    const stocksIdsDistinct = stockIds.filter((uid, i) => stockIds.indexOf(uid) === i);

    // get stock details
    await this.sellerStocksSummaryService
      .getClientData({
        ids: stocksIdsDistinct,
        includeFeatures: true
      })
      .then(async (stocks: Stock[]) => {

        // get user info
        const creatorIds = items.map((offer: Offers) => {
          return offer.transaction.createdBy;
        });

        // get distinct user ids
        const creatorIdsDistinct = creatorIds.filter((uid, i) => creatorIds.indexOf(uid) === i);

        await this.userServices
          .getDataByIds(creatorIdsDistinct);
        const owners = this.userServices.collection.items;

        stocks.forEach((stock: Stock) => {

          let stockPendingOffers = items.map((offer: Offers) => {
            if (stock.id == offer.stock.stockId) {
              return offer;
            }
          });

          // fitler out null stock
          stockPendingOffers = stockPendingOffers.filter(offer => offer != null);
          stock.features.offers = stockPendingOffers;

          // add user to offers
          stock.features.offers.forEach((offer: Offers) => {
            const user = owners.find((user: User) => {
              return offer.transaction.createdBy === user.id;
            });
            offer.transaction.owner = user;
            offer.isOwner = true;
          });

        });

        this.stocksItems = stocks;
        // change data
        this.init({
          items: items,
          pageNumber: this.collection.pageNumber,
          totalPages: this.collection.totalPages,
          totalCount: this.collection.totalCount,
          hasPreviousPage: this.collection.hasPreviousPage,
          hasNextPage: this.collection.hasNextPage
        });

      });
  }
}
