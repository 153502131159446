import { Component, Input, OnInit } from '@angular/core';

import { NotificationService } from '@app/data';

import { StateService, config } from '@app/core';
import {
  Notification,
  NotificationChannels,
  NotificationHistorySummary,
  Organization
} from '@app/model';
import { InsightService } from '@app/insight';

import { AuthService } from '@app/auth';
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {
  @Input() isAll: boolean = false;
  @Input() notifications: NotificationHistorySummary[] = [];

  public constructor(
    public state: StateService,
    public notificationService: NotificationService,
    private insightService: InsightService,
    private authService: AuthService
  ) { }

  public ngOnInit(): void { }

  // methods
  public getBadgeClass(isRead: boolean): any {
    return { unread: !isRead };
  }

  public async onReadMessage(
    currentNotification: NotificationHistorySummary,
    isRemoveButton: boolean = false
  ): Promise<void> {
    // log insight
    this.insightService.logEvent('NotificationMarkedAsRead', {
      notification: currentNotification
    });

    // update is read
    this.notification.allNotifications = this.notification.allNotifications.map(
      (notification: any) => {
        if (notification.code === currentNotification.code) {
          notification.isRead = true;
        }

        return notification;
      }
    );

    if (!this.isAll) {
      this.notification.unReadNotifications = this.notification.allNotifications.filter(
        (notification: NotificationHistorySummary) => {
          return !notification.isRead;
        }
      );
    }

    this.notificationService.init(this.notification);

    // for details
    if (!isRemoveButton) {
      this.state.setIsShowNotificationBox(!this.state.isShowNotificationBox);
      var needReload = this.setCurrentOrgBeforeNextMove(currentNotification);

      const url = this.notificationService.getDetailsUrl(currentNotification);
      await this.state.navigateByUrl(url).then(() => {
        if (needReload)
          window.location.reload();
      });
    }

    await this.notificationService.markAsRead(
      NotificationChannels.WebNotification,
      currentNotification.code
    );
  }

  private setCurrentOrgBeforeNextMove(currentNotification: NotificationHistorySummary): boolean {
    const entityOrganizationId = currentNotification.metaData.EntityOrganizationId;
    if (entityOrganizationId == undefined || entityOrganizationId == null)
      return false;
    if (currentNotification.metaData.Action == "Offer") {
      this.state.setOrganizationId(entityOrganizationId);
      const organization = this.state.currentOrganizations.find((organization: Organization) =>
        organization.id === entityOrganizationId
      );
      this.state.setCurrentOrganization(organization);
      return true;
    }
    else if (currentNotification.metaData.Action == "Invite") {
      if (this.authService.checkOrgRoles(this.state.currentOrganization, undefined, ['BUYER']) == false) {
        var buyerOrg = this.state.currentOrganizations.find((organization: Organization) =>
          this.authService.checkOrgRoles(organization, undefined, ['BUYER'])
        );
        if (buyerOrg != undefined && buyerOrg != null) {

          this.state.setOrganizationId(buyerOrg.id);
          this.state.setCurrentOrganization(buyerOrg);
          return true;
        }
      }
    }
    return false;
  }

  public getImageUrl(item: any): string {
    return item.thumbnailUrl ? item.thumbnailUrl : item.url ? item.url : '';
  }

  public getAltText(item: any): string {
    return item.title.charAt(0);
  }

  // getters
  public get notification(): Notification {
    let notification: Notification;

    this.state.on(
      this.state.isNotificationsLoaded$,
      (isNotificationsLoaded: boolean) => {
        if (isNotificationsLoaded) {
          notification = this.notificationService.collection.item;
        }
      }
    );

    return notification;
  }
}
