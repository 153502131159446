import { Injectable } from '@angular/core';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { Logger } from '@app/shared';

import { TenderDashboard, Offers, User } from '@app/model';
import { UsersService } from './users.service';

export class TenderDashboardCollection extends DataScalar<TenderDashboard> { }

@Injectable({
  providedIn: 'root'
})
export class TenderDashboardService extends ItemService<TenderDashboard> {
  protected serviceName = 'TenderDashboardService';

  constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private userServices: UsersService
  ) {
    super(dataAccessService, logger);
  }

  public get collection(): TenderDashboardCollection {
    return new TenderDashboardCollection(this.internalItem);
  }

  public async getTenderDashboard(eventId: string, roleType: string): Promise<TenderDashboard> {

    super.configure(`${roleType}/tender/v1/transactions`);

    const urlPostfix = `event/${eventId}/dashboard`;

    return await super.getData(urlPostfix, null, true).then(async () => {
      let transactions = this.collection.item as TenderDashboard;

      if (transactions.pendingOffers && transactions.pendingOffers.length > 0) {
        // get user ids
        const creatorIds = transactions.pendingOffers.map((pendingOffer: Offers) => {
          return pendingOffer.transaction.createdBy;
        });

        // get distinct ids
        const creatorIdsDistinct = creatorIds.filter((uid, i) => creatorIds.indexOf(uid) === i);

        // get user info
        await this.userServices
        .getDataByIds(creatorIdsDistinct)
          .then(() => {
            const owners = this.userServices.collection.items;

            // add user to offers
            transactions.pendingOffers.forEach((offer: Offers) => {
              const user = owners.find((owner: User) => {
                return offer.transaction.createdBy === owner.id;
              });

              offer.transaction.owner = user;
            });

          });
      }

      return transactions;

    });
  }

}
