import { Identity } from './identity';

export class ImportStock extends Identity {
  vinNumber: string = '';
  price: number = 0;
  location: ImportStockLocation = {};
  imagesUrls: ImportStockImageUrl[] = [];
  features: ImportStockFeatures = {};
  metaData: any = {};

  // optional
  isSending?: boolean;
  isSucceeded?: boolean;
  isFailed?: boolean;
  errors?: any[];

  constructor() {
    super();
  }
}

export class ImportVinYearStock extends Identity {
  organizationId: string = '';
  userId: string = '';
  tenantCode: string = '';
  vinNumber: string = '';
  year: number = 0;
  price?: number = 0;
  engineNumber?: string = '';
  color?: string = '';
  odometer?: number = 0;
  numberOfCylinders?: number = 0;
  stockNumber?: string = '';
  registrationNumber?: string = '';
  registerationExpiryDate?: string = '';
  defaultReservePrice?: number = 0;
  location: ImportStockLocation = {};
  imagesUrls: ImportStockImageUrl[] = [];

  constructor() {
    super()
  }
}

export class ImportStockFeatures {
  year?: number = 0;
  make?: string = '';
  model?: string = '';
  badgeVariant?: string = '';
  series?: string = '';
  bodyType?: string = '';
  transmission?: string = '';
  engineNumber?: string = '';
  engineSize?: number = 0;
  fuelType?: string = '';
  seats?: number = 0;
  doors?: number = 0;
  color?: string = '';
  odometer?: number = 0;
  numberOfCylinders?: number = 0;
  stockNumber?: string = '';
  registrationNumber?: string = '';
  registerationExpiryDate?: string = '';
  defaultReservePrice?: number = 0;
  tyreDepth?: string = '';
  spareKey?: string = '';
  serviceHistory?: string = '';

  constructor() {}
}

export class ImportStockLocation {
  country?: string = '';
  state?: string = '';
  city?: string = '';
  formattedAddress?: string;

  constructor() {}
}

export class ImportStockImageUrl {
  url?: string = '';

  constructor() {}
}

export const ImportStockHeaders = [
  {
    id: 'year',
    text: 'Year',
    isRequired: true
  },
  {
    id: 'vinNumber',
    text: 'Vin Number',
    isRequired: true
  },
  {
    id: 'engineNumber',
    text: 'Engine Number',
    isRequired: false
  },
  {
    id: 'color',
    text: 'Color',
    isRequired: false
  },
  {
    id: 'odometer',
    text: 'Odometer',
    isRequired: false
  },
  {
    id: 'noOfCylinders',
    text: 'Number Of Cylinders',
    isRequired: false
  },
  {
    id: 'dealerStockNumber',
    text: 'Stock Number',
    isRequired: false
  },
  {
    id: 'registrationNumber',
    text: 'Registration Number',
    isRequired: false
  },
  {
    id: 'registrationExpiryDate',
    text: 'Registration Expiry Date',
    isRequired: false
  },
  {
    id: 'defaultReservePrice',
    text: 'Default Reserve Price',
    isRequired: false
  },
  {
    id: 'price',
    text: 'Price',
    isRequired: false
  },
  {
    id: 'imageUrl',
    text: 'Image Url',
    isRequired: false
  }
];

export enum CSVImportStockTypes {
  Customized = 'Customized',
  Basic = 'Basic',
  Standard = 'Standard'
}

export enum ImportStockTypes {
  CSV = 'CSV',
  UBS = 'Ultimate Business Solution',
  EE = 'EvalExpert',
}
