import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { DeleteConfirmationComponent } from './delete-confirmation-popup';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
declare let $: any;
@Component({
  selector: 'app-dynamic-form-editor',
  templateUrl: './dynamic-form-editor.component.html',
})
export class DynamicFormEditorComponent {
  @ViewChild(DeleteConfirmationComponent, null) deleteConfirmationComponent: DeleteConfirmationComponent;
  @ViewChild('DynamicFormComponent', null) dynamicFormComponent: DynamicFormComponent;

  @Input() addHeaderTitle?: string;
  @Input() editHeaderTitle?: string;
  @Input() alertDeleteTitle?: string;
  @Input() alertDeleteMessage?: string;
  @Input() isUser?: boolean = false;
  @Input() isEmailConfirmed?: boolean= false;
  @Input() isBackVisible: boolean = true;
  @Input() isEditing$ = new BehaviorSubject<boolean>(false);
  @Input() canEdit$ = new BehaviorSubject<boolean>(true);
  @Input() canDelete$ = new BehaviorSubject<boolean>(true);
  @Input() canCancel$ = new BehaviorSubject<boolean>(true);
  @Input() isNeedToResetForm$ = new BehaviorSubject<boolean>(false);
  @Input() isSecondSubmitVisible$ = new BehaviorSubject<boolean>(false);
  @Input() isLoadingDataVisible$ = new BehaviorSubject<boolean>(false);
  @Input() isAllFieldsReadOnly = false;

  @Input() data$ = new BehaviorSubject<any>({});
  @Input() steps$ = new BehaviorSubject<any[]>([]);

  @Output() goBack: EventEmitter<any> = new EventEmitter();
  @Output() saveCommand: EventEmitter<any> = new EventEmitter();
  @Output() secondSaveCommand: EventEmitter<any> = new EventEmitter();
  @Output() deleteCommand: EventEmitter<any> = new EventEmitter();

  public deleteItem: any;

  public onGoBack(): void {
    this.goBack.emit();
  }

  public onSaveCommand(item: any): void {
    this.saveCommand.emit(item);
  }

  public onSecondSaveCommand(item: any): void {
    this.secondSaveCommand.emit(item);
  }
  // delete question
  public onDeleteConfirmation(item: any): void {
    this.deleteItem = item;
    this.deleteConfirmationComponent.openModal();
  }

  public onDeleteCommand(): void {
    this.deleteCommand.emit(this.deleteItem);
  }
  // getters
  public get isShowHeader(): boolean {
    return this.addHeaderTitle != null || this.editHeaderTitle != null;
  }

  public get headerTitle() {
    return this.isEditing$.getValue()
      ? this.editHeaderTitle
      : this.addHeaderTitle;
  }
}
