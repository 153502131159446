import { Component, Input, OnInit, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ItemsService, DataAccessService, DynamicServiceMaps } from '@app/data';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '@app/shared';
import { StateService } from '@app/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent extends ItemsService<any> implements OnInit {
  @Input() field$ = new BehaviorSubject<any>({});
  @Input() field: any = {};
  @Input() index?: number;
  @Input() group: FormGroup;
  @Input() itemName?: string;

  public isValue = false;
  public items$ = new BehaviorSubject<any>([]);
  public isExistItems$ = new BehaviorSubject<boolean>(false);
  public values: string[] = [];

  public isLoadingDataVisible$ = new BehaviorSubject<boolean>(false);

  protected serviceName = 'CheckboxComponent';

  public dynamicService: ItemsService<any>;

  constructor(
    dataAccess: DataAccessService,
    logger: Logger,
    private state: StateService,
    private injector: Injector
  ) {
    super(dataAccess, logger);
  }

  public async ngOnInit(): Promise<void> {
    if (this.field.serviceSource != null) {
      await this.getDynamicItems();
    }

    if (this.field.dataSource != null) {
      await this.getItems();
    }

    if (
      this.field.isMultiple &&
      (this.field.value || this.field.value == '0')
    ) {
      this.values.push(this.field.value);
      this.patchValues();
    }
  }

  public async getDynamicItems(): Promise<void> {
    if (DynamicServiceMaps.hasOwnProperty(this.field.serviceSource)) {
      this.dynamicService = this.injector.get<ItemsService<any>>(
        DynamicServiceMaps[this.field.serviceSource]
      );

      // start
      this.isLoadingDataVisible$.next(true);

      await this.dynamicService.getData();

      let items = this.dynamicService.collection.items;
      this.generateList(items);

    }
  }

  public async getItems(): Promise<void> {
    // start
    this.isLoadingDataVisible$.next(true);

    const baseApiUrl = this.field.baseApiUrlKey
      ? `${this.state.environment[this.field.baseApiUrlKey]}`
      : '';

    let dataSource = `${baseApiUrl}${this.field.dataSource}`;

    // get value from state
    if (this.field.dataStateKey) {
      dataSource = `${dataSource}/${this.state[this.field.dataStateKey]}`;
    }

    super.configure(dataSource, this.field.isFullUrl);

    await this.getData('', null, true);
    let items = this.collection.items;
    this.generateList(items);
  }

  public generateList(items: any): void {
    // has inner key
    if (this.field.dataInnerKey) {
      const item = <any>items;
      items = Object.assign([], item[this.field.dataInnerKey]);
    }

    this.isExistItems$.next(items.length > 0);
    this.items$.next(items);

    // stop
    this.isLoadingDataVisible$.next(false);
  }

  public onChange(event: any, item: any) {
    const key =
      item.key || item.key == '0' ? item.key : (this.field.getObject ? item : item[this.field.dataValue]);
    if (event.target.checked) {
      this.values.push(key);
    } else {
      const index = this.values.indexOf(key);
      if (index > -1) {
        this.values.splice(index, 1);
      }
    }

    this.patchValues();
  }

  public isSelected(key: any): boolean {
    let isSelectedValue: boolean = false;
    let keys = this.group.value[this.field.name];

    if (keys || keys == '0') {
      if (keys instanceof Array) {
        keys.forEach((value: any) => {
          if (value && !isSelectedValue) {
            isSelectedValue = value === key;
          }

          if (!value && !isSelectedValue) {
            isSelectedValue = value === key;
          }
        });
      } else {
        if (!isSelectedValue) {
          isSelectedValue = (keys && keys.indexOf(key) > -1) || key == '0';
        }
      }
    }

    return isSelectedValue;
  }

  public setValues(): void {
    const valuesArray = this.group.value[this.field.name];
    if (valuesArray) {
      const values = valuesArray.map((value: any) => {
        return value;
      });

      this.values = values;
    }
  }

  public isDisabled(value: string = ''): any {
    const foundValue =
      this.field.disabledValues &&
      this.field.disabledValues.some((disable: string) => {
        return value.indexOf(disable) > -1;
      });

    return foundValue || this.field.disabled ? '' : null;
  }

  // getters
  public get isRequired() {
    return (
      this.field.validations &&
      this.field.validations.some((x: any) => x.name === 'required')
    );
  }

  // private
  private patchValues() {
    const newValues = this.mappingValues(this.values);

    this.group.patchValue({
      [this.field.name]: this.field.isArrayValues
        ? newValues
        : newValues.join(',')
    });
  }

  private mappingValues(values: any[]) {
    const key = this.itemName
      ? this.itemName
      : this.field.itemName
        ? this.field.itemName
        : null;

    if (key) {
      return values.map((value: any) => {
        let object = value;

        if (!(value instanceof Object)) {
          object = Object.assign({ [key]: value });
        }

        return object;
      });
    }

    return values;
  }

  // getters
  public get isShowLabel() {
    return this.field.label != null;
  }

  public setName(key: string) {
    const index = this.index != null ? `-${this.index}` : '';
    return `${this.field.name}-${key}${index}`;
  }

  trackByItem(index: number, item: any): any {
    if (this.field == undefined)
      return null;
    return item.key || item[this.field.dataValue];
  }

  get checkboxClass(): string {
    return this.field.checkboxType === 'inline' ? 'custom-control-inline' : '';
  }
}
