import { Component, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SharedService } from '@app/shared';
import {
  takeUntil,
  map,
  tap,
  debounceTime,
  switchMap,
  distinctUntilChanged
} from 'rxjs/operators';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { bindValidations } from '@app/validator';

import moment, { Moment } from 'moment';
import { FilterTypes } from '@app/model';
import { StateService } from '@app/core';
import { Router } from '@angular/router';
import { DisposeService } from '@app/data';
import { InsightService } from '@app/insight';

@Component({
  selector: 'app-dynamic-filter',
  templateUrl: './dynamic-filter.component.html'
})
export class DynamicFilterComponent {
  @Input() data$ = new BehaviorSubject<any>({});
  @Input() filters$ = new BehaviorSubject<any[]>([]);
  @Input() isLoadingItemsVisible: boolean = false;

  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() updateForm: EventEmitter<any> = new EventEmitter();

  @ContentChild('titleRightContent', { read: TemplateRef, static: false }) titleRightContent: any;

  public form: FormGroup;
  public unsubscribe$: Subject<void> = new Subject();
  public isFormCreated$ = new BehaviorSubject<boolean>(false);
  public defaultValues: any;

  constructor(
    public shared: SharedService,
    public formBuilder: FormBuilder,
    public state: StateService,
    public router: Router,
    public disposeService: DisposeService,
    private insightService: InsightService
  ) { }

  public ngOnInit(): void {
    this.state.on(this.filters$, (filters: any) => {
      if (Object.keys(filters).length > 0) {
        this._getFields();

        // change
        this.form.valueChanges.subscribe((value: any) => {
          const hasNull = Object.keys(value.filters).some((key: string) => {
            return value.filters[key] == null;
          });

          if (!hasNull && !this.defaultValues) {
            this.defaultValues = value;
          }
        });
      }
    });
  }

  public toggleFilter() {
    this.state.setFilterToggled(!this.state.isFilterToggled);

    // set class for mobile view
    if (this.state.isFilterToggled && this.state.isMobileResolution) {
      if (!document.body.classList.contains('show-popup')) {
        document.body.classList.add('show-popup');
      }
    } else if (document.body.classList.contains('show-popup')) {
      document.body.classList.remove('show-popup');
    }
  }

  public onSearch(event: Event) {
    event.preventDefault();

    if (this.form.valid) {
      // log insight
      this.insightService.logEvent('FilterClicked', this.value);

      this.toggleFilter();
      this.search.emit(this.value);
    } else {
      event.stopPropagation();
      this._validateAllFormFields(this.form);
    }
  }

  public async onReset(): Promise<void> {
    await this.state.navigateByUrl(this.router.url.split('?')[0]);
    window.location.reload();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getForm4Object(name: string): FormGroup {
    return <FormGroup>this.form.controls[name];
  }

  public setForm4Buttons(filter: any): FormGroup {
    return filter.type === 'object'
      ? this.getForm4Object(filter.name)
      : this.form;
  }

  // getters
  public get value(): any {
    let value: any = {};

    // get values in filters
    value = Object.assign(value, this.getRealValues());
    return value;
  }

  public getRealValues() {
    const exceptValues = this.filters.exceptValues;
    const value = this.form.value;

    return Object.keys(value).reduce((result, key) => {
      result[key] = this.filterValue(value[key], exceptValues);
      return result;
    }, {});
  }

  public filterValue(value: any, exceptValues: any): any {
    const isExceptValuesNull = exceptValues == null;

    return Object.keys(value)
      .filter(
        mainKey =>
          isExceptValuesNull || !exceptValues.includes(mainKey)
      )
      .reduce((object, key) => {
        object[key] = this.setFilterType(value, key, value[key]);
        return object;
      }, {});
  }

  public setFilterType(parent: any, key: string, value: any): any {
    outerLoop: for (const field of this.fields) {
      if (field.type === 'object') {
        for (const index of Object.keys(field.object)) {
          const item = field.object[index];

          if (item.cascadeControls) {
            for (const element of item.cascadeControls) {
              if (element.name === key) {
                value = this.setValues(parent, element, key, value);
                break outerLoop;
              }
            }
          } else {
            value = this.setValues(parent, item, key, value);
          }
        }
      }
    }

    return value;
  }

  public setValues(parent: any, item: any, key: any, value: any): any {
    if (item.name === key) {
      // array
      if (value instanceof Array) {
        // optional
        if (item.filterType === FilterTypes.Optional || item.filterType === FilterTypes.Keyword) {
          value = value
            .filter(this.hasValue)
            .map((element: any) => `${element}$${item.filterType}`);
        }

        // range
        if (item.filterType === FilterTypes.Range) {
          const values = value as Array<any>;

          if (values.some(moment.isMoment)) {
            value = `${this.convertDate2UTC(values[0])},${this.convertDate2UTC(values[1])}$${item.filterType}`;
          } else {
            value = `${value}$${item.filterType}`;
          }
        }
      }
      // date
      else if (moment.isMoment(value)) {
        const dateTime = this.convertDate2UTC(value);

        // start and end
        if (item.endValueName) {
          let endDateTime = this.convertDate2UTC(moment("2099-01-01T00:00:00.000Z"));
          if (parent[item.endValueName] != undefined) {
            endDateTime = this.convertDate2UTC(parent[item.endValueName]);
          }

          value = `${dateTime},${endDateTime}$${item.filterType}`;
        }
        // end and start
        else if (item.startValueName) {
          let startDateTime = this.convertDate2UTC(moment("1900-01-01T00:00:00.000Z"));
          if (parent[item.startValueName] != undefined) {
            startDateTime = this.convertDate2UTC(parent[item.startValueName]);
          }
          value = `${startDateTime},${dateTime}$${item.filterType}`;
        }
        // only date
        else {
          value = `${dateTime}$${item.filterType}`;
        }
      }
      // others
      else if (this.hasValue(value)) {
        // keyword
        if (item.filterType === FilterTypes.Keyword) {
          value = item.keywords.map((keyword: any) => `${keyword}$${value}$${item.filterType}`);
        } else {
          value = `${value}$${item.filterType}`;
        }
      }
    }

    return value;
  }

  public convertDate2UTC(value: any): any {
    const formattedValue = value.format();
    return (value.isUTC() ? value : moment.utc(formattedValue)).format();
  }

  public hasValue(value: any): boolean {
    return (value && value != 'all') || value == '0';
  }

  // getters
  public get filters(): any {
    return this.filters$.getValue();
  }

  public get fields(): any[] {
    return this.filters.fields;
  }

  public get form4Filters(): FormGroup {
    return <FormGroup>this.form.controls['filters'];
  }

  // privates
  private _getFields(): void {
    this.filters$
      .pipe(
        switchMap(form => {
          this.form = this._createForm(form);
          this._listenFormChanges(this.form);
          return this.data$;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this._patchValue);
  }

  private _createForm = (filters: any): FormGroup => {
    const group: FormGroup = filters.fields.reduce((group: FormGroup, filter: any) => {
      group.addControl(filter.name, filter.type === 'object'
        ? this._object(filter.object)
        : this._control(filter));
      return group;
    }, new FormGroup({}));

    this.isFormCreated$.next(true);
    return group;
  };

  private _control = (filter: any): FormControl => {
    return this.formBuilder.control(
      filter.value,
      bindValidations(filter.validations || [])
    );
  };

  private _object = (object: any): FormGroup => {
    return this._addChildren(object);
  };

  private _addChildren = (children: any): FormGroup => {
    return children.reduce((group: FormGroup, child: any) => {
      group.addControl(child.name, this._control(child));
      return group;
    }, new FormGroup({}));
  };

  private _listenFormChanges(form: FormGroup): void {
    form.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((changes: any) => this.updateForm.emit(changes));
  }

  private _patchValue = (data: any): void => {
    data = data instanceof Array && data.length > 0 ? data[1] : data;

    if (data) {
      data = Object.keys(data).reduce((newData: any, key: string) => {
        const field = data[key];

        if (field.length > 0 && key === 'filters') {
          const values = field instanceof Array ? field : [field];

          newData[key] = values.map((value: string, index: number) => {
            const filters = value.split('$');

            // keyword
            if (filters[2] === FilterTypes.Keyword && index === 0 && this._isInputKeyword(filters[0])) {
              return { keyword: filters[1] };
            }

            return value;
          });
        }

        return newData;
      }, {});
    }

    this.form.patchValue(data || {}, { emitEvent: false });
  };

  private _isInputKeyword = (value: string): boolean => {
    return this.filters.fields.some(field =>
      field.object.some(obj =>
        obj.type == 'input' && obj.keywords.some(s => s.includes(value))
      )
    );
  }

  private _validateAllFormFields(formGroup: FormGroup) {
    for (const control of Object.values(formGroup.controls)) {
      control.markAsTouched({ onlySelf: true });
    }
  }
  private _setRedirectUrl(): void {
    const currentRoute = this.router.url.split('?')[0];

    const hasPermission =
      this.state.isAdminApp ||
      ((!this.state.isAdminApp && currentRoute.includes('dashboard')) ||
        (currentRoute.includes('manage') && this.state.isAdmin) ||
        (currentRoute.includes('sell') && this.state.isSeller) ||
        (currentRoute.includes('buy') && this.state.isBuyer));

    // save redirect url
    this.state.setRedirectUrl(hasPermission ? currentRoute : '');
  }
}
