import { Injectable } from '@angular/core';

import { AuctionStock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class SellerStockTendersCollection extends DataCollection<AuctionStock> {}

@Injectable({
  providedIn: 'root'
})
export class SellerStockTendersService extends ItemsService<AuctionStock> {
  protected serviceName = 'SellerStockTendersService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger
  ) {
    super(dataAccessService, logger);
    super.configure('seller/tender/v2/tenders');
  }

  public setAccessLevel(
    accessLevel: string
  ) {
    super.configure(`${accessLevel}/tender/v2/tenders`);
  }

  public get collection(): SellerStockTendersCollection {
    return new SellerStockTendersCollection(this.internalData);
  }

  public async getTenderStocks(tednerId: string, pageNumber : number = 1, pageSize : number = 1, stockIds : string[] = null): Promise<void> {

    const urlPostfix = `${tednerId}/stocks`;
    let params: any = {
      pageSize: pageSize,
      pageNumber: pageNumber
    };

    if(stockIds){
      let filters : string[] = [];
      stockIds.forEach(stockId => {
        filters.push(`stockid$${stockId}$1`)
      });
      params.filters = filters;
    }
    await super.getData(urlPostfix, params, true);
  }

}
