import { Injectable } from '@angular/core';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { Logger } from '@app/shared';

import { Hub } from '@app/model';

export class TenantHubChatCollection extends DataScalar<Hub> {}

@Injectable({
  providedIn: 'root'
})
export class TenantHubChatService extends ItemService<Hub> {
  protected serviceName = 'TenantHubChatService';

  constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('tenant/v1/specs');
  }

  public get collection(): TenantHubChatCollection {
    return new TenantHubChatCollection(this.internalItem);
  }

  public async getChatKeys(): Promise<void> {
    await this.getData('services.chat.keys');
  }
}
