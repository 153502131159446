import { Injectable } from '@angular/core';

import { Invoice } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class InvoiceCollection extends DataCollection<Invoice> { }

import { HttpHeaders } from '@angular/common/http';
import { StateService } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends ItemsService<Invoice> {
  protected serviceName = 'InvoiceService';
  public invoice: any;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService

  ) {
    super(dataAccessService, logger);
    super.configure('api/v1/payment/retrieveinvoice');

  }

  public get collection(): InvoiceCollection {
    return new InvoiceCollection(this.internalData);
  }


  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {

    let staticParams = {
      organizationId: this.state.organizationId,
      tenantCode: this.state.tenantCode,
    }
    params = { ...params, ...staticParams };

    await super.getData(urlPostfix, params, forceGet, options).then
      (async () => {
        const collection = this.collection;

        let items = collection.items;

        if (items.length > 0) {

          const data = {
            items: items,
            pageNumber: params.pageNumber,
            totalPages: collection.totalPages,
            totalCount: collection.totalCount,
            hasPreviousPage: params.pageNumber > 1,
            hasNextPage: params.pageNumber < collection.totalPages,
            pageSize : params.pageSize
          };

          this.init(data);
        }
      });
  }
}
