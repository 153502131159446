import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dynamic-data-editor',
  templateUrl: './dynamic-data-editor.component.html'
})
export class DynamicDataEditorComponent {
  @Input() data: any;
  @Input() parentId: any;
  @Input() isDeleting: boolean;
  @Input() selectedItem: any;

  @Output() deleteItem: EventEmitter<any> = new EventEmitter();
  @Output() deleteItemCommand: EventEmitter<any> = new EventEmitter();
  @Output() cancelDelete: EventEmitter<any> = new EventEmitter();

  public onDeleteItem() {
    this.deleteItem.emit();
  }

  public onDeleteItemCommand() {
    this.deleteItemCommand.emit();
  }

  public onCancelDelete() {
    this.cancelDelete.emit();
  }

  // getters
  public get isSelectedRow(): boolean {
    return this.selectedItem && this.data === this.selectedItem;
  }

  public get getEditRouter(): any[] {
    return this.parentId
      ? ['edit', `${this.selectedItem.id}`, `${this.parentId}`]
      : ['edit', `${this.selectedItem.id}`];
  }
}
