import { Injectable } from '@angular/core';

import { Stock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { HttpHeaders } from '@angular/common/http';

export class BuyerStocksCollection extends DataCollection<Stock> { }

@Injectable({
  providedIn: 'root'
})
export class BuyerStocksService extends ItemsService<Stock> {
  protected serviceName = 'BuyerStocksService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger
  ) {
    super(dataAccessService, logger);
    super.configure('buyer/catalog/v1/client/stocks');
  }

  public get collection(): BuyerStocksCollection {
    return new BuyerStocksCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    if (this._data && !forceGet)
      return;
    try {
      const response = await this.dataAccess.getData<any>(
        this.uri,
        urlPostfix,
        params,
        this.isFullUrl,
        options
      );
      this.init(response);
    } catch (error) {
      console.error(`BuyerStocksService getAndValidateData ${JSON.stringify(this.uri)} failed`, error);
      throw error;
    }
  }

  public async getDataById(
    id: string,
    urlPostfix: string = '',
    forceGet: boolean = false,
    options?: HttpHeaders,
    params: any = {}
  ): Promise<Stock | undefined> {
    if (this._data && !forceGet) {
      const cachedItem = this.collection.getItemById(id);
      if (cachedItem) {
        return cachedItem;
      }
    }

    const uri = 'buyer/catalog/v3/client/stocks';
    try {
      const data = await this.dataAccess.getDataById<Stock>(
        id,
        uri,
        urlPostfix,
        this.isFullUrl,
        options,
        params
      );

      return data;
    } catch (error) {
      console.error(`getDataById ${uri} failed`);
      console.error(error);
      throw error;
    }
  }
}