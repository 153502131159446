import { OnInit, OnDestroy, EventEmitter, HostListener } from '@angular/core';

import { SharedService } from '@app/shared';

import { TranslateService } from '@app/translate';
import { DisposeService, PublicTenantV2Service } from '@app/data';

import { Router, ActivatedRoute } from '@angular/router';

import { StateService } from '@app/core';
import { Tenant } from '@app/model';
import { InsightService } from '@app/insight';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import smartlookClient from 'smartlook-client';

export abstract class BaseComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const width = event.target.innerWidth;
    // check mobile view
    this.state.setIsMobileResolution(width < 1025);
  }

  private initialized = false;

  public constructor(
    public shared: SharedService,
    public translate: TranslateService,
    public publicTenantService: PublicTenantV2Service,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public state: StateService,
    public disposeService: DisposeService,
    public insightService: InsightService
  ) {}

  public async ngOnInit(): Promise<void> {
    if (this.initialized) {
      console.error(
        `${this.constructor.name} overrides ngOnInit() without calling super.ngOnDestroy()`
      );
    }
    this.setupChangingRouter();

    // get tenant info from server
    await this.getTenantInfo();
    await this.getTenantInstrumentationKey();
    this.initialized = true;
  }

  public ngOnDestroy(): void {
    if (!this.initialized) {
      console.error(
        `${this.constructor.name} overrides ngOnDestroy() without calling super.ngOnInit()`
      );
    }

    this.initialized = false;

    this.destroy$.next();
    this.destroy$.complete();

    // destroy sub menu extend
    this.state.isSubMenuExtended = false;
    this.state.setIsShowNotificationBox(false);
    this.state.setSubMenuExtended(this.state.isSubMenuExtended);
  }

  public goBack(countParent: number = 1): void {
    const activatedParent = this.activatedRoute.parent;

    const relativeTo =
      countParent === 3
        ? activatedParent.parent.parent
        : countParent === 2
        ? activatedParent.parent
        : activatedParent;

    // stop loading
    this.state.setIsLoadingButtonVisible(false);

    // redirect
    this.router.navigate(['.'], {
      relativeTo: relativeTo
    });
  }

  public navigate(newUrl: string): void {
    this.router.navigate([newUrl]);
  }

  public scrollTop() {
    window.scrollTo(0, 0);
  }

  public hasEvent(event: EventEmitter<any>): boolean {
    return event.observers.length === 0;
  }

  public onMenuToggled(): void {
    this.state.setMenuToggled(!this.state.isMenuToggled);
  }

  public onSubMenuExtended(): void {
    this.state.setSubMenuExtended(true);
  }

  public getSubMenuExtended(url: string): boolean {
    return this.router.url.indexOf(url) > -1;
  }

  public getRoute(route: string = ''): string {
    return `/${this.state.tenantCode}/${route}`;
  }

  public setupChangingRouter() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      this.scrollTop();

      // mobile view
      if (this.state.isMobileResolution) {
        // change state
        this.state.setMenuToggled(false);
        this.state.setIsShowNotificationBox(false);
      }
    });
  }

  public initSmartLookAnalytics(): void {
    // smartlook video recording analytics
    if (
      this.state.tenantInfo &&
      this.state.tenantInfo.analytics &&
      this.state.tenantInfo.analytics.smartlook &&
      !smartlookClient.initialized()
    ) {
      smartlookClient.init(
        this.state.tenantInfo.analytics.smartlook.smartLookProjectKey
      );
    }
  }

  public async getTenantInfo(): Promise<void> {
    await this.publicTenantService.initData();
    let interval = setInterval(() => {
      this.initSmartLookAnalytics();
      clearInterval(interval);
    }, 500);
  }

  public async getTenantInstrumentationKey(): Promise<void> {
    await this.publicTenantService.getInstrumentationKey();
  }

  public get backgroundUrl(): any {
    let backgroundImageStyle = {};

    this.state.on(this.state.tenantInfo$, (tenantInfo: Tenant) => {
      tenantInfo = tenantInfo ? tenantInfo : this.state.tenantInfo;

      if (tenantInfo && tenantInfo.defaultImages) {
        backgroundImageStyle = {
          'background-image': `url(${ tenantInfo.defaultImages.branding.backgroundImage})`
        };
      }
    });

    return backgroundImageStyle;
  }
}
