import { Router, ActivatedRoute } from '@angular/router';

import { SharedService } from '@app/shared';
import { TranslateService } from '@app/translate';
import { DisposeService, PublicTenantV2Service } from '@app/data';

import { BaseComponent } from './base.component';
import { StateService } from '@app/core';
import { InsightService } from '@app/insight';

export abstract class BaseDetailsComponent extends BaseComponent {
  public item: any = {};
  public isLoadingItemVisible: boolean = false;

  public constructor(
    shared: SharedService,
    translate: TranslateService,
    publicTenantService: PublicTenantV2Service,
    router: Router,
    activatedRoute: ActivatedRoute,
    state: StateService,
    disposeService: DisposeService,
    insightService: InsightService,
    public itemsService: any
  ) {
    super(
      shared,
      translate,
      publicTenantService,
      router,
      activatedRoute,
      state,
      disposeService,
      insightService
    );
  }
  public async ngOnInit(isGetItem: boolean = true): Promise<void> {

    if (isGetItem) {
      await this.getItem();
    }
    await super.ngOnInit();
  }

  public async getItem(
    parentId: string = '',
    urlPostfix: string = '',
    forceGet: boolean = false
  ): Promise<void> {
    // start loading
    this.isLoadingItemVisible = true;
    this.item = {};
    const innerId = this.shared.params.get('innerId');
    const id = innerId ? innerId : this.shared.params.get('id');

    this.item = await this.itemsService
      .getDataById(id, urlPostfix, forceGet);

    if (this.item && parentId) {
        const parentIdValue = this.shared.params.get(parentId);
        if (parentIdValue) {
          this.item[parentId] = parentIdValue;
        }
    }

    // stop loading
    this.isLoadingItemVisible = false;

  }

  // getters
  public get isEmpty(): boolean {
    return this.item && Object.keys(this.item).length === 0;
  }

}
