import { Identity } from "./identity";

export class Payment  extends Identity {
  tenantCode: string;
  key:        string;
  details:    Details;

  constructor() {
    super();
  }
}

export class Details {
  Provider:              string;
  Currency:              string;
  StockTenderListingFee: string;
  TransactionFee:        string;
  ReceiptWebHookPath:    string;
  ApiKey:                string;
  PaymentWebHookApiKey:  string;
  ChargeWebHookApiKey:   string;
  TaxPct:                string;
  TaxDisplayName:        string;
  TierPricing:           TierPricing[];

  constructor() {
  }
}

export class TierPricing {
  Transaction: number;
  Fee:         number;

  constructor() {
  }
}
