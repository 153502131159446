import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TenantConfigService {

  // list of tenants
  public static carZapp: string = 'CarZapp';
  public static avisDirect: string = 'AvisDirect';
  public static picklesAsia: string = 'PicklesAsia';

  public static clientVersion(tenantCode: string): string {
    switch (tenantCode) {
      case this.avisDirect: {
        return '1.27.0';
      }
      default:
        return '5.32.0';
    }
  }

  public static adminVersion(tenantCode: string): string {
    switch (tenantCode) {
      case this.avisDirect: {
        return '1.27.0';
      }
      default:
        return '5.32.0';
    }
  }
}
