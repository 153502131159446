import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import {
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';

import { SharedService } from '@app/shared';
import { TranslateService } from '@app/translate';
import { DisposeService, PublicTenantV2Service } from '@app/data';

import { DynamicFormEditorComponent } from '@app/dynamics';
import { BaseComponent } from './base.component';
import { StateService } from '@app/core';
import { InsightService } from '@app/insight';

export abstract class BaseEditorComponent extends BaseComponent
  implements AfterViewInit {
  @ViewChild(DynamicFormEditorComponent, { read: false, static: false })
  formEditor: DynamicFormEditorComponent;

  // dynamic forms
  public data$ = new BehaviorSubject<any>({});
  public isEditing?: boolean = null;
  public canEdit: boolean = true;
  public canDelete: boolean = true;
  public canCancel: boolean = true;
  public isNeedToResetForm: boolean = false;
  public isSecondSubmitVisible: boolean = false;
  public isLoadingDataVisible: boolean = false;
  public isBackVisible: boolean = true;

  public constructor(
    shared: SharedService,
    translate: TranslateService,
    publicTenantService: PublicTenantV2Service,
    router: Router,
    activatedRoute: ActivatedRoute,
    state: StateService,
    disposeService: DisposeService,
    insightService: InsightService,
    public changeDetectorRef: ChangeDetectorRef,
    public itemsService: any,
    public formStepsData: any
  ) {
    super(
      shared,
      translate,
      publicTenantService,
      router,
      activatedRoute,
      state,
      disposeService,
      insightService
    );
  }

  public ngAfterViewInit(
    setEditorForm: boolean = true,
    isSetFormEditorInitData: boolean = true
  ) {
    this._setIsEditing();
    if (isSetFormEditorInitData) {
      this.setFormEditorInitData();
    }

    if (setEditorForm) {
      this.setEditorForm();
    }
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
      }
  }

  public async setEditorForm(
    parentId: string = '',
    urlPostfix: string = '',
    forceGet: boolean = false
  ): Promise<any> {
    let item = await this.getData4EditingForm(parentId, urlPostfix, forceGet);
    if (item) {
          return this.setData4EditingForm(item);
        } else {
          return this.setFormEditorParameters(true);
        }
      }

  public async setData4EditingForm(data: any) {
    this.data$.next(data);
    this.setFormEditorParameters(true);
  }

  public async getData4EditingForm(
    parentId: string = '',
    urlPostfix: string = '',
    forceGet: boolean = false
  ): Promise<any> {
    const innerId = this.shared.params.get('innerId');
    const id = innerId ? innerId : this.shared.params.get('id');

    if (this.isEditing) {
      const item = await this.itemsService.getDataById(
        id,
        urlPostfix,
        forceGet
      );

      if (item) {
        if (parentId) {
          const parentIdValue = this.shared.params.get(parentId);
          if (parentIdValue) {
            item[parentId] = parentIdValue;
          }
        }
        return item;
      }
    }

    return null;
  }

  public async saveCommand(
    item: any,
    urlPostfix: string = '',
    isPut: boolean = false,
    isReturnNewResponse: boolean = true
  ): Promise<void> {
    return await this.itemsService
      .saveItem(item, urlPostfix, isPut, isReturnNewResponse)
      .then(() => {
        // log insight
        this.insightService.logEvent(
          `${this.formStepsData.module}${
            this.isEditing || isPut ? 'Edited' : 'Added'
          }`
        );

        this.goBack();
      });
  }

  public async secondSaveCommand(
    item: any,
    urlPostfix: string = '',
    isPut: boolean = false,
    isReturnNewResponse: boolean = true
  ): Promise<void> {
    return await this.itemsService
      .saveItem(item, urlPostfix, isPut, isReturnNewResponse)
      .then(() => {
        // log insight
        this.insightService.logEvent(
          `${this.formStepsData.module}${
            this.isEditing || isPut ? 'Edited' : 'Added'
          }`
        );

        this.goBack();
      });
  }

  public async deleteCommand(
    item: any,
    params: any = {},
    isRemoveItem: boolean = true,
    showLogger: boolean = true
  ): Promise<void> {
    return await this.itemsService
      .deleteItem(item.id, params, isRemoveItem, showLogger)
      .then(() => {
        // log insight
        this.insightService.logEvent(`${this.formStepsData.module}Deleted`);

        this.goBack();
      });
  }

  public setFormEditorInitData() {
    if (this.formEditor) {
      this.formEditor.isEditing$.next(this.isEditing);
      this.formEditor.canEdit$.next(this.canEdit);
      this.formEditor.canDelete$.next(this.canDelete);
      this.formEditor.isBackVisible = this.isBackVisible;
      this.formEditor.canCancel$.next(this.canCancel);
      this.formEditor.isNeedToResetForm$.next(this.isNeedToResetForm);
      this.formEditor.isSecondSubmitVisible$.next(this.isSecondSubmitVisible);
    }
  }

  public setFormEditorParameters(isLoadingDataVisible: boolean) {
    this.isLoadingDataVisible = isLoadingDataVisible;

    if (this.formEditor) {
      // inputs
      this.formEditor.steps$.next(this.formStepsData.steps);
      this.formEditor.data$.next(this.data$.getValue());
      this.formEditor.isLoadingDataVisible$.next(this.isLoadingDataVisible);

      // outputs
      this._setEvent(this.formEditor.goBack, 'goBack');
      this._setEvent(this.formEditor.saveCommand, 'saveCommand');
      this._setEvent(this.formEditor.secondSaveCommand, 'secondSaveCommand');
      this._setEvent(this.formEditor.deleteCommand, 'deleteCommand');

      if (!this.changeDetectorRef['destroyed']) {
        this.changeDetectorRef.detectChanges();
        }
    }
  }

  // privates
  private _setIsEditing() {
    this.isEditing =
      this.isEditing == null ? this.shared.editing : this.isEditing;
  }

  private _setEvent(emitter: EventEmitter<any>, name: string): void {
    if (this.hasEvent(emitter)) {
      this.state.on(emitter, (event: any) => {
        switch (name) {
          case 'saveCommand':
            event = this.saveCommand(event);
            break;

          case 'secondSaveCommand':
            event = this.secondSaveCommand(event);
            break;

          case 'deleteCommand':
            event = this.deleteCommand(event);
            break;

          case 'goBack':
            event = this.goBack();
            break;
        }
      });
    }
  }
}
