import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

declare let $: any;
@Component({
    selector: 'app-delete-confirmation',
    templateUrl: './delete-confirmation-popup.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteConfirmationComponent {

    @Input() alertDeleteTitle?: string;
    @Input() alertDeleteMessage?: string;

    @Output() onDelete: EventEmitter<any> = new EventEmitter();

    public get deleteTitle() {
        return this.alertDeleteTitle
            ? this.alertDeleteTitle
            : 'Delete Confirmation';
    }

    public onDeleteCommand(): void {
        this.onDelete.emit();
    }

    public openModal(): void {
        $('#deleteModal').modal({ keyboard: false });
    }

    public get deleteMessage() {
        return this.alertDeleteMessage
            ? this.alertDeleteMessage
            : 'Are you sure you want to delete this item?';
    }
}
