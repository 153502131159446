import { Injectable } from '@angular/core';

import { EventTypes, EventTypesAction, EventTypesText, EventTypesTransactionType, Transaction } from '@app/model';
import { Logger } from '@app/shared';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { InsightService } from '@app/insight';

@Injectable({
  providedIn: 'root'
})
export class TransactionsAuctionService extends ItemsService<Transaction> {
  protected serviceName = 'TransactionsAuctionService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    public insightService: InsightService
  ) {
    super(dataAccessService, logger);
    super.configure('transaction/v1/OrchestrationFunction_HttpStart');
  }

  public async saveTransaction(description: string, eventType: EventTypes, values: any[],
    entityId: string, eventId: string, entityOrganizationId: string): Promise<void> {

    const transaction: Transaction = {
      id: null,
      Name: EventTypesAction[eventType],
      Payload: {
        eventId: eventId,
        entityId: entityId,
        entityOrganizationId: entityOrganizationId,
        description: description,
        parentId: null,
        typeText: '',
        type: EventTypesTransactionType[eventType],
        values: values
      }
    };

    await Promise.all([this.saveItem(
      transaction,
      null,
      false,
      false,
      false,
      false
    ), this.insightService.logEvent(EventTypesText[eventType], transaction)]);
  }
}
