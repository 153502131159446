import { Injectable } from '@angular/core';

import { Chat } from '@app/model';
import { Logger } from '@app/shared';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { StateService } from '@app/core';
import { HttpHeaders } from '@angular/common/http';

export class ChatsCollection extends DataCollection<Chat> {}

@Injectable({
  providedIn: 'root'
})
export class ChatsService extends ItemsService<Chat> {
  protected serviceName = 'ChatsService';

  public constructor(dataAccessService: DataAccessService, logger: Logger,
    private state: StateService,) {
    super(dataAccessService, logger);
     super.configure('chat/v1/chat');
  }

  public get collection(): ChatsCollection {
    return new ChatsCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    if (this._data && !forceGet)
      return;
    try {
      const response = await this.dataAccess.getData<any>(
        this.uri,
        urlPostfix,
        params,
        this.isFullUrl,
        options
      );
      this.init(response);
    } catch (error) {
      console.error(`ChatService getAndValidateData ${JSON.stringify(this.uri)} failed`, error);
      throw error;
    }
  }

  public async getDataById(
    id: string,
    urlPostfix: string = '',
    forceGet: boolean = false,
    options?: HttpHeaders,
    params: any = {}
  ): Promise<Chat> {
    if (this._data && !forceGet) {
      return <Chat>this.collection.getItemById(id);
    } else {
      try {
        const data = await this.dataAccess.getDataById<Chat>(
          id,
          this.uri,
          urlPostfix,
          this.isFullUrl,
          options,
          params
        );
  
        return <Chat>data;
      } catch (error) {
        throw new Error(`ChatService getAndValidateData ${JSON.stringify(this.uri)} failed: ${error}`);
      }
    }
  }

  public async getChats(
    entityType: string = '',
    entityId: string = '',
    params: any = {},
    forceGet: boolean = false
  ): Promise<void> {
    const urlPostfix = `type/${entityType}/entity/${entityId}`;
    await super.getData(urlPostfix, params, forceGet);
  }

  public async send(item: Chat): Promise<void> {
    await super.saveItem(item, `send/${this.state.tenantCode}`, false, false, false, true);
  }

  public async saveNote(item: Chat): Promise<void> {
    await super.saveItem(item, 'send', false, false, false, true);
  }
}
