import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { config } from '@app/core';
import { PipeModule } from '@app/pipe';

import { ShowMoreListComponent } from './show-more-list.component';
import { BackComponent } from './back.component';
import { GoogleMapComponent } from './google-map.component';
import { UserImageComponent } from './user-image.component';
import { NotificationComponent } from './notification.component';
import { NotificationPopupComponent } from './notification-popup.component';
import { FollowUserComponent } from './follow-user.component';
import { InitialLoaderComponent } from './initial-loader.component';
import { LoadingComponent } from './loading.component';
import { BrandingImageComponent } from './branding-image.component';
import { EnvironmentNameComponent } from './environment-name.component';
import { EnvironmentVersionComponent } from './environment-version.component';
import { AddressLinkComponent } from './address-link.component';
import { TelLinkComponent } from './tel-link.component';
import { EmailLinkComponent } from './email-link.component';
import { AlertComponent } from './alert.component';
import { RefreshIconComponent } from './refresh-icon.component';
import { BackIconComponent } from './back-icon.component';

@NgModule({
  imports: [
    CommonModule,
    PipeModule,
    RouterModule,
    AgmCoreModule.forRoot({ apiKey: config.googleApiKey })
  ],
  declarations: [
    ShowMoreListComponent,
    BackComponent,
    GoogleMapComponent,
    UserImageComponent,
    NotificationComponent,
    NotificationPopupComponent,
    FollowUserComponent,
    InitialLoaderComponent,
    LoadingComponent,
    BrandingImageComponent,
    EnvironmentNameComponent,
    EnvironmentVersionComponent,
    AddressLinkComponent,
    EmailLinkComponent,
    TelLinkComponent,
    AlertComponent,
    RefreshIconComponent,
    BackIconComponent
  ],
  exports: [
    ShowMoreListComponent,
    GoogleMapComponent,
    BackComponent,
    UserImageComponent,
    NotificationComponent,
    NotificationPopupComponent,
    FollowUserComponent,
    InitialLoaderComponent,
    LoadingComponent,
    BrandingImageComponent,
    EnvironmentNameComponent,
    EnvironmentVersionComponent,
    AddressLinkComponent,
    EmailLinkComponent,
    TelLinkComponent,
    AlertComponent,
    RefreshIconComponent,
    BackIconComponent
  ]
})
export class UtilitiesModule {}
