import { Injectable } from '@angular/core';

import { Stock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { HttpHeaders } from '@angular/common/http';

export class OrganizationStocksCollection extends DataCollection<Stock> {}

@Injectable({
  providedIn: 'root'
})
export class OrganizationStocksService extends ItemsService<Stock> {
  protected serviceName = 'OrganizationStocksService';
  public organizationId : string;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger
  ) {
    super(dataAccessService, logger);
    super.configure(`supervisor/catalog/v1/stocks`);
  }

  public get collection(): OrganizationStocksCollection {
    return new OrganizationStocksCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {

    let localUrlPostfix = `organization/${this.organizationId}`
    await super.getData(localUrlPostfix, params, true, options);
  }

  public async getOrgAvailableStocks(organizationId: string, pageNumber : number = 1, pageSize : number = 1): Promise<void> {
    const urlPostfix = `organization/${organizationId}`;
    const params = {
      filters: 'status$Available$0',
      pageSize: pageSize,
      pageNumber: pageNumber
    };

    await super.getData(urlPostfix, params, true);
  }
}
