import { Injectable } from '@angular/core';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { Logger } from '@app/shared';

import { Tenant, InstrumentationKey } from '@app/model';
import { HttpHeaders } from '@angular/common/http';
import { StateService } from '@app/core';

export class PublicTenantCollection extends DataScalar<any> { }

@Injectable({
  providedIn: 'root'
})
export class PublicTenantService extends ItemService<any> {
  protected serviceName = 'PublicTenantService';

  public readonly isServiceReady = $.Deferred();

  constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
    super.configure('public/tenant/v1/specs');
  }

  public get collection(): PublicTenantCollection {
    return new PublicTenantCollection(this.internalItem);
  }

  public async getEmailTargets(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    await super.getData('services.notification.emailtargets', '', true);
  }

  public async getInstrumentationKey(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    if(!(this.state.tenantCode && !this.state.instrumentationKey))
    return;
      await super.getData('client.instrumentationKeys', '', true);
        const item = this.collection.item as InstrumentationKey;
        if (item && item.WebAdmin && item.WebClient) {
          const key = this.state.isAdmin ? item.WebAdmin : item.WebClient;
          this.state.setInstrumentationKey(key);
        }
  }

  public async getBasics(): Promise<void> {
    await super.getData('basics');
  }

  public async initData(
  ): Promise<void> {
    if (this.state.tenantCode && !this.state.tenantInfo) {
      await this.getBasics();
      const item = this.collection.item as Tenant;
      // set default data
      this.state.setTenantInfo(item);
      this.isServiceReady.resolve();
    } else if (this.state.tenantInfo) {
      this.init(this.state.tenantInfo);
      this.isServiceReady.resolve();
    }
  }
}
