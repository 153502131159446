import { Injectable } from '@angular/core';

import { Binding } from '@app/model';
import { Logger } from '@app/shared';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

import { StateService } from '@app/core';

export class BindingCollection extends DataScalar<Binding> {}

@Injectable({
  providedIn: 'root'
})
export class BindingService extends ItemService<Binding> {
  protected serviceName = 'BindingService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
    super.configure('notification/v1/binding');
  }

  public get collection(): BindingCollection {
    return new BindingCollection(this.internalItem);
  }

  public async bind(item: Binding): Promise<void> {
    await super.saveItem(item, 'bind', false, false, false);
  }
}
