import { Injectable } from '@angular/core';

import { UserPreference } from '@app/model';
import { Logger } from '@app/shared';
import { DataScalar, ItemService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class UserPreferenceCollection extends DataScalar<UserPreference> { }
@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService extends ItemService<UserPreference> {
  protected serviceName = 'UserPreferenceService';
  public stock: any;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger
  ) {
    super(dataAccessService, logger);
    super.configure('identity/v1/userpreferences');
  }

  public get collection(): UserPreferenceCollection {
    return new UserPreferenceCollection(this.internalItem);
  }

  public async saveUserPreferences(userPreference: UserPreference, isPut: boolean): Promise<void> {

    await this.saveItem(
      userPreference,
      null,
      isPut,
      true,
      false,
      false
    ).then(() => {

    });
  }

  public async getUserPreferences(userId : string): Promise<void> {
    await super.getData(userId, null, true);
  }
}
