import { config, TenantConfigService } from '@app/core';

const baseApiUrl = 'https://apitest.env.carzapp.com.au/';

export const environment = {
  name: 'Test',
  version: TenantConfigService.clientVersion(config.tenantCode),
  production: false,

  baseApiUrl: baseApiUrl,

  baseOrganizationsApiUrl: `${baseApiUrl}identity/v1/organizations`,
  newWebUrl: 'https://aea-cz-web-client-avis-new-test.azurewebsites.net',
};
