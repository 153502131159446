import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html'
})
export class BackComponent {
  @Input() btnClass: string = '';
  
  @Output() back: EventEmitter<any> = new EventEmitter();

  public onBack(): void {
    this.back.emit();
  }
}
