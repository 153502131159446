import { Injectable } from '@angular/core';

import { Connection, ConnectionEntityTypeText, User } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { HttpHeaders } from '@angular/common/http';
import { StateService } from '@app/core';
import { UsersByRoleService } from './users-by-role.service';

export class UsersRoleConnectionCollection extends DataCollection<any> {}

@Injectable({
  providedIn: 'root'
})
export class UsersRoleConnectionService extends ItemsService<any> {
  protected serviceName = 'UsersRoleConnection';
  public role : string ;

  public constructor(dataAccessService: DataAccessService,
     logger: Logger,
     private state: StateService,
     private usersByRoleService: UsersByRoleService) {
    super(dataAccessService, logger,);
  }

  public get collection(): UsersRoleConnectionCollection {
    return new UsersRoleConnectionCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {

    super.configure(`connection/v1/connections`);

    //get user info
    await this.usersByRoleService
      .getUsersByRole(this.role, params)
      .then(async () => {

        const users = this.usersByRoleService.collection;
        const usersList = this.usersByRoleService.collection.items;

        const userIds = usersList.map(({ id }) => id);

        if (userIds && userIds.length > 0) {

        let connections: Connection[] = [];

        let localUrlPostfix = `sourceEntity/${this.state.currentUserId}/destinationEntities/connected`;

        // get connected list
        await super.getData(localUrlPostfix, { destinationEntityIds: userIds }, true)
          .then(() => {
            const connectedList = this.collection.items as any[];

            usersList.forEach(user => {
              // create connection object
              let connection = new Connection();
              let foundConnection = connectedList.find(connection => connection.entityId == user.id);
              connection.isConnected = foundConnection.isConnected;
              connection.id = foundConnection.connectionId;
              connection.features = user;
              connection.sourceEntityId = this.state.currentUserId
              connection.destinationEntityId = user.id
              connection.destinationEntityType = ConnectionEntityTypeText["0"]
              connections.push(connection)
            });

            // assign to base property for removing an object.
            this.internalData.items = connections;
          });

          // change data
          const data = {
            items: connections,
            pageNumber: users.pageNumber,
            totalPages: users.totalPages,
            totalCount: users.totalCount,
            hasPreviousPage: users.hasPreviousPage,
            hasNextPage: users.hasNextPage
          };
         this.init(data);
      }
      });
  }
}
