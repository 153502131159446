import { Injectable } from '@angular/core';

import { Register } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class PublicRegisterCollection extends DataCollection<Register> {}

@Injectable({
  providedIn: 'root'
})
export class PublicRegisterService extends ItemsService<Register> {
  protected serviceName = 'PublicRegisterService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('public/identity/v1/authentications');
  }

  public get collection(): PublicRegisterCollection {
    return new PublicRegisterCollection(this.internalData);
  }

  public async register(item: any): Promise<void> {
    await this.saveItem(item, 'organizations', false, false, false);
  }

  public async confirmEmail(userId: string, token: string): Promise<void> {
    await this.saveItem(
      null,
      `users/${userId}/${token}/confirm`,
      false,
      false,
      false
    );
  }

  public async isUserExists(
    email: string = ''
  ): Promise<boolean> {
     await super.getData(`users/${email}/exists`, '', true);
        const item = this.collection.items as any
       return item.scalarValue;
  }
}
