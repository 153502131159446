import { Component, Input } from '@angular/core';
import { Location } from 'libs/model/src/lib/location';

declare let google: any;

@Component({
  selector: 'app-address-link',
  templateUrl: './address-link.component.html'
})
export class AddressLinkComponent {
  @Input() location: Location;

  public mapUrl: string = ""
  public address: string = ""

  public ngOnInit() {
    this.mapUrl = this.location.mapUrl ? this.location.mapUrl : `https://www.google.com/maps/search/?api=1&query='${this.location.formattedAddress}`;
    this.getAddress();
  }

  public getAddress() {
    if (this.location.placeId) {
      this.getAddressByPlaceId(this.location.placeId);
    } else {
      this.address = this.location.formattedAddress
    }
  }

  public getAddressByPlaceId(placeId : string) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'placeId': placeId }, (results, status) => {
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        this.address = (results[0].formatted_address);
      }
    });
  }
}
