import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Logger } from '../contracts';

@Injectable({
  providedIn: 'root'
})
export class AppLogger implements Logger {
  public static readonly NAME = 'logger';

  public constructor(public toastr: ToastrService) { }

  public log(message: string): void {
    // for development use. no need to toast it
    console.log(message);
  }

  private toast(type: 'error' | 'info' | 'success' | 'warning', message: string, title: string = '', data?: {}, positionClass: ToasterPosition = ToasterPosition.bottomRight): void {
    const consoleMethodMap = {
      error: 'error',
      info: 'info',
      success: 'log',
      warning: 'warn'
    };

    this.toastr[type](message, title, { positionClass });
  }

  public error(message: string, data?: {}, title: string = '', positionClass: ToasterPosition = ToasterPosition.bottomRight): void {
    this.toast('error', message, title, data, positionClass);
  }

  public info(message: string, data?: {}, title: string = '', positionClass: ToasterPosition = ToasterPosition.bottomRight): void {
    this.toast('info', message, title, data, positionClass);
  }

  public success(message: string, data?: {}, title: string = '', positionClass: ToasterPosition = ToasterPosition.bottomRight): void {
    this.toast('success', message, title, data, positionClass);
  }

  public warning(message: string, data?: {}, title: string = '', positionClass: ToasterPosition = ToasterPosition.bottomRight): void {
    this.toast('warning', message, title, data, positionClass);
  }
}

export enum ToasterPosition {
  topRight = 'toast-top-right',
  topLeft = 'toast-top-left',
  bottomRight = 'toast-bottom-right',
  bottomLeft = 'toast-bottom-left'
}