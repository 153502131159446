import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dynamic-sort',
  templateUrl: './dynamic-sort.component.html'
})
export class DynamicSortComponent {
  @Input() keySort: string = '';
  @Input() isAscSort: boolean = true;
  @Input() sort$ = new BehaviorSubject<any>({});
  @Input() isSortVisible: boolean = false;

  @Output() sort: EventEmitter<any> = new EventEmitter();
  @Output() ascOrDesc: EventEmitter<any> = new EventEmitter();

  public onSort(key: any) {
    this.sort.emit(key);
  }

  public onAscOrDesc() {
    this.ascOrDesc.emit(!this.isAscSort);
  }

  // methods
  public active(key: any) {
    return this.keySort === key ? 'active' : '';
  }

  // getters
  public get sortData() {
    return this.sort$.getValue().data;
  }

  public get sortText(): string {
    const findSort = this.sortData.find((x: any) => x.id === this.keySort);
    return findSort ? findSort.text : '';
  }

  public get ascDescText(): string {
    return this.isAscSort ? 'Asc' : 'Desc';
  }
}
