import { Identity } from './identity';
import { Location } from './location';
import { Stock } from './stock';

export class Tender extends Identity {
  name: string = '';
  location: Location = null;
  start: string = '';
  end: string = '';
  type: number = 0;
  isMember: boolean = false;
  status: string = '0';
  stocks: TenderStock[] = [];
  membersIds: any[] = [];
  anyUserBlocked = false;
  blockedUserIds: any[] = [];
  inspections: any[] = [];
  description: string = '';
  createdBy?: string;
  organizationId?: string;

  constructor() {
    super();
  }
}

export class TenderStock extends Identity {
  stockId: string = '';
  reservePrice: number = 0;
  details: Stock;
  isSold?: boolean = false;
  lotNumber: number = 0;
  reserveMet?: boolean;
  winningTender?: number = 0;
  hideReserveMetFlag?: boolean;

  constructor() {
    super();
  }
}

export enum TenderTypes {
  Public,
  Invitation,
  Auction
}

export const TenderTypeText = {
  [TenderTypes.Public]: 'Public',
  [TenderTypes.Invitation]: 'Private',
  [TenderTypes.Auction]: 'Auction'
};

export enum TenderStatus {
  Created = '0',
  Scheduled = '1',
  Running = '2',
  Ended = '3',
  Completed = '4',
  HouseKeeping = '5',
  Finished = '6',
  Closing = '7'
}

export const TenderStatusText = {
  [TenderStatus.Created]: 'Created',
  [TenderStatus.Scheduled]: 'Scheduled',
  [TenderStatus.Running]: 'Running',
  [TenderStatus.Ended]: 'Ended',
  [TenderStatus.Completed]: 'Completed',
  [TenderStatus.HouseKeeping]: 'HouseKeeping',
  [TenderStatus.Finished]: 'Finished',
  [TenderStatus.Closing]: 'Closing'
};