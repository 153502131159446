import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html'
})
export class ValidationComponent {
  @Input() field: any;
  @Input() group: FormGroup;

  // getters
  public get name() {
    return this.group.controls[this.field.name];
  }
}
