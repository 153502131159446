import { Injectable } from '@angular/core';

import { Stock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class SellerTendersStocksCollection extends DataCollection<Stock> {}

@Injectable({
  providedIn: 'root'
})
export class SellerTendersStocksService extends ItemsService<Stock> {
  protected serviceName = 'SellerTendersStocksService';
  public stock: any;

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('seller/catalog/v1/stocks');
  }

  public setAccessLevel(
    accessLevel: string
  ) {
    super.configure(`${accessLevel}/catalog/v1/stocks`);
  }

  public get collection(): SellerTendersStocksCollection {
    return new SellerTendersStocksCollection(this.internalData);
  }

  public async getAvailable(pageNumber : number = 1, pageSize : number = 1): Promise<void> {
    const urlPostfix = 'organization';
    const params = {
      filters: 'status$Available$0',
      pageSize: pageSize,
      pageNumber: pageNumber
    };

    await super.getData(urlPostfix, params, true);
  }
}
