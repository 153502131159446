import { Injectable } from '@angular/core';

import { Organization } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { StateService, config } from '@app/core';
import { HttpHeaders } from '@angular/common/http';

export class OrganizationsCollection extends DataCollection<Organization> {}

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService extends ItemsService<Organization> {
  protected serviceName = 'OrganizationsService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
    super.configure('identity/v1/organizations');
  }

  public get collection(): OrganizationsCollection {
    return new OrganizationsCollection(this.internalData);
  }

  public async saveItem(
    item: Organization,
    urlPostfix: string = '',
    isPut: boolean = false,
    isReturnNewResponse: boolean = true,
    showLogger: boolean = true,
    showLoading: boolean = true,
    isNeedReloadItems: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {

    await super.saveItem(
      item,
      urlPostfix,
      isPut,
      isReturnNewResponse,
      showLogger,
      showLoading,
      isNeedReloadItems,
      options
    );
  }

  public async getData4EditingForm(
    paramId: string = '',
    urlPostfix: string = '',
    forceGet: boolean = true
  ): Promise<any> {
      const item = await this.getDataById(
        paramId,
        urlPostfix,
        forceGet
      );

      // check if feeds exist,then manipulate data
      if (item && item.features.feeds && Array.isArray(item.features.feeds) && item.features.feeds.toString().includes('-')) {

          let feedsList : any = item.features.feeds;
          let feedListMap = feedsList.map(f => f.split('-')[1]);
          let feedListKeyValueMap = feedsList.map(f => f.split('-')[0])[0];
          item.features.feeds = feedListMap.toString();
          item.features.dmsName = feedListKeyValueMap;
      }
      return item;
}

  public async getOrganizations(
    forceGet: boolean = false
  ): Promise<void> {
    this.state.setInitialDataState('Loading Dealerships...');
    await super.getData('', null, forceGet);
      this.state.setInitialDataState(`Dealerships ${config.loadedDataIcon}`);
      // stop loading
      this.state.setIsOrganizationsLoaded(true);
  }
}
