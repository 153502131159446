import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ItemService, DataAccessService } from '@app/data';
import { Logger } from '@app/shared';
import { BehaviorSubject } from 'rxjs';
import { StateService } from '@app/core';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html'
})
export class InputSearchComponent extends ItemService<any> implements OnInit {
  @Input() field: any;
  @Input() group: FormGroup;
  @Input() isReadOnly?: boolean = null;
  @Input() isEditing?: boolean = null;

  protected serviceName = 'InputSearchComponent';

  public isValidData: boolean = false;
  public isSearched: boolean = false;
  public validationMessage: string = '';

  public isLoadingDataVisible$ = new BehaviorSubject<boolean>(false);

  public currentValue: any;

  constructor(
    dataAccess: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccess, logger);
  }

  public ngOnInit(): void {
    this.currentValue = this.group.value[this.field.name];
  }

  private updateParentDataSource(item: any, dataSource: string): string {
    let key = item.key.replace('#p#', '');
    let keys = key.split('.');

    let value = this.group.parent.controls[keys[0]];
    if (keys.length > 1) {
      value = value.controls[keys[1]];
    }
    if (!value.value) {
      this.validationMessage = item.error;
      return null;
    }
    dataSource = dataSource.replace(`*|${item.key}|*`, value.value);
    return dataSource;
  }

  private updateDataSource(item: any, dataSource: string): string {
    const value = this.group.value[item.key];
    if (!value) {
      this.validationMessage = item.error;
      return null;
    }
    dataSource = dataSource.replace(`*|${item.key}|*`, value);
    return dataSource;
  }

  public async onSearch(): Promise<void> {
    this.validationMessage = '';

    const inputValue = this.group.value[this.field.name];
    if (!inputValue) return;

    // start
    this.isLoadingDataVisible$.next(true);

    // search
    const baseApiUrl = this.field.baseApiUrlKey
      ? `${this.state.environment[this.field.baseApiUrlKey]}`
      : '';

    let dataSource = `${baseApiUrl}${this.field.dataSource}`;

    if (this.field.dataSourceKeys) {
      const items = this.field.dataSourceKeys as Array<any>;
      items.forEach((item: any) => {
        if (item.key.includes('#p#')) {
          dataSource = this.updateParentDataSource(item, dataSource);
        }
        else {
          dataSource = this.updateDataSource(item, dataSource);
        }
        if (dataSource == null)
          return;
      });
    } else {
      dataSource = `${dataSource}${inputValue}`;
    }

    if (this.validationMessage) {
      // stop
      this.isLoadingDataVisible$.next(false);
      return;
    }

    super.configure(dataSource, this.field.isFullUrl);

    this.isSearched = false;

    await this.getData('', null, true);
    this.isSearched = true;

    const item = this.collection.item;
    const isObject = item instanceof Object && !('scalarValue' in item);

    if (!(item.scalarValue || isObject)) //check if is valid data
    {
      this.group.setErrors({ incorrect: true });
      this.isLoadingDataVisible$.next(false);
      return;
    }

    this.isValidData = true;
    this.group.markAsTouched();

    // object
    if (isObject) {
      // change related default value when the result is object
      if (this.field.relatedValueIsObject) {
        this.group.patchValue({
          [this.field.relatedControl]: this.field.relatedValueIsObject
        });
      }

      //added response mapping for even more controls
      if (this.field.responseMapping != undefined && this.field.responseMapping.length > 0) {
        this.field.responseMapping.forEach((map: any) => {
          if(this.isEditing && map.disabledInEdit == true)
            return;
          if(map.destination.includes('#p#'))
          {
            const formGroup = <FormGroup>(this.group.parent);
            const newMapDestination = map.destination.replace('#p#', '');
            formGroup.patchValue({
              [newMapDestination]: item[map.source]
            });
          }
          else
          {
            this.group.patchValue({
              [map.destination]: item[map.source]
            });
          }
        });
      }

      // change forms with the object result
      Object.keys(item).forEach((key: string) => {

        if (key === this.field.objectKey) {
          const object = item[key];
          Object.keys(object).forEach((field: string) => {
            this.group.patchValue({
              [field]: object[field]
            });
          });
        }
      });
    }
    // non object
    else {
      // change related default value to default value
      if (this.field.relatedValueIsNonObject) {
        this.group.patchValue({
          [this.field.relatedControl]: this.field.relatedValueIsNonObject
        });
      }

      // change related controls to empty
      if (this.field.relatedControls && !this.isEditing) {
        const controls = this.field.relatedControls as Array<string>;

        controls.forEach((control: string) => {
          this.group.patchValue({
            [control]: ''
          });
        });
      }
    }
    // stop
    this.isLoadingDataVisible$.next(false);

  }

  public onChange(input: any): void {
    this.isSearched = false;
    this.validationMessage = '';

    if (this.field.secondName) {
      const formGroup = <FormGroup>(
        (this.field.isSecondNameInParent ? this.group.parent : this.group)
      );

      formGroup.patchValue({
        [this.field.secondName]: input.target.value
      });
    }
  }

  // getters
  public get isShowLabel() {
    return this.field.label != null;
  }

  public get disabled(): any {
    return this.isReadOnly || (this.isEditing && this.field.disabledInEdit)
      ? ''
      : null;
  }

  public get isButtonDisabled(): boolean {
    return (
      !this.group.value[this.field.name] ||
      this.isReadOnly ||
      (this.isEditing && this.currentValue == this.group.value[this.field.name])
    );
  }

  public get columnClass(): any {
    return {
      'col-lg-6': this.field.isHalfColumn,
      'col-lg-12': !this.field.isHalfColumn
    };
  }

  public get isRequired() {
    return (
      this.field.validations &&
      this.field.validations.some((x: any) => x.name === 'required')
    );
  }
}
