import { Identity } from './identity';

export class Binding extends Identity {
  deviceAddress: string = '';
  platform: BindingPlatforms;
  metaData?: BindingMetaData;

  constructor() {
    super();
  }
}


export enum BindingPlatforms {
  APN = 1,
  FCM = 2,
  Unknown = 0
}

export const BindingPlatformText = {
  [BindingPlatforms.APN]: 'IOS',
  [BindingPlatforms.FCM]: 'Android'
};


export class BindingMetaData {
  constructor() {}
}
