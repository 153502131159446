import { Injectable } from '@angular/core';
import { User } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class UsersByRoleCollection extends DataCollection<User> {}

@Injectable({
  providedIn: 'root'
})
export class UsersByRoleService extends ItemsService<User> {
  protected serviceName = 'UsersByRoleService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
  }

  public get collection(): UsersByRoleCollection {
    return new UsersByRoleCollection(this.internalData);
  }

  public async getUsersByRole(
    role: string,
    params: any = {}
  ): Promise<any> {

    super.configure(`identity/v1/users/role`);
    return await super
      .getData(`${role}/users/active`, params, true)
      .then(async () => {
      });
  }
}
