import { Component, OnInit } from '@angular/core';
import { StateService } from '@app/core';
import { InitialService } from '@app/data';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-initial-loader',
  templateUrl: './initial-loader.component.html'
})
export class InitialLoaderComponent implements OnInit {
  constructor(
    public state: StateService,
    private initialService: InitialService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.initialService.getAllInitializer();
    this.state.setInitialDataState('Loading Completed.');
    of(null).pipe(delay(1500)).subscribe(() => this.state.setInitialDataState(''));
  }
}