import { Injectable } from '@angular/core';

import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class DashboardTransactionsCollection extends DataCollection<any> {}

@Injectable({
  providedIn: 'root'
})
export class DashboardTransactionsService extends ItemsService<any> {
  protected serviceName = 'DashboardTransactionsService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('tender/v1/transactions/dashboard');
  }

  public get collection(): DashboardTransactionsCollection {
    return new DashboardTransactionsCollection(this.internalData);
  }

  public async getDashboardTransactions(userRole : string): Promise<any> {
    await super.getData(userRole, null, true);
      return this.collection.items;
  }
}
