import { Identity } from './identity';
import { Role } from './role';

export class Organization extends Identity {
  name: string;
  features?: OrganizationFeature;
  roles: Role[];
  primaryExternalUniqueId?: string;
  secondaryExternalUniqueId?: string;
  acronym: string;
  isHidden?: boolean;

  constructor() {
    super();
  }
}

export class OrganizationFeature {
  country?: string;
  addressLine1?: string;
  addressLine2?: string;
  suburb?: string;
  postCode?: string;
  state?: string;
  billingCountry?: string;
  billingAddressLine1?: string;
  billingAddressLine2?: string;
  billingSuburb?: string;
  billingPostCode?: string;
  billingState?: string;
  metaData?: any = {};
  feeds?: any ;
  dmsName?: any ;
  csvMapping?: any[] = [];
  tenderRules?: string;
  auctionRules?: string;

  constructor() {}
}
