import {
  Directive,
  Type,
  OnChanges,
  OnInit,
  Input,
  ComponentRef,
  ComponentFactoryResolver,
  ViewContainerRef
} from '@angular/core';

import { FormGroup } from '@angular/forms';
import { InputComponent } from '../dynamic-fields/input/input.component';
import { InputSearchComponent } from '../dynamic-fields/input-search/input-search.component';
import { TextareaComponent } from '../dynamic-fields/textarea/textarea.component';
import { ButtonComponent } from '../dynamic-fields/button/button.component';
import { CheckboxComponent } from '../dynamic-fields/checkbox/checkbox.component';
import { SelectComponent } from '../dynamic-fields/select/select.component';
import { RadioButtonComponent } from '../dynamic-fields/radio-button/radio-button.component';
import { FileUploadComponent } from '../dynamic-fields/file-upload/file-upload.component';
import { CheckboxCollapseComponent } from '../dynamic-fields/checkbox-collapse/checkbox-collapse.component';
import { ToggleCollapseComponent } from '../dynamic-fields/toggle-collapse/toggle-collapse.component';
import { RadioCollapseComponent } from '../dynamic-fields/radio-collapse/radio-collapse.component';
import { ToggleComponent } from '../dynamic-fields/toggle/toggle.component';
import { AvatarUploadComponent } from '../dynamic-fields/avatar-upload/avatar-upload.component';
import { SelectCheckboxComponent } from '../dynamic-fields/select-checkbox/select-checkbox.component';
import { DateTimeComponent } from '../dynamic-fields/date-time/date-time.component';
import { InputGroupComponent } from '../dynamic-fields/input-group/input-group.component';
import { GooglePlaceAutocompleteComponent } from '../dynamic-fields/google-place-autocomplete/google-place-autocomplete.component';
import { CardListComponent } from '../dynamic-fields/card-list/card-list.component';

const componentsMapper: { [key: string]: Type<any> } = {
  input: InputComponent,
  inputGroup: InputGroupComponent,
  inputSearch: InputSearchComponent,
  textarea: TextareaComponent,
  checkbox: CheckboxComponent,
  toggle: ToggleComponent,
  button: ButtonComponent,
  select: SelectComponent,
  selectCheckbox: SelectCheckboxComponent,
  radioButton: RadioButtonComponent,
  fileUpload: FileUploadComponent,
  avatarUpload: AvatarUploadComponent,
  checkboxCollapse: CheckboxCollapseComponent,
  toggleCollapse: ToggleCollapseComponent,
  radioCollapse: RadioCollapseComponent,
  dateTime: DateTimeComponent,
  googlePlaceAutocomplete: GooglePlaceAutocompleteComponent,
  cardList: CardListComponent
};

@Directive({
  selector: '[appDynamicFields]'
})
export class DynamicFieldsDirective implements OnInit, OnChanges {
  @Input() field: any;
  @Input() index?: number;
  @Input() isEditing?: boolean;
  @Input() group: FormGroup;
  @Input() data: any;
  @Input() isReadOnly: boolean;

  component: ComponentRef<any>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}

  ngOnInit() {
    const component = this.resolver.resolveComponentFactory<any>(
      componentsMapper[this.field.type]
    );

    this.component = this.container.createComponent(component);

    this.component.instance.field = this.field;
    this.component.instance.index = this.index;
    this.component.instance.isEditing = this.isEditing;
    this.component.instance.group = this.group;
    this.component.instance.data = this.data;
    this.component.instance.isReadOnly = this.isReadOnly;
  }

  ngOnChanges() {
    if (this.component) {
      this.component.instance.field = this.field;
      this.component.instance.index = this.index;
      this.component.instance.isEditing = this.isEditing;
      this.component.instance.group = this.group;
      this.component.instance.data = this.data;
      this.component.instance.isReadOnly = this.isReadOnly;
    }
  }
}
