import { Injectable } from '@angular/core';
import { PushNotificationPermission, PushNotification } from '@app/model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {
  public permission: PushNotificationPermission;
  constructor() {
    this.permission = this.isSupported ? 'default' : 'denied';
  }

  public get isSupported(): boolean {
    return 'Notification' in window;
  }

  public requestPermission(): void {
    if (this.isSupported) {
      Notification.requestPermission(status => {
        return (this.permission = status);
      });
    }
  }

  public create(title: string, options?: PushNotification): Observable<any> {
    return new Observable(observer => {
      if (!('Notification' in window)) {
        observer.error('Notifications are not available in this environment');
      }

      if (this.permission !== 'granted') {
        observer.error("The user hasn't granted you permission to send push notifications");
      }

      const notify = new Notification(title, options);

      notify.onshow = event => {
        return observer.next({
          notification: notify,
          event: event
        });
      };

      notify.onclick = event => {
        event.preventDefault();
        window.open((event.target as Notification).data);
      };

      notify.onerror = event => {
        return observer.error({
          notification: notify,
          event: event
        });
      };

      notify.onclose = () => {
        return observer.complete();
      };
    });
  }

  public generateNotification(source: any[]): void {
    source.forEach(item => {
      const options = {
        body: item.content,
        icon: item.icon,
        data: item.url
      };
      const notify = this.create(item.title, options).subscribe({
        error: err => console.error(err)
      });
    });
  }
}
