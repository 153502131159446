import { NgModule } from '@angular/core';
import { CommonModule, AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';

import { DynamicFieldsDirective } from './directives/dynamic-fields.directive';

import { InputComponent } from './dynamic-fields/input/input.component';
import { InputSearchComponent } from './dynamic-fields/input-search/input-search.component';
import { TextareaComponent } from './dynamic-fields/textarea/textarea.component';
import { ButtonComponent } from './dynamic-fields/button/button.component';
import { ValidationComponent } from './dynamic-fields/validation/validation.component';

import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { CheckboxComponent } from './dynamic-fields/checkbox/checkbox.component';

import { DynamicDataEditorComponent } from './dynamic-data-editor/dynamic-data-editor.component';
import { RouterModule } from '@angular/router';
import { PipeModule } from '@app/pipe';
import { TranslateModule } from '@app/translate';
import { UtilitiesModule } from '@app/utilities';

import { NgSelect2Module } from 'ng-select2';

import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { OwlDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';

import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { DynamicFormEditorComponent } from './dynamic-form-editor/dynamic-form-editor.component';
import { DynamicScrollTopComponent } from './dynamic-scroll-top/dynamic-scroll-top.component';
import { DynamicPaginationComponent } from './dynamic-pagination/dynamic-pagination.component';
import { DynamicRecordsComponent } from './dynamic-pagination/dynamic-records.component';
import { DynamicSortComponent } from './dynamic-sort/dynamic-sort.component';
import { DynamicFilterComponent } from './dynamic-filter/dynamic-filter.component';
import { DynamicFilterIconComponent } from './dynamic-filter/dynamic-filter-icon.component';
import { DynamicPolicyComponent } from './dynamic-web-content/dynamic-web-content.component'

import { SelectComponent } from './dynamic-fields/select/select.component';
import { RadioButtonComponent } from './dynamic-fields/radio-button/radio-button.component';
import { FileUploadComponent } from './dynamic-fields/file-upload/file-upload.component';
import { CheckboxCollapseComponent } from './dynamic-fields/checkbox-collapse/checkbox-collapse.component';
import { ToggleCollapseComponent } from './dynamic-fields/toggle-collapse/toggle-collapse.component';
import { RadioCollapseComponent } from './dynamic-fields/radio-collapse/radio-collapse.component';
import { ToggleComponent } from './dynamic-fields/toggle/toggle.component';
import { AvatarUploadComponent } from './dynamic-fields/avatar-upload/avatar-upload.component';
import { SelectCheckboxComponent } from './dynamic-fields/select-checkbox/select-checkbox.component';
import { DateTimeComponent } from './dynamic-fields/date-time/date-time.component';
import { InputGroupComponent } from './dynamic-fields/input-group/input-group.component';
import { GooglePlaceAutocompleteComponent } from './dynamic-fields/google-place-autocomplete/google-place-autocomplete.component';
import { CardListComponent } from './dynamic-fields/card-list/card-list.component';

import {DeleteConfirmationComponent} from './dynamic-form-editor/delete-confirmation-popup'; // Delete Confirmation Class for  dynamic form
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
// date time format
export const MY_MOMENT_FORMATS = {
  parseInput: 'DD/MM/YYYY, LT',
  fullPickerInput: 'DD/MM/YYYY, LT',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

@NgModule({
  imports: [
    ScrollingModule,
    DragDropModule,
    CommonModule,
    PipeModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxPaginationModule,
    NgSelect2Module,
    GooglePlaceModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    UtilitiesModule,
    CarouselModule
  ],
  declarations: [
    InputComponent,
    InputGroupComponent,
    InputSearchComponent,
    TextareaComponent,
    ButtonComponent,
    CheckboxComponent,
    ToggleComponent,
    ValidationComponent,
    SelectComponent,
    SelectCheckboxComponent,
    FileUploadComponent,
    AvatarUploadComponent,
    RadioButtonComponent,
    CheckboxCollapseComponent,
    ToggleCollapseComponent,
    RadioCollapseComponent,
    DateTimeComponent,
    GooglePlaceAutocompleteComponent,
    CardListComponent,

    DynamicFormComponent,
    DynamicFieldsDirective,
    DynamicDataEditorComponent,
    DynamicFormEditorComponent,
    DynamicPaginationComponent,
    DynamicRecordsComponent,
    DynamicFilterComponent,
    DynamicFilterIconComponent,
    DynamicSortComponent,
    DynamicScrollTopComponent,
    DeleteConfirmationComponent,
    DynamicPolicyComponent
  ],
  entryComponents: [
    InputComponent,
    InputGroupComponent,
    InputSearchComponent,
    TextareaComponent,
    CheckboxComponent,
    ToggleComponent,
    ButtonComponent,
    SelectComponent,
    SelectCheckboxComponent,
    RadioButtonComponent,
    FileUploadComponent,
    AvatarUploadComponent,
    CheckboxCollapseComponent,
    ToggleCollapseComponent,
    RadioCollapseComponent,
    DateTimeComponent,
    GooglePlaceAutocompleteComponent,
    CardListComponent
  ],
  exports: [
    DynamicFormComponent,
    DynamicDataEditorComponent,
    DynamicFormEditorComponent,
    DynamicPaginationComponent,
    DynamicRecordsComponent,
    DynamicFilterComponent,
    DynamicFilterIconComponent,
    DynamicSortComponent,
    DynamicScrollTopComponent,
    DeleteConfirmationComponent,
    DynamicPolicyComponent,

    InputComponent,
    InputGroupComponent,
    InputSearchComponent,
    TextareaComponent,
    CheckboxComponent,
    ToggleComponent,
    ButtonComponent,
    SelectComponent,
    SelectCheckboxComponent,
    RadioButtonComponent,
    FileUploadComponent,
    AvatarUploadComponent,
    CheckboxCollapseComponent,
    ToggleCollapseComponent,
    RadioCollapseComponent,
    DateTimeComponent,
    GooglePlaceAutocompleteComponent,
    CardListComponent
  ],
  providers: [
    AsyncPipe,
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }
  ]
})
export class DynamicsModule {}
