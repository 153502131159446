import { Injectable } from '@angular/core';

import { Stock} from '@app/model';
import { Logger } from '@app/shared';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

import { HttpHeaders } from '@angular/common/http';
import { TransactionsCountService } from './transactions-count.service';

export class TenderStocksMetReserveCollection extends DataCollection<
Stock
  > { }

@Injectable({
  providedIn: 'root'
})
export class TenderStocksMetReserveService extends ItemsService<Stock> {
  protected serviceName = 'TenderStocksMetReserveService';
  public eventId: string = '';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private transactionsCountService: TransactionsCountService
  ) {
    super(dataAccessService, logger);
    super.configure('tender/v1/tenders');
  }

  public get collection(): TenderStocksMetReserveCollection {
    return new TenderStocksMetReserveCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {

    let localUrlPostfix = `${this.eventId}/stocks/metreserve`;
    await super.getData(localUrlPostfix, params, true, options)      .then(async () => {
      const collection = this.collection;

      let items = collection.items;

      // extract Stocks from TenderStocks
      items = items.map((item: any) => {
        //replace normal price with reserve price
        item.details.features.price = item.reservePrice
        return item.details;
      });

      if (items.length > 0) {

        // stock ids
        const stockIds = items.map((item: Stock) => {
          return item.id;
        });

        // get view count
        await this.transactionsCountService
          .getBatchViewsCount(stockIds)
          .then(() => {
            // replace view count
            const viewCounts = this.transactionsCountService.collection.item as any;
            items = items.map((item: Stock) => {
              const count = viewCounts.find(vcount => vcount.id == item.id)
              item.viewCount = count ? count.count as number : 0
              return item;
            });

            // change data
            const data = {
              items: items,
              pageNumber: collection.pageNumber,
              totalPages: collection.totalPages,
              totalCount: collection.totalCount,
              hasPreviousPage: collection.hasPreviousPage,
              hasNextPage: collection.hasNextPage
            };

            this.init(data);
          });
      }
    });
  }
}
