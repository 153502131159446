import { Injectable } from '@angular/core';

import { Stock, User } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class BuyerStocksSummaryCollection extends DataCollection<Stock> {}

@Injectable({
  providedIn: 'root'
})
export class BuyerStocksSummaryService extends ItemsService<Stock> {
  protected serviceName = 'BuyerStocksSummaryService';

  public constructor(private dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('buyer/catalog/v1/stocks/summary');
  }

  public get collection(): BuyerStocksSummaryCollection {
    return new BuyerStocksSummaryCollection(this.internalData);
  }

  public async getClientData(params: any = {}): Promise<Stock[]> {
    return await this.dataAccessService.getData<Stock[]>('buyer/catalog/v1/client/stocks/summary', '', params, false);
  }
}
