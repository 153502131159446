import { Component } from '@angular/core';
import { StateService } from '@app/core';

@Component({
  selector: 'app-environment-version',
  templateUrl: './environment-version.component.html'
})
export class EnvironmentVersionComponent {
  constructor(private state: StateService) {}

  // getters
  public get version(): string {
    let version: string = '';

    this.state.on(this.state.isAdminApp$, () => {
      version = this.state.environment.version;
    });


    return version;
  }
}
