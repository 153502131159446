import { Component, Input, Renderer2, ElementRef } from '@angular/core';
import { StateService } from '@app/core';

@Component({
  selector: 'app-dynamic-filter-icon',
  templateUrl: './dynamic-filter-icon.component.html'
})
export class DynamicFilterIconComponent {
  @Input() isLoadingItemsVisible: boolean = false;

  constructor(private state: StateService, private renderer: Renderer2, private el: ElementRef) {
  }

  public toggleFilter() {
    this.state.setFilterToggled(!this.state.isFilterToggled);

    // set class for mobile view
    if (this.state.isFilterToggled && this.state.isMobileResolution) {
      this.renderer.addClass(document.body, 'show-popup');
    }
    else {
      this.renderer.removeClass(document.body, 'show-popup');
    }
  }
}