import { Location } from 'libs/model/src/lib/location';
import { Component, Input } from '@angular/core';

declare let google: any;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html'
})
export class GoogleMapComponent {
  @Input() location: Location;

  public zoom: number = 15;

  public ngOnInit() {
    if (this.location.formattedAddress && this.location.formattedAddress != '' &&
      this.location.latitude == 0 && this.location.longitude == 0) {
        let address = '';
        if(this.location.cityName != 'Unknown'){
          address = this.location.cityName
        }
        if(this.location.stateName != 'Unknown'){
          address = `${address}, ${this.location.stateName}`
        }
        if(this.location.countryName != 'Unknown'){
          address = `${address}, ${this.location.countryName}`
        }

        // validate if any address found else show map
        if(address !== ''){
          this.getLatLongByAddress(address);
        }
    }else{
      this.getAddressByPlaceId();
    }
  }

  public getAddressByPlaceId() {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'placeId': this.location.placeId }, (results, status) => {
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        this.location.latitude = (results[0].geometry.location.lat());
        this.location.longitude = (results[0].geometry.location.lng());
      }
    });
  }

  public getLatLongByAddress(address :  string) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'address': address }, (results, status) => {
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status == google.maps.GeocoderStatus.OK) {
        this.location.latitude = (results[0].geometry.location.lat());
        this.location.longitude = (results[0].geometry.location.lng());
      }
    });
  }

  // getters
  public get isValidLatitudeAndLongitude(): boolean {
    return this.location.latitude != 0 && this.location.longitude != 0;
  }
}
