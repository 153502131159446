import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StateService } from '@app/core';

@Component({
  selector: 'app-input-group',
  templateUrl: './input-group.component.html'
})
export class InputGroupComponent {
  @Input() field: any;
  @Input() index?: number;
  @Input() group: FormGroup;
  @Input() disabled?: boolean = null;
  @Input() isEditing?: boolean;
  @Input() isReadOnly?: boolean = null;

  constructor(private state: StateService) {}

  // event
  public onKeypress(event: any): void {
    if (this.type != 'number') return;

    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  // getters
  public get isShowLabel() {
    return this.field.label != null;
  }

  public get groupClass(): any {
    return {
      'form-group form-label-group': this.type !== 'hidden',
      'custom-number': this.type === 'number'
    };
  }

  public get inputClass(): any {
    return {
      'border-left-0': this.field.removeBorderLeft,
      'remove-space-control': this.field.removeBottomSpace
    };
  }

  public get columnClass(): any {
    return {
      'col-lg-6': this.field.isHalfColumn,
      'col-lg-12': !this.field.isHalfColumn
    };
  }

  public get prefixOrPostfixClass(): any {
    return {
      prefix: this.field.prefix || this.field.prefixTenantKey,
      postfix: this.field.postfix || this.field.postfixTenantKey
    };
  }

  public get type() {
    return this.field.inputType || 'text';
  }

  public get name() {
    const index = this.index != null ? `-${this.index}` : '';
    return `${this.field.name}${index}`;
  }

  public get step(): number {
    return this.field.step ? this.field.step : 1;
  }

  public get min(): number {
    return this.field.min ? this.field.min : 0;
  }

  public get isRequired() {
    return (
      this.field.validations &&
      this.field.validations.some((x: any) => x.name === 'required')
    );
  }

  public get prefix(): string {
    return this.field.prefixTenantKey
      ? this.state.tenantInfo[this.field.prefixTenantKey]
      : this.field.prefix;
  }

  public get postfix(): string {
    return this.field.postfixTenantKey
      ? this.state.tenantInfo[this.field.postfixTenantKey]
      : this.field.postfix;
  }

  public get isDisabled(): any {
    if (this.disabled != null) {
      this.group.setErrors({ incorrect: this.disabled });
      const control = this.group.controls[this.field.name];
      this.disabled ? control.disable() : control.enable();

      return this.disabled ? '' : null;
    } else {
      return (this.isEditing && this.field.disabledInEdit) ||
        this.field.disabled ||
        this.isReadOnly
        ? ''
        : null;
    }
  }
}
