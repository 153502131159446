import { Injectable } from '@angular/core';

import { StateService, config } from '@app/core';

import { JwtHelperService } from '@auth0/angular-jwt';
import { Organization, Role } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public redirectUrl: string;
  public tenantCode: string;

  constructor(private state: StateService) {
    this.tenantCode = this.state.tenantCode;
    this.tenantCode = this.tenantCode ? this.tenantCode : config.tenantCode;
  }

  public isAuthenticated(): boolean {
    const token = this.state.token;

    if (!token) {
      return false;
    }

    const jwtHelper = new JwtHelperService();
    const isTokenExpired = jwtHelper.isTokenExpired(token);

    // remove tenant info
    if (isTokenExpired) {
      this.state.setTenantInfo(null);
    }

    return !isTokenExpired;
  }

  public hasPermission(roles: string[]): boolean {
    const token = this.state.token;

    if (!token) {
      return true;
    }

    const jwtHelper = new JwtHelperService();
    const decode = jwtHelper.decodeToken(token);
    let hasPermission: boolean = false;

    // don't have any roles
    if (this.state.profile && this.state.profile.organizations.length === 0) {
      return false;
    }

    // admin app
    if (this.state.isAdminApp) {
      hasPermission = this._isSupervisor(decode);
    }
    // client app
    else {
      const isAdmin = this._isAdmin(decode);
      const isSeller = this._isSeller(decode);
      const isBuyer = this._isBuyer(decode);
      const isGuest = this._isGuest(decode);
      const isExistRoles = this._isExistRoles(decode, roles);

      hasPermission = (isAdmin || isSeller || isBuyer || isGuest) && isExistRoles;
    }

    return hasPermission;
  }

  // privates
  private _isSupervisor(token: any): boolean {
    let isSupervisor: boolean = false;
    const acronym = this.state.organizationAcronym;

    if (acronym) {
      const supervisor = `${acronym}-SUPERVISOR`;

      // check organization
      const organizations = token.orgs.split(',');
      const isExistAcronym = organizations.includes(acronym);

      // check supervisor
      const roles = token.role.split(',');
      const isExistSupervisor = roles.includes(supervisor);
      isSupervisor = isExistAcronym && isExistSupervisor;

      // change state
      this.state.setIsSupervisor(isSupervisor);
    }

    return isSupervisor;
  }

  private _isAdmin(token: any): boolean {
    // check admin
    const isAdmin = token.role.includes('ADMIN');

    // change state
    if (!this.state.organizationId) {
      this.state.setIsAdmin(isAdmin);
    }

    return isAdmin;
  }

  private checkRoleAndSetState(token: any, role: string, setState: (value: boolean) => void): boolean {
    const hasRole = token.role.includes(role);

    // change state
    if (!this.state.organizationId) {
      setState(hasRole);
    }
    return hasRole;
  }

  private _isSeller(token: any): boolean {
    return this.checkRoleAndSetState(token, 'SELLER', this.state.setIsSeller.bind(this.state));
  }

  private _isGuest(token: any): boolean {
    return this.checkRoleAndSetState(token, 'GUEST', this.state.setIsGuest.bind(this.state));
  }

  private _isBuyer(token: any): boolean {
    return this.checkRoleAndSetState(token, 'BUYER', this.state.setIsBuyer.bind(this.state));
  }

  private _isExistRoles(token: any, roles: string[]): boolean {
    if (roles.length === 0) return true;

    return this.checkOrgRoles(this.state.currentOrganization, token, roles);
  }

  public checkOrgRoles(org: Organization, token: any, roles: string[]): boolean {
    if (roles.length === 0) return true;

    let currentRoles = org ? org.roles
      .map((role: Role) => {
        return role.name;
      }).join(',') : token ? token.role : '';

    if (currentRoles === '') {
      return false;
    }

    return roles.some((role: string) => {
      if (role.includes(':')) {
        return role.split(':').every((andRole: string) => {
          return currentRoles.includes(andRole);
        });
      }
      return currentRoles.includes(role);
    });
  }
}
