import { Injectable } from '@angular/core';

import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class InstantValCollection extends DataCollection<any> {}
@Injectable({
  providedIn: 'root'
})
export class InstantValService extends ItemsService<any> {
  protected serviceName = 'InstantValService';
  public stock: any;

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('guest/catalog/v1/appraisal/InstantVal');
  }

  public get collection(): InstantValCollection {
    return new InstantValCollection(this.internalData);
  }

  public async getInstantVal(
    appraisalId: string,
    vinNumber: string,
    odometer: string,
    state: string
  ): Promise<any> {
    let params: any = {
      appraisalId: appraisalId,
      vin: vinNumber,
      state: state,
      odometer: odometer
    };
    return await super.getData(null, params, true).then(() => {
      return this._data;
    });
  }
}
