import { Injectable } from '@angular/core';

import { TransactionCount } from '@app/model';
import { Logger } from '@app/shared';
import { DataScalar, ItemService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class TransactionsCountCollection extends DataScalar<TransactionCount> {}

@Injectable({
  providedIn: 'root'
})
export class TransactionsCountService extends ItemService<TransactionCount> {
  protected serviceName = 'TransactionsCountService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('tender/v2/transactions');
  }

  public get collection(): TransactionsCountCollection {
    return new TransactionsCountCollection(this.internalItem);
  }

  public async getViewsCount(entityId: string): Promise<void> {
    await super.getData(`entity/${entityId}/views/count`, null, true);
  }

  public async getBatchViewsCount(entityIds: string[]): Promise<void> {
    const params = { entityIds: entityIds };
    await super.getData('batch/entity/views/count', params, true);
  }

  public async getBatchEventOffersCount(entityIds: string[], eventId : string = '00000000-0000-0000-0000-000000000000'): Promise<void> {
    const params = { entityIds: entityIds };
    await super.getData(`batch/event/${eventId}/entity/offers/count`, params, true);
  }

  public async getOffersCount4Seller(entityId: string): Promise<void> {
    await super.getData(`entity/${entityId}/offers/count`, null, true);
  }

  public async getEventOffersCount(entityId: string, eventId : string = '00000000-0000-0000-0000-000000000000', includeDeleted: boolean = false): Promise<void> {
    await super.getData(`event/${eventId}/entity/${entityId}/offers/count?includeDeletedOffers=${includeDeleted}`, null, true);
  }

  public async getWinningTender(entityId: string, eventId : string = '00000000-0000-0000-0000-000000000000'): Promise<void> {
    await super.getData(`event/${eventId}/entity/${entityId}/winningtender`, null, true);
  }
}
