import { Injectable } from '@angular/core';

import { Connection } from '@app/model';
import { Logger } from '@app/shared';
import { DataScalar, ItemService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { config, StateService } from '@app/core';

export class ConnectionCollection extends DataScalar<Connection> {}

@Injectable({
  providedIn: 'root'
})
export class ConnectionService extends ItemService<Connection> {
  protected serviceName = 'ConnectionService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
    super.configure('connection/v1/connections');
  }

  public get collection(): ConnectionCollection {
    return new ConnectionCollection(this.internalItem);
  }

  public async getConnection(
    sourceEntityId: string,
    destinationEntityId: string,
    destinationEntityType: string = 'User'
  ): Promise<void> {
    const urlPostfix = `sourceEntity/${sourceEntityId}/destinationEntity/${destinationEntityId}/destinationEntity/type/${destinationEntityType}/available`;

    await super.getData(urlPostfix, null, true);
  }
}