import { Identity } from './identity';
import { Location } from './location';
import { Image } from './image';
import { StockNote } from './stock';
import { InstantVal } from './instant-val';

export class Appraisal extends Identity {
  vinNumber: string;
  year: string;
  make: string;
  model: string;
  value: number;
  badgeVariant: string;
  series: string;
  fuelType: string;
  bodyType: string;
  transmission: string;
  engineSize: string;
  seats: string;
  doors: string;
  features: Features;
  organizationId: string;
  tenantCode: string;
  createdBy: string;
  lastUpdatedOn: Date;
  images: Image[] = [];
  location?: Location;
  instantVal: InstantVal;
  customerName: string;
  status: AppraisalStatus;

  constructor() {
    super();
  }
}

export class AppraisalStatus {
  status: string = '';
  reason: string = '';

  constructor() {}
}
export class AppraisalCustomerContact {
  name: string = '';
  email: string = '';
  phoneNumber: number = 0;

  constructor() {}
}
export class Features {
  color: string = '';
  odometer: number = 0;
  noOfCylinders: number = 0;
  notes: StockNote[] = [];
  customerEmail: string;
  customerPhone: string;
  featuredImageSequences: any[];

  constructor() {}
}

export enum AppraisalStatusType {
  Lost = 'Lost',
  WonRetail = 'Won Retail',
  WonWholesale = 'Won Wholesale'
}

export enum AppraisalLostReasonStatus {
  NewDealLost = 'New Deal Lost',
  SoldPrivately = 'Sold Privately',
  ValueTooLow = 'Value too low'
}
