import { Identity } from './identity';
import { OrganizationFeature } from './organization';

export class Register extends Identity {
  register: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string;
    roles: string[];
  };
  genericOrganization: {
    id: string;
    primaryExternalUniqueId: string;
    secondaryExternalUniqueId: string;
    name: string;
    features: OrganizationFeature;
  };

  constructor() {
    super();
  }
}
