import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

import { ItemsService, DataAccessService } from '@app/data';
import { Logger, createSelect2Data, SharedService } from '@app/shared';
import { BehaviorSubject } from 'rxjs';
import { bindValidations } from '@app/validator';
import { StateService } from '@app/core';

@Component({
  selector: 'app-select-checkbox',
  templateUrl: './select-checkbox.component.html'
})
export class SelectCheckboxComponent extends ItemsService<any>
  implements OnInit {
  @Input() field: any;
  @Input() group: FormGroup;
  @Input() index?: number;
  @Input() data: any;
  @Input() isEditing?: boolean;
  @Input() isReadOnly?: boolean = null;

  protected serviceName = 'SelectCheckboxComponent';

  public selectItems$ = new BehaviorSubject<any>([]);
  public checkBoxes$ = new BehaviorSubject<any>([]);

  public checkBoxField: any;
  public checkboxGroup: FormGroup;

  public isCheckboxItemsCompleted$ = new BehaviorSubject<boolean>(false);
  public checkboxValues: any[] = [];
  public tempIndex: number;

  public isLoadingDataVisible$ = new BehaviorSubject<boolean>(false);

  constructor(
    dataAccess: DataAccessService,
    logger: Logger,
    public changeDetectorRef: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    public shared: SharedService,
    public state: StateService
  ) {
    super(dataAccess, logger);
  }

  public async ngOnInit(): Promise<void> {
    this.tempIndex = this.index;
    await this.getItems();
  }

  public async getItems(): Promise<void> {
    // start
    this.isLoadingDataVisible$.next(true);

    const baseApiUrl = this.field.baseApiUrlKey
      ? `${this.state.environment[this.field.baseApiUrlKey]}`
      : '';

    super.configure(`${baseApiUrl}${this.field.dataSource}`);

    await this.getData();

      // rename
      const newSelectItems = createSelect2Data(
        this.collection.items,
        this.field.dataValue,
        this.field.dataText
      );

      this.selectItems$.next(newSelectItems);

      const defaultValue =
        newSelectItems.length > 0 && !this.group.value[this.field.name]
          ? this.collection.items[0][this.field.dataValue]
          : this.group.value[this.field.name];

      this.group.patchValue({
        [this.field.name]: defaultValue
      });
      // check box
      await this.setCheckbox(defaultValue);
  }

  public async setCheckbox(value?: string): Promise<void> {
    this.checkBoxField = Object.assign({}, this.field.child);

    // array
    this.checkboxGroup = new FormGroup({});

    // group
    this.group.addControl(this.checkBoxField.name, this._control({
      value: [],
      validations:
        'validations' in this.checkBoxField
          ? this.checkBoxField['validations']
          : null
    }));

    const baseApiUrl = this.checkBoxField.baseApiUrlKey
      ? `${this.state.environment[this.checkBoxField.baseApiUrlKey]}`
      : '';

    this.checkBoxField.dataSource = `${baseApiUrl}${this.checkBoxField.dataSource}/${value}`;

    await this._getCheckboxItems();
  }

  // select
  public onChangeSelect(event: any) {
    const value = event.value;
    this.group.patchValue({
      [this.field.name]: value
    });

    this.setCheckbox(value);
  }

  // checkbox
  public onChangeCheckbox(event: any, item: any, index: number) {
    const key = this.checkBoxField.dataValue;

    if (event.target.checked) {
      this.checkboxValues.push(item);
    }

    if (!event.target.checked) {
      this.checkboxValues = this.checkboxValues.filter(value => {
        return value[key] !== item[key];
      });
    }

    this.patchCheckboxValues(index);
  }

  public isCheckboxSelected(key: any): boolean {
    let isSelectedValue: boolean = false;
    if (this.checkBoxField) {
      const keys = this.group.value[this.checkBoxField.name];

      if (keys) {
        keys.forEach((item: any) => {
          const value = item[this.checkBoxField.dataValue];
          if (!isSelectedValue) {
            isSelectedValue = value && value === key;
          }
        });
      }
    }

    return isSelectedValue;
  }

  public isShowLabel(control?: any) {
    control = control ? control : this.field;
    return control.label != null;
  }

  public isRequired(control?: any) {
    control = control ? control : this.field;

    return (
      control.validations &&
      control.validations.some((x: any) => x.name === 'required')
    );
  }

  public isDisabledValueInCheckbox(value: string): any {
    let isDisabled: boolean = false;

    if (this.checkBoxField && this.checkBoxField.enabledValues) {
      const stateValue = this.field.stateKey4DisableChild
        ? this.state[this.field.stateKey4DisableChild]
        : '';

      const selectValue = this.group.value[this.field.name];

      const foundValue = this.checkBoxField.enabledValues.some(
        (enable: string) => {
          return value.indexOf(enable) > -1;
        }
      );

      isDisabled = !foundValue || (stateValue && stateValue != selectValue);
    }

    return isDisabled ? '' : null;
  }

  public checkboxClass(value: string): any {
    return {
      'custom-control-inline': this.checkBoxField.checkboxType === 'inline',
      disabled: this.isDisabledValueInCheckbox(value) != null
    };
  }

  // setters
  private patchCheckboxValues(index: number) {
    this.group.controls[`${this.checkBoxField.name}`].setValue(
      this.checkboxValues
    );
    if (this.tempIndex !== this.index) {
      this.checkboxValues = [];
      this.tempIndex = this.index;
    }
  }

  public setCheckboxName(key: string) {
    if (this.checkBoxField) {
      const index = this.index != null ? `-${this.index}` : '';
      return `${this.checkBoxField.name}-${key}${index}`;
    }
  }

  // getters
  public get isHidden(): boolean {
    const parentName = this.field.parentName;
    const child = parentName && this.data[parentName][this.index];

    return this.data && child && child.isHidden;
  }

  public get columnClass(): any {
    return {
      'col-lg-6': this.field.isHalfColumn,
      'col-lg-12': !this.field.isHalfColumn
    };
  }

  public get isDisabledSelect(): any {
    return (
      this.isReadOnly ||
      this.field.disabled ||
      (this.isEditing && this.field.disabledInEdit)
    );
  }

  public get disabledSelectClass(): any {
    return { disabled: this.isDisabledSelect };
  }

  // privates
  private async _getCheckboxItems(): Promise<void> {
    const baseApiUrl = this.checkBoxField.baseApiUrlKey
      ? `${this.state.environment[this.checkBoxField.baseApiUrlKey]}`
      : '';

    super.configure(`${baseApiUrl}${this.checkBoxField.dataSource}`);

    await this.getData('', null, true);
      let checkboxItems: any[] = [];
      checkboxItems = Object.assign([],(this.collection.items as any).roles);

      // set values
      const defaultValue = this.group.value[this.checkBoxField.name] ? this.group.value[this.checkBoxField.name]
        : this.checkBoxField.value? this.checkBoxField.value 
        : [];

      
        this.checkboxValues = Object.assign([], defaultValue);
        this.patchCheckboxValues(this.index);
      
      this.checkBoxes$.next(checkboxItems);
      this.isCheckboxItemsCompleted$.next(true);

      // stop
      this.isLoadingDataVisible$.next(false);
  }

  private _control = (field: any): FormControl => {
    return this.formBuilder.control(
      field.value,
      bindValidations(field.validations || [])
    );
  };
}
