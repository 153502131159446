import { Injectable } from '@angular/core';

import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { RegisterIncomplete } from '@app/model';

export class PublicRegisterIncompleteCollection extends DataCollection<RegisterIncomplete> {}

@Injectable({
  providedIn: 'root'
})
export class PublicRegisterIncompleteService extends ItemsService<RegisterIncomplete> {
  protected serviceName = 'PublicRegisterIncompleteService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('public/identity/v1/incompleteregistration');
  }

  public get collection(): PublicRegisterIncompleteCollection {
    return new PublicRegisterIncompleteCollection(this.internalData);
  }

  public async registerIncomplete(item: any): Promise<void> {
    await this.saveItem(item, '', false, false, false);
  }
}
