import { Injectable } from '@angular/core';

import { Tender, Stock, TenderStock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

import { StateService } from '@app/core';
import { HttpHeaders } from '@angular/common/http';
import { TransactionsCountService } from './transactions-count.service';

export class BuyerTendersCollection extends DataCollection<Tender> { }

@Injectable({
  providedIn: 'root'
})
export class BuyerTendersService extends ItemsService<Tender> {
  protected serviceName = 'BuyerTendersService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService,
    private transactionsCountService: TransactionsCountService
  ) {
    super(dataAccessService, logger);
    super.configure('buyer/tender/v2/tenders');
  }

  public get collection(): BuyerTendersCollection {
    return new BuyerTendersCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    const staticFilters =
      this.state.tenantInfo.serviceConfigs.tenderConfig.defaultFilters.length >
      0
        ? this.state.tenantInfo.serviceConfigs.tenderConfig.defaultFilters
        : ['type$Public$0'];

    params.filters =
      params.filters && params.filters instanceof Array
        ? params.filters.some((x: string) => x.indexOf('type') > -1)
          ? params.filters
          : staticFilters.concat(params.filters)
        : staticFilters;

    await super.getData(urlPostfix, params, forceGet, options);
  }

  public async getTenderById(tenderId: string): Promise<Tender> {
    await super.getData(tenderId, null, true);
      const tender: any = this.collection.items;
      return tender as Tender;
  }

  public async getDataById(
    id: string,
    urlPostfix: string = '',
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<Tender> {
    return await super
      .getDataById(id, urlPostfix, forceGet, options)
      .then(async (tender: Tender) => {
        if (tender) {
          // stock ids
          const stockIds = tender.stocks.map((item: TenderStock) => {
            return item.stockId;
          });

          if (stockIds.length > 0) {
            // get view count
            await this.transactionsCountService
              .getBatchViewsCount(stockIds)
              .then(() => {
                // replace view count
                const viewCounts = this.transactionsCountService.collection
                  .item as any;
                tender.stocks = tender.stocks.map((item: TenderStock) => {
                  const details = item.details as Stock;

                  if (Object.keys(details).length > 0) {
                    const count = viewCounts.find(
                      vcount => vcount.id == details.id
                    );
                    details.viewCount = count ? (count.count as number) : 0;

                    item.details = details;
                  }
                  return item;
                });
              });
          }
        }

        return tender;
      });
  }

  public async join(id: string): Promise<void> {
    await this.saveItem( { id: id } as Tender, `join/${id}`, true, false, false, true);
  }

  public async getBuyerTendersCount(): Promise<number> {
    const staticFilters = [
      'type$Public$0',
      'status$Created$1',
      'status$Scheduled$1',
      'status$Closing$1',
      'status$Running$1'
    ];

    let params: any = {};

    params.filters = staticFilters;
    params.pageSize = 1;

   await super.getData('', params, true);
   return this.collection.totalCount;
  }

  public async getTenderId(stockId: string): Promise<string> {
    super.configure('buyer/tender/v3/tenders');
    return await super
      .getData(`stock/${stockId}/recentTenderId`, null, true)
      .then(() => {
        return this.collection['_data'];
      })
      .finally(() => super.configure('buyer/tender/v2/tenders'));
  }

  public async getTenderDetails(stockIds: string[]): Promise<any[]> {
    super.configure('buyer/tender/v1/tenders');
   let url = stockIds.reduce((url, id) => url =  url + "stockIds=" + id + "&", `stock/RecentTender/Details?`);
    return await super
      .getData(url, null, true)
      .then(() => {
        return this.collection['_data'];
      })
      .finally(() => super.configure('buyer/tender/v2/tenders'));
  }
}
