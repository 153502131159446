import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CompareValidator } from './compare-validator';

export function bindValidations(validations: any) {
  if (validations.length > 0) {
    const validList = [];
    validations.forEach((valid: any) => {
      const validatorItem = validator(
        valid.name,
        valid.confirmControl,
        valid.min,
        valid.max,
        valid.pattern
      );
      validList.push(validatorItem);
    });

    return Validators.compose(validList);
  }

  return null;
}

export function validator(
  name: string,
  confirmControl?: string,
  minNumber?: number,
  maxNumber?: number,
  pattern?: string | RegExp
): any {
  switch (name) {
    case 'required':
      return Validators.required;

    case 'compare':
      return CompareValidator.compare(confirmControl);

    case 'email':
      return Validators.email;

    case 'min':
      return Validators.min(minNumber);

    case 'max':
      return Validators.max(maxNumber);

    case 'pattern':
      return Validators.pattern(pattern);

    case 'greaterThan':
      return greaterThan(minNumber);
  }
}

export function greaterThan(requiredValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | undefined => {
    let value: number = control.value;
    if (isNaN(value) || value <= requiredValue) {
      return { 'greaterThan': true, 'requiredValue': requiredValue };
    }

    return undefined;
  }
}
