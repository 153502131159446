import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SafeStorageService {
  public static readonly NAME = 'safeStorageService';
  private state: {};

  public constructor(private storageService: StorageService) {}

  public get(key: string): string | null {
    try {
      // Try local storage
      return this.storageService.get(key);
    } catch (err) {
      // Catch error and return value from state
      return this.state[key];
    }
  }

  public remove(key: string): void {
    try {
      this.storageService.remove(key);
    } catch (err) {
      this.state[key] = null;
    }
  }

  public set(key: string, value: string): void {
    try {
      this.storageService.set(key, value);
    } catch (err) {
      this.state[key] = value;
    }
  }
}


