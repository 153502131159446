import { Injectable } from '@angular/core';

import { ImportStock, ImportStockFeatures, ImportVinYearStock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class IntegrationStocksCSVCollection extends DataCollection<
  any
> {}

@Injectable({
  providedIn: 'root'
})
export class SellerStocksCSVService extends ItemsService<any> {
  protected serviceName = 'SellerStocksCSVService';
  public stock: any;

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('seller/mezzanine/v1');
  }

  public get collection(): IntegrationStocksCSVCollection {
    return new IntegrationStocksCSVCollection(this.internalData);
  }

  public async save(item: ImportStock): Promise<void> {
    // convert types
    item.features = this.convertTypes<ImportStockFeatures>(
      item.features,
      ImportStockFeatures
    );

    item = this.convertTypes<ImportStock>(item, ImportStock);

    await this.saveItem(item, 'stock', false, false, false, true, false);
  }

  public async pushBasicCsv(item: ImportVinYearStock[]): Promise<void> {
    return await this.saveItem(item, 'stocks', false, true, false, true, false)
  }

  public async saveClient(item: ImportStock): Promise<void> {
    // convert types
    item.features = this.convertTypes<ImportStockFeatures>(
      item.features,
      ImportStockFeatures
    );

    super.updateClientItem(item, null, true);
  }

  public async deleteClient(item: ImportStock): Promise<void> {
    super.deleteClientItem(item, true, true);
  }
}
