import { Identity } from './identity';

export class Chat extends Identity {
  message: string = '';
  entityType: string = '';
  entityId: string = '';
  isOffline: boolean = false;
  metaData?: ChatMetaData;

  constructor() {
    super();
  }
}

export class ChatMetaData {
  senderFullName: string = '';

  constructor() {}
}
