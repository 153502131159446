import { Injectable, OnDestroy } from '@angular/core';

import { ActionModel, Auction, AuctionBid, AuctionBidInfo, AuctionEndTimeActionModel, AuctionInfo, AuctionStatusActionModel, AuctionStockAction, AuctionStocksStatusActionModel, AuctionStocksUpdateActionModel, AuctionUpdateActionModel, CounterOfferActionModel, CounterOfferResponseActionModel, InvalidBidActionModel, Tenant } from '@app/model';
import { Logger } from '@app/shared';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

import { HttpParams } from '@angular/common/http';

import * as signalR from '@microsoft/signalr';
import { StateService } from '@app/core';
import { BehaviorSubject, Subject, Subscription, throwError } from 'rxjs';
import * as $ from 'jquery';
import { DefaultHttpClient, HttpRequest, HttpResponse } from '@microsoft/signalr';
import { PublicTenantV2Service } from './public-tenant-v2.service';
export class SignalLogger implements signalR.ILogger {
  log(logLevel: signalR.LogLevel, message: string) {
    // Use `message` and `logLevel` to record the log message to your own system
  }
}
class SignalRHttpClient extends DefaultHttpClient {

  private tenantCode: string;
  private userId: string;
  private orgId: string;
  public constructor(tenantCode: string, orgId: string, userId: string, logger: signalR.ILogger) {
    super(logger);
    this.tenantCode = tenantCode;
    this.orgId = orgId;
    this.userId = userId;
  }
  public send(request: HttpRequest): Promise<HttpResponse> {
    request.headers = {
      ...request.headers,
      "X-Tenant-Code": this.tenantCode,
      "X-Organization-Id": this.orgId,
      "X-User-Id": this.userId,
    };
    return super.send(request);
  }
}

export class AuctionCollection extends DataCollection<
  Auction
> { }

@Injectable({
  providedIn: 'root'
})
export class AuctionService extends ItemsService<Auction> implements OnDestroy {
  protected serviceName = 'AuctionService';

  public readonly isServiceReady = $.Deferred();
  private readonly onBidInfoUpdated$ = new BehaviorSubject<AuctionBidInfo>(undefined);
  public readonly onBidInfoUpdated = this.onBidInfoUpdated$.asObservable();
  private readonly onStatusUpdated$ = new BehaviorSubject<AuctionStatusActionModel>(undefined);
  public readonly onStatusUpdated = this.onStatusUpdated$.asObservable();
  private readonly onEndTimeUpdated$ = new BehaviorSubject<AuctionEndTimeActionModel>(undefined);
  public readonly onEndTimeUpdated = this.onEndTimeUpdated$.asObservable();
  private readonly onAuctionStocksUpdated$ = new BehaviorSubject<AuctionStocksUpdateActionModel>(undefined);
  public readonly onAuctionStocksUpdated = this.onAuctionStocksUpdated$.asObservable();
  private readonly onAuctionStocksStatusUpdated$ = new BehaviorSubject<AuctionStocksStatusActionModel>(undefined);
  public readonly onAuctionStocksStatusUpdated = this.onAuctionStocksStatusUpdated$.asObservable();
  private readonly onAuctionInfoUpdated$ = new BehaviorSubject<AuctionUpdateActionModel>(undefined);
  public readonly onAuctionInfoUpdated = this.onAuctionInfoUpdated$.asObservable();
  private readonly onCounterOfferActionModel$ = new BehaviorSubject<CounterOfferActionModel>(undefined);
  public readonly onCounterOfferActionModel = this.onCounterOfferActionModel$.asObservable();
  private readonly onCounterOfferResponseActionModel$ = new BehaviorSubject<CounterOfferResponseActionModel>(undefined);
  public readonly onCounterOfferResponseActionModel = this.onCounterOfferResponseActionModel$.asObservable();
  private readonly onInvalidBidActionModel$ = new BehaviorSubject<InvalidBidActionModel>(undefined);
  public readonly onInvalidBidActionModel = this.onInvalidBidActionModel$.asObservable();

  public readonly refreshAuctionStockInfo = new Subject<void>();

  public eventId: string = '';
  public offerStatus: string = '';

  private subscriptions: Subscription[] = [];
  private connection: signalR.HubConnection;
  private isConnectionReady = $.Deferred();
  private unsubscribePromise: Promise<any>;
  private subscribedAuctionId = [];

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private stateService: StateService,
    private publicTenantService: PublicTenantV2Service
  ) {
    super(dataAccessService, logger);
    super.configure('tender/v1/transactions');
    this.subscriptions.push(this.stateService.isProfileLoaded$.subscribe(this.onProfileLoaded.bind(this)));
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  public onProfileLoaded(isLoaded: boolean): void {
    if (!isLoaded) {
      return;
    }

    this.isServiceReady.resolve();
  }

  public get collection(): AuctionCollection {
    return new AuctionCollection(this.internalData);
  }

  public async getAuctionBidInfo(isBuyer: boolean,
    entityId: string, userId: string, auctionId: string
  ): Promise<AuctionBidInfo> {
    super.configure(`${isBuyer ? 'buyer' : 'seller'}/auction/v1/getbidinfo`);
    const param = new HttpParams()
      .set('entityId', entityId)
      .set('userId', userId)
      .set('auctionId', auctionId);
    let response = await this.dataAccess.getData(`${isBuyer ? 'buyer' : 'seller'}/auction/v1/getbidinfo`,
      null, param, false);
    return response as AuctionBidInfo;
  }

  public async postAuctionBid(bid: AuctionBid): Promise<any> {
    let response = await this.dataAccess.postData(bid,
      `buyer/auction/v1/postbid`);
    return response;
  }

  public async getAuctionInfo(isBuyer: boolean, auctionId: string, userId?: string): Promise<AuctionInfo> {
    const url = `${isBuyer ? 'buyer' : 'seller'}/auction/v1/getauctioninfo`;
    super.configure(url);
    let params = undefined;
    if (userId) {
      params = {
        userId: userId
      };
    }
    let response = await this.dataAccess.getData(url, auctionId, params, false);
    return response as AuctionInfo;
  }

  public async startConnection(): Promise<void> {
    this.subscribedAuctionId = [];
    let auctionConfig = await this.publicTenantService.getTenantAuctionConfig();
    if (auctionConfig.IsEnabled == false)
      return;
    if (auctionConfig.SignalRService == null || auctionConfig.SignalRService == undefined || auctionConfig.SignalRService == "")
      throw throwError("SignalRService not defined");
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(auctionConfig.SignalRService, {
        httpClient: new SignalRHttpClient(this.stateService.tenantCode, this.stateService.organizationId, this.stateService.currentUserId, new SignalLogger()),
        accessTokenFactory: () => {
          return this.stateService.token
        }
      })
      .withAutomaticReconnect([0, 2000, 10000, 15000, 20000, 25000, 30000, 60000, null])
      .configureLogging(signalR.LogLevel.Information)
      .build();
    this.connection.on('onUpdated', this.onUpdated.bind(this));
    this.connection.onreconnected(() => {
      for (const id of this.subscribedAuctionId) {
        this.subscribeToAuction(id);
      }
    });
    await this.connection.start();
    this.isConnectionReady.resolve();
  }

  private onUpdated(message: ActionModel) {
    message = message || new ActionModel();
    switch (message.type) {
      case 'AuctionBidInfo':
        this.onBidInfoUpdated$.next(message as AuctionBidInfo);
        break;
      case 'AuctionStatusActionModel':
        this.onStatusUpdated$.next(message as AuctionStatusActionModel);
        break;
      case 'AuctionEndTimeActionModel':
        this.onEndTimeUpdated$.next(message as AuctionEndTimeActionModel);
        break;
      case 'AuctionStocksUpdateActionModel':
        this.onAuctionStocksUpdated$.next(message as AuctionStocksUpdateActionModel);
        break;
      case 'AuctionStocksStatusActionModel':
        this.onAuctionStocksStatusUpdated$.next(message as AuctionStocksStatusActionModel);
        break;
      case 'AuctionUpdateActionModel':
        this.onAuctionInfoUpdated$.next(message as AuctionUpdateActionModel);
        break;
      case 'CounterOfferActionModel':
        this.onCounterOfferActionModel$.next(message as CounterOfferActionModel);
        break;
      case 'CounterOfferResponseActionModel':
        this.onCounterOfferResponseActionModel$.next(message as CounterOfferResponseActionModel);
        break;
      case 'InvalidBidActionModel':
        this.onInvalidBidActionModel$.next(message as InvalidBidActionModel);
        break;
      default:
        break;
    }
  }

  public async stopConnection(): Promise<void> {
    if (this.connection == null) {
      return;
    }

    try {
      await this.isConnectionReady.promise();
      if (this.unsubscribePromise !== undefined) {
        await this.unsubscribePromise;
      }
    } finally {
      try {
        await this.connection.stop();
      } finally {
        this.resetSubjects();
        this.subscribedAuctionId = [];
        this.connection = undefined;
        this.isConnectionReady.reject();
        this.isConnectionReady = $.Deferred();
      }
    }
  }

  public async subscribeToAuction(auctionId: string): Promise<void> {
    await this.isConnectionReady.promise();
    await this.connection.invoke("subscribeToAuction", auctionId);
    const index = this.subscribedAuctionId.findIndex(x => x === auctionId);
    if (index < 0) {
      this.subscribedAuctionId.push(auctionId);
    }
  }

  public async unsubscribeFromAuction(auctionId: string): Promise<void> {
    await this.isConnectionReady.promise();
    try {
      this.unsubscribePromise = this.connection.invoke("unsubscribeFromAuction", auctionId);
      await this.unsubscribePromise;
    } finally {
      this.unsubscribePromise = undefined;
      this.resetSubjects();
      const index = this.subscribedAuctionId.findIndex(x => x === auctionId);
      if (index >= 0) {
        this.subscribedAuctionId.splice(index, 1);
      }
    }
  }

  private resetSubjects() {
    this.onBidInfoUpdated$.next(undefined);
    this.onStatusUpdated$.next(undefined);
    this.onEndTimeUpdated$.next(undefined);
    this.onAuctionStocksUpdated$.next(undefined);
    this.onAuctionStocksStatusUpdated$.next(undefined);
    this.onAuctionInfoUpdated$.next(undefined);
    this.onCounterOfferActionModel$.next(undefined);
    this.onCounterOfferResponseActionModel$.next(undefined);
    this.onInvalidBidActionModel$.next(undefined);
  }

  public async postStockSellerAction(action: AuctionStockAction): Promise<any> {
    let response = await this.dataAccess.postData(action,
      `seller/auction/v1/postuseraction`);
    return response;
  }

  public async postStockBuyerAction(action: AuctionStockAction): Promise<any> {
    let response = await this.dataAccess.postData(action,
      `buyer/auction/v1/postuseraction`);
    return response;
  }

  public async getBuyerPendingActions(userId: string): Promise<any> {
    let response = await this.dataAccess.getData(`buyer/auction/v1/GetPendingActions/${userId}`);
    return response;
  }
}
