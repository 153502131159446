import { Component } from '@angular/core';
import { StateService } from '@app/core';

@Component({
  selector: 'app-environment-name',
  templateUrl: './environment-name.component.html'
})
export class EnvironmentNameComponent {
  constructor(private state: StateService) {}

  public get name(): string {
    let name = '';

    this.state.on(this.state.isAdminApp$, () => {
      name = this.state.environment.name;
    });

    return name;
  }
}
