import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

import {
  combineLatest,
  debounceTime,
  takeUntil,
  map,
  tap,
  filter,
  distinctUntilChanged
} from 'rxjs/operators';

import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';

import { bindValidations } from '@app/validator';
import { SharedService, Logger, Dict } from '@app/shared';

import moment, { Moment } from 'moment';
import { StateService } from '@app/core';
import { ResendConfirmationService } from '@app/data';
import { Dictionary } from 'lodash';

declare let $: any;

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html'
})
export class DynamicFormComponent {
  public paramId?: string;

  @Input() data$: Observable<any>;
  @Input() isUser$ = new BehaviorSubject<boolean>(false);
  @Input() isEmailConfirmed = new BehaviorSubject<boolean>(false);
  @Input() steps$ = new BehaviorSubject<any[]>([]);
  @Input() isTouched$: Observable<boolean>;
  @Input() isEditing$ = new BehaviorSubject<boolean>(false);
  @Input() canEdit$ = new BehaviorSubject<boolean>(true);
  @Input() canDelete$ = new BehaviorSubject<boolean>(true);
  @Input() canCancel$ = new BehaviorSubject<boolean>(true);
  @Input() isNeedToResetForm$ = new BehaviorSubject<boolean>(false);
  @Input() isSecondSubmitVisible$ = new BehaviorSubject<boolean>(false);
  @Input() isAllFieldsReadOnly = false;

  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() secondSubmit: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() resend: EventEmitter<any> = new EventEmitter();
  @Output() updateForm: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;
  public fields$ = new BehaviorSubject<any[]>([]);
  public currentStep$ = new BehaviorSubject<number>(0);
  public childrenArray: FormArray;

  public unsubscribe$: Subject<void> = new Subject();
  public isFormCreated$ = new BehaviorSubject<boolean>(false);

  constructor(
    public shared: SharedService,
    public formBuilder: FormBuilder,
    public state: StateService,
    private logger: Logger,
    private resendConfirmation: ResendConfirmationService
  ) { }

  public async ngOnInit(): Promise<void> {
    this.paramId = this.shared.params.get('id');

    this.state.on(this.steps$, (steps: any) => {
      if (steps.length > 0) {
        this.setForm();
      }
    });
  }

  public setForm(): void {
    this._getFields();

    if (this.isTouched$) {
      this.isTouched$
        .pipe(
          filter(x => !x && !!this.form),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(() => this.form.reset());
    }
  }

  public onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.form.valid) {
      // submit or second submit
      this.isSecondSubmitVisible
        ? this.secondSubmit.emit(this.value)
        : this.submit.emit(this.value);

      // reset form
      if (this.isNeedToResetForm) {
        this.form.reset();
      }
    } else {
      this._validateAllFormFields(this.form);
    }
  }

  public onDelete() {
    this.delete.emit(this.value);
  }

  public onBack() {
    this.back.emit();
  }

  public async onResend() {
    let result = await this.resendConfirmation.sendById(this.paramId);

    if (!result) {
      this.logger.error(
        `Resend Email Confirmation Failed`, this.paramId
      );
    }

    else {
      this.logger.success(
        `Resend Email Confirmation was Successful`, this.paramId
      );
      $("#resend").prop('disabled', true);
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onNextStep(): void {
    this._setStep(this.currentStep$.getValue() + 1);
  }

  public onPreviousStep(): void {
    this._setStep(this.currentStep$.getValue() - 1);
  }

  public canGoPrevious(): boolean {
    return this.currentStep$.getValue() > 0;
  }

  public addNewChild(children: any): void {
    this.childrenArray.push(this._addChildren(children));
  }

  public removeChild(index: number, hasDefaultItem: boolean): void {
    if (hasDefaultItem == undefined || hasDefaultItem == false) {
      this.childrenArray.removeAt(index);
      return;
    };
    if (index == 0 && this.childrenArray.length == 1) {
      let key = Object.entries(this.childrenArray.value[0]).map(([k, v]) => k)[0];
      let dictionary: Dictionary<string> = {};
      dictionary[key] = "";
      setTimeout(() => {
        this.childrenArray.setValue([dictionary]);
      });
    }
    else {
      this.childrenArray.removeAt(index);
    }
  }

  public canGoNext(): boolean {
    return this.currentStep$.getValue() < this.steps$.getValue().length - 1;
  }

  public goToStep(step: number): void {
    this._setStep(step);
  }

  public getSubmitButtonText(step: any) {
    return step.submitText ? step.submitText : 'Save';
  }

  public getSubmitButtonClass(step: any) {
    return step.submitClass
      ? step.submitClass
      : 'btn btn-primary col-lg-1 col-lg-2 pull-right mr-md-3 mb-xl-3';
  }

  public getSecondSubmitButtonText(step: any) {
    return step.secondSubmitText ? step.secondSubmitText : 'Save';
  }

  public getSecondSubmitButtonClass(step: any) {
    return step.submitClass
      ? step.submitClass
      : 'btn btn-primary col-lg-1 col-lg-2 pull-right mr-md-3 mb-xl-3';
  }

  public getCancelButtonClass(step: any) {
    return step.submitClass
      ? step.cancelClass
      : 'btn btn-outline-default col-lg-1 col-lg-2 pull-right mr-md-3';
  }

  public getResendConfirmationClass(step: any) {
    return step.submitClass
      ? step.cancelClass
      : 'btn btn-outline-info col-lg-1 col-lg-2 pull-right mr-md-3';
  }

  // getters
  public get isEditing() {
    return this.isEditing$.getValue();
  }

  public get canEdit() {
    return this.canEdit$.getValue();
  }

  public get canDelete() {
    return this.canDelete$.getValue();
  }

  public get canCancel() {
    return this.canCancel$.getValue();
  }

  public get isUser() {
    return this.isUser$;
  }

  public get isNeedToResetForm() {
    return this.isNeedToResetForm$.getValue();
  }

  public get isSecondSubmitVisible() {
    return this.isSecondSubmitVisible$.getValue();
  }

  public get isReadOnly(): boolean {
    return !this.canEdit && !this.canDelete;
  }

  public get value(): any {
    let value: any = {};

    // get values in steps
    this.steps$.getValue().forEach((step: any) => {
      value = Object.assign(value, this.getRealValues(step));
    });

    return value;
  }

  public getRealValues(step: any) {
    const exceptValues = step.exceptValues;
    const value = this.form.value[step.id];
  
    const result = Object.keys(value).reduce((acc, key) => {
      if (
        value[key] instanceof Array ||
        (value[key] instanceof Object &&
          Object.keys(value[key]).length > 0 &&
          !moment.isMoment(value[key]))
      ) {
        acc[key] = this.filterValue(value[key], exceptValues);
      }
      return acc;
    }, {});
  
    return { ...result, ...this.filterValue(value, exceptValues) };
  }

  public filterValue(value: any, exceptValues: any): any {
    if (value instanceof Array) {
      return this.getValues4Array(value, exceptValues);
    } else {
      return Object.keys(value)
        .filter(
          mainKey =>
            (exceptValues && !exceptValues.includes(mainKey)) ||
            exceptValues == null
        )
        .reduce((object, key) => {
          if (value[key] instanceof Array) {
            object[key] = this.getValues4Array(value[key], exceptValues);
          } else if (
            !(
              value[key] instanceof Object &&
              Object.keys(value[key]).length > 0 &&
              !moment.isMoment(value[key])
            )
          ) {
            object[key] = this.convertDate2UTC(value[key]);
          }

          return object;
        }, {});
    }
  }

  public getValues4Array(value: any, exceptValues: any): any {
    if (value.length > 0 && value[0] instanceof Object) {
      return value
        .filter(
          (mainKey: any) =>
            (exceptValues && !exceptValues.includes(mainKey)) ||
            exceptValues == null
        )
        .map((item: any) => {
          return this.convertObject2UTC(item);
        });
    } else {
      return value.map((item: any) => {
        return this.convertDate2UTC(item);
      });
    }
  }

  public convertObject2UTC(value: any): any {
    return Object.keys(value).reduce((object, key) => {
      object[key] = this.convertDate2UTC(value[key]);
      return object;
    }, {});
  }

  public convertDate2UTC(value: any): any {
    if (moment.isMoment(value)) {
      const dateTime = value as Moment;
      value = dateTime.isUTC() ? value : moment.utc(value.format());
    }

    return value;
  }

  public get isMultiSteps(): boolean {
    return this.steps$.getValue().length > 1;
  }

  public showCurrentStep(index: number): string {
    return index === this.currentStep$.getValue() ? '' : 'd-none';
  }

  public isActive(index: number): string {
    return index === this.currentStep$.getValue() ? 'active' : '';
  }

  public activeBreadCrumb(index: number): string {
    return index === this.currentStep$.getValue() ? 'text-success' : '';
  }

  public setForm4Buttons(
    field: any,
    stepId: string,
    arrayIndex?: number,
    arrayChild?: any,
    objectName?: string
  ): FormGroup {
    return objectName != null
      ? this.getForm4ObjectInnerChild(
        stepId,
        field.name,
        arrayIndex,
        arrayChild,
        objectName
      )
      : field.type !== 'button' &&
        field.type !== 'object' &&
        field.type !== 'children'
        ? this.getForm4Step(stepId)
        : field.type === 'object'
          ? this.getForm4Object(stepId, field.name)
          : field.type === 'children'
            ? this.getForm4Child(stepId, field.name, arrayIndex, arrayChild)
            : this.form;
  }

  public getForm4Step(stepId: string): FormGroup {
    return <FormGroup>this.form.controls[stepId];
  }

  public getForm4ObjectInnerChild(
    stepId: string,
    children: string,
    arrayIndex: number,
    arrayChild: any,
    objectName: string
  ): FormGroup {
    const group = this.getForm4Object(stepId, objectName);
    return this.getForm4Child(stepId, children, arrayIndex, arrayChild, group);
  }

  public getForm4Child(
    stepId: string,
    children: string,
    arrayIndex: number,
    arrayChild?: any,
    group: FormGroup = this.getForm4Step(stepId)
  ): FormGroup {
    const array = <FormArray>group.controls[children];
    const currentGroup = <FormGroup>array.controls[arrayIndex];
  
    return arrayChild
      ? <FormGroup>currentGroup.controls[arrayChild.name]
      : <FormGroup>array.controls[arrayIndex];
  }

  public getForm4Object(stepId: string, name: string): FormGroup {
    const group = this.getForm4Step(stepId);
    return <FormGroup>group.controls[name];
  }

  // privates
  private _setStep(step: number) {
    this.currentStep$.next(step);
  }

  private _getFields(): void {
    this.steps$
      .pipe(
        map(this._createForm),
        tap(form => (this.form = form)),
        tap(form => this._listenFormChanges(form)),
        combineLatest(this.data$),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this._patchValue);
  }

  private _createForm = (steps: any[]): FormGroup => {
    let group: FormGroup = new FormGroup({});
    group = this._createSteps(steps, group);

    this.isFormCreated$.next(true);
    return group;
  };

  private _createSteps = (steps: any[], group: FormGroup): FormGroup => {
    steps.forEach((step: any) => {
      const stepGroup: FormGroup = new FormGroup({});
      const fields = step.fields;
  
      fields.forEach((field: any) => {
        if (this._shouldSkipField(field)) return;
  
        const control = this._createControl(field);
        stepGroup.addControl(field.name, control);
  
        if (
          field.type === 'checkboxCollapse' ||
          field.type === 'toggleCollapse' ||
          field.type === 'radioCollapse'
        ) {
          this._collapseControls(stepGroup, field.collapse);
        }
      });
  
      group.addControl(step.id, stepGroup);
    });
  
    return group;
  };
  
  private _shouldSkipField(field: any): boolean {
    if (field.type === 'button') return true;
    if (field.name === 'id' && field.value == null && !this.isEditing) return true;
    if (field.noNeedInCreate && !this.isEditing) return true;
    if (field.noNeedInEditing && this.isEditing) return true;
  
    return false;
  }
  
  private _createControl(field: any) {
    switch (field.type) {
      case 'object':
        return this._object(field.object);
      case 'children':
        return this._array(field.children);
      default:
        return this._control(field);
    }
  }

  private _collapseControls = (group: FormGroup, controls: any): void => {
    controls.forEach((control: any) => {
      group.addControl(control.name, this._control(control));
    });
  };

  private _control = (field: any): FormControl => {
    // set state validations
    const updatedField = this._setStateValidations(field);
  
    const validations = updatedField.validations ? bindValidations(updatedField.validations) : null;
  
    return this.formBuilder.control(
      updatedField.value,
      validations
    );
  };

  private _setStateValidations(field: any): any {
    const updatedField = { ...field };
  
    if ('validations' in updatedField && updatedField.validations) {
      updatedField.validations.forEach((validation: any) => {
        if (validation.name === 'pattern' && 'parentStateKey' in validation) {
          const parentStateValidations = this.state.tenantInfo.validations[validation.parentStateKey];
  
          // pattern
          validation.pattern = parentStateValidations[validation.patternStateKey];
  
          // message
          validation.message = parentStateValidations[validation.messageStateKey];
        }
      });
    }
  
    return updatedField;
  }

  private _object = (object: any): FormGroup => {
    return this._addChildren(object);
  };

  private _array = (children: any): FormArray => {
    this.childrenArray = new FormArray([]);
    this.childrenArray.push(this._addChildren(children));
    return this.childrenArray;
  };

  private _addChildren = (children: any): FormGroup => {
    const group: FormGroup = new FormGroup({});
    const collapseTypes = new Set(['checkboxCollapse', 'toggleCollapse', 'radioCollapse']);
  
    children.forEach((child: any) => {
      const control =
        child.type === 'object'
          ? this._object(child.object)
          : child.type === 'children'
            ? this._array(child.children)
            : this._control(child);
  
      group.addControl(child.name, control);
  
      if (collapseTypes.has(child.type)) {
        this._collapseControls(group, child.collapse);
      }
    });
  
    return group;
  };

  private _validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.controls[field];
      control.markAsTouched({ onlySelf: true });
    });
  }

  private _listenFormChanges(form: FormGroup): void {
    form.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((changes: any) => this.updateForm.emit(changes));
  }

  private _patchValue = (data: any): void => {
    data = data instanceof Array && data.length > 0 ? data[1] : data;

    this.steps$.getValue().forEach((step: any) => {
      let form = <FormGroup>this.form.controls[step.id];

      Object.keys(data).forEach((key: any) => {
        const value = data[key];

        // array
        if (value instanceof Array) {
          // children
          form = this._makeChildren(form, key, value);
        }
        // object
        else if (value instanceof Object) {
          Object.keys(value).forEach((objectKey: any) => {
            const objectValue = value[objectKey];

            // array
            if (objectValue instanceof Array) {
              // children
              form = this._makeChildrenInObject(form, objectKey, objectValue);
            }
          });
        }
        // not children
        else {
          !!data
            ? form.patchValue(data, { emitEvent: false })
            : form.patchValue({}, { emitEvent: false });
        }
      });
    });
  };

  private _makeChildrenInObject(
    form: FormGroup,
    key: any,
    value: any
  ): FormGroup {
    const steps = this.steps$.getValue();

    steps.forEach(step => {
      const fields = step.fields;

      fields.forEach((field: any) => {
        if (field.type === 'object') {
          const objects = field.object as Array<any>;
          let objectForm = <FormGroup>form.controls[field.name];

          objects.forEach((object: any) => {
            if (object.name === key && object.children) {
              objectForm.setControl(
                key,
                this._setExistingChildren(object.children, value)
              );
            }
          });

          form.setControl(field.name, objectForm);
        }
      });
    });

    return form;
  }

  private _makeChildren(form: FormGroup, key: any, value: any): FormGroup {
    const steps = this.steps$.getValue();

    steps.forEach(step => {
      const fields = step.fields;

      fields.forEach((field: any) => {
        if (field.name === key && field.children) {
          form.setControl(
            key,
            this._setExistingChildren(field.children, value)
          );
        }
      });
    });

    return form;
  }

  private _setExistingChildren(children: any, data: any[]): FormArray {
    this.childrenArray = new FormArray([]);

    data.forEach(control => {
      const group: FormGroup = new FormGroup({});

      Object.keys(control).forEach(key => {
        children.forEach((child: any) => {
          if (child.name === key) {
            const innerChild = child.child;
            if (innerChild) {
              this._addChildControl(
                group,
                innerChild,
                control[innerChild.name]
              );
            }

            this._addChildControl(group, child, control[key]);
          } else if (child.isOnlyOnClient) {
            this._addChildControl(
              group,
              child,
              control[child.valueOnlyOnClient]
            );
          } else if (child.type === 'object') {
            const childGroup: FormGroup = new FormGroup({});

            Object.keys(child.object).forEach((index: any) => {
              const item = child.object[index];
              const innerControl = control[child.name];
              this._addChildControl(childGroup, item, innerControl[item.name]);
            });

            group.setControl(child.name, childGroup);
          }
        });
      });

      this.childrenArray.push(group);
    });

    return this.childrenArray;
  }

  private _addChildControl(group: FormGroup, child: any, value: any) {
    const field = {
      value: value,
      validations: 'validations' in child ? child['validations'] : null
    };

    group.addControl(child.name, this._control(field));
  }
  trackByIndex(index: number): number {
    return index;
  }
  shouldDisplayField(field: any): boolean {
    return ((field.name === 'id' && field.value != null && this.isEditing === true) || field.name !== 'id') && (field.noNeedInCreate == null || this.isEditing === true) && (field.noNeedInEditing == null || this.isEditing === false);
  }
  
  get isFieldReadOnly(): boolean {
    return this.isReadOnly || this.isAllFieldsReadOnly;
  }
}
