import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../../translate/src/lib/translate.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(key: any): any {
    const result = this.translate.item[key] || key;
    return result ? result : '';
  }
}
