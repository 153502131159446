import { Identity } from './identity';

export class Hub extends Identity {
  hub: string;
  url: string;

  constructor() {
    super();
  }
}
