import { Injectable } from '@angular/core';

import { Stock, StockStatus } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class SellerStocksManageCollection extends DataCollection<Stock> { }

import { HttpHeaders } from '@angular/common/http';
import { TransactionsCountService } from './transactions-count.service';

@Injectable({
  providedIn: 'root'
})
export class SellerStocksManageService extends ItemsService<Stock> {
  protected serviceName = 'SellerStocksManageService';
  public stock: any;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private transactionsCountService: TransactionsCountService
  ) {
    super(dataAccessService, logger);
    super.configure('seller/catalog/v1/stocks');
  }

  public get collection(): SellerStocksManageCollection {
    return new SellerStocksManageCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = null,
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    const staticFilters = ['status$Available$1', 'status$Hidden$1'];

    let localUrlPostfix = 'organization';

    params.filters =
      params.filters && params.filters instanceof Array
        ? params.filters.some((x: string) => x.indexOf('status') > -1)
          ? params.filters
          : staticFilters.concat(params.filters)
        : staticFilters;

    await super
      .getData(localUrlPostfix, params, forceGet, options);

    // let items = this.collection.items;
    if (this.collection.items.length <= 0) {
      return;
    };
    // stock ids
    const stockIds = this.collection.items.map((item: Stock) => item.id);

    // get view count
    await this.transactionsCountService
      .getBatchViewsCount(stockIds);
    // replace view count
    let items = this.collection.items.map((item: Stock) => {
      const count = (this.transactionsCountService.collection.item as any).find(vcount => vcount.id == item.id)
      item.viewCount = count ? count.count as number : 0
      return item;
    });

    // change data

    this.init({
      items: items,
      pageNumber: this.collection.pageNumber,
      totalPages: this.collection.totalPages,
      totalCount: this.collection.totalCount,
      hasPreviousPage: this.collection.hasPreviousPage,
      hasNextPage: this.collection.hasNextPage
    });

  }

  public async setHide(ids: string[]): Promise<void> {
    const item = { status: StockStatus.Hidden, ids: ids } as any;
    await this.saveItem(item, 'status', true);
  }

  public async setUnHide(ids: string[]): Promise<void> {
    const item = { status: StockStatus.Available, ids: ids } as any;
    await this.saveItem(item, 'status', true);
  }
}
