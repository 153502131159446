import { NgZone } from '@angular/core';
import { Injectable } from '@angular/core';
import { HTTPError, Logger } from '@app/shared';
import { Subject, BehaviorSubject, throwError } from 'rxjs';

import { map, catchError, timeout } from 'rxjs/operators';
import { StateService } from '@app/core';

import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEventType,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

function responseError(response: HttpResponse<any>): HTTPError {
  let message: any = null;
  try {
    message = response.statusText;
  } catch (e) {
    message = 'request status cannot be intercepted';
  }
  return new HTTPError(response.status, message);
}

@Injectable({
  providedIn: 'root'
})
export class DataAccessService {
  public static readonly NAME = 'dataAccessService';
  public baseUrl: string = '';
  public timeoutValue: number = 600000;

  public requestStarted = new Subject<void>();
  public requestFinished = new Subject<void>();

  private static checkInAngularZone(method: string, url: string): void {
    if (!NgZone.isInAngularZone()) {
      console.warn(`Request made from outside angular zone: ${method} ${url}`);
      console.warn(new Error().stack);
    }
  }

  public constructor(
    private http: HttpClient,
    private logger: Logger,
    private state: StateService
  ) {
    this.state.on(this.state.environment$, (environment: any) => {
      if (environment) {
        this.baseUrl = environment.baseApiUrl;
      }
    });
  }

  private _setHeaders(options: HttpHeaders): HttpHeaders {
    // think about null guard later

    const accessToken = this.state.token;
    if (accessToken) {
      options = options.set('Authorization', `Bearer ${accessToken}`);
    }

    const tenantCode = this.state.tenantCode;
    if (tenantCode) {
      options = options.set('X-Tenant-Code', tenantCode);
    }

    const userId = this.state.currentUserId;
    options = options.set('X-User-Id', userId ? userId : 'Unknown');

    const organizationId = this.state.organizationId;
    options = options.set(
      'X-Organization-Id',
      organizationId ? organizationId : 'Unknown'
    );

    return options;
  }

  private _handleError(responseFailure: any): void {
    // bad requests
    if (responseFailure.status === 400) {
      const response = responseFailure.error;

      if (response) {
        if (response instanceof Array) {
          return;
        } else {
          Object.keys(response).forEach((index: any) => {
            let errors = response[index];
            if (!(errors instanceof Array)) return;

            errors = errors as Array<any>;
            errors.forEach((error: any) => {
              this.logger.error(error);
            });
          });
        }
      }
    }
    // expected if there is no authentication performed
    else if (responseFailure.status === 401) {
      if (responseFailure != undefined
        && responseFailure.error != undefined
        && responseFailure.error.scalarValue != undefined
        && responseFailure.error.scalarValue != "") {
        this.logger.error(responseFailure.error.scalarValue);
      }
      else {
        this.logger.error("You don't have permission to perform this action!");
      }
    }
    // not found
    else if (responseFailure.status === 404) {
      this.logger.error('Item has been deleted or no longer available!');
    }
    // 5XX errors
    else {
      let error = responseFailure.error.Detail;
      error = error ? error : responseFailure.error.Message;
      error = error ? error : responseFailure.error.scalarValue;

      if (error) {
        this.logger.error(error);
      }
    }
  }

  private _handleResponse(response: HttpResponse<any>): any {
    if (response.status < 200 || response.status > 299) {
      this._handleError(response);
      throw responseError(response);
    }

    try {
      return response.body;
    } catch (e) {
      return undefined;
    }
  }

  private _getEventMessage(
    event: HttpEvent<any>,
    percentDone$: BehaviorSubject<number>
  ) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const percent = Math.round((100 * event.loaded) / event.total);
        return percentDone$.next(percent);

      case HttpEventType.Response:
        return event.body;
    }
  }

  private _handleFileError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError('Something bad happened; please try again later.');
  }

  private async _get(
    url: string,
    options?: HttpHeaders,
    params: any = {}
  ): Promise<any> {
    DataAccessService.checkInAngularZone('GET', url);

    options = options || new HttpHeaders();
    options = this._setHeaders(options);

    this.requestStarted.next();

    try {
      const response = await this.http
        .get<any>(url, {
          params: params,
          headers: options,
          observe: 'response'
        })
        .pipe(
          timeout(this.timeoutValue)
        )
        .toPromise();
      return this._handleResponse(response);
    } catch (error) {
      this._handleError(error);
      throw error;
    } finally {
      this.requestFinished.next();
    }
  }

  private async _post(
    url: string,
    data: any,
    options?: HttpHeaders,
    isTextResponseType = false
  ): Promise<any> {
    DataAccessService.checkInAngularZone('POST', url);

    options = options || new HttpHeaders();
    options = this._setHeaders(options);

    this.requestStarted.next();

    try {
      var response: HttpResponse<any>;
      if (isTextResponseType) {
        response = await this.http
          .post(url, data, { headers: options, observe: 'response', responseType: 'text'})
          .pipe(
            timeout(this.timeoutValue)
          )
          .toPromise();
      } else {
        response = await this.http
          .post(url, data, { headers: options, observe: 'response' })
          .pipe(
            timeout(this.timeoutValue)
          )
          .toPromise();
      }
      return this._handleResponse(response);
    } catch (error) {
      this._handleError(error);
      throw error;
    } finally {
      this.requestFinished.next();
    }
  }

  private async _postFile(
    url: string,
    file: any,
    percentDone$: BehaviorSubject<number>,
    options?: HttpHeaders
  ): Promise<any> {
    DataAccessService.checkInAngularZone('POST', url);

    options = options || new HttpHeaders();
    options = this._setHeaders(options);

    this.requestStarted.next();

    try {
      return await this.http
        .post(url, file, {
          headers: options,
          reportProgress: true,
          observe: 'events'
        })
        .pipe(
          map(event => this._getEventMessage(event, percentDone$)),
          catchError(this._handleFileError)
        )
        .toPromise();
    } catch (error) {
      this._handleError(error);
      throw error;
    } finally {
      this.requestFinished.next();
    }
  }

  private async _put(
    url: string,
    data: any,
    options?: HttpHeaders
  ): Promise<any> {
    DataAccessService.checkInAngularZone('PUT', url);

    options = options || new HttpHeaders();
    options = this._setHeaders(options);

    this.requestStarted.next();

    try {
      const response = await this.http
        .put(url, data, { headers: options, observe: 'response' })
        .pipe(
          timeout(this.timeoutValue)
        )
        .toPromise();
      return this._handleResponse(response);
    } catch (error) {
      this._handleError(error);
      throw error;
    } finally {
      this.requestFinished.next();
    }
  }

  private async _delete(
    url: string,
    params: any = {},
    options?: HttpHeaders
  ): Promise<any> {
    DataAccessService.checkInAngularZone('DELETE', url);

    options = options || new HttpHeaders();
    options = this._setHeaders(options);

    this.requestStarted.next();

    try {
      const response = await this.http
        .delete(url, { headers: options, params: params, observe: 'response' })
        .pipe(
          timeout(this.timeoutValue)
        )
        .toPromise();
      return this._handleResponse(response);
    } catch (error) {
      this._handleError(error);
      throw error;
    } finally {
      this.requestFinished.next();
    }
  }

  public getData<T>(
    url: string,
    urlPostfix: string = '',
    params: any = {},
    isFullUrl: boolean = false,
    options?: HttpHeaders
  ): Promise<T> {
    if (!this.baseUrl) return;

    return new Promise<T>((resolve, reject) => {
      urlPostfix = urlPostfix ? `/${urlPostfix}` : '';

      const finalUrl = isFullUrl
        ? `${url}${urlPostfix}`
        : `${this.baseUrl}${url}${urlPostfix}`;

      this._get(finalUrl, options, params).then(
        responseData => {
          resolve(<T>responseData);
        },
        errorRes => {
          reject(errorRes.statusText);
        }
      );
    });
  }

  public getDataById<T>(
    id: string,
    uri: string,
    urlPostfix: string = '',
    isFullUrl: boolean = false,
    options?: HttpHeaders,
    params: any = {}
  ): Promise<T> {
    if (!this.baseUrl) return;

    return new Promise<T>((resolve, reject) => {
      urlPostfix = urlPostfix ? `/${urlPostfix}` : '';

      const url = isFullUrl
        ? `${uri}${urlPostfix}/${id}`
        : `${this.baseUrl}${uri}${urlPostfix}/${id}`;

      this._get(url, options, params).then(
        responseData => {
          resolve(<T>responseData);
        },
        errorRes => {
          reject(errorRes.statusText);
        }
      );
    });
  }

  public postData<T>(
    data: T,
    uri: string,
    urlPostfix: string = '',
    isFullUrl: boolean = false,
    showLoading: boolean = true,
    options?: HttpHeaders,
    isTextResponseType = false,
  ): Promise<T> {
    if (!this.baseUrl) return;

    return new Promise<T>((resolve, reject) => {
      this.state.setIsLoadingButtonVisible(showLoading);

      urlPostfix = urlPostfix ? `/${urlPostfix}` : '';

      const url = isFullUrl
        ? `${uri}${urlPostfix}`
        : `${this.baseUrl}${uri}${urlPostfix}`;

      this._post(url, data, options, isTextResponseType).then(
        responseData => {
          resolve(<T>responseData);
          this.state.setIsLoadingButtonVisible(false);
        },
        errorRes => {
          reject(errorRes);
          this.state.setIsLoadingButtonVisible(false);
        }
      );
    });
  }

  public postDataEx<T, R>(
    data: T,
    uri: string,
    isFullUrl: boolean = false,
    options?: HttpHeaders
  ): Promise<R> {
    if (!this.baseUrl) return;

    return new Promise<R>((resolve, reject) => {
      this.state.setIsLoadingButtonVisible(true);

      const url = isFullUrl ? uri : `${this.baseUrl}${uri}`;

      this._post(url, data, options).then(
        responseData => {
          resolve(<R>responseData);
          this.state.setIsLoadingButtonVisible(false);
        },
        errorRes => {
          reject(errorRes);
          this.state.setIsLoadingButtonVisible(false);
        }
      );
    });
  }

  public putData<T>(
    data: T,
    uri: string,
    urlPostfix: string = '',
    isFullUrl: boolean = false,
    options?: HttpHeaders
  ): Promise<any> {
    if (!this.baseUrl) return;

    return new Promise<any>((resolve, reject) => {
      this.state.setIsLoadingButtonVisible(true);

      urlPostfix = urlPostfix ? `/${urlPostfix}` : '';

      const url = isFullUrl
        ? `${uri}${urlPostfix}`
        : `${this.baseUrl}${uri}${urlPostfix}`;

      this._put(url, data, options).then(
        responseData => {
          resolve(responseData);
          this.state.setIsLoadingButtonVisible(false);
        },
        errorRes => {
          reject(errorRes);
          this.state.setIsLoadingButtonVisible(false);
        }
      );
    });
  }

  public deleteData(
    id: string | null,
    uri: string,
    params: any = {},
    isFullUrl: boolean = false,
    showLoading: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    if (!this.baseUrl) return;

    return new Promise<any>((resolve, reject) => {
      this.state.setIsLoadingButtonVisible(showLoading);

      id = id ? `/${id}` : '';

      const url = isFullUrl ? `${uri}${id}` : `${this.baseUrl}${uri}${id}`;

      this._delete(url, params, options).then(
        () => {
          resolve(void 0);
          this.state.setIsLoadingButtonVisible(false);
        },
        errorRes => {
          reject(errorRes);
          this.state.setIsLoadingButtonVisible(false);
        }
      );
    });
  }

  public uploadFiles(
    uri: string,
    isFullUrl: boolean = false,
    fileOrFiles: any,
    percentDone: BehaviorSubject<number>,
    options?: HttpHeaders
  ): Promise<any> {
    if (!this.baseUrl) return;

    return new Promise<any>((resolve, reject) => {
      const fd = new FormData();

      if (fileOrFiles.length) {
        // For now just take the first selected file
        fd.append('file', fileOrFiles[0]);
      } else {
        fd.append('file', fileOrFiles);
      }

      const url = isFullUrl ? uri : `${this.baseUrl}${uri}`;

      this._postFile(url, fd, percentDone, options).then(
        responseData => {
          resolve(responseData);
        },
        errorRes => {
          reject(errorRes);
        }
      );
    });
  }
}
