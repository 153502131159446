import { Injectable } from '@angular/core';

import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { HttpHeaders } from '@angular/common/http';

export class TenderSellerParticipantsCollection extends DataCollection<any> { }

@Injectable({
  providedIn: 'root'
})
export class TenderSellerParticipantsService extends ItemsService<any> {
  protected serviceName = 'TenderSellerParticipantsService';

  public tenderId : string = ''

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger
  ) {
    super(dataAccessService, logger);
    super.configure('seller/tender/v1/tenders');
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {


    let localUrlPostfix = `${this.tenderId}/participants`;

    await super.getData(localUrlPostfix, params, forceGet, options);
  }

  public get collection(): TenderSellerParticipantsCollection {
    return new TenderSellerParticipantsCollection(this.internalData);
  }
}
