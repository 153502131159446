import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { StorageService } from '@app/shared';

import { config } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  public item: any;
  public locale: string;

  constructor(private http: HttpClient, private store: StorageService) {}

  public use(locale: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      this.item = {};
      // if (this.item && this.locale === locale) {
      //   return;
      // } else {
      //   this.locale = locale;
      //   this.store.set('Current-Locale', this.locale);

      //   const baseApiUrl = this.state.environment.baseApiFileUrl;
      //   const path = `${baseApiUrl}/locale/${locale || 'en'}.json`;

      //   this.http.get<{}>(path).subscribe(
      //     translation => {
      //       this.item = Object.assign({}, translation || {});
      //       resolve(this.item);
      //     },
      //     error => {
      //       this.item = {};
      //       console.log(`Error: ${error}`);
      //       resolve(this.item);
      //     }
      //   );
      // }
      resolve('');
    });
  }

  public getCurrentLocale(): string {
    const currentLocale = this.store.get('Current-Locale');
    return currentLocale ? currentLocale : 'en';
  }
}
