import { Injectable } from '@angular/core';

import { Connection, Stock } from '@app/model';
import { Logger, SharedService } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { HttpHeaders } from '@angular/common/http';

export class TenderTransactionsCollection extends DataCollection<Connection> { }

@Injectable({
  providedIn: 'root'
})
export class TenderTransactionsService extends ItemsService<Connection> {
  protected serviceName = 'TenderTransactionsService';

  public constructor(
    private shared: SharedService,
    dataAccessService: DataAccessService,
    logger: Logger
  ) {
    super(dataAccessService, logger);
    super.configure('supervisor/tender/v1/transactions');
  }

  public get collection(): TenderTransactionsCollection {
    return new TenderTransactionsCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {

    const tenderId = this.shared.params.get('id');

    const staticFilters = [
      'eventId$'+tenderId+'$0',
      'type$offer$3',
      'type$acceptoffer$3',
      'type$rejectoffer$3',
    ];

    params.filters =
      params.filters && params.filters instanceof Array
        ? params.filters.some((x: string) => x.indexOf('eventId',) > -1) &&
          params.filters.some((x: string) => x.indexOf('type$offer$3',) > -1) &&
          params.filters.some((x: string) => x.indexOf('type$acceptoffer$3',) > -1) &&
          params.filters.some((x: string) => x.indexOf('type$rejectoffer$3',) > -1)
          ? params.filters
          : params.filters.concat(staticFilters)
        : staticFilters;

    await super.getData(urlPostfix, params, true, options);
  }


  public async getTransactionById(transactionId: string): Promise<any> {
    const staticFilters = [
      'Id$' + transactionId + '$0'
    ];

    let transaction: any;
    await super.getData('', { filters: staticFilters }, true)
      .then(() => {
        transaction = this._data.items[0];
      }
      );;

    return transaction;
  }

}
