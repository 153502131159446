import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { ItemsService, DataAccessService } from '@app/data';
import { Logger, createSelect2Data, SharedService } from '@app/shared';

import { BehaviorSubject } from 'rxjs';
import { StateService } from '@app/core';
import { Select2OptionData } from 'ng-select2';

import { Options } from 'select2';
import { Tenant } from '@app/model';

declare let $: any;

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html'
})
export class SelectComponent extends ItemsService<any> implements OnInit {
  @Input() field: any;
  @Input() group: FormGroup;
  @Input() data: any;
  @Input() isEditing?: boolean;
  @Input() isReadOnly?: boolean = null;

  protected serviceName = 'SelectComponent';

  public items$ = new BehaviorSubject<any>([]);
  public cascadeItems$ = new BehaviorSubject<any>({});
  public queryValue?: any = null;
  public values: string[] = [];
  public defaultValue: any;

  public isCascadeItemsCompleted$ = new BehaviorSubject<boolean>(false);
  public isLoadingDataVisible$ = new BehaviorSubject<boolean>(false);

  public options: Options;

  constructor(
    dataAccess: DataAccessService,
    logger: Logger,
    public shared: SharedService,
    public state: StateService
  ) {
    super(dataAccess, logger);
  }

  public ngOnInit(): Promise<void> {
    return this.getDataSource().then(() => {
      if (this.field.queryKey)
        this.queryValue = this.shared.params.get(this.field.queryKey);

      this.options = {
        width: '300',
        templateResult: this.templateResult,
        templateSelection: this.templateSelection
      };
    });
  }
  // events
  public onSelectAll(event: any) {
    if (!event.target.checked) {
      this.group.get(this.field.name).setValue([]);
      return;
    }

    const items = (this.isExistItems()
      ? this.items$.getValue()
      : this.field.data) as any[];

    const values = items
      .filter((item: any) => {
        return item.text != null;
      })
      .map((item: any) => {
        return item.key;
      });

    this.group.get(this.field.name).setValue(values);
  }

  public onChange(event: any) {
    if (event != null) {
      let control =
        this.group.get(this.field.name);
      if (control != null) {
        control.setValue(event.value);
      }
    }
  }

  public async onChangeCascade(index: number, control: any, event: any): Promise<void> {
    if (index === this.field.cascadeControls.length - 1) return;
    this.group.patchValue({
      [control.name]: event.value
    });

    // set second value
    if (!control.keyIsValue && control.secondName) {
      this.group.patchValue({
        [control.secondName]: event.data[0].key
      });
    }

    this.data = null;
    await this.getCascadeItems(index, control.name);
  }

  // methods
  public async getDataSource(): Promise<void> {
    if (this.field.isDataSourceInTenant) {
      this.getItemsInTenant();
    } else if (this.field.dataSource != null) {
      await this.getItems();
    } else if (this.field.cascadeControls != null) {
      await this.getCascadeItems();
    }
  }

  public getObjectPathData(obj: any, key: string, index: number = 0): any {
    const parts = key.split('.');
    const newObj = obj[parts[index]];
    if (parts[index + 1]) {
      return this.getObjectPathData(newObj, key, index + 1);
    }
    return newObj;
  }

  public getItemsInTenant(): void {
    this.state.on(this.state.tenantInfo$, (tenantInfo: Tenant) => {
      tenantInfo = tenantInfo ? tenantInfo : this.state.tenantInfo;

      if (tenantInfo) {
        const items = this.getObjectPathData(tenantInfo, this.field.tenantKey);

        // rename
        let newItems = createSelect2Data(
          items,
          this.field.dataValue,
          this.field.dataText,
          true,
          this.field.isNeedSortAsc,
          this.field.hasAllField,
          this.field.isDataInInnerObject
        );

        this.items$.next(newItems);

        const value = this.group.value[this.field.name];

        const defaultValue =
          newItems.length > 0 && this.queryValue != null
            ? this.queryValue
            : newItems.length > 0 && !value
              ? this.field.isSetDefaultValue || this.field.hasAllField
                ? this.field.isMultiple
                  ? [
                    `${items[this.field.isNeedSortAsc ? items.length - 1 : 0][this.field.dataValue]}`,
                    `${items[this.field.isNeedSortAsc ? 0 : items.length - 1][this.field.dataValue]}`
                  ]
                  : newItems[0].id
                : ''
              : value;

        this.group.patchValue({
          [this.field.name]: defaultValue
        });
      }
    });
  }

  public async getItems(): Promise<void> {
    if (this.isNeedShowInput) return;

    // start
    this.isLoadingDataVisible$.next(true);

    const stateKey = this.field.stateKey ? this.state[this.field.stateKey] : '';

    const baseApiUrl = this.field.baseApiUrlKey
      ? `${this.state.environment[this.field.baseApiUrlKey]}`
      : '';

    let dataSource = `${baseApiUrl}${this.field.dataSource}`;
    const parameters = this.field.dataParameters
      ? this.field.dataParameters
      : '';

    dataSource = stateKey
      ? `${dataSource}/${stateKey}${parameters}`
      : dataSource;

    super.configure(dataSource, this.field.isFullUrl);

    await this.getData('', null, false);

    // rename
    let newItems = createSelect2Data(
      this.collection.items,
      this.field.dataValue,
      this.field.dataText,
      true,
      this.field.isNeedSortAsc,
      this.field.hasAllField,
      this.field.isDataInInnerObject
    );

    this.items$.next(newItems);

    const defaultValue =
      newItems.length > 0 && this.queryValue != null
        ? this.queryValue
        : newItems.length > 0 && !this.group.value[this.field.name]
          ? this.field.isSetDefaultValue || this.field.hasAllField
            ? this.field.isMultiple
              ? [
                `${this.collection.items[this.field.isNeedSortAsc ? this.collection.items.length - 1 : 0][this.field.dataValue]}`,
                `${this.collection.items[this.field.isNeedSortAsc ? 0 : this.collection.items.length - 1][this.field.dataValue]}`
              ]
              : newItems[0].id
            : ''
          : this.group.value[this.field.name];

    this.group.patchValue({
      [this.field.name]: defaultValue
    });

    // stop
    this.isLoadingDataVisible$.next(false);
  }

  public async getCascadeItems(
    index?: number,
    parentName?: string
  ): Promise<void> {
    if (this.isNeedShowInput) {
      this.isCascadeItemsCompleted$.next(true);
      return;
    }

    const data: {} = {};
    let parentValue: any;
    let currentObject: any;

    // start
    this.isLoadingDataVisible$.next(true);

    for (let i = 0; i < this.field.cascadeControls.length; i++) {
      const control = this.field.cascadeControls[i];

      // prev control
      if (index && i < index) {
        data[control.name] = this.cascadeItems$.getValue()[control.name];
        continue;
      }

      // current parent control
      if (control.name === parentName) {
        data[parentName] = this.cascadeItems$.getValue()[parentName];
        continue;
      }

      // get parent value for editing form
      if (this.data && control.dataParentName) {
        currentObject = this.field.objectName
          ? this.data[this.field.objectName]
          : this.data;

        parentValue = currentObject
          ? currentObject[control.dataParentName]
          : null;
      }

      // next control
      let param = parentValue
        ? parentValue
        : this.group.value[control.dataParentName];

      this.defaultValue = control.defaultValue ? control.defaultValue : this.defaultValue;

      param = param ? param : (this.defaultValue);

      // remove parent value
      parentValue = null;

      const parameter = control.dataParentName && param ? `/${param}` : '';

      const baseApiUrl = control.baseApiUrlKey
        ? `${this.state.environment[control.baseApiUrlKey]}`
        : '';

      // get data
      super.configure(`${baseApiUrl}${control.dataSource}${parameter}`, control.isFullUrl);

      await this.getData('', null, true);

      if (!this.group.contains(control.name)) {
        this.group.addControl(
          control.name,
          new FormControl(control.value, null)
        );
      }

      let value = this.group.value[control.name];

      // get value for editing form
      if (this.data && currentObject && !value) {
        value = currentObject ? currentObject[control.name] : null;
      }

      if (this.collection.items.length > 0) {
        // rename
        let newItems = createSelect2Data(
          this.collection.items,
          control.dataValue,
          control.dataText,
          control.keyIsValue,
          false,
          control.hasAllField
        );

        this.defaultValue = newItems[0];
        data[control.name] = newItems;

        this.group.patchValue({
          [control.name]:
            newItems.length > 0 && (!value || parentName)
              ? this.field.isSetDefaultValue || control.hasAllField
                ? newItems[0].id
                : ''
              : value
        });

        // set second value
        if (!control.keyIsValue && control.secondName) {
          value = this.group.value[control.secondName];

          // get value for editing form
          if (this.data && currentObject && !value) {
            value = currentObject ? currentObject[control.secondName] : null;
          }

          this.group.patchValue({
            [control.secondName]:
              this.collection.items.length > 0 && (!value || parentName)
                ? this.collection.items[0][control.dataValue]
                : value
          });
        }
      }

      if (i === this.field.cascadeControls.length - 1) {
        this.cascadeItems$.next(data);
        this.isCascadeItemsCompleted$.next(true);

        // stop
        this.isLoadingDataVisible$.next(false);
      }
    }
  }

  // template
  public templateResult = (state: Select2OptionData): any => {
    if (!state.id) {
      return state.text;
    }

    let image = '<span class="image"></span>';

    if (state.additional.image) {
      image = `<span class="image"><img src="${state.additional.image}"</span>`;
    }

    return $(
      `<span><b>${state.additional.winner}.</b> ${image} ${state.text}</span>`
    );
  };

  // function for selection template
  public templateSelection = (state: Select2OptionData): any => {
    if (!state.id) {
      return state.text;
    }

    return $(`<span><b>${state.additional.winner}.</b> ${state.text}</span>`);
  };

  // getters
  public get isNeedShowInput(): any {
    return this.isEditing && this.field.disabledInEdit;
  }

  public get isDisabled(): any {
    return (
      this.isReadOnly ||
      this.field.disabled ||
      (this.isEditing && this.field.disabledInEdit)
    );
  }

  public get disabledClass(): any {
    return { disabled: this.isDisabled };
  }

  public get isCheckedAll(): any {
    if (!this.field.hasSelectAll) {
      return false;
    }

    const items = this.isExistItems() ? this.items$.getValue() : this.field.data;

    if (!items || !this.group.value[this.field.name]) {
      return false;
    }

    return this.group.value[this.field.name].length === items.length;
  }

  public get isCascadeControls(): boolean {
    return this.field.cascadeControls;
  }

  public get columnClass(): any {
    return {
      'col-lg-6': this.field.isHalfColumn && !this.isNeedShowInput,
      'col-lg-12': !this.field.isHalfColumn || this.isNeedShowInput
    };
  }

  // methods
  public isExistItems(control?: any) {
    if (control) {
      const cascadeItems = this.cascadeItems$.getValue();
      return cascadeItems && Object.keys(cascadeItems).length > 0;
    }

    const items = this.items$.getValue();
    return items && items.length > 0;
  }

  public isShowLabel(control?: any) {
    control = control ? control : this.field;
    return control.label != null;
  }

  public isRequired(control?: any) {
    control = control || this.field;

    if (!control.validations) {
      return false;
    }

    return control.validations.some((x: any) => x.name === 'required');
  }
}
