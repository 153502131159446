import { Injectable } from '@angular/core';

import { User } from '@app/model';
import { Logger } from '@app/shared';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class ConfirmationCollection extends DataScalar<User> { }

@Injectable({
  providedIn: 'root'
})
export class ResendConfirmationService extends ItemService<User> {
  protected serviceName = 'ResendConfirmationService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
  ) {
    super(dataAccessService, logger);
  }

  public get collection(): ConfirmationCollection {
    return new ConfirmationCollection(this.internalItem);
  }

  public async sendById(userId: string): Promise<boolean> {
    return this.dataAccess.postData(null,
      'identity/v1/users/resendconfirmation',
      userId, null, null).then(() => {
        // Success
        return true;
      },
        () => {
          // error
          return false;
        }
      );
  }
}
