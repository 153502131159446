import { Injectable } from '@angular/core';

import { TermsCondition } from 'libs/model/src/lib/terms-condition';
import { Logger } from '@app/shared';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class PublicTermsCollection extends DataScalar<TermsCondition> { }

@Injectable({
  providedIn: 'root'
})
export class TermsConditionService extends ItemService<TermsCondition> {
  protected serviceName = 'TermsConditionService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('public/tenant/v1/specs');
  }

  public get collection(): PublicTermsCollection {
    return new PublicTermsCollection(this.internalItem);
  }

  public async getTermsConditionUrl(): Promise<string> {
    return await super.getData('client.termsandconditions', '', true).then(async () => {
      const item = this.collection.item;
      return item.url;
    });
  }
}
