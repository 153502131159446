import { Component, Input, ViewChild, AfterViewChecked } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { StateService } from '@app/core';
import { ComponentRestrictions } from 'ngx-google-places-autocomplete/objects/options/componentRestrictions';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
declare let google: any;
@Component({
  selector: 'app-google-place-autocomplete',
  templateUrl: './google-place-autocomplete.component.html'
})
export class GooglePlaceAutocompleteComponent implements AfterViewChecked {
  @Input() field: any;
  @Input() group: FormGroup;
  @Input() isEditing?: boolean;
  @Input() isReadOnly?: boolean = true;


  @ViewChild('placesRef', { read: false, static: false })
  placesRef: GooglePlaceDirective;

  public isDisableSetDefaultValue: boolean = false;

  constructor(private state: StateService) { }

  public ngAfterViewChecked(): void {
    this._getDefaultValue();
  }

  // events
  public handleAddressChange(address: any) {
    if (this.field.relatedFields) {
      this.field.relatedFields.forEach((field: any) => {
        let value: any;

        switch (field) {
          case 'countryName':
          case 'stateName':
          case 'cityName':
            value = this._getLongName(address, field);
            break;

          case 'countryShortName':
          case 'stateShortName':
          case 'cityShortName':
            value = this._getShortName(address, field);
            break;

          case 'referenceId':
            value = address.reference;
            break;

          case 'externalId':
            value = address.id;
            break;

          case 'placeId':
            value = address.place_id;
            break;

          case 'mapUrl':
            value = address.url;
            break;

          case 'latitude':
            value = address.geometry.location.lat();
            break;

          case 'longitude':
            value = address.geometry.location.lng();
            break;
        }

        // change values in the form
        this.group.patchValue({ [field]: value });
      });
    } else {
      this.group.patchValue({
        [this.field.name]: address.formatted_address
      });
    }
  }

  // getters
  public get isShowLabel() {
    return this.field.label != null;
  }

  public get disabled(): any {
    return (this.isEditing && this.field.disabledInEdit) ||
      this.field.disabled ||
      this.isReadOnly
      ? ''
      : null;
  }

  public get columnClass(): any {
    return {
      'col-lg-6': this.field.isHalfColumn,
      'col-lg-12': !this.field.isHalfColumn
    };
  }

  public get isRequired() {
    return (
      this.field.validations &&
      this.field.validations.some((x: any) => x.name === 'required')
    );
  }

  public get options(): Options {
    let options: Options = new Options();
    let componentRestrictions: ComponentRestrictions = new ComponentRestrictions();

    // country
    const restrictionCountry = this.field.restrictionCountryTenantKey
      ? this.state.tenantInfo
        ? this.state.tenantInfo[this.field.restrictionCountryTenantKey]
        : ''
      : '';

    if (restrictionCountry) {
      componentRestrictions.country = restrictionCountry;
    }

    // componentRestrictions
    if (componentRestrictions.country) {
      options.componentRestrictions = componentRestrictions;
    }

    return options;
  }

  // private
  private _getLongName(address: Address, field: string): string {
    let name: string = '';

    address.address_components.forEach((addressComponent: AddressComponent) => {
      const types = addressComponent.types;

      if (types.indexOf('country') > -1 && field === 'countryName') {
        name = addressComponent.long_name;
        return;
      }

      if (
        types.indexOf('administrative_area_level_1') > -1 &&
        field === 'stateName'
      ) {
        name = addressComponent.long_name;
        return;
      }

      if (types.indexOf('locality') > -1 && field === 'cityName') {
        name = addressComponent.long_name;
        return;
      }
    });

    return name;
  }

  private _getShortName(address: Address, field: string): string {
    let name: string = '';

    address.address_components.forEach((addressComponent: AddressComponent) => {
      const types = addressComponent.types;

      if (types.indexOf('country') > -1 && field === 'countryShortName') {
        name = addressComponent.short_name;
        return;
      }

      if (
        types.indexOf('administrative_area_level_1') > -1 &&
        field === 'stateShortName'
      ) {
        name = addressComponent.short_name;
        return;
      }

      if (types.indexOf('locality') > -1 && field === 'cityShortName') {
        name = addressComponent.short_name;
        return;
      }
    });

    return name;
  }

  public _getDefaultValue(): void {
    const currentValue = this.group.value[this.field.name];

    if (
      this.field.stateKeys &&
      !currentValue &&
      !this.isDisableSetDefaultValue
    ) {
      const stateKeys = this.field.stateKeys
        .filter((key: string) => {
          return this.state[key] != '';
        })
        .map((key: string) => {
          return this.state[key];
        });

      this.isDisableSetDefaultValue = stateKeys.length > 0;
      const defaultValue = stateKeys.join(', ');
      this.group.patchValue({ [this.field.name]: defaultValue });
      this.group.patchValue({ ["stateShortName"]: stateKeys[1] });

      if (defaultValue == "")
        return;
      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input: defaultValue }, (predictions, status) => {
        if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
          return;
        }
        const geocoder = new google.maps.Geocoder();
        geocoder
          .geocode({ placeId: predictions[0].place_id })
          .then(({ results }) => {
            this.handleAddressChange(results[0]);
          });
      });
    }
  }
}
