export function leftpad(value: any, length: number, pad?: string): string {
  value = '' + value;
  pad = pad || ' ';
  while (value.length < length) {
    value = pad + value;
  }
  return value;
}

export function rightpad(value: any, length: number, pad?: string): string {
  value = '' + value;
  pad = pad || ' ';
  while (value.length < length) {
    value = value + pad;
  }
  return value;
}

export function limitstr(value: any, length: number): string {
  value = value + '';
  if (value.length > length) {
    value = value.slice(0, length - 3) + '...';
  }
  return value;
}

export function orderBy<T, S>(
  array: T[],
  compare: (a: S, b: S) => number,
  project: (item: T) => S
): T[] {
  array = array.slice(); // make a copy, because sort() modifies the array
  array.sort((a, b) => compare(project(a), project(b)));
  return array;
}

export function compareStrings(a: string, b: string): number {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
}

export function compareStringsCI(a: string, b: string): number {
  return compareStrings(a.toLowerCase(), b.toLowerCase());
}

export function filterByStringMatchPrecomputed<T>(
  objects: T[],
  searchFields: string[][],
  expression: string
): T[] {
  expression = expression.toLowerCase();

  if (objects.length !== searchFields.length) {
    throw new Error(
      `objects has ${objects.length} items, searchFields has ${searchFields.length} items`
    );
  }

  const result: T[] = [];
  for (let i = 0; i < objects.length; i++) {
    let match = false;
    for (const field of searchFields[i]) {
      if (field.indexOf(expression) >= 0) {
        match = true;
        break;
      }
    }
    if (match) {
      result.push(objects[i]);
    }
  }
  return result;
}

export function filterByStringMatch<T>(
  objects: T[],
  getSearchFields: (obj: T) => string[],
  expression: string | null | undefined
): T[] {
  if (
    expression === null ||
    expression === undefined ||
    expression.trim() === ''
  ) {
    return objects;
  } else if (typeof expression !== 'string') {
    throw new Error(
      `Expression must be a string, is actually a ${typeof expression}`
    );
  } else {
    const searchFields = objects.map(obj =>
      getSearchFields(obj).map(s => s.toLowerCase())
    );
    return filterByStringMatchPrecomputed(objects, searchFields, expression);
  }
}

export function getNonEmptyStrings(
  strings: (string | undefined | null)[]
): string[] {
  const result: string[] = [];
  for (const str of strings) {
    if (str) {
      result.push(str);
    }
  }
  return result;
}

export function typeName(value: any): string {
  try {
    if (value === null) {
      return 'null';
    } else if (typeof value === 'function') {
      if (value.name) {
        return value.name;
      } else {
        return 'function';
      }
    } else if (typeof value === 'object') {
      return value.constructor.name;
    } else {
      return typeof value;
    }
  } catch (e) {
    return typeof value;
  }
}

function checkNotBeingVisited(value: any, visiting: any[]): void {
  if (visiting.indexOf(value) >= 0) {
    throw new Error('Attempt to copy a cyclic data structure');
  }
}

function deepCopyRecursive<T>(orig: T, visiting: any[]): T {
  if (orig === null || typeof orig !== 'object') {
    return orig;
  }

  checkNotBeingVisited(orig, visiting);

  if (orig instanceof Date) {
    return <any>new Date(orig);
  } else if (orig instanceof Array) {
    const copy: any = [];
    visiting.push(orig);
    for (const child of orig) {
      copy.push(deepCopyRecursive(child, visiting));
    }
    visiting.pop();
    return <T>copy;
  } else {
    const copy = {};
    visiting.push(orig);
    for (const key of Object.keys(orig)) {
      copy[key] = deepCopyRecursive(orig[key], visiting);
    }
    visiting.pop();
    return <T>copy;
  }
}

export function deepCopy<T>(orig: T): T {
  return deepCopyRecursive(orig, []);
}

export function delayms(ms: number): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => resolve(), ms);
  });
}

export function createSelect2Data(
  items: any[],
  dataValue: string,
  dataText: string,
  keyIsValue: boolean = true,
  isNeedToSortAsc: boolean = false,
  hasAllField: boolean = false,
  findInInnerObject: boolean = false
): any[] {
  let initItems = [];
  if (hasAllField)
    initItems.push({ id: 'all', text: 'All', key: 'all' });

  let newItems = items.map(item =>
    Object.keys(item).map(key => {
      const object = item[key];

      // object
      if (findInInnerObject && object instanceof Object) {
        if (Object.keys(object).find(x => x == dataValue))
          return ({
            id: keyIsValue ? object[dataValue] : object[dataText],
            text: object[dataText],
            key: object[dataValue]
          });
      }
      // parent
      else if (!findInInnerObject && key === dataValue) {
        return ({
          id: keyIsValue ? item[dataValue] : item[dataText],
          text: item[dataText],
          key: item[dataValue]
        });
      }
    })
  );

  newItems = initItems.concat([].concat.apply([], newItems)).filter(x => x && x.text != null);

  if (isNeedToSortAsc)
    newItems = sortAsc(newItems, 'id');

  return newItems;
}

export function sortAsc(array: any[], key: any): any[] {
  return [...array].sort((a: any, b: any) => {
    const keyA = a[key];
    const keyB = b[key];

    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;

    return 0;
  });
}

export function divideArray2Columns(array: any[], count: number): any[] {
  let rows: any[] = [];

  array.forEach((item: any, index: number) => {
    const rowIndex = Math.floor(index / count);

    if (index % count === 0) {
      rows[rowIndex] = [];
    }

    rows[rowIndex][index % count] = item;
  });

  return rows;
}

export function getEmptyGuid(): string {
  return '00000000-0000-0000-0000-000000000000';
}

export function toCamelCase(string: string) {
  return string.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return '';
    return index == 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export function convertCapital2CamelCaseInArray(array: any[]): any[] {
  return array.map((item: any) => {
    const newItem: any = {};

    Object.keys(item).forEach((key: any) => {
      const value = item[key];
      const camelCaseKey = toCamelCase(key);
      const isObjectValue = value instanceof Object;

      newItem[camelCaseKey] = isObjectValue
        ? convertCapital2CamelCaseInObject(value)
        : value;
    });

    return newItem;
  });
}

export function convertCapital2CamelCaseInObject(object: any): any {
  const newItem: any = {};

  Object.keys(object).forEach((key: any) => {
    const camelCaseKey = toCamelCase(key);
    newItem[camelCaseKey] = object[key];
  });

  return newItem;
}

export function groupBy(key: any, array: any[]): any[] {
  return array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});
}

export function generateRandomString(len: number, bits: any = null) {
  bits = bits || 36;
  let outStr = '';
  let newStr = '';

  while (outStr.length < len) {
    newStr = Math.random()
      .toString(bits)
      .slice(2);
    outStr += newStr.slice(0, Math.min(newStr.length, len - outStr.length));
  }

  return outStr.toUpperCase();
}

export function csv2JSON(csv: any) {
  csv = csv.split('\n');

  let attrs = csv.splice(0, 1);

  let result = csv.map((row: any, index: number) => {
    let obj: any = {};
    let rowData = row.split(',');

    attrs[0].split(',').forEach((val: any, idx: number) => {
      obj = constructObj(val, obj, rowData[idx]);
    });

    return obj;
  });

  return result;
}

export function constructObj(str: string, parentObj: any, data: any) {
  str = str.trim();

  if (str.split('.').length === 1) {
    parentObj[str] = data;
    return parentObj;
  }

  let curKey = str.split('.')[0];
  if (!parentObj[curKey]) {
    parentObj[curKey] = {};
  }

  parentObj[curKey] = constructObj(
    str
      .split('.')
      .slice(1)
      .join('.'),
    parentObj[curKey],
    data
  );

  return parentObj;
}

export function validateNumber(value: number, defaultValue: number) {

  let validatedValue = value
    ? Number(value) ? value : defaultValue
    : defaultValue;

  return validatedValue;
}

export function validateURL(str: string) {
  let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}

//csv to array reader
export function parseCSVRow(strData: string, strDelimiter: string) {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = (strDelimiter || ",");

  // Create a regular expression to parse the CSV values.
  let objPattern = new RegExp(
    (
      // Delimiters.
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

      // Standard fields.
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  let arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  let arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while (arrMatches = objPattern.exec(strData)) {

    // Get the delimiter that was found.
    let strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      strMatchedDelimiter !== strDelimiter
    ) {

      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }

    let strMatchedValue: any;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {

      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(
        new RegExp("\"\"", "g"),
        "\""
      );
    } else {

      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  // Return the parsed data.
  return (arrData);
}