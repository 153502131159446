import { Injectable } from '@angular/core';

import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class Car2CashValCollection extends DataCollection<any> { }

@Injectable({
  providedIn: 'root'
})
export class Car2CashService extends ItemsService<any> {
  protected serviceName = 'Car2CashService';
  public stock: any;

  public constructor(private dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('guest/catalog/v1/appraisal/car2cashoffer');
  }

  public get collection(): Car2CashValCollection {
    return new Car2CashValCollection(this.internalData);
  }

  public async getCar2CashOffer(
    appraisalId: string,
    rego: string,
    regoState: string,
    odometer: string,
    value: any
  ): Promise<any> {
    let params: any = {
      appraisalId,
      rego,
      regoState,
      odometer
    };
    params = Object.assign({}, params, value);
    await super.getData(null, params, true);
    return this._data;
  }
}
