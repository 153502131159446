import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ItemsService, DataAccessService } from '@app/data';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '@app/shared';
import { StateService } from '@app/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html'
})
export class RadioButtonComponent extends ItemsService<any> implements OnInit {
  @Input() field: any;
  @Input() index?: number;
  @Input() group: FormGroup;
  @Input() isEditing?: boolean;
  @Input() isReadOnly?: boolean = null;

  @Output() onChangeSelection: EventEmitter<any> = new EventEmitter();

  protected serviceName = 'RadioButtonComponent';
  protected items$ = new BehaviorSubject<any>([]);

  public isLoadingDataVisible$ = new BehaviorSubject<boolean>(false);

  constructor(
    dataAccess: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccess, logger);
  }

  public async ngOnInit(): Promise<void> {
    if (this.field.dataSource != null) {
      const baseApiUrl = this.field.baseApiUrlKey
        ? `${this.state.environment[this.field.baseApiUrlKey]}`
        : '';

      super.configure(`${baseApiUrl}${this.field.dataSource}`);
      await this.getItems();
    }
  }

  public async getItems(): Promise<void> {
    // start
    this.isLoadingDataVisible$.next(true);

    await this.getData();
      this.items$.next(this.collection.items);

      // stop
      this.isLoadingDataVisible$.next(false);
    
  }

  // event
  onChange() {
    this.onChangeSelection.emit();
  }

  // getters
  public get isRadioVisible(): boolean {
    const value = this.group.value[this.field.name];
    const items = (this.isExistItems
      ? this.items$.getValue()
      : this.field.data) as Array<any>;

    return (
      value == '' ||
      value == null ||
      items.some((item: any) => {
        return item[this.field.dataValueKey] == value || item.key == value;
      })
    );
  }

  public get isExistItems() {
    return this.items$.getValue().length > 0;
  }

  public get isShowLabel() {
    return this.field.label != null;
  }

  public setName(key: string) {
    const index = this.index != null ? `-${this.index}` : '';
    return `${this.field.name}-${key}${index}`;
  }

  public get isRequired() {
    return (
      this.field.validations &&
      this.field.validations.some((x: any) => x.name === 'required')
    );
  }

  public get isDisabled(): any {
    const disabled =
      this.isReadOnly ||
      this.field.disabled ||
      (this.isEditing && this.field.disabledInEdit);

    return disabled ? '' : null;
  }
}
