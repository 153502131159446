export class NotFoundError extends Error {
  public constructor(type: string, id: string) {
    super(`${type} ${id} not found`);
  }
}

export class Dict<T> {
  private _entries: { [key: string]: T } = {};
  private _size: number;

  public constructor(pairs?: [string, T][]) {
    if (pairs) {
      pairs.forEach(([key, value]) => this.set(key, value));
    }
  }

  public static fromArray<T>(
    entries: T[],
    keyfun: (item: T) => string
  ): Dict<T> {
    return entries.reduce((dict, entry) => {
      dict.set(keyfun(entry), entry);
      return dict;
    }, new Dict<T>());
  }

  public get size(): number {
    return this._size;
  }

  public clear(): void {
    this._entries = {};
    this._size = 0;
  }

  public get(key: string): T | undefined {
    return this._entries[key];
  }

  public lookup(key: string, createFun?: () => T): T {
    if (this.has(key)) {
      return this._entries[key];
    } else if (createFun !== undefined) {
      const value = createFun();
      this.set(key, value);
      return value;
    } else {
      throw new NotFoundError('Entry', key);
    }
  }

  public set(key: string, value: T): this {
    if (this._entries[key] === undefined) {
      this._size++;
    }
    this._entries[key] = value;
    return this;
  }
  
  public has(key: string): boolean {
    return this._entries[key] !== undefined;
  }

  public delete(key: string): boolean {
    const wasPresent = this.has(key);
    if (wasPresent) {
      this._size--;
      delete this._entries[key];
    }
    return wasPresent;
  }

  public entries(): [string, T][] {
    const result: [string, T][] = [];
    for (const key in this._entries) {
      if (this.has(key)) {
        result.push([key, this._entries[key]]);
      }
    }
    return result;
  }

  public keys(): string[] {
    const result: string[] = [];
    for (const key in this._entries) {
      if (this.has(key)) {
        result.push(key);
      }
    }
    return result;
  }

  public values(): T[] {
    const result: T[] = [];
    for (const key in this._entries) {
      if (this.has(key)) {
        result.push(this._entries[key]);
      }
    }
    return result;
  }
}

export function arrayGet<T>(
  array: T[],
  predicate: (value: T) => boolean
): T | undefined {
  for (let i = 0; i < array.length; i++) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
  return undefined;
}

export function arrayLookup<T>(
  array: T[],
  predicate: (value: T) => boolean,
  description?: string
): T {
  for (let i = 0; i < array.length; i++) {
    if (predicate(array[i])) {
      return array[i];
    }
  }
  if (description !== undefined) {
    throw new Error(description + ' not found.');
  } else {
    throw new Error('Item not found.');
  }
}
