import { Injectable } from '@angular/core';

import { EmailNotification } from '@app/model';
import { Logger } from '@app/shared';
import { DataScalar, ItemService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class FeedbackCollection extends DataScalar<EmailNotification> {}

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends ItemService<EmailNotification> {
  protected serviceName = 'FeedbackService';
  public stock: any;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger
  ) {
    super(dataAccessService, logger);
    super.configure('notification/v1/proxy');
  }

  public get collection(): FeedbackCollection {
    return new FeedbackCollection(this.internalItem);
  }
}
