import { Injectable } from '@angular/core';
import { SafeStorageService } from './safe-storage.service';

import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { User, Tenant, Organization, Notification, Tender, Auction } from '@app/model';

import { Params, Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { TenantConfigService } from './tenant-config.service';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  // public
  public subscriptions: Subscription[] = [];
  public notificationHubConnection: signalR.HubConnection;

  // device
  public deviceId: string = '';
  public devicePlatform: string = '';
  public isOnDevice: boolean = false;

  // loading
  public isProfileLoaded: boolean = false;
  public isOrganizationsLoaded: boolean = false;
  public isConnections4SellerLoaded: boolean = false;
  public isConnections4BuyerLoaded: boolean = false;
  public isNotificationsLoaded: boolean = false;
  public isWatchListLoaded: boolean = false;
  public isLoadingButtonVisible: boolean = false;

  // app
  public tenantInfo: Tenant = null;
  public isAdminApp: boolean = false;
  public defaultCountry: string = '';
  public environment: any = {};
  public instrumentationKey: string = '';
  public defaultCountryCode: string = '';
  public locale: string = '';
  public theme: string = '';
  public title: string = '';
  public initialDataState: string = '';
  public isMobileResolution: boolean = false;
  public archiveTender: Tender = new Tender();
  public archiveAuction: Auction = new Auction();
  public navigateValue: string = '';
  public isBillingInfoEnabled: boolean = false;
  public isTenderReportsEnabled = false;

  // organization
  public token: string = '';
  public tenantCode: string = '';
  public organizationId: string = '';
  public currentOrganization: Organization;
  public organizationAcronym: string = '';
  public organizationSuburb: string = '';
  public organizationState: string = '';
  public organizationCountry: string = '';

  // roles
  public isSupervisor: boolean = false;
  public isAdmin: boolean = false;
  public isSeller: boolean = false;
  public isBuyer: boolean = false;
  public isGuest: boolean = false;

  // menu
  public isMenuToggled: boolean = false;
  public isFilterToggled: boolean = false;
  public isSubMenuExtended: boolean = false;
  public isShowNotificationBox: boolean = false;

  // user
  public currentUserId = '';
  public userLoginCredentials: any = null
  public profile: User = null;
  public isBiometricEnabled: boolean = false;
  public isBiometricFeatureReminder: boolean = true;
  public currentOrganizations: Organization[] = null;
  public isProfilePictureUploaded: boolean = false;
  public secondaryAccessToken: string = '';

  //third party
  public resizerWidth200: string = "https://9gbid5gtfh.execute-api.ap-southeast-1.amazonaws.com/production/bypass/?w=150&u=";

  // notification
  public notification: Notification = null;

  // route
  public redirectUrl: string = '';

  // reload list
  public isNeedToReloadList: boolean = false;

  // clone
  public cloneData: any = null;

  // counter
  public isCounterModalVisible: boolean = false;

  // public observables
  // loading
  public isLoadingButtonVisible$ = new BehaviorSubject<boolean>(false);
  public isProfileLoaded$ = new BehaviorSubject<boolean>(false);
  public isOrganizationsLoaded$ = new BehaviorSubject<boolean>(false);
  public isConnections4SellerLoaded$ = new BehaviorSubject<boolean>(false);
  public isConnections4BuyerLoaded$ = new BehaviorSubject<boolean>(false);
  public isNotificationsLoaded$ = new BehaviorSubject<boolean>(false);
  public isWatchListLoaded$ = new BehaviorSubject<boolean>(false);

  // menu
  public isShowNotificationBox$ = new BehaviorSubject<boolean>(false);

  // app
  public tenantInfo$ = new BehaviorSubject<Tenant>(null);
  public isAdminApp$ = new BehaviorSubject<boolean>(null);
  public environment$ = new BehaviorSubject<any>(null);
  public instrumentationKey$ = new BehaviorSubject<any>(null);

  // counter
  public isCounterModalVisible$ = new BehaviorSubject<boolean>(false);

  // private observables
  // device
  private _deviceId$ = new BehaviorSubject<string>('');
  private _devicePlatform$ = new BehaviorSubject<string>('');
  private _isOnDevice$ = new BehaviorSubject<boolean>(false);

  // app
  private _locale$ = new BehaviorSubject<string>('');
  private _theme$ = new BehaviorSubject<string>('');
  private _title$ = new BehaviorSubject<string>('');
  private _initialDataState$ = new BehaviorSubject<string>('');
  private _isMobileResolution$ = new BehaviorSubject<boolean>(false);
  private _archiveTender$ = new BehaviorSubject<Tender>(new Tender());
  private _archiveAuction$ = new BehaviorSubject<Auction>(this.archiveAuction);
  private _navigateValue$ = new BehaviorSubject<string>('');

  // organization
  private _token$ = new BehaviorSubject<string>('');
  private _tenantCode$ = new BehaviorSubject<string>('');
  private _organizationId$ = new BehaviorSubject<string>('');
  private _currentOrganization$ = new BehaviorSubject<Organization>(null);
  private _organizationAcronym$ = new BehaviorSubject<string>('');
  private _organizationSuburb$ = new BehaviorSubject<string>('');
  private _organizationState$ = new BehaviorSubject<string>('');
  private _organizationCountry$ = new BehaviorSubject<string>('');

  // roles
  private _isSupervisor$ = new BehaviorSubject<boolean>(false);
  private _isAdmin$ = new BehaviorSubject<boolean>(false);
  private _isSeller$ = new BehaviorSubject<boolean>(false);
  private _isBuyer$ = new BehaviorSubject<boolean>(false);
  private _isGuest$ = new BehaviorSubject<boolean>(false);

  // menu
  private _isMenuToggled$ = new BehaviorSubject<boolean>(false);
  private _isFilterToggled$ = new BehaviorSubject<boolean>(false);
  private _isSubMenuExtended$ = new BehaviorSubject<boolean>(false);

  // user
  private _currentUserId$ = new BehaviorSubject<string>('');
  private _userLoginCredentials$ = new BehaviorSubject<any>(null);
  private _profile$ = new BehaviorSubject<User>(null);
  private _currentOrganizations$ = new BehaviorSubject<Organization[]>(null);
  private _isBiometricEnabled$ = new BehaviorSubject<boolean>(false);
  private _isBiometricFeatureReminder$ = new BehaviorSubject<boolean>(true);
  private _isProfilePictureUploaded$ = new BehaviorSubject<boolean>(false);

  // notification
  private _notification$ = new BehaviorSubject<Notification>(null);

  // route
  private _redirectUrl$ = new BehaviorSubject<string>('');

  // reload list
  private _isNeedToReloadList$ = new BehaviorSubject<boolean>(false);

  // clone
  private _cloneData$ = new BehaviorSubject<any>(null);

  // constructor
  constructor(private storage: SafeStorageService, private router: Router) {

    // check mobile view
    this.setIsMobileResolution(window.innerWidth < 1025);

    // init data
    this.tenantCode = this.storage.get('X-Tenant-Code');

    this.token = this.storage.get(`${this.tenantCode}-Access-Token`);
    this.secondaryAccessToken = this.storage.get(`${this.tenantCode}-Secondary-Access-Token`);

    this.setTenantInfo(JSON.parse(
      this.storage.get(`${this.tenantCode}-Info`)
    ) as Tenant);

    this.setInstrumentationKey(this.storage.get(`InstrumentationKey`));

    this.organizationId = this.storage.get(
      `${this.tenantCode}-Organization-Id`
    );
    this.organizationAcronym = this.storage.get(
      `${this.tenantCode}-Organization-Acronym`
    );
    this.isMenuToggled =
      this.storage.get(`${this.tenantCode}-Menu-Toggled`) === 'true';

    this.isBiometricEnabled =
      this.storage.get(`${this.tenantCode}-Biometric`) === 'true';

    this.isBiometricFeatureReminder =
      this.storage.get(`${this.tenantCode}-BiometricFeatureReminder`) === 'true';

    this.isProfilePictureUploaded =
      this.storage.get(`${this.tenantCode}-ProfilePictureUploaded`) === 'true';

    this.currentUserId = this.storage.get(`${this.tenantCode}-Current-User-Id`);

    this.setUserLoginCredentials(JSON.parse(
      this.storage.get(`${this.tenantCode}-User-Login-Credentials`)
    ) as any);

    combineLatest([this._tenantCode$, this._isMobileResolution$]).subscribe(([tenantCode, isMobileResolution]) => {
      this.isTenderReportsEnabled = (tenantCode != TenantConfigService.avisDirect) && !isMobileResolution;
      this.isBillingInfoEnabled = (tenantCode != TenantConfigService.avisDirect);
    });
  }

  public on<T>(observable: Observable<T>, cb: (value: T) => any): void {
    this.subscriptions.push(observable.subscribe(cb,
      error => console.error(`Error in observable: ${error}`)));
  }

  // setter methods
  // device
  public setDeviceId(deviceId: string): void {
    this._deviceId$.next(deviceId);
    this.deviceId = deviceId;
  }

  public setDevicePlatform(devicePlatform: string): void {
    this._devicePlatform$.next(devicePlatform);
    this.devicePlatform = devicePlatform;
  }

  public setIsOnDevice(isOnDevice: boolean): void {
    this._isOnDevice$.next(isOnDevice);
    this.isOnDevice = isOnDevice;
  }

  // loading
  public setIsProfileLoaded(isProfileLoaded: boolean): void {
    this.isProfileLoaded$.next(isProfileLoaded);
    this.isProfileLoaded = isProfileLoaded;
  }

  public setIsOrganizationsLoaded(isOrganizationsLoaded: boolean): void {
    this.isOrganizationsLoaded$.next(isOrganizationsLoaded);
    this.isOrganizationsLoaded = isOrganizationsLoaded;
  }

  public setIsConnections4SellerLoaded(isConnections4SellerLoaded: boolean): void {
    this.isConnections4SellerLoaded$.next(isConnections4SellerLoaded);
    this.isConnections4SellerLoaded = isConnections4SellerLoaded;
  }

  public setIsConnections4BuyerLoaded(isConnections4BuyerLoaded: boolean): void {
    this.isConnections4BuyerLoaded$.next(isConnections4BuyerLoaded);
    this.isConnections4BuyerLoaded = isConnections4BuyerLoaded;
  }

  public setIsNotificationsLoaded(isNotificationsLoaded: boolean): void {
    this.isNotificationsLoaded$.next(isNotificationsLoaded);
    this.isNotificationsLoaded = isNotificationsLoaded;
  }

  public setIsWatchListLoaded(isWatchListLoaded: boolean): void {
    this.isWatchListLoaded$.next(isWatchListLoaded);
    this.isWatchListLoaded = isWatchListLoaded;
  }

  public setIsLoadingButtonVisible(isLoadingButtonVisible: boolean): void {
    this.isLoadingButtonVisible$.next(isLoadingButtonVisible);
    this.isLoadingButtonVisible = isLoadingButtonVisible;
  }

  // app
  public setTitle(title: string): void {
    this._title$.next(title);
    this.title = title;
  }

  public setAdminApp(isAdminApp: boolean): void {
    this.isAdminApp$.next(isAdminApp);
    this.isAdminApp = isAdminApp;
  }

  public setEnvironment(environment: any): void {
    this.environment$.next(environment);
    this.environment = environment;
  }

  public setInstrumentationKey(instrumentationKey: any): void {
    const key = `InstrumentationKey`;

    instrumentationKey
      ? this.storage.set(key, instrumentationKey)
      : this.storage.remove(key);

    this.instrumentationKey$.next(instrumentationKey);

    // get instrumentationKey
    this.instrumentationKey = instrumentationKey;
  }

  public setLocale(locale: string): void {
    this._locale$.next(locale);
    this.locale = locale;
  }

  public setTheme(theme: string): void {
    this._theme$.next(theme);
    this.theme = theme;
  }

  public setInitialDataState(initialDataState: string): void {
    this._initialDataState$.next(initialDataState);
    this.initialDataState = initialDataState;
  }

  public setIsMobileResolution(isMobileResolution: boolean): void {
    this._isMobileResolution$.next(isMobileResolution);
    this.isMobileResolution = isMobileResolution;
  }

  public setNavigateValue(navigateValue: string): void {
    this._navigateValue$.next(navigateValue);
    this.navigateValue = navigateValue;
  }

  public setArchiveTender(archiveTender: Tender): void {
    this._archiveTender$.next(archiveTender);
    this.archiveTender = archiveTender;
  }

  public setArchiveAuction(archiveAuction: Auction): void {
    this._archiveAuction$.next(archiveAuction);
    this.archiveAuction = archiveAuction;
  }

  // organization
  public setToken(token: string): void {
    const key = `${this.tenantCode}-Access-Token`;

    token ? this.storage.set(key, token) : this.storage.remove(key);
    this._token$.next(token);
    this.token = token;
  }

  public setTenantCode(tenantCode: string): void {
    const key = 'X-Tenant-Code';

    tenantCode ? this.storage.set(key, tenantCode) : this.storage.remove(key);
    this._tenantCode$.next(tenantCode);
    this.tenantCode = tenantCode;
  }

  public setTenantInfo(tenantInfo: Tenant): void {
    const key = `${this.tenantCode}-Info`;

    tenantInfo && Object.keys(tenantInfo).length > 0
      ? this.storage.set(key, JSON.stringify(tenantInfo))
      : this.storage.remove(key);

    this.tenantInfo$.next(tenantInfo);
    this.tenantInfo = tenantInfo;
  }

  public setOrganizationId(organizationId: string): void {
    const key = `${this.tenantCode}-Organization-Id`;

    organizationId
      ? this.storage.set(key, organizationId)
      : this.storage.remove(key);
    this._organizationId$.next(organizationId);
    this.organizationId = organizationId;
  }

  public setCurrentOrganization(currentOrganization: Organization): void {
    this._currentOrganization$.next(currentOrganization);
    this.currentOrganization = currentOrganization;
  }

  public setOrganizationAcronym(organizationAcronym: string): void {
    const key = `${this.tenantCode}-Organization-Acronym`;

    organizationAcronym
      ? this.storage.set(key, organizationAcronym)
      : this.storage.remove(key);
    this._organizationAcronym$.next(organizationAcronym);
    this.organizationAcronym = organizationAcronym;
  }

  public setOrganizationSuburb(organizationSuburb: string): void {
    this._organizationSuburb$.next(organizationSuburb);
    this.organizationSuburb = organizationSuburb;
  }

  public setOrganizationState(organizationState: string): void {
    this._organizationState$.next(organizationState);
    this.organizationState = organizationState;
  }

  public setOrganizationCountry(organizationCountry: string): void {
    this._organizationCountry$.next(organizationCountry);
    this.organizationCountry = organizationCountry;
  }

  // roles
  public setIsSupervisor(isSupervisor: boolean): void {
    this._isSupervisor$.next(isSupervisor);
    this.isSupervisor = isSupervisor;
  }

  public setIsAdmin(isAdmin: boolean): void {
    this._isAdmin$.next(isAdmin);
    this.isAdmin = isAdmin;
  }

  public setIsGuest(isGuest: boolean): void {
    this._isGuest$.next(isGuest);
    this.isGuest = isGuest;
  }

  public setIsSeller(isSeller: boolean): void {
    this._isSeller$.next(isSeller);
    this.isSeller = isSeller;
  }

  public setIsBuyer(isBuyer: boolean): void {
    this._isBuyer$.next(isBuyer);
    this.isBuyer = isBuyer;
  }

  // menu
  public setMenuToggled(isMenuToggled: boolean): void {
    const key = `${this.tenantCode}-Menu-Toggled`;

    isMenuToggled != null
      ? this.storage.set(key, `${isMenuToggled}`)
      : this.storage.remove(key);
    this._isMenuToggled$.next(isMenuToggled);
    this.isMenuToggled = isMenuToggled;
  }

  public setSubMenuExtended(isSubMenuExtended: boolean): void {
    this._isSubMenuExtended$.next(isSubMenuExtended);
    this.isSubMenuExtended = isSubMenuExtended;
  }

  public setIsShowNotificationBox(isShowNotificationBox: boolean): void {
    this.isShowNotificationBox$.next(isShowNotificationBox);
    this.isShowNotificationBox = isShowNotificationBox;

    // add classes
    if (isShowNotificationBox && this.isMobileResolution) {
      document.body.classList.add('show-popup');
      document.body.classList.add('show-notification');
    } else {
      document.body.classList.remove('show-popup');
      document.body.classList.remove('show-notification');
    }
  }

  public setFilterToggled(isFilterToggled: boolean): void {
    this._isFilterToggled$.next(isFilterToggled);
    this.isFilterToggled = isFilterToggled;
  }

  // user
  public setCurrentUserId(currentUserId: string): void {
    const key = `${this.tenantCode}-Current-User-Id`;

    currentUserId
      ? this.storage.set(key, currentUserId)
      : this.storage.remove(key);
    this._currentUserId$.next(currentUserId);
    this.currentUserId = currentUserId;
  }

  public setUserLoginCredentials(userLoginCredentials: any): void {
    const key = `${this.tenantCode}-User-Login-Credentials`;

    userLoginCredentials && Object.keys(userLoginCredentials).length > 0
      ? this.storage.set(key, JSON.stringify(userLoginCredentials))
      : this.storage.remove(key);
    this._userLoginCredentials$.next(userLoginCredentials);
    this.userLoginCredentials = userLoginCredentials;
  }

  public setIsBiometricEnabled(isBiometricEnabled: boolean): void {
    const key = `${this.tenantCode}-Biometric`;

    isBiometricEnabled != null
      ? this.storage.set(key, `${isBiometricEnabled}`)
      : this.storage.remove(key);
    this._isBiometricEnabled$.next(isBiometricEnabled);
    this.isBiometricEnabled = isBiometricEnabled;
  }

  public setIsProfilePictureUploaded(isProfilePictureUploaded: boolean): void {
    const key = `${this.tenantCode}-ProfilePictureUploaded`;

    isProfilePictureUploaded != null
      ? this.storage.set(key, `${isProfilePictureUploaded}`)
      : this.storage.remove(key);
    this._isProfilePictureUploaded$.next(isProfilePictureUploaded);
    this.isProfilePictureUploaded = isProfilePictureUploaded;
  }

  public setSecondaryAccessToken(accessToken: string): void {
    const key = `${this.tenantCode}-Secondary-Access-Token`;
    if (accessToken != null) {
      this.secondaryAccessToken = accessToken;
      this.storage.set(key, `${accessToken}`)
    }
    else {
      this.storage.remove(key);
    }

  }

  public setIsBiometricFeatureReminder(isBiometricFeatureReminder: boolean): void {
    const key = `${this.tenantCode}-BiometricFeatureReminder`;

    isBiometricFeatureReminder != null
      ? this.storage.set(key, `${isBiometricFeatureReminder}`)
      : this.storage.remove(key);
    this._isBiometricFeatureReminder$.next(isBiometricFeatureReminder);
    this.isBiometricFeatureReminder = isBiometricFeatureReminder;
  }

  public setProfile(profile: User): void {
    if (profile) {
      this._profile$.next(profile);
      this.profile = profile;
    }
  }

  public setCurrentOrganizations(currentOrganizations: Organization[]): void {
    if (currentOrganizations) {
      this._currentOrganizations$.next(currentOrganizations);
      this.currentOrganizations = currentOrganizations;
    }
  }

  public setNotification(notification: Notification): void {
    if (notification) {
      this._notification$.next(notification);
      this.notification = notification;
    }
  }

  // route
  public setRedirectUrl(redirectUrl: string): void {
    this._redirectUrl$.next(redirectUrl);
    this.redirectUrl = redirectUrl;
  }

  public setIsNeedToReloadList(isNeedToReloadList: boolean): void {
    this._isNeedToReloadList$.next(isNeedToReloadList);
    this.isNeedToReloadList = isNeedToReloadList;
  }

  // methods
  public async navigateWithTenantCode(newUrl: string, params?: Params): Promise<any> {
    if (this.tenantCode) {
      return await this.router.navigate([
        newUrl ? `${this.tenantCode}/${newUrl}` : this.tenantCode
      ], { queryParams: params });
    }
  }

  public async navigateByUrl(newUrl: string): Promise<any> {
    return await this.router.navigateByUrl(this.router.parseUrl(newUrl));
  }

  // clone
  public setCloneData(cloneData: any): void {
    this._cloneData$.next(cloneData);
    this.cloneData = cloneData;
  }

  // counter
  public setIsCounterModalVisible(isCounterModalVisible: boolean): void {
    this.isCounterModalVisible$.next(isCounterModalVisible);
    this.isCounterModalVisible = isCounterModalVisible;
  }

  // stop notification hub
  public async stopNotificationHubConnection(): Promise<void> {
    if (this.isNotificationHubConnected) {
      await this.notificationHubConnection.stop();
    }
  }

  // getters
  public get isNotificationHubConnected(): boolean {
    return (
      this.notificationHubConnection &&
      this.notificationHubConnection.state === signalR.HubConnectionState.Connected
    );
  }
}
