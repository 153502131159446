import { NgxUiLoaderConfig } from 'ngx-ui-loader';
import { tenantCode } from './tenant-config/tenant-code';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'rgba(255,255,255,0.8)',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 70,
  bgsType: 'ball-scale-multiple',
  blur: 3,
  delay: 0,
  fgsPosition: 'center-center',
  fgsSize: 30,
  fgsType: 'fading-circle',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(255,255,255,0.75)',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: false,
  text: '',
  textColor: '',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 500
};

export const config = {
  // defaults
  ngxUiLoaderConfig: ngxUiLoaderConfig,
  tenantCode: tenantCode,
  googleApiKey: 'AIzaSyDXraB8eb0pYEvhzkB2E7HetP6Gl61Y-5A',
  shortDateFormat: 'MMM DD, YYYY',
  longDateFormat: 'DD MMM YYYY, h:mm a',
  longDateByDayNameFormat: 'ddd DD/MM/YY, h:mm a',
  eventDayDateFormat: 'DD/MM/YYYY h:mm a',
  notificationDateFormat: 'DD/MM/YYYY | hh:mm A',
  monthYearFormat: 'MMM-YYYY',
  emptyGuid: '00000000-0000-0000-0000-000000000000',
  loadedDataIcon: '<i class="icon icon-checked default inline sm ml-2"></>',
  androidSenderId: '592116683094',
  defaultPageSize: 20,
  notPagedRecordNumber: 1000,
  allowedFileExtension: ["jpg", "jpeg", "png"],
  imageFileUploadExtensionErrorMessage: 'Image format is not supported. Please upload with .png, .jpg or .jpeg format only.',

  // buy / make offer
  buyOfferDescription:
    "On: '*|UTCDateTime|*' UserEmail: '*|CurrentUserEmail|*' UserFullName: '*|CurrentUserFullName|*' UserId: '*|CurrentUserId|*' Made: '*|Action|*' Transaction From: 'LISTING' On: '*|StockName|*' with Values: '*|Values|*'",
  tenderOfferDescription:
    "On: '*|UTCDateTime|*' UserEmail: '*|CurrentUserEmail|*' UserFullName: '*|CurrentUserFullName|*' UserId: '*|CurrentUserId|*' Made: '*|Action|*' In the Event: '*|EventId|*' Transaction From: 'TENDER' On: '*|StockName|*' with Values: '*|Values|*'",

  // accept / reject / counter
  transactionDescription:
    "On: '*|UTCDateTime|*' UserEmail: '*|CurrentUserEmail|*' UserFullName: '*|CurrentUserFullName|*' UserId: '*|CurrentUserId|*' Made: '*|Action|*' Transaction In Response To: '*|ParentId|*' From: 'LISTING' On: '*|StockName|*' with Values: '*|Values|*'",
  tenderTransactionDescription:
    "On: '*|UTCDateTime|*' UserEmail: '*|CurrentUserEmail|*' UserFullName: '*|CurrentUserFullName|*' UserId: '*|CurrentUserId|*' Made: '*|Action|*' Transaction In Response To: '*|ParentId|*' From: 'TENDER' On: '*|StockName|*' with Values: '*|Values|*'",

  // view / invite
  viewInviteStockDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' Made: '*|Action|*' From: 'LISTING' On: '*|StockName|*' with Values: '*|Values|*'",
  tenderViewInviteStockDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' Made: '*|Action|*' Transaction In Response To: '*|ParentId|*' From: 'TENDER' On: '*|StockName|*' with Values: '*|Values|*'",

  loginDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' UserFullName: '*|CurrentUserFullName|*' Action: '*|Action|*'",

  watchDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' StockId: '*|StockId|*' StockName: '*|StockName|*' Action: '*|Action|*' ActionType: '*|EventType|*'",

  stockDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' StockId: '*|StockId|*' PreviousData: '*|Previous|*' NewData: '*|New|*' Action: '*|Action|*' ActionType: '*|EventType|*'",

  connectionDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' TargetUser: '*|TargetUser|*' Action: '*|Action|*' ActionType: '*|EventType|*'",

  tenderDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' TenderId: '*|TenderId|*' Action: '*|Action|*' ActionType: '*|EventType|*'",

  viewStockInTenderDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' StockId: '*|StockId|*' StockName: '*|StockName|*' TenderId: '*|TenderId|*' Action: '*|Action|*' ActionType: '*|EventType|*'",

  viewStockOutsideTenderDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' StockId: '*|StockId|*' StockName: '*|StockName|*' Action: '*|Action|*' ActionType: '*|EventType|*'",

  buyerNotesDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' StockId: '*|StockId|*' Message: '*|Message|*' Action: '*|Action|*' ActionType: '*|EventType|*'",


  profileDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' UserFullName: '*|CurrentUserFullName|*' Action: '*|Action|*' ActionType: '*|EventType|*'",

  batchTenderDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' Number Of Stocks: '*|NumberOfStocks|*' TenderName: '*|TenderName|*' Action: '*|Action|*' ActionType: '*|EventType|*'",

  auctionDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' AuctionId: '*|AuctionId|*' Action: '*|Action|*' ActionType: '*|EventType|*'",

  viewStockInAuctionDescription:
    "On: '*|UTCDateTime|*' User: '*|CurrentUserId|*' UserEmail: '*|CurrentUserEmail|*' StockId: '*|StockId|*' StockName: '*|StockName|*' AuctionId: '*|AuctionId|*' Action: '*|Action|*' ActionType: '*|EventType|*'",

};
