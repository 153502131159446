import { Component } from '@angular/core';
import { StateService } from '@app/core';
import { ImageThumbnailSizes } from '@app/model';

@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html'
})
export class UserImageComponent {
  constructor(private state: StateService) {}

  // getters
  public get imageUrl(): string {
    const profile = this.state.profile;
    const defaultImages = this.state.tenantInfo && this.state.tenantInfo.defaultImages;
    const defaultImageUrl = defaultImages && defaultImages.identity && defaultImages.identity.profile ? defaultImages.identity.profile : '';
  
    if (profile) {
      const fileUrl = profile.fileUrl;
  
      if (fileUrl.hasAnyThumbnail) {
        const thumbnailUrl = fileUrl.thumbnailUrls.find(
          x => x.sizeCodeValue === ImageThumbnailSizes.Small
        ) && fileUrl.thumbnailUrls.find(
          x => x.sizeCodeValue === ImageThumbnailSizes.Small
        ).url;
  
        return thumbnailUrl || fileUrl.url || defaultImageUrl;
      }
  
      return fileUrl.url || defaultImageUrl;
    }
  
    return defaultImageUrl;
  }
}
