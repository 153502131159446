import { Injectable } from '@angular/core';

import { Stock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class BuyerStocksUserCollection extends DataCollection<Stock> {}

@Injectable({
  providedIn: 'root'
})
export class BuyerStocksUserService extends ItemsService<Stock> {
  protected serviceName = 'BuyerStocksUserService';
  public stock: any;

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('buyer/catalog/v1/stocks');
  }

  public get collection(): BuyerStocksUserCollection {
    return new BuyerStocksUserCollection(this.internalData);
  }

  public async getDataByUserId(
    userId: string = '',
    forceGet: boolean = false
  ): Promise<void> {
    const params = {
      filters: `createdBy$${userId}$0`
    };

    await super.getData('', params, forceGet);
  }
}
