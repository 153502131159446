import { Identity } from './identity';
import { User } from './user';
import { TenderStock } from './tender';

export class Offers extends Identity {
  transaction: TransactionPayload;
  status: OfferStatues;
  isOwner: boolean;
  stock?: TenderStock;

  constructor() {
    super();
  }
}
export class TransactionCount extends Identity {
  scalarValue: number | number[];

  constructor() {
    super();
  }
}

export class Transaction extends Identity {
  Name: string;
  Payload: TransactionPayload;

  constructor() {
    super();
  }
}

export class TransactionPayload {
  id?: string;
  eventId: string;
  entityId: string;
  entityOrganizationId: string;
  organizationId?: string =  '';
  description: string;
  parentId: string;
  type: string;
  typeText: string = '';
  values: any[];
  createdOn?: string;
  createdBy?: string;
  updatedOn?: string;
  value?: number;
  expiryDate?: Date;
  owner?: User;
}

export enum OfferStatues {
  Accepted,
  Rejected,
  Countered,
  Pending,
  Sold,
  Expired,
  Current,
  UserBid
}

export const TransactionStatusesText = {
  [OfferStatues.Accepted]: 'Accepted',
  [OfferStatues.Rejected]: 'Rejected',
  [OfferStatues.Countered]: 'Countered',
  [OfferStatues.Pending]: 'Pending',
  [OfferStatues.Sold]: 'Sold',
  [OfferStatues.Expired]: 'Expired',
  [OfferStatues.Current]: 'Winning Bid',
  [OfferStatues.UserBid]: 'Your Bid'
};

export enum TransactionTypes {
  View = 'View',
  Offer = 'Offer',
  Buy = 'Buy',
  AcceptOffer = 'AcceptOffer',
  RejectOffer = 'RejectOffer',
  Invite = 'Invite',
  CounterOffer = 'CounterOffer',
  Login = 'Login',
  Logout = 'Logout',
  Remove = 'Remove',
  Add = 'Add',
  Update = 'Update',
  BatchOffer = 'BatchOffer'
}

export enum ActionTypes {
  MakeOffer = 'MakeOffer',
  Buy = 'Buy',
  AcceptOffer = 'AcceptOffer',
  RejectOffer = 'RejectOffer',
  RequestOffer = 'RequestOffer',
  CounterOffer = 'CounterOffer',
  Log = 'Log'
}

export enum TransactionEntities{
  User = 'User',
  Stock = 'Stock',
  Tender = 'Tender',
  Connection = 'Connection',
  Watch = 'Watch',
}

export enum EventTypes {
  Login = 'Login',
  Logout = 'Logout',
  Watch = 'Watch',
  UnWatch = 'UnWatch',
  AddStock = 'AddStock',
  ViewTender = 'ViewTender',
  UpdateProfile = 'UpdateProfile',
  ViewStockInTender = 'ViewInTender',
  ViewStockOutOfTender = 'ViewOutOfTender',
  AddConnection = 'AddConnection',
  RemoveConnection = 'RemoveConnection',
  AddBuyerNotes = 'AddBuyerNotes',
  UpdateSellerNotes = 'UpdateSellerNotes',
  UpdateStockPrice = 'UpdatePrice',
  UpdateStockDefaultReservePrice = 'UpdateDefaultReservePrice',
  MakeOffer = 'MakeOffer',
  AcceptOffer = 'AcceptOffer',
  RejectOffer = 'RejectOffer',
  CounterOffer = 'CounterOffer',
  RequestOffer = 'RequestOffer',
  Buy = 'Buy',
  UpdateUserPreferences = 'UpdateUserPreferences',
  BatchTenderOffer = 'BatchTenderOffer'
}

export const EventTypesText = {
  [EventTypes.Login]: 'Login',
  [EventTypes.Logout]: 'Logout',
  [EventTypes.Watch]: 'Watch',
  [EventTypes.UnWatch]: 'Un Watch',
  [EventTypes.AddStock]: 'Add Stock',
  [EventTypes.ViewTender]: 'View Tender',
  [EventTypes.UpdateProfile]: 'Update Profile',
  [EventTypes.ViewStockInTender]: 'View Stock In Tender',
  [EventTypes.ViewStockOutOfTender]: 'View Stock Out Of Tender',
  [EventTypes.AddConnection]: 'Add Connection',
  [EventTypes.RemoveConnection]: 'Remove Connection',
  [EventTypes.AddBuyerNotes]: 'Add Buyer Notes',
  [EventTypes.UpdateSellerNotes]: 'Update Seller Notes',
  [EventTypes.UpdateStockPrice]: 'Update Stock Price',
  [EventTypes.UpdateStockDefaultReservePrice]: 'Update Stock Default Reserve Price',
  [EventTypes.UpdateUserPreferences]: 'Update User Preferences',
  [EventTypes.BatchTenderOffer]: 'Batch Tender Offer'
};

export const EventTypesAction = {
  [EventTypes.Login]: ActionTypes.Log,
  [EventTypes.Logout]: ActionTypes.Log,
  [EventTypes.Watch]: ActionTypes.Log,
  [EventTypes.UnWatch]: ActionTypes.Log,
  [EventTypes.AddStock]: ActionTypes.Log,
  [EventTypes.ViewTender]: ActionTypes.Log,
  [EventTypes.UpdateProfile]: ActionTypes.Log,
  [EventTypes.ViewStockInTender]: ActionTypes.Log,
  [EventTypes.ViewStockOutOfTender]: ActionTypes.Log,
  [EventTypes.AddConnection]: ActionTypes.Log,
  [EventTypes.RemoveConnection]: ActionTypes.Log,
  [EventTypes.AddBuyerNotes]: ActionTypes.Log,
  [EventTypes.UpdateSellerNotes]: ActionTypes.Log,
  [EventTypes.UpdateStockPrice]: ActionTypes.Log,
  [EventTypes.UpdateStockDefaultReservePrice]: ActionTypes.Log,
  [EventTypes.BatchTenderOffer]: ActionTypes.Log,
  [EventTypes.UpdateUserPreferences]: ActionTypes.Log
};

export const EventTypesTransactionType = {
  [EventTypes.Login]: TransactionTypes.Login,
  [EventTypes.Logout]: TransactionTypes.Logout,
  [EventTypes.Watch]: TransactionTypes.Add,
  [EventTypes.UnWatch]: TransactionTypes.Remove,
  [EventTypes.AddStock]: TransactionTypes.Add,
  [EventTypes.ViewTender]: TransactionTypes.View,
  [EventTypes.UpdateProfile]: TransactionTypes.Update,
  [EventTypes.ViewStockInTender]: TransactionTypes.View,
  [EventTypes.ViewStockOutOfTender]: TransactionTypes.View,
  [EventTypes.AddConnection]: TransactionTypes.Add,
  [EventTypes.RemoveConnection]: TransactionTypes.Remove,
  [EventTypes.AddBuyerNotes]: TransactionTypes.Add,
  [EventTypes.UpdateSellerNotes]: TransactionTypes.Update,
  [EventTypes.UpdateStockPrice]: TransactionTypes.Update,
  [EventTypes.UpdateStockDefaultReservePrice]: TransactionTypes.Update,
  [EventTypes.MakeOffer]: TransactionTypes.Offer,
  [EventTypes.Buy]: TransactionTypes.Buy,
  [EventTypes.AcceptOffer]: TransactionTypes.AcceptOffer,
  [EventTypes.RejectOffer]: TransactionTypes.RejectOffer,
  [EventTypes.RequestOffer]: TransactionTypes.Invite,
  [EventTypes.CounterOffer]: TransactionTypes.CounterOffer,
  [EventTypes.BatchTenderOffer]: TransactionTypes.BatchOffer,
  [EventTypes.UpdateUserPreferences]: TransactionTypes.Update
};

export const EventTypesTransactionEntity = {
  [EventTypes.Watch]: TransactionEntities.Watch,
  [EventTypes.UnWatch]: TransactionEntities.Watch,
  [EventTypes.AddStock]: TransactionEntities.Stock,
  [EventTypes.ViewTender]: TransactionEntities.Tender,
  [EventTypes.UpdateProfile]: TransactionEntities.User,
  [EventTypes.ViewStockInTender]: TransactionTypes.View,
  [EventTypes.ViewStockOutOfTender]: TransactionTypes.View,
  [EventTypes.AddConnection]: TransactionTypes.Add,
  [EventTypes.RemoveConnection]: TransactionTypes.Remove,
  [EventTypes.AddBuyerNotes]: TransactionTypes.Add,
  [EventTypes.UpdateSellerNotes]: TransactionTypes.Update,
  [EventTypes.UpdateStockPrice]: TransactionTypes.Update,
  [EventTypes.UpdateStockDefaultReservePrice]: TransactionTypes.Update
};

export interface TsxLoggingBackendConfig {
  IsEnabled?: boolean;
}
