import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route
} from '@angular/router';
import { DisposeService } from '@app/data';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private disposeService: DisposeService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const { roles = [] } = route.data;
    return this._checkAuthorization(state.url, roles);
  }

  public canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }

  public canLoad(route: Route): boolean {
    return this._checkAuthorization(`/${route.path}`, []);
  }

  public resolve(): void {
    if (this.authService.isAuthenticated()) {
      this.router.navigate([this.authService.tenantCode, 'dashboard']);
    }
  }

  private _checkAuthorization(url: string, roles: string[]): boolean {
    const isAuthenticated = this.authService.isAuthenticated();
    const hasPermission = this.authService.hasPermission(roles);

    if (isAuthenticated && hasPermission) {
      return true;
    }

    if (!hasPermission) {
      // access denied
      this.authService.redirectUrl = '';
      this.router.navigate([this.authService.tenantCode, 'error', '401']);
      return false;
    }

    if (!isAuthenticated) {
      // store the attempted URL for redirecting
      this.authService.redirectUrl = url;
      // remove state and cache
      this.disposeService.dispose();
      // redirect to login
      this.router.navigate([this.authService.tenantCode, 'login']);
      return false;
    }
  }
}