import { Component, HostListener } from '@angular/core';

import { NotificationService, TenantHubNotificationService } from '@app/data';

import { StateService } from '@app/core';

import * as signalR from '@microsoft/signalr';
import { HubConnectionState } from '@microsoft/signalr';

import {
  Notification,
  NotificationChannels,
  NotificationHistorySummary
} from '@app/model';

import { PushNotificationService } from 'libs/notification/src/lib/push-notification.service';
import { Router } from '@angular/router';

declare let cordova: any;

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html'
})
export class NotificationPopupComponent {
  // stop connection before unload
  @HostListener('window:beforeunload')
  stopHubConnection() {
    this.state.stopNotificationHubConnection();
  }

  public connectionState: signalR.HubConnectionState;

  public pushNotificationData: any[] = [];

  public pageSize: number = 5;

  public unReadNotificationsCount: number = 0;
  public previousUnreadCount = this.unReadNotificationsCount;

  public constructor(
    private router: Router,
    private state: StateService,
    private notificationService: NotificationService,
    private pushNotificationService: PushNotificationService,
    private tenantHubNotificationService: TenantHubNotificationService
  ) { }

  public async ngOnInit(): Promise<void> {
    // start browser notification
    this.pushNotificationService.requestPermission();

    // get data
    await this._getData();

    // start connection
    if (!this.state.isNotificationHubConnected) {
      await this._setHubConnection();
    }
  }

  // methods
  public onNotificationIcon(): void {
    this.state.setIsShowNotificationBox(!this.state.isShowNotificationBox);
  }

  // getters
  public get notification(): Notification {
    let notification: Notification;

    this.state.on(
      this.state.isNotificationsLoaded$,
      (isNotificationsLoaded: boolean) => {
        if (isNotificationsLoaded) {
          notification = this.notificationService.collection.item;
        }
      }
    );

    return notification;
  }

  public get unReadNotifications(): NotificationHistorySummary[] {
    return this.notification ? this.notification.unReadNotifications : [];
  }

  public get isExistNotifications(): boolean {
    return this.unReadNotifications.length > 0;
  }

  public get showAlarmClass(): any {
    this.unReadNotificationsCount = this.unReadNotifications.length;

    if (this.state.isMobileResolution && (typeof (cordova) !== 'undefined' && cordova && this.previousUnreadCount != this.unReadNotificationsCount)) {
      this.previousUnreadCount = this.unReadNotificationsCount;
      cordova.plugins.notification.badge.set(this.unReadNotificationsCount);
    }

    return {
      show: this.isExistNotifications
    };
  }

  public get showNotificationsClass(): any {
    return { show: this.state.isShowNotificationBox };
  }

  public get limitNotifications(): NotificationHistorySummary[] {
    return this.unReadNotifications.slice(0, this.pageSize);
  }

  public get isShowAllVisible(): boolean {
    return this.router.url.indexOf('notification') === -1;
  }

  // privates
  private async _setHubConnection(): Promise<void> {
    await this.tenantHubNotificationService
      .getNotificationKeys()
      .then(async () => {
        const { url, hub } = this.tenantHubNotificationService.collection.item;
        const { currentUserId, tenantCode } = this.state;

        // hub builder
        let hubUrl = `${url}?X-User-Id=${currentUserId}&X-Tenant-Code=${tenantCode}&key=${hub}`;

        this.state.notificationHubConnection = new signalR.HubConnectionBuilder()
          .withUrl(hubUrl)
          .withAutomaticReconnect()
          .build();
        // receive messages
        this.state.notificationHubConnection.on(
          'receiveCarZappNotification',
          (
            title: string,
            content: string,
            url: string,
            thumbnailUrl: string,
            code: string,
            metaData: any,
            createdOn: string
          ) => {
            // count
            this.unReadNotificationsCount++;

            // add the message to the page.
            const newNotification: NotificationHistorySummary = {
              code: code,
              title: title,
              content: content,
              url: url,
              thumbnailUrl: thumbnailUrl,
              isRead: false,
              creationDateTime: createdOn,
              metaData: JSON.parse(metaData)
            };

            if (this.notification) {
              this.notification.allNotifications.unshift(newNotification);
              this.notification.unReadNotifications.unshift(newNotification);
            }

            // update
            this.notificationService.init(this.notification);

            // add to browser notification
            const detailUrl = this.notificationService.getDetailsUrl(
              newNotification
            );

            const newPushNotification = {
              title: title,
              icon: thumbnailUrl
                ? thumbnailUrl
                : this.state.tenantInfo.defaultImages.branding.notificationIcon,
              content: content,
              url: `/#${detailUrl}`
            };

            this.pushNotificationData.unshift(newPushNotification);

            // generate push notification
            this.pushNotificationService.generateNotification(
              this.pushNotificationData
            );

            // remove current push
            this.pushNotificationData = [];
          }
        );

        // on close
        this.state.notificationHubConnection.onclose(async error => {
          if (error) {
            console.log('Notification Connection closed with error: ' + JSON.stringify(error));
            await this._startConnection(true, true);
          } else {
            console.log('Notification Connection Disconnected!');
          }
        });

        // start hub
        await this._startConnection();
      });
  }

  private async _startConnection(
    isGetData: boolean = false,
    forceGet: boolean = false
  ): Promise<void> {
    const { notificationHubConnection } = this.state;

    if (!notificationHubConnection || notificationHubConnection.state !== HubConnectionState.Disconnected) {
      return;
    }

    await notificationHubConnection.start();

    // get data
    if (isGetData) {
      await this._getData(forceGet);
    }
  }

  private async _getData(forceGet: boolean = false): Promise<void> {
    await this.notificationService
      .getNotifications(NotificationChannels.WebNotification, forceGet);
  }
}
