import { Component, Input, AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
declare let $: any;

@Component({
    selector: 'app-dynamic-web-content',
    templateUrl: './dynamic-web-content.component.html',
})
export class DynamicPolicyComponent implements AfterViewInit {

    @Input() url?: string;
    @Input() width?: number;
    @Input() height?: number;

    public iframe: any;

    public constructor(
        private sanitizer: DomSanitizer
    ) { }

    public ngAfterViewInit() {
        $('iframe').attr("frameborder", "0");
    }

    public ngOnInit() {
        this.iframe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }
}