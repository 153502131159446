import { Injectable } from '@angular/core';

import { Organization, Payment } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { StateService, config } from '@app/core';
import { HttpHeaders } from '@angular/common/http';

export class PaymentCollection extends DataCollection<Payment> {}

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends ItemsService<Payment> {
  protected serviceName = 'PaymentService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
    super.configure('supervisor/tenant/v2/specs');
  }

  public get collection(): PaymentCollection {
    return new PaymentCollection(this.internalData);
  }

  public async saveItem(
    item: Payment,
    urlPostfix: string = '',
    isPut: boolean = false,
    isReturnNewResponse: boolean = true,
    showLogger: boolean = true,
    showLoading: boolean = true,
    isNeedReloadItems: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    await super.saveItem(
      item,
      urlPostfix,
      isPut,
      isReturnNewResponse,
      showLogger,
      showLoading,
      isNeedReloadItems,
      options
    );
  }

  public async getData4EditingForm(
    paramId: string = '',
    urlPostfix: string = '',
    forceGet: boolean = true
  ): Promise<any> {
    return await this.getDataById(paramId, urlPostfix, forceGet);
  }
}
