import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StateService } from '@app/core';

@Component({
  selector: 'app-radio-collapse',
  templateUrl: './radio-collapse.component.html'
})
export class RadioCollapseComponent implements OnInit {
  @Input() field: any;
  @Input() index?: number;
  @Input() group: FormGroup;
  @Input() isEditing?: boolean;
  @Input() isReadOnly?: boolean = null;
  
  constructor(private state: StateService) {}

  public ngOnInit(): void {}

  // methods
  public isShowCollapse(key: string): boolean {
    const value = this.group.controls[this.field.name].value;
    return key === value;
  }

  // getters
  public get radioClass(): any {
    return {
      'd-none': this.field.isHidden,
      'custom-control-inline': this.field.checkboxType === 'inline'
    };
  }

  public get isShowLabel(): boolean {
    return this.field.label != null;
  }

  public setName(key: string): string {
    const index = this.index != null ? `-${this.index}` : '';
    return `${this.field.name}-${key}${index}`;
  }

  public get isRequired(): boolean {
    return (
      this.field.validations &&
      this.field.validations.some((x: any) => x.name === 'required')
    );
  }
}
