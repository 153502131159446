import { Pipe, PipeTransform } from '@angular/core';

import moment, { Moment } from 'moment';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {
  transform(value: Date | Moment | string, format?: string): string | Moment {
    const utcDateTime = value
      ? moment.utc(value).toDate()
      : moment.utc().toDate();

    return format ? moment(utcDateTime).format(format) : moment(utcDateTime);
  }
}
