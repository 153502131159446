import { Injectable } from '@angular/core';

import { Connection, Stock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { config, StateService } from '@app/core';
import { HttpHeaders } from '@angular/common/http';
import { TransactionsCountService } from './transactions-count.service';

export class WatchListCollection extends DataCollection<Connection> { }

@Injectable({
  providedIn: 'root'
})
export class WatchListService extends ItemsService<Connection> {
  protected serviceName = 'WatchListService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService,
    private transactionsCountService: TransactionsCountService
  ) {
    super(dataAccessService, logger);
    super.configure('connection/v1/connections');
  }

  public get collection(): WatchListCollection {
    return new WatchListCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    const staticFilters = [
      'destinationEntityType$Stock$0',
      'features.status$Available$3',
      'features.status$InTender$3',
      'features.status$OnHold$3'
    ];

    urlPostfix = urlPostfix
      ? urlPostfix
      : `sourceEntity/${this.state.currentUserId}`;

    params.filters =
      params.filters && params.filters instanceof Array
        ? params.filters.some((x: string) => x.indexOf('status') > -1)
          ? params.filters
          : staticFilters.concat(params.filters)
        : staticFilters;

    params.pageSize = config.notPagedRecordNumber;

    await super
      .getData(urlPostfix, params, forceGet, options);

    if (this.collection.items.length > 0) {
      // stock ids
      const stockIds = this.collection.items.map((item: Connection) => {
        return item.destinationEntityId;
      });

      // get view count
      await this.transactionsCountService
        .getBatchViewsCount(stockIds);
      // replace view count
      const viewCounts = this.transactionsCountService.collection.item as any;


      // change data
      const data = {
        items: this.collection.items.map((item: Connection) => {
          const features = item.features as Stock;
          const count = viewCounts.find((vcount: { id: string; }) => vcount.id === features.id)
          features.viewCount = count ? count.count as number : 0
          item.features = features;
          return item;
        }),
        pageNumber: this.collection.pageNumber,
        totalPages: this.collection.totalPages,
        totalCount: this.collection.totalCount,
        hasPreviousPage: this.collection.hasPreviousPage,
        hasNextPage: this.collection.hasNextPage
      };

      this.init(data);

    }

  }

  public async getWatchList(forceGet: boolean = false): Promise<void> {
    this.state.setInitialDataState('Loading Watch List...');

    await this.getData();
    this.state.setInitialDataState(`Watch List ${config.loadedDataIcon}`);

    // stop loading
    this.state.setIsWatchListLoaded(true);

  }
}
