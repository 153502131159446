import { Injectable } from '@angular/core';

import { User } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class SupervisorUsersCollection extends DataCollection<User> {}

@Injectable({
  providedIn: 'root'
})
export class SupervisorUsersService extends ItemsService<User> {
  protected serviceName = 'SupervisorUsersService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('identity/v1/users');
  }

  public get collection(): SupervisorUsersCollection {
    return new SupervisorUsersCollection(this.internalData);
  }

  public getData4EditingForm(
    paramId: string = '',
    urlPostfix: string = '',
    forceGet: boolean = true
  ): Promise<any> {
    const item = this.getDataById(
      paramId,
      urlPostfix,
      forceGet
    );

    return item;
  }
}
