import { Injectable } from '@angular/core';

import { StockMake } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class StockMakeCollection extends DataCollection<StockMake> {}

@Injectable({
  providedIn: 'root'
})
export class StockMakeService extends ItemsService<StockMake> {
  protected serviceName = 'StockMakeService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('catalog/v2/makes');
  }

  public get collection(): StockMakeCollection {
    return new StockMakeCollection(this.internalData);
  }
}
