import { Identity } from './identity';
import { Organization, OrganizationFeature } from './organization';
import { Role } from './role';
import { File } from './file';

export class User extends Identity {
  firstName?: string;
  lastName?: string;
  fullName?: string;
  email?: string;
  userName: string;
  password?: string;
  confirmPassword?: string;
  phoneNumber?: string;
  roles?: Role[];
  organizationId?: string;
  isActive?: boolean;
  emailConfirmed?: boolean;
  pictureName?: string;
  currentPassword?: string;
  newPassword?: string;
  confirmNewPassword?: string;
  organizations: Organization[];
  fileUrl?: File;

  defaultOrganizationName?: string;
  defaultOrganizationFeatures?: OrganizationFeature;
  accessToken?: string;

  constructor() {
    super();
  }
}

export enum SummaryDashboardOptions {
  Buyer = "BuyerOnly",
  Seller = "SellerOnly",
  BuyerAndSeller = "BuyerAndSeller"
}
