import { Component, Input, OnInit } from '@angular/core';
import { Connection, ConnectionEntityTypeText, EventTypes, EventTypesText, EventTypesTransactionType } from '@app/model';
import { config, StateService } from '@app/core';
import { BuyerConnectionsService, SellerConnectionsService, TransactionsService } from '@app/data';
import { InsightService } from '@app/insight';
import moment from 'moment';

@Component({
  selector: 'app-follow-user',
  templateUrl: './follow-user.component.html'
})
export class FollowUserComponent implements OnInit {
  @Input() connection: Connection;
  @Input() isBuyer: boolean = false;

  public connectionService: any;

  constructor(
    public state: StateService,
    private buyerConnectionService: BuyerConnectionsService,
    private sellerConnectionService: SellerConnectionsService,
    private transactionsService: TransactionsService,
    private insightService: InsightService
  ) {}

  public ngOnInit(): void {
    this.connectionService = this.isBuyer
      ? this.buyerConnectionService
      : this.sellerConnectionService;
  }

  // events
  public async onFollow(destinationEntityId: string): Promise<void> {
    const item: Connection = {
      id: null,
      isConnected: true,
      features: this.connection.features,
      sourceEntityId: this.state.currentUserId,
      destinationEntityId: destinationEntityId,
      sourceEntityType: ConnectionEntityTypeText["0"],
      destinationEntityType: ConnectionEntityTypeText["0"]
    };

    await this.connectionService
      .saveItem(item, null, false, true, false)
      .then(async () => {
        // log insight
        this.insightService.logEvent('Followed', {
          followerId: destinationEntityId
        });
        // find item
        const items = this.connectionService.collection.items;
        const newConnection = items.find(
          (connection: Connection) =>
            connection.destinationEntityId === destinationEntityId
        );

        if (newConnection) {
          // replace new data
          if (this.connection.destinationEntityId === destinationEntityId) {
            this.connection.id = newConnection.id;
            this.connection.isConnected = true;
          }
        }

        // get data
        if (this.isBuyer) {
          await this.sellerConnectionService.getData('', {}, true);
        } else {
          await this.buyerConnectionService.getData('', {}, true);
        }

        // save transaction
        this.saveTransaction(EventTypes.AddConnection, this.connection.destinationEntityId);
      });
  }

  public async onUnFollow(connectionId: string): Promise<void> {
    await this.connectionService
      .deleteItem(connectionId, null, true, false)
      .then(async () => {
        // log insight
        this.insightService.logEvent('UnFollowed', {
          connectionId: connectionId
        });

        // un follow
        if (this.connection.id === connectionId) {
          this.connection.isConnected = false;
        }

        // get data
        if (this.isBuyer) {
          await this.sellerConnectionService.getData('', {}, true);
        } else {
          await this.buyerConnectionService.getData('', {}, true);
        }

        // save transaction
        this.saveTransaction(EventTypes.RemoveConnection, this.connection.destinationEntityId);
      });
  }

  public async saveTransaction(eventType : EventTypes, destinationEntityId : string): Promise<void>  {
    const description = config.connectionDescription
    .replace('*|UTCDateTime|*', moment.utc().format())
    .replace('*|CurrentUserId|*', this.state.currentUserId)
    .replace('*|CurrentUserEmail|*', this.state.profile.email)
    .replace('*|TargetUser|*', destinationEntityId)
    .replace('*|Action|*', EventTypesTransactionType[eventType])
    .replace('*|EventType|*', EventTypesText[eventType])
    await this.transactionsService.saveTransaction(description, eventType, [eventType],
       destinationEntityId, config.emptyGuid, this.state.organizationId)
  }

  // getters
  public get isFollowButtonVisible(): boolean {
    return this.connection.destinationEntityId != this.state.currentUserId;
  }

  public get isConnectionsLoaded(): boolean {
    return (
      (this.isBuyer && this.state.isConnections4BuyerLoaded$.getValue()) ||
      (!this.isBuyer && this.state.isConnections4SellerLoaded$.getValue())
    );
  }
}
