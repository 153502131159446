import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ItemsService, DataAccessService } from '@app/data';
import { Logger } from '@app/shared';
import { BehaviorSubject } from 'rxjs';

import { config, StateService } from '@app/core';

@Component({
  selector: 'app-avatar-upload',
  templateUrl: './avatar-upload.component.html'
})
export class AvatarUploadComponent extends ItemsService<any> implements OnInit {
  @Input() field: any;
  @Input() index?: number;
  @Input() group: FormGroup;

  protected serviceName = 'AvatarUploadComponent';

  public fileName: string = '';
  public url: string = '';

  public isUploaded$ = new BehaviorSubject<boolean>(false);
  public percentDone$ = new BehaviorSubject<number>(0);

  constructor(
    dataAccess: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccess, logger);
  }

  public ngOnInit(): void {
    super.configure('storage/v1/storage');
  }

  public async onChange(event: any): Promise<void> {
    this.group.setErrors({ incorrect: true });

    const files = event.target.files;

    if (files.length > 0) {
      const file = files[0];
      let fileExtension = file.name.split('.').pop().toLowerCase();
      if(config.allowedFileExtension.includes(fileExtension)==false)
      {
        this.logger.info(config.imageFileUploadExtensionErrorMessage);
        return;
      }
      this.isUploaded$.next(false);
      this.percentDone$.next(0);

      await this.uploadFiles(file, this.percentDone$).then(response => {
        this.url = response.fileLocation;
        this.fileName = response.name;

        if(this.field.shouldReturnObject){
          this.group.patchValue({
            [this.field.name]: response,
          });
        }else{
          this.group.patchValue({
            [this.field.name]: this.fileName,
          });
        }

        this.group.markAsTouched();
        this.isUploaded$.next(true);
      });
    }
  }

  public deleteFile(url: string): void {
    this.group.patchValue({ [this.field.name]: this.url });
    this.group.markAsTouched();
  }

  // getters
  public get imageUrl(): string {
    return this.url ? this.url : this.group.value[this.field.url]['url'];
  }
}
