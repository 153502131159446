import { Injectable } from '@angular/core';

import { Stock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class SellerStocksUserCollection extends DataCollection<Stock> {}

@Injectable({
  providedIn: 'root'
})
export class SellerStocksUserService extends ItemsService<Stock> {
  protected serviceName = 'SellerStocksUserService';
  public stock: any;

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('seller/catalog/v1/stocks');
  }

  public get collection(): SellerStocksUserCollection {
    return new SellerStocksUserCollection(this.internalData);
  }

  public async getDataByUserId(
    userId: string = '',
    forceGet: boolean = false
  ): Promise<void> {
    const params = {
      filters: `createdBy$${userId}$0`
    };

    await super.getData('', params, forceGet);
  }
}
