import { Injectable } from '@angular/core';

import { Offers, Stock, User } from '@app/model';
import { Logger } from '@app/shared';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

import { UsersService } from './users.service';
import { HttpHeaders } from '@angular/common/http';
import { BuyerStocksSummaryService } from './buyer-stocks-summary.service';

export class BuyerStocksPurchasedCollection extends DataCollection<
  Offers
  > { }

@Injectable({
  providedIn: 'root'
})
export class BuyerStocksPurchasedService extends ItemsService<Offers> {
  protected serviceName = 'BuyerStocksPurchasedService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private userServices: UsersService,
    private buyerStocksSummaryService: BuyerStocksSummaryService
  ) {
    super(dataAccessService, logger);
    super.configure('buyer/tender/v1/transactions/entity/purchased');
  }

  public get collection(): BuyerStocksPurchasedCollection {
    return new BuyerStocksPurchasedCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {

    await super.getData(urlPostfix, params, true, options);

    const collection = this.collection;

    let items = collection.items;

    if (items && items.length > 0) {

      // get stock ids
      const stockIds = items.map((offer: Offers) => {
        return offer.stock.stockId;
      });

      // get distinct stocks ids
      const stocksIdsDistinct = stockIds.filter((uid, i) => stockIds.indexOf(uid) === i);

      // get stock details
      await this.buyerStocksSummaryService
        .getData(null, { ids: stocksIdsDistinct }, true)
        .then(async () => {

          const stocks = this.buyerStocksSummaryService.collection.items;

          // add stock to offers stock details
          items.forEach((offer: Offers) => {
            const st = stocks.find((stock: Stock) => {
              return offer.stock.stockId === stock.id;
            });

            offer.stock.details = st;
          });

          // fitler out null stock
          items = items.filter(offer => offer.stock.details != null);

          // get user info
          const creatorIds = items.map((offer: Offers) => {
            return offer.stock.details.ownerId;
          });

          // get distinct user ids
          const creatorIdsDistinct = creatorIds.filter((uid, i) => creatorIds.indexOf(uid) === i);

          await this.userServices
          .getDataByIds(creatorIdsDistinct)
            .then(() => {
              const owners = this.userServices.collection.items;

              // add user to offers
              items.forEach((offer: Offers) => {
                const user = owners.find((owner: User) => {
                  return offer.stock.details.ownerId === owner.id;
                });

                offer.transaction.owner = user;
              });

              // change data
              const data = {
                items: items,
                pageNumber: collection.pageNumber,
                totalPages: collection.totalPages,
                totalCount: collection.totalCount,
                hasPreviousPage: collection.hasPreviousPage,
                hasNextPage: collection.hasNextPage
              };

              this.init(data);
            });

        });
    }
  }

  public async getStocksPurchasedCount(): Promise<any> {

    return await super
      .getData('count', null, true)
      .then(() => {
        return this._data;
      });
  }
}
