import { Injectable } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  NavigationEnd,
  UrlSegment,
  ActivatedRoute
} from '@angular/router';

import { Subject } from 'rxjs';
import { Context } from '@app/model';

import { StateService, config } from '@app/core';

import { Dict } from '../common/collections';
import { InsightService } from '@app/insight';
import { Title } from '@angular/platform-browser';

export interface State {
  component: any;
  url: UrlSegment[];
}

export interface StateData {
  context: Context | null;
  params: Dict<string>;
  states: State[];
  editing: boolean;
}

function stateDataFromRouterSnapshot(
  routerSnapshot: RouterStateSnapshot
): StateData {
  const newState: StateData = {
    context: null,
    params: new Dict<string>(),
    states: [],
    editing: false
  };

  function recurse(arsnap: ActivatedRouteSnapshot): void {
    newState.states.push({
      component: arsnap.component,
      url: arsnap.url
    });
    let tenantCode: any;
    for (const [key, value] of Object.entries(arsnap.params)) {
      if (key === 'tenantCode') {
        tenantCode = value;
      } else if (typeof value === 'string' && !newState.params.has(key)) {
        newState.params.set(key, value);
      }
    }
    if (newState.context === null && typeof tenantCode === 'string') {
      newState.context = {
        tenantCode: tenantCode
      };
    }

    for (const child of arsnap.children) {
      recurse(child);
    }
  }

  recurse(routerSnapshot.root);

  for (const [key, value] of Object.entries(routerSnapshot.root.queryParams)) {
    if (typeof value === 'string' && !newState.params.has(key)) {
      newState.params.set(key, value);
    }
  }
  return newState;
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public stateChange = new Subject<StateData>();
  public context: Context | null;
  public params: Dict<string> = new Dict<string>();
  public states: State[] = [];
  public editing: boolean;

  public constructor(
    public router: Router,
    private state: StateService,
    private activatedRoute: ActivatedRoute,
    private insightService: InsightService,
    private titleService: Title
  ) {
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      const stateData = stateDataFromRouterSnapshot(this.router.routerState.snapshot);

      this.context = stateData.context;
      this.params = stateData.params;
      this.states = stateData.states;

      this.editing = this.params.get('innerId') != null || this.params.get('id') != null;

      // change state
      this.state.setTenantCode(this.context != null ? this.context.tenantCode : config.tenantCode);

      // set app title
      let child = this.activatedRoute.firstChild;
      while (child.firstChild) {
        child = child.firstChild;
      }

      const appTitle = child.snapshot.data['title'];
      if (appTitle) {
        this.titleService.setTitle(`${this.state.tenantCode} - ${appTitle}`);
        this.state.setTitle(appTitle);
      }

      // set insight page preview
      this.insightService.logPageView(this.state.title.replace(/ /g, ''), event.url);

      this.stateChange.next(stateData);
    });
  }

}
