import { Injectable } from '@angular/core';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class CordovaService {
  public static readonly NAME = 'cordovaService';

  public isCordovaPlatform(): boolean {
    return Boolean(window && window.cordova);
  }

  public openBrowser(url: string): any {
    if (!this.isCordovaPlatform() || !window.cordova.InAppBrowser || !window.cordova.InAppBrowser.open) {
      return false;
    }

    return window.cordova.InAppBrowser.open(url, '_system', 'location=yes') || true;
  }
}