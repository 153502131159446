import { Injectable } from '@angular/core';

import { StockBadge } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class StockBadgeCollection extends DataCollection<StockBadge> {}

@Injectable({
  providedIn: 'root'
})
export class StockBadgeService extends ItemsService<StockBadge> {
  protected serviceName = 'StockBadgeService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('catalog/v2/badges');
  }

  public get collection(): StockBadgeCollection {
    return new StockBadgeCollection(this.internalData);
  }
}
