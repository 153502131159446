import { Component, Input } from '@angular/core';
import { StateService } from '@app/core';
import { Tenant } from '@app/model';

@Component({
  selector: 'app-branding-image',
  templateUrl: './branding-image.component.html'
})
export class BrandingImageComponent {
  @Input() type: string = '';
  @Input() className: string = '';

  constructor(private state: StateService) {}

  public get logoUrl(): string {
    let imageUrl = '';

    this.state.on(this.state.tenantInfo$, (tenantInfo: Tenant) => {
      tenantInfo = tenantInfo ? tenantInfo : this.state.tenantInfo;

      if (tenantInfo && tenantInfo.defaultImages) {
        const branding = tenantInfo.defaultImages.branding;
        imageUrl =
          this.type === 'white'
            ? branding.whiteLogo
            : this.type === 'colored'
            ? branding.coloredLogo
            : this.type === 'background'
            ? branding.backgroundImage
            : '';
      }
    });

    return imageUrl;
  }
}
