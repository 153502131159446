import { Injectable } from '@angular/core';

import { Stock } from '@app/model';
import { Logger } from '@app/shared';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { HttpHeaders } from '@angular/common/http';

import { TransactionsCountService } from './transactions-count.service';

export class BuyerStocksOffersMadeCollection extends DataCollection<Stock> { }

@Injectable({
  providedIn: 'root'
})
export class BuyerStocksOffersMadeService extends ItemsService<Stock> {
  protected serviceName = 'BuyerStocksOffersMadeService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private transactionsCountService: TransactionsCountService
  ) {
    super(dataAccessService, logger);
    super.configure('buyer/catalog/v1/stocks');
  }

  public get collection(): BuyerStocksOffersMadeCollection {
    return new BuyerStocksOffersMadeCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    const staticFilters = [
      'status$Available$1',
      'status$InTender$1',
      'status$OnHold$1'
    ];

    urlPostfix = urlPostfix ? urlPostfix : 'tenders/transactions/made';

    params.filters =
      params.filters && params.filters instanceof Array
        ? params.filters.some((x: string) => x.indexOf('status') > -1)
          ? params.filters
          : staticFilters.concat(params.filters)
        : staticFilters;

    await super
      .getData(urlPostfix, params, forceGet, options)
      .then(async () => {
        const collection = this.collection;

        let items = collection.items;

        if (items.length > 0) {
          // stock ids
          const stockIds = items.map((item: Stock) => {
            return item.id;
          });

          // get view count
          await this.transactionsCountService
            .getBatchViewsCount(stockIds)
            .then(() => {
              // replace view count
              const viewCounts = this.transactionsCountService.collection.item as any;
              items = items.map((item: Stock) => {
                const count = viewCounts.find(vcount => vcount.id == item.id)
                item.viewCount = count ? count.count as number : 0
                return item;
              });

              // change data
              const data = {
                items: items,
                pageNumber: collection.pageNumber,
                totalPages: collection.totalPages,
                totalCount: collection.totalCount,
                hasPreviousPage: collection.hasPreviousPage,
                hasNextPage: collection.hasNextPage
              };

              this.init(data);
            });
        }
      });
  }
}
