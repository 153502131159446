import { Injectable } from '@angular/core';

import { User } from '@app/model';
import { Logger } from '@app/shared';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class StockCollection extends DataScalar<User> { }

@Injectable({
  providedIn: 'root'
})
export class StockService extends ItemService<User> {
  protected serviceName = 'StockService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
  ) {
    super(dataAccessService, logger);
    super.configure('supervisor/catalog/v1/stocks');
  }

  public get collection(): StockCollection {
    return new StockCollection(this.internalItem);
  }

  public async getStockById(stockId: string, forceGet: boolean = false): Promise<any> {
    let stock: any;
    await super
      .getDataById(stockId, '', forceGet)
      .then(() => {
        stock = this._item;
      }
      );
    return stock;
  }
}
