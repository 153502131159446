import { Identity } from './identity';

export class Role extends Identity {
  name: string;
  claim: string;
  description: string;

  constructor() {
    super();
  }
}
