import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent {
  @Input() type: 'success' | 'info' | 'danger' | 'warning';
  @Input() message: string = '';
}
