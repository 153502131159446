import { Injectable } from '@angular/core';

import { InsightsEventList, Tender, EventActivityTimeline } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class SellerTendersCollection extends DataCollection<Tender> { }

import { HttpHeaders } from '@angular/common/http';
import { StateService } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class SellerTendersService extends ItemsService<Tender> {
  protected serviceName = 'SellerTendersService';

  public constructor(
    public dataAccessService: DataAccessService,
    public stateService: StateService,
    logger: Logger
  ) {
    super(dataAccessService, logger);
    super.configure('seller/tender/v2/tenders');
  }

  public get collection(): SellerTendersCollection {
    return new SellerTendersCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    const staticFilters = ['type$Public$0'];

    urlPostfix = urlPostfix ? urlPostfix : 'organization';

    params.filters =
      params.filters && params.filters instanceof Array
        ? params.filters.some((x: string) => x.indexOf('type') > -1)
          ? params.filters
          : staticFilters.concat(params.filters)
        : staticFilters;

    await super.getData(urlPostfix, params, forceGet, options);
  }

  public async saveItem(
    item: Tender,
    urlPostfix: string = '',
    isPut: boolean = false,
    isReturnNewResponse: boolean = true,
    showLogger: boolean = true,
    showLoading: boolean = true,
    isNeedReloadItems: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    // save and go to server and get new data
    await super.saveItem(
      item,
      urlPostfix,
      isPut,
      isReturnNewResponse,
      showLogger,
      showLoading,
      isNeedReloadItems,
      options
    );
  }

  public async deleteItem(
    id: string | null,
    params: any = {},
    isRemoveItem: boolean = true,
    showLogger: boolean = true,
    showLoading: boolean = true,
    isNeedReloadItems: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    // delete and go to server and get new data
    await super.deleteItem(
      id,
      params,
      isRemoveItem,
      showLogger,
      isNeedReloadItems,
      showLoading,
      options
    );
  }

  public async getSellerTendersCount(): Promise<number> {
    const staticFilters = [
      'type$Public$0',
      'status$Scheduled$1',
      'status$Created$1',
      'status$Running$1',
      'status$Closing$1',
      'status$Ended$1'
    ];

    let params: any = {};

    params.filters = staticFilters;
    params.pageSize = 1;

    return await super.getData('organization', params, true).then(() => {
      return this.collection.totalCount;
    });
  }
}
