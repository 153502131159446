import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Offers } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class OffersEventsService {
  public makeOffer = new Subject<Offers>();
  public buyNow = new Subject<Offers>();
  public rejectOffer = new Subject<string>();
  public accectOffer = new Subject<string>();
  public counterOffer = new Subject<Offers>();
  public addOffer = new Subject<void>();
  public deleteOffer = new Subject<void>();

  public removePendingOffer = new Subject<string>();

  setBuynow(value: Offers) {
    this.buyNow.next(value); //it is publishing this value to all the subscribers that have already subscribed to this message
  }

  setMakeOffer(value: Offers) {
    this.makeOffer.next(value); //it is publishing this value to all the subscribers that have already subscribed to this message
  }

  setRejectOffer(value: string) {
    this.rejectOffer.next(value); //it is publishing this value to all the subscribers that have already subscribed to this message
  }

  setAccectOffer(value: string) {
    this.accectOffer.next(value); //it is publishing this value to all the subscribers that have already subscribed to this message
  }

  setCounterOffer(value: Offers) {
    this.counterOffer.next(value); //it is publishing this value to all the subscribers that have already subscribed to this message
  }

  setRemovePendingOffer(value: string) {
    this.removePendingOffer.next(value); //it is publishing this value to all the subscribers that have already subscribed to this message
  }

  setAddOffer() {
    this.addOffer.next(); //it is publishing this value to all the subscribers that have already subscribed to this message
  }

  setDeleteOffer() {
    this.deleteOffer.next(); //it is publishing this value to all the subscribers that have already subscribed to this message
  }
}
