import { Identity } from "./identity";

export class Invoice extends Identity {
  invoiceNumber: string;
  status: string;
  amount: number = 0;
  date: string;
  paymentLink: string;
  constructor() {
    super();
  }
}

export class InvoiceResponseSummary
{
    totalAmount : number = 0
    totalOweAmount : number = 0
    totalOverDueAmount : number = 0
    totalCount : number = 0
    items?: Invoice[] = [];
      constructor() {}
}
