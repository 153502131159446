import { Injectable } from '@angular/core';

import { User } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class UsersCollection extends DataCollection<User> { }

@Injectable({
  providedIn: 'root'
})
export class UsersService extends ItemsService<User> {
  protected serviceName = 'UsersService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('identity/v1/users/list');
  }

  public get collection(): UsersCollection {
    return new UsersCollection(this.internalData);
  }
  public async returnDataByIds(ids: string[]): Promise<User[]> {
   return await this.dataAccess.getData<User[]>('identity/v1/users/list','all', { ids },false);
  }

  public async getDataByIds(ids: string[]): Promise<void> {
    await this.getData('all', { ids }, true);
  }
}
