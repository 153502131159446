import { Injectable } from '@angular/core';
import { DataAccessService } from '../data-access.service';
import { Logger } from '@app/shared';

import { SafeStorageService, StateService } from '@app/core';
import { TenantAuctionConfig, LocalStorageModel, TsxLoggingBackendConfig } from '@app/model';
import { PublicTenantCollection, PublicTenantService } from './public-tenant.service';
import { ItemService } from '../data-service.service';
@Injectable({
  providedIn: 'root'
})
export class PublicTenantV2Service extends PublicTenantService {
  protected serviceName = 'PublicTenantV2Service';
  private stateService: StateService;

  isGetAuctionConfigProcessing = false;
  getAuctionConfigResolve: any;
  getAuctionConfigReject: any;
  isGetAuctionConfigReady = new Promise<TenantAuctionConfig>((resolve, reject) => {
    this.getAuctionConfigResolve = resolve;
    this.getAuctionConfigReject = reject;
  });

  constructor(
    public dataAccessService: DataAccessService,
    logger: Logger,
    state: StateService,
    private safeStorageService: SafeStorageService
  ) {
    super(dataAccessService, logger, state);
    super.configure('public/tenant/v2/specs');
    this.stateService = state;
  }

  public get collection(): PublicTenantCollection {
    return new PublicTenantCollection(this.internalItem);
  }

  private async getTenantAuctionConfigFromApi(): Promise<TenantAuctionConfig> {
    if (this.isGetAuctionConfigProcessing) {
      let result = await this.isGetAuctionConfigReady;
      return result;
    }

    try {
      this.isGetAuctionConfigProcessing = true;
      let result = await this.dataAccessService.getData<TenantAuctionConfig>(this.uri, 'services.auction.configuration', undefined, this.isFullUrl);
      this.getAuctionConfigResolve(result);
      return result;
    } catch (error) {
      this.getAuctionConfigReject(error);
      throw new Error(error);
    } finally {
      this.isGetAuctionConfigProcessing = false;
    }
  }

  private createCache(data: TenantAuctionConfig): void {
    const key = `${this.stateService.tenantCode}-ServicesAuctionConfiguration`;
    const lStorage: LocalStorageModel = {
      data: data,
      expiryDate: Date.now() + 600 * 1000 // cached for 600 seconds
    };
    this.safeStorageService.set(key, JSON.stringify(lStorage));
  }

  public async getTenantAuctionConfig(): Promise<TenantAuctionConfig> {
    const key = `${this.stateService.tenantCode}-ServicesAuctionConfiguration`;
    let val = this.safeStorageService.get(key);
    if (val == null || val == undefined) {
      let data = await this.getTenantAuctionConfigFromApi();
      this.createCache(data);
      return data;
    }
    else {
      let lStorage = JSON.parse(val) as LocalStorageModel;
      if (new Date().getTime() > lStorage.expiryDate) {
        this.safeStorageService.remove(key);
        let data = await this.getTenantAuctionConfigFromApi();
        this.createCache(data);
        return data;
      }
      else {
        return lStorage.data as TenantAuctionConfig;
      }
    }
  }

  public async getTenantTsxLoggingBackend(): Promise<TsxLoggingBackendConfig> {
    const key = `${this.stateService.tenantCode}-BackendLoggingConfiguration`;
    let val = this.safeStorageService.get(key);

    if (val != null) {
      let model: LocalStorageModel = new LocalStorageModel();
      try {
        model = JSON.parse(val);
      } catch (e) { }

      if (model && model.data && Date.now() <= model.expiryDate) {
        return model.data;
      }
    }

    await this.isServiceReady.promise();

    const data = await this.dataAccessService.getData<TsxLoggingBackendConfig>(this.uri, 'transactions.logging.backend', undefined, this.isFullUrl);

    const lStorage: LocalStorageModel = {
      data: data,
      expiryDate: Date.now() + (600 * 1000) //cached for 600 seconds
    };

    this.safeStorageService.set(key, JSON.stringify(lStorage));

    return data;
  }
}
