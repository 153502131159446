import { Injectable } from '@angular/core';

import { Login } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class PublicLoginCollection extends DataCollection<Login> {}

@Injectable({
  providedIn: 'root'
})
export class PublicLoginService extends ItemsService<Login> {
  protected serviceName = 'PublicLoginService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('public/identity/v1/authentications/tokens');
  }

  public get collection(): PublicLoginCollection {
    return new PublicLoginCollection(this.internalData);
  }

  public async forgotPassword(userName: string): Promise<void> {
    await this.saveItem(
      null,
      `users/${userName}/forgotPassword`,
      false,
      false,
      false
    );
  }

  public async resetPassword(data: any): Promise<void> {
    await this.saveItem(data, 'users/reset', false, false, false);
  }
}
