import { Injectable } from '@angular/core';

import { Offers, TransactionTypes, User } from '@app/model';
import { Logger } from '@app/shared';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

import { config } from '@app/core';
import { UsersService } from './users.service';
import { HttpHeaders } from '@angular/common/http';

export class TransactionsOffersCollection extends DataCollection<
  Offers
  > { }

@Injectable({
  providedIn: 'root'
})
export class TransactionsOffersService extends ItemsService<Offers> {
  protected serviceName = 'TransactionsOffersService';

  public eventId: string = '';
  public offerStatus: string = '';
  public isBuyer = true;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private userServices: UsersService
  ) {
    super(dataAccessService, logger);
    super.configure('tender/v1/transactions');
  }

  public configureSellerUrl() {
    super.configure('seller/tender/v1/transactions');
  }

  public configureBaseUrl() {
    super.configure('tender/v1/transactions');
  }

  public get collection(): TransactionsOffersCollection {
    return new TransactionsOffersCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {

    super.configure(`${this.isBuyer ? 'buyer' : 'seller'}/tender/v1/transactions`);

    let localUrlPostfix = `event/${this.eventId}/offers/type/${this.offerStatus}`;
    await super.getData(localUrlPostfix, params, true, options);

    const collection = this.collection;

    let items = collection.items;

    // fitlered out, stock null object
    items = items.filter(offer => offer.stock != null);

    if (items && items.length > 0) {
      // get user ids
      const creatorIds = items.map((offer: Offers) => {
        return offer.transaction.createdBy;
      });

      // get distinct ids
      const creatorIdsDistinct = creatorIds.filter((uid, i) => creatorIds.indexOf(uid) === i);
      // get user info
      await this.userServices
      .getDataByIds(creatorIdsDistinct)
        .then(() => {
          const owners = this.userServices.collection.items;

          // add user to offers
          items.forEach((offer: Offers) => {
            const user = owners.find((owner: User) => {
              return offer.transaction.createdBy === owner.id;
            });

            offer.transaction.owner = user;
          });

          // change data
          const data = {
            items: items,
            pageNumber: collection.pageNumber,
            totalPages: collection.totalPages,
            totalCount: collection.totalCount,
            hasPreviousPage: collection.hasPreviousPage,
            hasNextPage: collection.hasNextPage
          };

          this.init(data);
        });
    }
  }

  public async getOffers4Seller(
    entityId: string,
    isTender: boolean,
    tenderId: string
  ): Promise<any> {
    return await super
      .getData(`entity/${entityId}/offers`, null, true)
      .then(async () => {
        const offers = this.collection.items.filter(
          (item: Offers) => {
            return (
              (isTender && item.transaction.eventId == tenderId) ||
              (!isTender && item.transaction.eventId == config.emptyGuid)
            );
          }
        );

        if (offers.length > 0) {
          // get user ids
          const creatorIds = offers.map((offer: Offers) => {
            return offer.transaction.createdBy;
          });

           // get distinct ids
          const creatorIdsDistinct = creatorIds.filter((uid, i) => creatorIds.indexOf(uid) === i);
          // get user info
          return await this.userServices
          .getDataByIds(creatorIdsDistinct)
            .then(() => {
              const owners = this.userServices.collection.items;

              // add user to offers
              offers.forEach((offer: Offers) => {
                const user = owners.find((owner: User) => {
                  return offer.transaction.createdBy === owner.id;
                });

                offer.transaction.owner = user;
              });

              return offers;
            });
        }
        return [];
      });
  }

  public async getOffers4Buyer(
    entityId: string,
    eventId: string,
    organizationAware: boolean = false
  ): Promise<any> {
    const url = `entity/${entityId}/event/${eventId}/offers`;
    const finalUrl = organizationAware ? `${url}/organization` : url;
    return await super
      .getData(finalUrl, null, true)
      .then(() => {
        const offers = this.collection.items;

        if (organizationAware) {
          return offers
        }

        const offersFiltered = offers.filter((item: Offers) => {
          return (
            item.isOwner ||
            (item.transaction.typeText == TransactionTypes.CounterOffer &&
              offers.some((offer: Offers) => {
                return (
                  offer.transaction.id == item.transaction.parentId &&
                  offer.isOwner
                );
              }))
          );
        });
        return offersFiltered;
      });
  }
}
