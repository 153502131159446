import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dynamic-pagination',
  templateUrl: './dynamic-pagination.component.html'
})
export class DynamicPaginationComponent {
  @Input() pageNumber: number = 1;
  @Input() totalPages: number = 0;
  @Input() pageSize: number = 10;
  @Input() pagesToShow: number = 10;
  @Input() hasPreviousPage: boolean = false;
  @Input() hasNextPage: boolean = false;
  @Input() isPagingVisible: boolean = false;

  @Output() goPage = new EventEmitter<number>();
  @Output() goPreviousPage = new EventEmitter();
  @Output() goNextPage = new EventEmitter();

  public onPage(pageNumber: number): void {
    this.goPage.emit(pageNumber);
  }

  public onPreviousPage(): void {
    this.goPreviousPage.emit();
  }

  public onNextPage(): void {
    this.goNextPage.emit();
  }

  public getPages(): number[] {
    const pages: number[] = [];
    let minPage: number = this.pageNumber;
    let maxPage: number = this.pageNumber;

    pages.push(this.pageNumber);

    for (let i = 0; i < this.pagesToShow - 1; i++) {
      if (minPage > 1) {
        pages.push(--minPage);
      }

      if (pages.length < this.pagesToShow && maxPage < this.totalPages) {
        pages.push(++maxPage);
      }

      if (pages.length >= this.pagesToShow) {
        break;
      }
    }

    pages.sort((a, b) => a - b);

    return pages;
  }
  
  trackByPage(index: number, page: number): number {
    return page;
  }
}
