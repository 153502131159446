import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SharedService } from '@app/shared';

import { TranslateService } from '@app/translate';
import { DisposeService, PublicTenantV2Service, TransactionsService } from '@app/data';

import { BaseComponent } from '@app/base';
import { StateService } from '@app/core';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { environment } from '@app/client/env';
import { InsightService } from '@app/insight';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends BaseComponent {
  constructor(
    shared: SharedService,
    translate: TranslateService,
    publicTenantService: PublicTenantV2Service,
    router: Router,
    activatedRoute: ActivatedRoute,
    state: StateService,
    disposeService: DisposeService,
    insightService: InsightService,
    private nxLoader: NgxUiLoaderService,
    private tsxService: TransactionsService,
  ) {
    super(
      shared,
      translate,
      publicTenantService,
      router,
      activatedRoute,
      state,
      disposeService,
      insightService
    );
  }

  public async ngOnInit(): Promise<void> {
    this.state.setEnvironment(environment);
    this.state.setAdminApp(false);
    this.nxLoader.start();
    try {
      await super.ngOnInit();
      await this.tsxService.initTsxLoggingConfig();
    } finally {
      this.nxLoader.stop();
    }
  }
}