import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import moment, { Moment } from 'moment';
import { UtcDatePipe } from '@app/pipe';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html'
})
export class DateTimeComponent implements OnInit {
  @Input() field: any;
  @Input() group: FormGroup;
  @Input() isEditing?: boolean;
  @Input() isReadOnly?: boolean = null;

  public defaultDateTime: Moment;
  public minDateTime: Moment;
  public maxDateTime: Moment;

  public constructor(private utcDatePipe: UtcDatePipe) { }

  public ngOnInit(): void {
    this.setDefaultValue();
  }

  public setDefaultValue(): void {
    let value = this.group.value[this.field.name];
    value = value ? value : this.field.value ? this.field.value : null;

    // set min or max date
    this._getMinDateTime();
    this._getMaxDateTime();

    // get default value
    if (value == null) {
      this._getDefaultDateTime();
      value = this.defaultDateTime;
    }

    // set current date
    const localDateTime = this.utcDatePipe.transform(value);

    if (this.field.skipDefaultValue != undefined && this.field.skipDefaultValue == true) {
      return;
    }
    this.group.patchValue({
      [this.field.name]: localDateTime
    });
  }

  // events
  public onDateChange(event: any) {
    const dateTime = event.value as Moment;

    // change date for related date time
    this._setRelatedTimesValues(dateTime);
  }

  // getters
  public get isShowLabel() {
    return this.field.label != null;
  }

  public get disabled(): any {
    return (this.isEditing && this.field.disabledInEdit) ||
      this.field.disabled ||
      this.isReadOnly
      ? ''
      : null;
  }

  public get columnClass(): any {
    return {
      'col-lg-6': this.field.isHalfColumn,
      'col-lg-12': !this.field.isHalfColumn
    };
  }

  public get isRequired() {
    return (
      this.field.validations &&
      this.field.validations.some((x: any) => x.name === 'required')
    );
  }

  // private
  private _getDefaultDateTime(): any {
    // days
    if (this.field.defaultDay != undefined) {
      this.defaultDateTime = moment().add(this.field.defaultDay, 'days');
    }

    // hours
    if (this.field.defaultHours != undefined) {
      this.defaultDateTime = this.defaultDateTime.add(
        this.field.defaultHours,
        'hours'
      );
    }

    // minutes
    if (this.field.defaultMinutes != undefined) {
      this.defaultDateTime = this.defaultDateTime.add(
        this.field.defaultMinutes,
        'minutes'
      );
    }
  }

  private _getMinDateTime(): any {
    if (this.field.minDay != undefined) {
      this.minDateTime = moment().add(this.field.minDay, 'days');
      if (this.field.isDate) {
        this.minDateTime = this.minDateTime.startOf('day');
      }
    }
  }

  private _getMaxDateTime() {
    if (this.field.maxYear != undefined) {
      this.maxDateTime = moment().add(this.field.maxYear, 'year');
    }

    if (this.field.maxDay != undefined) {
      this.maxDateTime = moment().add(this.field.maxDay, 'days');
    }
  }

  private _setRelatedTimesValues(dateTime: Moment): void {
    if (this.field.timeControls) {
      const date = dateTime.format('DD/MM/YYYY');

      this.field.timeControls.forEach((control: any) => {
        const controlDateTime = this.group.value[control];
        const time = controlDateTime.format('LT');

        const newDateTime = moment(`${date}, ${time}`, 'DD/MM/YYYY, LT');
        this.group.patchValue({
          [control]: newDateTime
        });
      });
    }
  }
}
