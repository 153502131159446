import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StateService } from '@app/core';
import { Tenant } from '@app/model';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html'
})
export class InputComponent implements OnInit {
  @Input() field: any;
  @Input() index?: number;
  @Input() group: FormGroup;
  @Input() disabled?: boolean = null;
  @Input() isEditing?: boolean;
  @Input() isReadOnly?: boolean = null;

  public isDisableSetDefaultValue: boolean = false;

  constructor(private state: StateService) {}

  public ngOnInit(): void {
    this._getDefaultValue();
  }

  // event
  public onKeypress(event: any): void {
    if (this.type !== 'number') return;
  
    const pattern = /[0-9\.]/;
    const inputChar = String.fromCharCode(event.charCode);
  
    if (pattern.test(inputChar)) return;
  
    // invalid character, prevent input
    event.preventDefault();
  }

  // getters
  public get isShowLabel() {
    return this.field.label != null;
  }

  public get isRequired() {
    return (
      this.field.validations &&
      this.field.validations.some((x: any) => x.name === 'required')
    );
  }

  public get type() {
    return this.field.inputType || 'text';
  }

  public get name() {
    const index = this.index != null ? `-${this.index}` : '';
    return `${this.field.name}${index}`;
  }

  public get groupClass(): any {
    return {
      'form-group form-label-group': this.type !== 'hidden',
      'custom-number': this.type === 'number'
    };
  }

  public get inputClass(): any {
    return {
      'border-left-0': this.field.removeBorderLeft,
      'remove-space-control': this.field.removeBottomSpace
    };
  }

  public get columnClass(): any {
    return {
      'col-lg-6': this.field.isHalfColumn,
      'col-lg-12': !this.field.isHalfColumn
    };
  }

  public get step(): number {
    return this.field.step ? this.field.step : 1;
  }

  public get min(): number {
    return this.field.min ? this.field.min : 0;
  }

  public get maxLength(): number {
    let maxLength: number;

    maxLength =
      'parentStateKey' in this.field
        ? this.state.tenantInfo.validations[this.field.parentStateKey][
            this.field.stateMaxLengthKey
          ]
        : this.field.maxLength;

    return maxLength;
  }

  // setters
  public get isDisabled(): any {
    let isDisabled = null;
  
    if (this.disabled !== null) {
      this.group.setErrors({ incorrect: this.disabled });
      const control = this.group.controls[this.field.name];
      this.disabled ? control.disable() : control.enable();
      isDisabled = this.disabled;
    } else {
      isDisabled = this.isEditing && this.field.disabledInEdit || this.field.disabled || this.isReadOnly;
    }
  
    return isDisabled ? '' : null;
  }

  // privates
  private _getDefaultValue(): void {
    this.state.on(this.state.tenantInfo$, (tenantInfo: Tenant) => {
      tenantInfo = tenantInfo || this.state.tenantInfo;
  
      if (tenantInfo) {
        // set value based on state key
        const value = this.group.value[this.field.name];
  
        if (!value && !this.field.value && (this.field.tenantKey || this.field.stateKey) && !this.field.noNeedSetTenantKey) {
          let stateValue = '';
          if (this.field.tenantKey && tenantInfo[this.field.tenantKey]) {
            stateValue = tenantInfo[this.field.tenantKey];
          } else if (this.field.stateKey && this.state[this.field.stateKey]) {
            stateValue = this.state[this.field.stateKey];
          }
  
          this.isDisableSetDefaultValue = stateValue !== null && stateValue !== '';
  
          this.group.patchValue({
            [this.field.name]: stateValue
          });
        }
      }
    });
  }
}
