import { SharedService } from '@app/shared';
import { TranslateService } from '@app/translate';
import { DisposeService, PublicTenantV2Service } from '@app/data';

import { Router, ActivatedRoute } from '@angular/router';

import { BaseComponent } from './base.component';

import {
  ChangeDetectorRef,
  ViewChild,
  EventEmitter,
  AfterViewInit
} from '@angular/core';

import { StateService } from '@app/core';

import { BehaviorSubject } from 'rxjs';
import { DynamicFormEditorComponent } from '@app/dynamics';
import { InsightService } from '@app/insight';

export abstract class BaseItemComponent extends BaseComponent
  implements AfterViewInit {
  @ViewChild(DynamicFormEditorComponent, { read: false, static: false })
  formEditor: DynamicFormEditorComponent;

  // dynamic forms
  public data$ = new BehaviorSubject<any>({});
  public isEditing?: boolean = null;
  public canEdit: boolean = true;
  public canDelete: boolean = true;
  public canCancel: boolean = true;
  public isNeedToResetForm: boolean = false;
  public isSecondSubmitVisible: boolean = false;
  public isLoadingDataVisible: boolean = false;
  public isBackVisible: boolean = true;

  public constructor(
    shared: SharedService,
    translate: TranslateService,
    publicTenantService: PublicTenantV2Service,
    router: Router,
    activatedRoute: ActivatedRoute,
    state: StateService,
    disposeService: DisposeService,
    insightService: InsightService,
    public changeDetectorRef: ChangeDetectorRef,
    public itemService: any,
    public formStepsData?: any
  ) {
    super(
      shared,
      translate,
      publicTenantService,
      router,
      activatedRoute,
      state,
      disposeService,
      insightService
    );
  }

  public async ngOnInit(setItem: boolean = true): Promise<void> {

    if (setItem) {
     await this.getItem();
    }
    await super.ngOnInit();
  }

  public ngAfterViewInit(setEditorForm: boolean = false) {
    // set editing
    this._setIsEditing();

    this.setFormEditorInitData();

    if (setEditorForm) {
      this.setEditorForm();
    }
  }

  // item
  public async getItem(
    urlPostfix: string = '',
    params?: any,
    forceGet: boolean = false
  ): Promise<any> {
    return await this.itemService.getData(urlPostfix, params, forceGet);
  }

  // edit
  public async setEditorForm(
    urlPostfix: string = '',
    params?: any,
    forceGet: boolean = false
  ): Promise<any> {
    await this.getItem(urlPostfix, params, forceGet).then(() => {
      if (this.item) {
        this.setData4EditingForm(this.item);
      } else {
        this._setFormEditorParameters(true);
      }
    });
  }

  public setData4EditingForm(data: any) {
    this.data$.next(data);
    this._setFormEditorParameters(true);
  }

  public async saveCommand(item: any, urlPostfix: string = ''): Promise<void> {
    return await this.itemService.saveItem(item, urlPostfix).then(() => {
      // log insight
      this.insightService.logEvent(
        `${this.formStepsData.module}${this.isEditing ? 'Edited' : 'Added'}`
      );

      this.goBack();
    });
  }

  public async secondSaveCommand(
    item: any,
    urlPostfix: string = ''
  ): Promise<void> {
    return await this.itemService.saveItem(item, urlPostfix).then(() => {
      // log insight
      this.insightService.logEvent(
        `${this.formStepsData.module}${this.isEditing ? 'Edited' : 'Added'}`
      );

      this.goBack();
    });
  }

  public setFormEditorInitData() {
    if (this.formEditor) {
      this.formEditor.isEditing$.next(this.isEditing);
      this.formEditor.canEdit$.next(this.canEdit);
      this.formEditor.canDelete$.next(this.canDelete);
      this.formEditor.isBackVisible = this.isBackVisible;
      this.formEditor.canCancel$.next(this.canCancel);
      this.formEditor.isNeedToResetForm$.next(this.isNeedToResetForm);
      this.formEditor.isSecondSubmitVisible$.next(this.isSecondSubmitVisible);
    }
  }

  // getter
  public get item(): any {
    try {
      return this.itemService.collection.item;
    } catch (error) {
      return {};
    }
  }

  // privates
  private _setIsEditing() {
    this.isEditing =
      this.isEditing == null ? this.shared.editing : this.isEditing;
  }

  private _setFormEditorParameters(isLoadingDataVisible: boolean) {
    this.isLoadingDataVisible = isLoadingDataVisible;

    if (this.formEditor) {
      // inputs
      this.formEditor.steps$.next(this.formStepsData.steps);
      this.formEditor.data$.next(this.data$.getValue());
      this.formEditor.isLoadingDataVisible$.next(this.isLoadingDataVisible);

      // outputs
      this._setEvent(this.formEditor.goBack, 'goBack');
      this._setEvent(this.formEditor.saveCommand, 'saveCommand');
      this._setEvent(this.formEditor.secondSaveCommand, 'secondSaveCommand');

      this.changeDetectorRef.detectChanges();
    }
  }

  private _setEvent(emitter: EventEmitter<any>, name: string): void {
    if (this.hasEvent(emitter)) {
      this.state.on(emitter, (event: any) => {
        switch (name) {
          case 'saveCommand':
            event = this.saveCommand(event);
            break;

          case 'secondSaveCommand':
            event = this.secondSaveCommand(event);
            break;

          case 'goBack':
            event = this.goBack();
            break;
        }
      });
    }
  }
}
