import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { StateService } from '@app/core';

@Component({
  selector: 'app-toggle-collapse',
  templateUrl: './toggle-collapse.component.html'
})
export class ToggleCollapseComponent implements OnInit {
  @Input() field: any;
  @Input() group: FormGroup;
  @Input() isEditing?: boolean;
  @Input() isReadOnly?: boolean = null;

  constructor(private state: StateService) {}

  public ngOnInit(): void {
    if (!this.isShowCollapse) {
      this.updateValidators();
    }
  }

  public onChange(event: any) {
    this.updateValidators();

    if (this.field.mainValue && this.field.mainName) {
      // set main value
      this.group.controls[this.field.mainName].setValue(
        event.target.checked ? this.field.mainValue : ''
      );

      // remove value from fake name
      this.group.controls[this.field.name].setValue('');
    }
  }

  public updateValidators(): void {
    this.field.collapse.forEach((field: any) => {
      // update validators
      const validators = field.validations
        ? (field.validations as Array<any>)
        : [];

      if (validators.length > 0) {
        const validations: ValidatorFn[] = [];

        // required
        const hasRequired = validators.some((validator: any) => {
          return validator.name === 'required';
        });

        if (hasRequired) {
          validations.push(Validators.required);
        }

        // pattern
        const hasPattern = validators.some((validator: any) => {
          return validator.name === 'pattern';
        });

        if (hasPattern) {
          // set state validations
          field = this._setStateValidations(field);

          const findPattern = field.validations.find(
            (validation: any) => validation.name === 'pattern'
          );

          const pattern = findPattern ? findPattern.pattern : '';

          validations.push(Validators.pattern(pattern));
        }

        // set validators
        this.group.controls[field.name].setValidators(
          this.isShowCollapse ? validations : []
        );
      }

      // update values
      this.group.controls[field.name].setValue(
        field.tenantKey && this.isShowCollapse
          ? this.state.tenantInfo[field.tenantKey]
          : ''
      );

      // update both
      this.group.controls[field.name].updateValueAndValidity();
    });
  }

  // privates
  private _setStateValidations(field: any): any {
    if ('validations' in field && field.validations) {
      field.validations.forEach((validation: any) => {
        if (validation.name === 'pattern' && 'parentStateKey' in validation) {
          // pattern
          validation.pattern = this.state.tenantInfo.validations[
            validation.parentStateKey
          ][validation.patternStateKey];

          // message
          validation.message = this.state.tenantInfo.validations[
            validation.parentStateKey
          ][validation.messageStateKey];
        }
      });
    }

    return field;
  }

  // getters
  public get isShowCollapse(): boolean {
    const value = this.group.controls[this.field.name].value;
    return (this.field.isReverse && !value) || (!this.field.isReverse && value);
  }

  public get disabled(): any {
    return (this.isEditing && this.field.disabledInEdit) ||
        this.field.disabled ||
        this.isReadOnly
        ? ''
        : null;
  }
}
