import { Injectable } from '@angular/core';

import { TenderStock, TenderTypes } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class BuyerStockTendersClientCollection extends DataCollection<TenderStock> {}

@Injectable({
  providedIn: 'root'
})
export class BuyerStockTendersClientService extends ItemsService<TenderStock> {
  protected serviceName = 'BuyerStockTendersClientService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('buyer/tender/client/v1/tenders');
  }

  public get collection(): BuyerStockTendersClientCollection {
    return new BuyerStockTendersClientCollection(this.internalData);
  }

  public async getTenderStocks(
    tednerId: string,
    pageNumber: number = 1,
    pageSize: number = 1,
    stockIds: string[] = null,
    filterParams: any = null,
    sortParams: any = null,
    includeDeletedOffers: boolean = false,
    tenderType: string = TenderTypes[TenderTypes.Public]
  ): Promise<void> {
    const urlPostfix = `${tednerId}/stocks`;
    let params: any = {
      pageSize: pageSize,
      pageNumber: pageNumber,
      includeDeletedOffers: includeDeletedOffers,
      tenderType:tenderType
    };

    if (filterParams) {
      params.filters = filterParams;
    }

    if (sortParams) {
      params.sortBy = sortParams.sortBy;
      params.ascendingSort = sortParams.ascendingSort;
    }

    if (stockIds) {
      let filters: string[] = [];
      stockIds.forEach(stockId => {
        filters.push(`stockid$${stockId}$1`);
      });
      params.filters = filters;
    }
    await super.getData(urlPostfix, params, true);
  }
}
