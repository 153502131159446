import { Injectable } from '@angular/core';

import { EventTypes, EventTypesAction, EventTypesText, EventTypesTransactionType, Transaction, TsxLoggingBackendConfig } from '@app/model';
import { Logger } from '@app/shared';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { InsightService } from '@app/insight';
import { PublicTenantV2Service } from './public-tenant-v2.service';
import * as _ from 'lodash';

export class TransactionsCollection extends DataCollection<Transaction> { }

@Injectable({
  providedIn: 'root'
})
export class TransactionsService extends ItemsService<Transaction> {
  protected serviceName = 'TransactionsService';
  private tsxLoggingBackendConfig: TsxLoggingBackendConfig = {
    IsEnabled: false
  };
  private isConfigResolve;
  private isConfigReject;
  public readonly isConfigReady = new Promise((resolve, reject) => {
    this.isConfigResolve = resolve;
    this.isConfigReject = reject;
  });

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    public publicTenantService: PublicTenantV2Service,
    public insightService: InsightService
  ) {
    super(dataAccessService, logger);
    super.configure('transaction/v1/OrchestrationFunction_HttpStart');
  }

  public get collection(): TransactionsCollection {
    return new TransactionsCollection(this.internalData);
  }

  private async logTransaction(transaction: Transaction, configBasedLogging: boolean) {
    if (configBasedLogging) {
      await this.isConfigReady;
      if (!this.isLoggingEnabled) {
        return;
      }
    }

    await this.saveItem(
      transaction,
      null,
      false,
      false,
      false,
      false
    );
  }

  public async saveTransaction(description: string, eventType: EventTypes, values: any[],
    entityId: string, eventId: string, entityOrganizationId: string
  ): Promise<void> {
    const transaction: Transaction = {
      id: null,
      Name: EventTypesAction[eventType],
      Payload: {
        eventId: eventId,
        entityId: entityId,
        entityOrganizationId: entityOrganizationId,
        description: description,
        parentId: null,
        typeText: '',
        type: EventTypesTransactionType[eventType],
        values: values
      }
    };

    let configBasedLogging = this.isUsingConfigBasedLogging(eventType);
    await this.logTransaction(transaction, configBasedLogging);
    this.insightService.logEvent(EventTypesText[eventType], transaction);
  }

  public isUsingConfigBasedLogging(eventType: EventTypes): boolean {
    if (eventType == EventTypes.Login) {
      return true;
    }
    if (eventType == EventTypes.Watch) {
      return true;
    }
    if (eventType == EventTypes.AddConnection) {
      return true;
    }
    if (eventType == EventTypes.AddStock) {
      return true;
    }
    if (eventType == EventTypes.AddBuyerNotes) {
      return true;
    }
    if (eventType == EventTypes.UnWatch) {
      return true;
    }
    if (eventType == EventTypes.RemoveConnection) {
      return true;
    }
    if (eventType == EventTypes.ViewTender) {
      return true;
    }
    if (eventType == EventTypes.ViewStockInTender) {
      return true;
    }
    if (eventType == EventTypes.ViewStockOutOfTender) {
      return true;
    }
    if (eventType == EventTypes.UpdateProfile) {
      return true;
    }
    if (eventType == EventTypes.UpdateSellerNotes) {
      return true;
    }
    if (eventType == EventTypes.UpdateStockPrice) {
      return true;
    }
    if (eventType == EventTypes.UpdateStockDefaultReservePrice) {
      return true;
    }
    if (eventType == EventTypes.UpdateUserPreferences) {
      return true;
    }

    return false;
  }

  public async initTsxLoggingConfig() {
    let result = await this.publicTenantService.getTenantTsxLoggingBackend();
    if (!_.isEmpty(result) && result.IsEnabled != null) {
      this.tsxLoggingBackendConfig = result;
    }

    this.isConfigResolve();
  }

  public get isLoggingEnabled(): boolean {
    return !this.tsxLoggingBackendConfig.IsEnabled;
  }
}
