export class HTTPError {
  public status: number | null;
  public data: any;
  public message: string | null;

  public constructor(status: number | undefined, data: any = {}) {
    this.status = status !== undefined ? status : null;
    this.data = data;
    this.message = null;

    if (data && typeof data.message === 'string') {
      this.message = data.message;
    }
  }

  public toString(): string {
    return `HTTP status ${this.status}: ${this.message || ''}`;
  }
}