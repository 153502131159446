import { Injectable } from '@angular/core';

import { InviceMonthlySummary } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class InvoiceSummaryCollection extends DataCollection<InviceMonthlySummary> { }

import { StateService } from '@app/core';

@Injectable({
  providedIn: 'root'
})
export class InvoiceSummaryService extends ItemsService<InviceMonthlySummary> {
  protected serviceName = 'InvoiceSummaryService';
  public invoice: any;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService

  ) {
    super(dataAccessService, logger);
    super.configure('api/v1/payment/retrieveinvoicesummary');

  }

  public get collection(): InvoiceSummaryCollection {
    return new InvoiceSummaryCollection(this.internalData);
  }

  public async getInvoiceSummary(
    params: any = {},
  ): Promise<any> {

    let staticParams = {
      organizationId: this.state.organizationId,
      tenantCode: this.state.tenantCode,
    }
    params = { ...params, ...staticParams };

    return await super.getData('', params,true).then
      (async () => {
        const collection = this.collection;

        let items = collection.items;
        if (items.length > 0) {
          const data = {
            items: items,
            pageNumber: params.pageNumber,
            totalPages: collection.totalPages,
            totalCount: collection.totalCount,
            hasPreviousPage: params.pageNumber > 1,
            hasNextPage: params.pageNumber < collection.totalPages,
            pageSize : params.pageSize

          };

          return data;
        }
      });
  }
}
