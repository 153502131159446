import { Identity } from './identity';

export class UserPreference extends Identity{
  notification?: UserPreferenceNotification;

  constructor() {
    super();
    this.notification = new UserPreferenceNotification();
  }
}

export class UserPreferenceNotification {
  smsNotification: boolean = false;
  emailNotification: boolean = false;
  pushNotification: boolean = false;
}
