import { Injectable } from '@angular/core';

import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { DeleteUserAccount } from '@app/model';

export class DeleteUserAccountCollection extends DataCollection<DeleteUserAccount> {}

@Injectable({
  providedIn: 'root'
})
export class DeleteUserAccountService extends ItemsService<DeleteUserAccount> {
  protected serviceName = 'DeleteUserAccountService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('identity/v1/accountdeletionrequest');
  }

  public get collection(): DeleteUserAccountCollection {
    return new DeleteUserAccountCollection(this.internalData);
  }

  public async deleteUserAccount(item: any): Promise<void> {
    return await this.saveItem(item, '', false, false, false);
  }
}
