import { Injectable, NgZone } from '@angular/core';
import { StateService, config } from '@app/core';

import { OrganizationsService } from './services/organizations.service';
import { ProfileService } from './services/profile.service';
import { BuyerConnectionsService } from './services/buyer-connections.service';
import { SellerConnectionsService } from './services/seller-connections.service';
import {
  Organization,
  Role,
  NotificationChannels,
  Binding,
  BindingPlatforms,
  BindingPlatformText,
  NotificationHistorySummary
} from '@app/model';
import { WatchListService } from './services/watch-list.service';
import { NotificationService } from './services/notification.service';
import { BindingService } from './services/binding.service';
import { DisposeService } from './dispose.service';
import { InsightService } from '@app/insight';
import { ToastrService } from 'ngx-toastr';
import { DashboardEventsService } from './services/dashboard-events.service';

import smartlookClient from 'smartlook-client';
import { throwError } from 'rxjs';

declare let device: any;
declare let PushNotification: any;
declare let applicationPlatform: string;
declare let IRoot: any;
declare let universalLinks: any;
// Constants for repeated string values
export const ADMIN = 'Admin';
export const SELLER = 'Seller';
export const BUYER = 'Buyer';
export const GUEST = 'Guest';

function isNullOrUndefined(variable: any): boolean {
  return variable === undefined || variable === null;
}
@Injectable({
  providedIn: 'root'
})
export class InitialService {
  public platform: string = 'web';

  public constructor(
    private state: StateService,
    private insightService: InsightService,
    private disposeService: DisposeService,
    private organizationsService: OrganizationsService,
    private profileService: ProfileService,
    private buyerConnectionsService: BuyerConnectionsService,
    private sellerConnectionsService: SellerConnectionsService,
    private watchListService: WatchListService,
    private notificationService: NotificationService,
    private bindingService: BindingService,
    private zone: NgZone,
    private toastr: ToastrService,
    private dashboardEventsService: DashboardEventsService
  ) { }

  public async getAllInitializer(): Promise<void> {
    await this.getDataPreInitializer();
    this.dashboardEventsService.setLoadDashboardTransactions();
    this._setupDevice();
    await this.getDataPrimer();
  }

  public async getDataPreInitializer(
    isNeedSetAuthenticated: boolean = true,
    forceGet: boolean = false
  ): Promise<any> {
    await this._loadProfile(forceGet);
    if (isNeedSetAuthenticated) {
      // log insight
      this.insightService.setAuthenticated(this.state.profile.userName);
    }
  }

  public getDataPrimer(): Promise<any> {
    const promises = [
      this._loadGeneralData(),
      this._loadSupervisorData(),
      this._loadBuyerOrSellerData(),
      this._loadSellerData(),
      this._loadBuyerData(),
      this._smartlookAnalytics()
    ].filter(Boolean);

    return Promise.all(promises);
  }

  // privates
  // cordova related
  private _setupDevice(): void {
    // listen to device
    document.addEventListener(
      'deviceready',
      function () {
        if (
          typeof device === 'undefined' ||
          device === null ||
          device === undefined
        ) {
          console.log('You are not running on Device. (Platform)');
          return;
        }

        console.log('App Platform: ' + device.platform);
        applicationPlatform = device.platform;
      },
      false
    );

    // rooted/jailbroken
    if (typeof IRoot !== 'undefined' && IRoot) {
      console.log('Inside IRooted');
      IRoot.isRooted(
        data => {
          if (data && data === 1) {
            console.log('This is rooted device');
            // disable back button on android
            document.addEventListener(
              'backbutton',
              function (e) {
                e.preventDefault();
              },
              false
            );

            // log insight
            this.insightService.logEvent(this.state.profile.email);

            this.disposeService.jailBreakLogout();
            return;
          } else {
            console.log('This is not rooted device');
            this.configurePushNotification();
            // universal deep linking
            universalLinks.subscribe(null, this.deepLinkingCallback.bind(this));
          }
        },
        data => { }
      );
    }
  }

  private deepLinkingCallback(eventData): void {
    this.zone.run(() => {
      this.state.navigateByUrl(eventData.hash);
    });
  }

  // general
  private configurePushNotification(): void {
    if (typeof PushNotification === 'undefined') {
      console.log('You are not running on Device. (Push)');
      return;
    }

    const push = PushNotification.init({
      android: {
        senderID: config.androidSenderId,
        icon: 'notification'
      },
      ios: {
        alert: true,
        badge: true,
        sound: true
      },
      windows: {}
    });

    push.on('registration', (data: any) => {
      const deviceAddress = data.registrationId;

      console.log('Got your Id: ' + deviceAddress);

      if (
        typeof deviceAddress === 'undefined' ||
        deviceAddress === null ||
        deviceAddress === undefined
      ) {
        return;
      }

      this.state.setIsOnDevice(true);
      this.state.setDeviceId(deviceAddress);
      this.state.setDevicePlatform(applicationPlatform);

      const devicePlatform = applicationPlatform.toLowerCase();
      const apn = BindingPlatformText[BindingPlatforms.APN].toLowerCase();
      const fcm = BindingPlatformText[BindingPlatforms.FCM].toLowerCase();

      const platform: BindingPlatforms =
        devicePlatform == apn
          ? BindingPlatforms.APN
          : devicePlatform == fcm
            ? BindingPlatforms.FCM
            : BindingPlatforms.Unknown;

      // post
      const newBinding: Binding = {
        id: null,
        deviceAddress: deviceAddress,
        platform: platform
      };

      this.bindingService.bind(newBinding);
    });

    push.on('notification', (data: any) => {
      if (
        data.additionalData.EventId &&
        data.additionalData.EntityId &&
        data.additionalData.Target &&
        data.additionalData.Action &&
        data.title &&
        data.message
      ) {
        this.zone.run(() => {
          let notification: NotificationHistorySummary = new NotificationHistorySummary();

          notification.metaData = {
            EventId: data.additionalData.EventId,
            EntityId: data.additionalData.EntityId,
            Target: data.additionalData.Target,
            Action: data.additionalData.Action
          };

          const url = this.notificationService.getDetailsUrl(notification);

          if (data.additionalData.foreground) {
            this.toastr
              .show(data.message, data.title, {
                positionClass: 'toast-top-right'
              })
              .onTap.subscribe(() => {
                this.state.navigateByUrl(url);
              });
          } else {
            this.state.navigateByUrl(url);
          }
        });
      }
    });

    push.on('error', (error: any) => {
      console.log(error.message);
    });
  }

  private _loadGeneralData(): Promise<void> {
    return this.notificationService.getNotifications(NotificationChannels.WebNotification);
  }

  // supervisor
  private _loadSupervisorData(): Promise<void> {
    if (!this.state.isSupervisor) {
      return Promise.resolve();
    }
  }

  // seller
  private _loadSellerData(): Promise<any> {
    if (this.state.isSeller) {
      return Promise.all([this.sellerConnectionsService.getConnections()]);
    }
  }

  // smartlook analytics
  private _smartlookAnalytics(): void {
    if (
      this.state.tenantInfo.analytics &&
      this.state.tenantInfo.analytics.smartlook &&
      smartlookClient.initialized()
    ) {
      smartlookClient.identify(this.state.profile.email, {
        email: this.state.profile.email,
        name: this.state.profile.fullName
      });
    }
  }

  // buyer
  private _loadBuyerData(): Promise<any> {
    if (this.state.isBuyer) {
      return Promise.all([
        this.watchListService.getWatchList(),
        this.buyerConnectionsService.getConnections()
      ]);
    }
  }

  // seller or buyer
  private _loadBuyerOrSellerData(): Promise<any> {
    if (this.state.isSeller || this.state.isBuyer) {
      return this.organizationsService.getOrganizations();
    }
  }

  // profile
  private async _loadProfile(forceGet: boolean = false): Promise<any> {
    try {
      await this.profileService.getProfile(forceGet);
      this._getCurrentRoles();
    } catch {
      await this.disposeService.logout();
    }
  }

  private _getCurrentRoles(): void {
    const profile = this.profileService.collection.item;

    // change state values
    this.state.setProfile(profile);
    this.state.setCurrentUserId(profile.id);

    let organizations = profile.organizations;

    if (!organizations || organizations.length === 0) return;

    // client app
    if (!this.state.isAdminApp) {
      organizations = organizations.filter((organization: Organization) => {
        // roles
        const roles = organization.roles.map((role: Role) => role.description);

        // permissions
        return (
          roles.includes(ADMIN) ||
          roles.includes(SELLER) ||
          roles.includes(GUEST) ||
          roles.includes(BUYER)
        );
      });
    }

    // set current user's organizations
    this.state.setCurrentOrganizations(organizations);

    let organizationId = this.state.organizationId || organizations[0].id;

    this._changeDefaultValues(organizationId);

    // stop loading
    this.state.setIsProfileLoaded(true);
  }

  private _changeDefaultValues(organizationId: string): void {
    if (isNullOrUndefined(organizationId)) throwError("Invalid organization id");

    // organization
    const organization = this.state.currentOrganizations.find((organization: Organization) =>
      organization.id === organizationId
    );
    if (isNullOrUndefined(organization)) throwError("Invalid organization");

    this.state.setCurrentOrganization(organization);

    // state
    this.state.setOrganizationId(organizationId);

    if (organization.features && Object.keys(organization.features).length > 0) {
      const { suburb, state, country } = organization.features;
      this.state.setOrganizationSuburb(suburb);
      this.state.setOrganizationState(state);
      this.state.setOrganizationCountry(country);
    }

    // roles
    const uniqueRoleDescriptions = organization.roles.map((role: Role) => role.description);

    this.state.setIsAdmin(uniqueRoleDescriptions.includes(ADMIN));
    this.state.setIsSeller(uniqueRoleDescriptions.includes(SELLER));
    this.state.setIsBuyer(uniqueRoleDescriptions.includes(BUYER));
    this.state.setIsGuest(uniqueRoleDescriptions.includes(GUEST));

    // acronym
    this._setOrganizationAcronym();
  }

  private _setOrganizationAcronym(): void {
    if (!this.state.isAdminApp || !this.state.currentOrganizations || this.state.currentOrganizations.length === 0) {
      return;
    }

    const organization = this.state.currentOrganizations.find(
      (organization: Organization) => organization.name === this.state.tenantCode
    );

    if (!organization) {
      return;
    }

    this.state.setOrganizationAcronym(organization.acronym);
  }
}
