export class Image {
  code: string = '';
  url: string = '';
  hasAnyThumbnail: boolean = false;
  thumbnailUrls: ImageThumbnailUrl[] = [];

  constructor() {}
}

export class ImageThumbnailUrl {
  sizeCodeValue: string = '';
  url: string = '';
}

export enum ImageThumbnailSizes {
  XSmall = '75',
  Small = '100',
  Medium = '240',
  Large = '320',
  XLarge = '468',
  XXLarge = '960'
}
