import { Identity } from './identity';

export class RegisterIncomplete extends Identity {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: string;
    leadSource: string;
}
