import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

export class CompareValidator {
  public static compare(controlNameToCompare: string): ValidationErrors | null {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value === null || control.value.length === 0) {
        return null;
      }

      const controlToCompare = <AbstractControl>(
        control.parent.controls[controlNameToCompare]
      );

      if (controlToCompare) {
        const subscription: Subscription = controlToCompare.valueChanges.subscribe(
          () => {
            control.updateValueAndValidity();
            subscription.unsubscribe();
          }
        );
      }

      return controlToCompare && controlToCompare.value !== control.value
        ? { compare: true }
        : null;
    };
  }
}
