import { StockModel } from './stock-model';
import { StockMMBS } from './stock-mmbs';

export class StockBadge extends StockMMBS {
  modelId: string;
  model: StockModel;

  constructor() {
    super();
  }
}
