import { Injectable } from '@angular/core';
import { StateService } from '@app/core';
import { ImageThumbnailSizes, Stock } from '@app/model';

@Injectable({
  providedIn: 'root'
})
export class Helper {

  public constructor(public state: StateService) {}

  public getStockImageUrl(item: Stock): string {

    let imageUrl = '';

    if (item.features.featuredImageSequences && item.features.featuredImageSequences.length > 0) {
      let filesList = [];
      item.features.featuredImageSequences.forEach(imageSequence => {
        let foundIndex = item.images.findIndex(img => img.url.split('/').pop().split('.')[0] == imageSequence.url.split('/').pop().split('.')[0])
        if (foundIndex > -1) {
          // get main image
          filesList.push(item.images[foundIndex]);
        }
      });

      item.images = filesList;
    }

    const defaultImages = this.state.tenantInfo
      ? this.state.tenantInfo.defaultImages
      : '';

    const defaultImage = defaultImages
      ? defaultImages.catalog.l
      : '';

    if (
      item.images != null &&
      item.images.length > 0
    ) {
      const image = item.images[0];

      imageUrl = image.hasAnyThumbnail
        ? image.thumbnailUrls.find(
            x => x.sizeCodeValue === ImageThumbnailSizes.Large
          ).url
        : image.url;
    }

    return imageUrl ? imageUrl : defaultImage;
  }
}
