import { Injectable } from '@angular/core';

import { Appraisal } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class AppraisalCollection extends DataCollection<Appraisal> { }
@Injectable({
  providedIn: 'root'
})
export class AppraisalService extends ItemsService<Appraisal> {
  protected serviceName = 'AppraisalService';
  public stock: any;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger
  ) {
    super(dataAccessService, logger);
    super.configure('guest/catalog/v1/appraisal');
  }

  public get collection(): AppraisalCollection {
    return new AppraisalCollection(this.internalData);
  }

  public async saveStatus(item: any): Promise<void> {
    await super.saveItem(item,'updatestatus');
  }
}
