import { Injectable } from '@angular/core';

import { Offers, Stock, User } from '@app/model';
import { Logger } from '@app/shared';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { HttpHeaders } from '@angular/common/http';

import { UsersService } from './users.service';
import { BuyerStocksSummaryService } from './buyer-stocks-summary.service';

export class BuyerPendingActionsCollection extends DataCollection<
  Stock
> { }

@Injectable({
  providedIn: 'root'
})
export class BuyerPendingActionsService extends ItemsService<Stock> {
  protected serviceName = 'BuyerPendingActionsService';
  public stocksItems: Stock[] = null;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private buyerStocksSummaryService: BuyerStocksSummaryService,
    private userServices: UsersService
  ) {
    super(dataAccessService, logger);
    super.configure('buyer/tender/v1/transactions');
  }

  public get collection(): BuyerPendingActionsCollection {
    return new BuyerPendingActionsCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {

    await super.getData('pending', params, true, options);

    const collection = this.collection;

    let items = collection.items as unknown as Offers[];

    if (items && items.length > 0) {

      // get stock ids
      const stockIds = items.map((offer: Offers) => {
        return offer.stock.stockId;
      });

      // get distinct stocks ids
      const stocksIdsDistinct = stockIds.filter((uid, i) => stockIds.indexOf(uid) === i);

      // get stock details
      await this.buyerStocksSummaryService
        .getClientData({
          ids: stocksIdsDistinct,
          includeFeatures: true
        })
        .then(async (stocks: Stock[]) => {

          // get user info
          const creatorIds = items.map((offer: Offers) => {
            return offer.transaction.createdBy;
          });

          // get distinct user ids
          const creatorIdsDistinct = creatorIds.filter((uid, i) => creatorIds.indexOf(uid) === i);

          await this.userServices.getDataByIds(creatorIdsDistinct)
            .then(() => {
              const owners = this.userServices.collection.items;

              stocks.forEach((stock: Stock) => {

                let stockPendingOffers = items.map((offer: Offers) => {
                  if (stock.id == offer.stock.stockId) {
                    return offer;
                  }
                });

                // fitler out null stock
                stockPendingOffers = stockPendingOffers.filter(offer => offer != null);
                stock.features.offers = stockPendingOffers;

                // add user to offers
                stock.features.offers.forEach((offer: Offers) => {
                  const user = owners.find((user: User) => {
                    return offer.transaction.createdBy === user.id;
                  });
                  offer.transaction.owner = user;
                  offer.isOwner = true;
                });

              });

              this.stocksItems = stocks;
              // change data
              const data = {
                items: items,
                pageNumber: collection.pageNumber,
                totalPages: collection.totalPages,
                totalCount: collection.totalCount,
                hasPreviousPage: collection.hasPreviousPage,
                hasNextPage: collection.hasNextPage
              };
              this.init(data);
            });
        });
    }
  }
}
