import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ObservablePipe } from './observable.pipe';
import { TranslatePipe } from './translate.pipe';
import { UtcDatePipe } from './utc-date.pipe';
import { TimeFromNowPipe } from './time-from-now.pipe';
import { TenantCurrencyPipe } from './tenant-currency.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TranslatePipe,
    ObservablePipe,
    UtcDatePipe,
    TimeFromNowPipe,
    TenantCurrencyPipe
  ],
  exports: [
    TranslatePipe,
    ObservablePipe,
    UtcDatePipe,
    TimeFromNowPipe,
    TenantCurrencyPipe
  ],
  providers: [
    TranslatePipe,
    ObservablePipe,
    UtcDatePipe,
    CurrencyPipe,
    TimeFromNowPipe,
    TenantCurrencyPipe
  ]
})
export class PipeModule {}
