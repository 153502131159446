import { Identity } from './identity';

export class Tenant extends Identity {
  name: string = '';
  theme: TenantTheme;
  version: string = '';
  supportedLanguages: string = '';
  defaultCountry: string = '';
  defaultCountryAlphaCode: string = '';
  defaultCountryCode: string = '';
  defaultStates: any[];
  defaultCurrency: string;
  defaultImages: TenantDefaultImages;
  description: string = '';
  validations: TenantValidations;
  registrationRoles: any[] = [];
  infoEmail: string = '';
  serviceConfigs: ServiceConfigs;
  analytics: Analytics;

  constructor() {
    super();
  }
}

export class Analytics {
  smartlook: Smartlook;

  constructor() {}
}

export class Smartlook {
  smartLookProjectKey: string = '';

  constructor() {}
}

export class ServiceConfigs {
  connectionConfig: ConnectionConfig;
  tenderConfig: TenderConfig;
  auctionConfig: AuctionConfig;

  constructor() {}
}

export class ConnectionConfig {
  buyerAddConnectionVisible: boolean = true;

  constructor() {}
}

export class TenderConfig {
  defaultFilters: any[] = [];

  constructor() {}
}

export class AuctionConfig {
  defaultFilters: any[] = [];

  constructor() {}
}

export class TenantTheme {
  url: string;

  constructor() {}
}

export class TenantValidations {
  registration: TenantRegistrationValidation;

  constructor() {}
}

export class TenantRegistrationValidation {
  abnLength: number;
  abnPattern: string;
  abnMessage: string;
  postCodeLength: number;
  postCodePattern: string;
  postCode: string;

  constructor() {}
}

export class TenantDefaultImages {
  identity: IdentityDefaultImageIdentity;
  catalog: CatalogDefaultImageCatalog;
  branding: BrandingDefaultImageIdentity;

  constructor() {}
}

export class BrandingDefaultImageIdentity {
  whiteLogo: string;
  coloredLogo: string;
  backgroundImage: string;
  notificationIcon: string;

  constructor() {}
}

export class IdentityDefaultImageIdentity {
  profile: string;

  constructor() {}
}

export class CatalogDefaultImageCatalog {
  xs: string;
  s: string;
  m: string;
  l: string;
  xl: string;

  constructor() {}
}
