import { Identity } from './identity';

export class StockMMBS extends Identity {
  name: string;
  tenantCode: string;
  organizationId: string;
  description: string;
  createdBy: string;

  constructor() {
    super();
  }
}
