import { Injectable } from '@angular/core';

import { Connection } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { config, StateService } from '@app/core';
import { HttpHeaders } from '@angular/common/http';

export class BuyerConnectionsCollection extends DataCollection<Connection> { }

@Injectable({
  providedIn: 'root'
})
export class BuyerConnectionsService extends ItemsService<Connection> {
  protected serviceName = 'BuyerConnectionsService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
    super.configure('connection/v1/connections');
  }

  public get collection(): BuyerConnectionsCollection {
    return new BuyerConnectionsCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    const staticFilters = [
      'destinationEntityType$User$0',
      'features.organizations.roles.description$Seller$0'
    ];

    let localUrlPostfix = `sourceEntity/${this.state.currentUserId}`;

    params.filters =
      params.filters && params.filters instanceof Array
        ? params.filters.some((x: string) => x.indexOf('destinationEntityType',) > -1) &&
          params.filters.some((x: string) => x.indexOf('features.organizations.roles.description',) > -1)
          ? params.filters
          : params.filters.concat(staticFilters)
        : staticFilters;

    params.pageSize = config.notPagedRecordNumber;

    await super.getData(localUrlPostfix, params, forceGet, options);
  }

  public async getConnections(forceGet: boolean = false): Promise<void> {
    this.state.setInitialDataState('Loading Connections...');

    await this.getData();
    this.state.setInitialDataState(`Connections ${config.loadedDataIcon}`);

    // stop loading
    this.state.setIsConnections4BuyerLoaded(true);

  }
}
