import { Pipe, PipeTransform } from '@angular/core';

import moment, { Moment } from 'moment';

@Pipe({
  name: 'timeFromNow'
})
export class TimeFromNowPipe implements PipeTransform {
  transform(value: Date | Moment | string, format?: string): string {
    const utcDateTime = value
      ? moment.utc(value).toDate()
      : moment.utc().toDate();

    const dateTime = format
      ? moment(utcDateTime).format(format)
      : moment(utcDateTime);
      
    return moment(dateTime).fromNow();
  }
}
