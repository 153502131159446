import { StockMake } from './stock-make';
import { StockMMBS } from './stock-mmbs';

export class StockModel extends StockMMBS {
  makeId: string;
  make: StockMake;

  constructor() {
    super();
  }
}
