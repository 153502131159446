import { Identity } from './identity';

export class Location extends Identity {
  countryName: string = '';
  stateName: string = '';
  cityName: string = '';
  formattedAddress: string = '';
  countryShortName: string = '';
  stateShortName: string = '';
  cityShortName: string = '';
  formattedShortAddress: string = '';
  referenceId: string = '';
  externalId: string = '';
  placeId: string = '';
  mapUrl: string = '';
  latitude: number = 0;
  longitude: number = 0;

  constructor() {
    super();
  }
}
