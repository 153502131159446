import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StateService } from '@app/core';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html'
})
export class TextareaComponent {
  @Input() field: any;
  @Input() index?: number;
  @Input() group: FormGroup;

  constructor(private state: StateService) {}

  // getters
  public get isShowLabel() {
    return this.field.label != null;
  }

  public get name() {
    const index = this.index != null ? `-${this.index}` : '';
    return `${this.field.name}${index}`;
  }

  public get columnClass(): any {
    return {
      'col-lg-6': this.field.isHalfColumn,
      'col-lg-12': !this.field.isHalfColumn
    };
  }

  public get maxLength(): number {
    let maxLength: number;

    maxLength =
      'parentStateKey' in this.field
        ? this.state.tenantInfo.validations[this.field.parentStateKey][
            this.field.stateMaxLengthKey
          ]
        : this.field.maxLength;

    return maxLength;
  }

  public get isRequired() {
    return (
      this.field.validations &&
      this.field.validations.some((x: any) => x.name === 'required')
    );
  }
}
