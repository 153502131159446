import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardEventsService {

  public loadDashboardTransactions = new Subject<void>();

  /**
   * Publishes a message to all subscribers of loadDashboardTransactions.
   */
  setLoadDashboardTransactions() {
    this.loadDashboardTransactions.next();
  }
}