import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'observable'
})
export class ObservablePipe implements PipeTransform {
  transform(item: any): Observable<any> {
    return of(item);
  }
}
