import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder } from '@angular/forms';
import { ItemsService, DataAccessService } from '@app/data';
import { BehaviorSubject } from 'rxjs';
import { Logger, SharedService, divideArray2Columns } from '@app/shared';
import { CurrencyPipe } from '@angular/common';
import { bindValidations } from '@app/validator';
import {
  Image,
  ImageThumbnailSizes,
  StockStatus,
  StockStatusText
} from '@app/model';
import { StateService } from '@app/core';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html'
})
export class CardListComponent extends ItemsService<any> implements OnInit {
  @Input() field: any = {};
  @Input() data: any;
  @Input() group: FormGroup;
  @Input() isReadOnly?: boolean = null;
  @Input() isEditing?: boolean;

  protected serviceName = 'CardListComponent';

  public items$ = new BehaviorSubject<any>([]);
  public items: any[] = [];
  public cardsArray: FormArray = new FormArray([]);

  public checkedList: string[] = [];

  public isLoadingItemsVisible: boolean = false;

  constructor(
    dataAccess: DataAccessService,
    logger: Logger,
    public shared: SharedService,
    public formBuilder: FormBuilder,
    private currency: CurrencyPipe,
    private state: StateService
  ) {
    super(dataAccess, logger);
  }

  public ngOnInit(): void {
    this.getDataSource();
  }

  public getDataSource() {
    if (this.field.dataSource != null) {
      this.getItems();
    }
  }

  public async getItems(): Promise<void> {
    // start
    this.isLoadingItemsVisible = true;
  
    const baseApiUrl = this.field.baseApiUrlKey
      ? `${this.state.environment[this.field.baseApiUrlKey]}`
      : '';
  
    const dataSource =
      this.isEditing && this.field.dataSourceInEditing
        ? this.field.dataSourceInEditing
        : this.field.dataSource;
  
    super.configure(`${baseApiUrl}${dataSource}`, this.field.isFullUrl);
  
    await this.getData();
  
    // add new fields
    const items = this.collection.items.map((item: any) => {
      const newItem = Object.assign({}, item);
  
      this.field.cardControls.forEach((control: any) => {
        newItem[control.name] =
          control.defaultValueKey === 'id'
            ? item.id
            : control.defaultValueKey === 'whole'
              ? Object.assign({}, item)
              : control.value;
      });
  
      return newItem;
    });
  
    // filter in editing
    if (this.isEditing) {
      const data = this.data[this.field.name];
      const ids = data.map((item: any) => item.stockId);
  
      this.items = items.filter((item: any) => {
        return (
          // can't edit
          (this.isReadOnly && ids.includes(item.id)) ||
          // edit
          (!this.isReadOnly &&
            (ids.includes(item.id) || StockStatus[item.status] == StockStatus.Available))
        );
      });
    } else {
      this.items = items;
    }
  
    this.items$.next(divideArray2Columns(this.items, 4));
  
    // add arrays
    if (this.group.controls[this.field.name] instanceof FormControl) {
      this.group.removeControl(this.field.name);
      this.group.addControl(this.field.name, this.cardsArray);
    }
  
    // patch values
    this._patchValues();
  
    // stop
    this.isLoadingItemsVisible = false;
  }

  // event
  public onKeypress(event: any): void {
    if (this.type != 'number') return;

    const pattern = /[0-9\.]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  // getters
  public get isItemsEmpty(): boolean {
    return this.items$.getValue().length === 0;
  }

  public get isViewButtonVisible(): boolean {
    return this.field.cardDetailsLink;
  }

  public get cardInput(): any {
    return this.field.cardInput;
  }

  public get isInputNumber(): boolean {
    return this.field.inputType === 'number';
  }

  public get disabled(): any {
    return this.isReadOnly ? '' : null;
  }

  public get type() {
    return this.cardInput.type || 'text';
  }

  public get step(): number {
    return this.cardInput.step ? this.cardInput.step : 1;
  }

  public get min(): number {
    return this.cardInput.min ? this.cardInput.min : 0;
  }

  public get groupClass(): any {
    return {
      'custom-number': this.type === 'number'
    };
  }

  public get prefixOrPostfixClass(): any {
    return {
      prefix: this.cardInput.prefix || this.cardInput.prefixTenantKey,
      postfix: this.cardInput.postfix || this.cardInput.postfixTenantKey
    };
  }

  public get prefix(): string {
    return this.cardInput.prefixTenantKey
      ? this.state.tenantInfo
        ? this.state.tenantInfo[this.cardInput.prefixTenantKey]
        : ''
      : this.cardInput.prefix;
  }

  public get postfix(): string {
    return this.cardInput.postfixTenantKey
      ? this.state.tenantInfo
        ? this.state.tenantInfo[this.cardInput.postfixTenantKey]
        : ''
      : this.cardInput.postfix;
  }

  // methods
  public getCardInputName(index: number): string {
    return `${this.cardInput.name}-${index}`;
  }

  public getBadgeClass(item: any): any {
    return {
      'badge-success': StockStatus[item.status] == StockStatus.Sold || item.status == StockStatus.Sold,
      'badge-danger': StockStatus[item.status] == StockStatus.Available || item.status == StockStatus.Available,
      'badge-info': StockStatus[item.status] == StockStatus.OnHold || item.status == StockStatus.OnHold,
      'badge-default': StockStatus[item.status] == StockStatus.Hidden || item.status == StockStatus.Hidden,
      'badge-warning': StockStatus[item.status] == StockStatus.InTender || item.status == StockStatus.InTender,
    };
  }

  public getStatusText(item: any): string {
    if (StockStatusText[StockStatus[item.status]]) {
      return StockStatusText[StockStatus[item.status]];
    }
    return StockStatusText[item.status];
  }

  public getImageUrl(item: any): string {
    let imageUrl = '';

    const defaultImages = this.state.tenantInfo
      ? this.state.tenantInfo.defaultImages
      : '';

    const defaultImage = defaultImages
      ? defaultImages[this.field.defaultImageKey].l
      : '';

    if (
      item[this.field.dataImageKey] != null &&
      item[this.field.dataImageKey].length > 0
    ) {
      const image = item[this.field.dataImageKey][0] as Image;

      imageUrl = image.hasAnyThumbnail
        ? image.thumbnailUrls.find(
          x => x.sizeCodeValue === ImageThumbnailSizes.Large
        ).url
        : image.url;
    }

    return imageUrl ? imageUrl : defaultImage;
  }

  public getTitle(item: any): string {
    let title: string = '';

    if (this.field.dataTitleKey) {
      title = item[this.field.dataTitleKey];
    } else if (this.field.dataTitleKeys) {
      const keys = this.field.dataTitleKeys as Array<string>;
      const titles = keys.map((key: string) => {
        const splits = key.split('.');
        if (splits.length === 2) {
          return item[splits[0]][splits[1]];
        }
        return item[key];
      });

      title = titles.join(' ');
    }

    return title;
  }

  public getBodyItemValue(item: any, bodyItem: any): any {
    return bodyItem.key
      ? item[bodyItem.key]
      : item[bodyItem.objectKey][bodyItem.innerKey];
  }

  public getHoverItemValue(item: any): any {
    const value = this.getBodyItemValue(item, this.field.dataHoverItem);

    return this.field.dataHoverItem.valueType === 'currency'
      ? this.currency.transform(value)
      : value;
  }

  // selected
  public onChange(event: any, card: any): void {
    // add new item
    if (event.target.checked) {
      const cardControls = this.field.cardControls.map((control: any) => {
        const newControl = Object.assign({}, control);
        newControl.value = card[control.name];
        return newControl;
      });

      this.checkedList.push(card.id);
      this.cardsArray.push(this._addCards(cardControls));
    }
    // remove item
    else {
      const index = this.checkedList.indexOf(card.id);
      this.cardsArray.removeAt(index);

      this.checkedList = this.checkedList.filter((item: any) => {
        return item !== card.id;
      });
    }
  }

  public isSelected(id: string): boolean {
    return this.checkedList.indexOf(id) > -1;
  }

  public onInputChange(event: any, card: any): void {
    this.setReservePrice(card, event.target.value);
  }

  public setReservePrice(card: any, value: any) {
    const index = this.checkedList.indexOf(card.id);

    if (index > -1) {
      card[this.cardInput.name] = this.isInputNumber ? Number(value) : value;

      const cardControls = this.field.cardControls.map((control: any) => {
        const newControl = Object.assign({}, control);
        newControl.value = card[control.name];
        return newControl;
      });

      this.cardsArray.setControl(index, this._addCards(cardControls));
    }
  }

  public loadReservePrice(card: any) {
    const value = this.getInputValue(card);
    this.setReservePrice(card, value);
    return value;
  }

  public getInputValue(card: any): any {
    // reserve price checks
    if (card[this.cardInput.name]) {
      return card[this.cardInput.name];
    }
    // default reserve price checks
    if (card[this.cardInput.objectKey][this.cardInput.innerKey]) {
      return card[this.cardInput.objectKey][this.cardInput.innerKey]
    }
    // load price if no values found
    return card[this.cardInput.objectKey][this.cardInput.alternateKey]

  }


  public getDetailsLink(item: any): string[] {
    let url = [];

    if (this.isViewButtonVisible) {
      url = url.concat(this.field.cardDetailsLink);
      url.push(item.id);
    }

    return url;
  }

  // private
  private _addCards = (cards: any[]): FormGroup => {
    const group: FormGroup = new FormGroup({});

    cards.forEach((card: any) => {
      group.addControl(card.name, this._control(card));
    });

    return group;
  };

  private _control = (field: any): FormControl => {
    return this.formBuilder.control(
      field.value,
      bindValidations(field.validations || [])
    );
  };

  private _patchValues() {
    if (this.data != null && Object.keys(this.data).length > 0) {
      const data = this.data[this.field.name];

      data.forEach((item: any) => {
        // default value
        let cardControls: any[] = [];

        Object.keys(item).forEach((key: any) => {
          this.field.cardControls.forEach((card: any) => {
            let newCard = Object.assign({}, card);

            if (newCard.name === key) {
              const value = item[key];
              const mainValue = item[this.field.mainKey];

              // change cart value
              newCard.value = value;

              // add extra fields to items
              this._addExtraFields2Items(mainValue, key, value);

              // add to check list
              if (newCard.defaultValueKey === 'id') {
                this.checkedList.push(newCard.value);
              }

              cardControls.push(newCard);
            }
          });
        });

        // add controls
        this.cardsArray.push(this._addCards(cardControls));
      });
    }
  }

  private _addExtraFields2Items(mainValue: string, key: string, value: any) {
    this.items.forEach((item: any) => {
      if (mainValue === item.id) {
        item[key] = value instanceof Object ? Object.assign({}, value) : value;
        return;
      }
    });
  }
}
