import { Injectable } from '@angular/core';

import { TenderStock } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class BuyerStockTendersCollection extends DataCollection<TenderStock> {}

@Injectable({
  providedIn: 'root'
})
export class BuyerStockTendersService extends ItemsService<TenderStock> {
  protected serviceName = 'BuyerStockTendersService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('buyer/tender/v2/tenders');
  }

  public get collection(): BuyerStockTendersCollection {
    return new BuyerStockTendersCollection(this.internalData);
  }

  public configureUrlApiVersion(apiVersion: string): void {
    super.configure(`buyer/tender/${apiVersion}/tenders`);
  }

  public async getTenderStocks(
    tednerId: string,
    pageNumber: number = 1,
    pageSize: number = 1,
    stockIds: string[] = null,
    filterParams: any = null,
    sortParams: any = null
  ): Promise<void> {
    const urlPostfix = `${tednerId}/stocks`;
    let params: any = {
      pageSize: pageSize,
      pageNumber: pageNumber
    };

    if (filterParams) {
      params.filters = filterParams;
    }

    if (sortParams) {
      params.sortBy = sortParams.sortBy;
      params.ascendingSort = sortParams.ascendingSort;
    }

    if (stockIds) {
      let filters: string[] = [];
      stockIds.forEach(stockId => {
        filters.push(`stockid$${stockId}$1`);
      });
      params.filters = filters;
    }
    await super.getData(urlPostfix, params, true);
  }
}
