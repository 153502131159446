import { Identity } from './identity';

export class Login extends Identity {
  email: string;
  password: string;
  accessToken: string;

  constructor() {
    super();
  }
}
