import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';

import { NxModule } from '@nrwl/angular';

import { AppRoutingModule } from './app-routing.module';

import { BaseModule } from '@app/base';
import { DynamicsModule } from '@app/dynamics';

import { PipeModule } from '@app/pipe';
import { TranslateModule, TranslateService } from '@app/translate';

import { AppComponent } from './app.component';
import { AuthModule, AuthGuardService } from '@app/auth';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelect2Module } from 'ng-select2';
import { config } from '@app/core';

// translate
export function setupTranslateFactory(
  translateService: TranslateService
): Function {
  const currentLocale = translateService.getCurrentLocale();
  return () => translateService.use(currentLocale);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AuthModule,
    AppRoutingModule,
    BaseModule,
    DynamicsModule,
    PipeModule,
    TranslateModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelect2Module,
    NgxPaginationModule,
    BrowserAnimationsModule,
    NxModule.forRoot(),
    NgxUiLoaderModule.forRoot(config.ngxUiLoaderConfig),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    })
  ],
  providers: [
    AuthGuardService,
    // translate
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
