import { StockBadge } from './stock-badge';
import { StockMMBS } from './stock-mmbs';

export class StockSeries extends StockMMBS {
  badgeId: string;
  badge: StockBadge;

  constructor() {
    super();
  }
}
