import { Component, Input, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ItemsService, DataAccessService } from '@app/data';
import { Logger } from '@app/shared';
import { BehaviorSubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

import { StateService, config } from '@app/core';

import { CdkDrag, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';

import {
  File,
  ImageThumbnailSizes,
  BindingPlatforms,
  BindingPlatformText,
} from '@app/model';
import { ViewportRuler } from '@angular/cdk/overlay';
import { InsightService } from '@app/insight';

declare let navigator: any;
declare let FileUploadOptions: any;
declare let FileTransfer: any;

declare let $: any;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html'
})
export class FileUploadComponent extends ItemsService<any> implements OnInit {
  @Input() field: any;
  @Input() index?: number;
  @Input() group: FormGroup;

  // drag and drop
  @ViewChild(CdkDropListGroup, null) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList, null) placeholder: CdkDropList;

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;

  protected serviceName = 'FileUploadComponent';

  public featuredImageSequences: any[] = [];
  public fileUrls: any[] = [];
  public fileNames: string[] = [];
  public removedFiles: string[] = [];

  public isUploaded$ = new BehaviorSubject<boolean>(false);
  public percentDone$ = new BehaviorSubject<number>(0);

  public featuredImageName: any;
  public fileName: any;

  constructor(
    dataAccess: DataAccessService,
    logger: Logger,
    public state: StateService,
    private cd: ChangeDetectorRef,
    private viewportRuler: ViewportRuler,
    private insightService: InsightService
  ) {
    super(dataAccess, logger);

    this.target = null;
    this.source = null;
  }

  public ngOnInit(): void {
    super.configure('storage/v1/storage');
    this.getFeaturedImageSequences();
    this.getFileUrls();
  }

  // popup
  public onOpenImageSequence(): void {

    // log insight
    this.insightService.logEvent('Order Images');

    $(`#image-sequence`).appendTo('body').modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  public onCloseImageSequence(): void {
    $('#image-sequence').modal('hide');
  }

  // event drag and drop
  dragMoved(e: CdkDragMove) {
    let point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  public async onChange(event: any): Promise<void> {
    this.group.setErrors({ incorrect: true });

    let headers: HttpHeaders;
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      let fileExtension = file.name.split('.').pop().toLowerCase();

      if (config.allowedFileExtension.includes(fileExtension) == false) {
        this.logger.info(config.imageFileUploadExtensionErrorMessage);
        continue;
      }
      this.isUploaded$.next(false);
      this.percentDone$.next(0);

      await this.uploadFiles(file, this.percentDone$, headers).then(
        response => {
          if (Object.keys(response).length > 0) {
            const item = {
              code: null,
              url: response.fileLocation,
              name: response.name
            };

            this.fileUrls.push(item);

            // set features sequence.
            this.setFeaturedImageSequences();

            this.fileNames.push(response.name);

            this.isUploaded$.next(true);

            if (i === files.length - 1) {
              // name
              this.group.patchValue({
                [this.field.name]: this.fileNames
              });

              // urls
              if (this.field.urlName) {
                this.group.patchValue({
                  [this.field.urlName]: this.fileUrls
                });
              }
              this.group.markAsTouched();
            }
          }
        }
      );
    }
  }

  //Drag drop
  dropListDropped(e: any) {
    if (!this.target)
      return;

    let phElement = this.placeholder.element.nativeElement;
    let parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex != this.targetIndex) {
      moveItemInArray(this.fileUrls, this.sourceIndex, this.targetIndex);
    }

  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder)
      return true;

    if (drop != this.activeContainer)
      return false;

    let phElement = this.placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;

    let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }

  // methods
  public deleteFile(file: any): void {
    const key = file.code ? 'code' : 'url';

    // remove from view
    let urlIndex = this.fileUrls.findIndex(x => x[key] === file[key]);
    if (urlIndex > -1) {

      const fileUrl = this.fileUrls[urlIndex];
      this.fileUrls.splice(urlIndex, 1);
      this.setFeaturedImageSequences();
      if (this.field.urlName) {
        this.group.patchValue({
          [this.field.urlName]: this.fileUrls
        });
      }

      // remove image name
      if (fileUrl.code === null) {
        const nameIndex = this.fileNames.findIndex(n => n === fileUrl.name);
        if (nameIndex > -1) {
          this.fileNames.splice(nameIndex, 1);
        }
      }
    }

    // remove from form
    if (file.code) {
      this.removedFiles.push(file.code);
      this.group.patchValue({ [this.field.removeName]: this.removedFiles });
    }

    this.group.markAsTouched();
  }

  public getFileUrls(): void {

    let filesList = [];
    const files = this.group.value[this.field.urlName];
    if (files != null && files.length) {
      files.forEach((file: File) => {
        const urlThumbnail = file.hasAnyThumbnail ? file.thumbnailUrls.find(
          x => x.sizeCodeValue === ImageThumbnailSizes.Medium
        ) : null

        const url = urlThumbnail ? urlThumbnail.url : file.url;

        const item = { code: file.code, url: url };

        filesList.push(item);
      });

      if (this.featuredImageSequences && this.featuredImageSequences.length > 0) {
        this.featuredImageSequences.forEach(imageSequence => {
          // extract image code

          let foundIndex = filesList.findIndex(img => img.url.split('/').pop().split('.')[0] == imageSequence.url.split('/').pop().split('.')[0])
          if (foundIndex > -1) {
            // get main image
            this.fileUrls.push(filesList[foundIndex]);
          }
        });
      } else {
        this.fileUrls = filesList;
      }
      this.setFeaturedImageSequences();
    }
  }

  public setFeaturedImageSequences(): void {
    if (this.fileUrls == undefined || this.fileUrls.length <= 0) {
      this.featuredImageSequences = [];
    }
    this.featuredImageSequences = this.fileUrls;
    this.group.patchValue({ [this.field.featuredImageSequence]: this.featuredImageSequences });
    this.group.markAsTouched();
  }

  public getFeaturedImageSequences(): void {
    const features = this.group.value[this.field.features];
    const featuredImageSequences: any[] = features[this.field.featuredImageSequence];
    if (featuredImageSequences && featuredImageSequences.length > 0) {
      this.featuredImageSequences = featuredImageSequences;
    }
  }

  public onGallery() {
    document.getElementById('gallery').click();
  }

  public onCamera() {
    document.getElementById('camera').click();
  }

  public onCameraOpen() {
    const ft = new FileTransfer();

    if (navigator != undefined) {
      navigator.camera.getPicture(
        (imageData: any) => {
          this.percentDone$.next(0);
          this.isUploaded$.next(false);

          let uri = encodeURI(
            `${this.state.environment.baseApiUrl}storage/v1/storage`
          );

          let options = new FileUploadOptions();
          options.fileKey = 'file';
          options.fileName = imageData.substr(imageData.lastIndexOf('/') + 1);
          options.mimeType = 'image/jpeg';
          // options.trustAllHosts = true;

          let headers = {
            'x-Tenant-Code': this.state.tenantCode,
            'X-User-Id': this.state.currentUserId,
            'X-Organization-Id': this.state.organizationId,
            Authorization: 'Bearer ' + this.state.token
          };

          options.headers = headers;

          ft.onprogress = (progressEvent: any) => {
            if (progressEvent.lengthComputable) {
              let progressPercentage = Math.round(
                (100 * progressEvent.loaded) / progressEvent.total
              );
              this.percentDone$.next(progressPercentage);
            } else {
              //loadingStatus.increment();
            }
          };

          ft.upload(
            imageData,
            uri,
            (response: any) => {
              const responseStorage = JSON.parse(response.response);

              if (Object.keys(responseStorage).length > 0) {
                const item = {
                  code: null,
                  url: responseStorage.fileLocation,
                  name: responseStorage.name
                };

                this.fileUrls.push(item);
                // set features sequence.
                if (this.featuredImageSequences && this.featuredImageSequences.length <= 0) {
                  this.setFeaturedImageSequences();
                }
                this.fileNames.push(responseStorage.name);

                this.isUploaded$.next(true);

                // name
                this.group.patchValue({
                  [this.field.name]: this.fileNames
                });

                // urls
                if (this.field.urlName) {
                  this.group.patchValue({
                    [this.field.urlName]: this.fileUrls
                  });
                }

                this.group.markAsTouched();
                this.cd.markForCheck();
              }
            },
            (error: any) => {
              console.log('upload error source ' + error.source);
              console.log('upload error target ' + error.target);
              console.log('upload error        ' + JSON.stringify(error));

              this.isUploaded$.next(true);
              this.cd.markForCheck();
            },
            options
          );
        },
        (error: any) => {
          console.log('Unable to Capture Picture At the moment: ' + error);
        },
        {
          quality: 100,
          allowEdit: false,
          correctOrientation: false,
          sourceType: navigator.camera.PictureSourceType.CAMERA,
          destinationType: navigator.camera.DestinationType.FILE_URI
        }
      );
    }
  }

  // getters
  public get name(): string {
    const index = this.index != null ? `-${this.index}` : '';
    return `${this.field.name}${index}`;
  }

  public get isShowImages(): boolean {
    return this.fileUrls.length > 0;
  }

  public get isCameraVisible(): boolean {
    return (
      this.state.isOnDevice &&
      this.state.devicePlatform.toLowerCase() ==
      BindingPlatformText[BindingPlatforms.FCM].toLowerCase()
    );
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
