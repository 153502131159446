import { Injectable } from '@angular/core';

import { User } from '@app/model';
import { Logger } from '@app/shared';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { StateService, config } from '@app/core';

export class IntegrationStocksDMSCollection extends DataScalar<User> {}

@Injectable({
  providedIn: 'root'
})
export class IntegrationStocksDMSService extends ItemService<User> {
  protected serviceName = 'IntegrationStocksDMSService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
    super.configure('seller/mezzanine/v1/stocks');
  }

  public get collection(): IntegrationStocksDMSCollection {
    return new IntegrationStocksDMSCollection(this.internalItem);
  }

  public async getImportUBSByFeedId(feedId: string): Promise<void> {
    return await super.getData(`ubs/${feedId}`, null, true).then(() => {
    });
  }
}
