import { Injectable } from '@angular/core';
import { ItemsService, DataCollection } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { Logger } from '@app/shared';

import { Tenant } from '@app/model';
import { HttpHeaders } from '@angular/common/http';
import { PublicTenantV2Service } from './public-tenant-v2.service';

export class PublicTenantStatesCollection extends DataCollection<Tenant> { }

@Injectable({
  providedIn: 'root'
})
export class PublicTenantStatesService extends ItemsService<Tenant> {
  protected serviceName = 'PublicTenantStatesService';

  constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private publicTenantService: PublicTenantV2Service
  ) {
    super(dataAccessService, logger);
    super.configure('public/tenant/v2/specs');
  }

  public get collection(): PublicTenantStatesCollection {
    return new PublicTenantStatesCollection(this.internalData);
  }

  public async getData(): Promise<void> {
    await this.publicTenantService.getData();
    const { item } = this.publicTenantService.collection;
    this.init({ items: item ? item.defaultStates : [] });
  }
}