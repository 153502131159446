import { Injectable } from '@angular/core';

import { Stock, User } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { HttpHeaders } from '@angular/common/http';

export class SellerStocksSummaryCollection extends DataCollection<Stock> {}

@Injectable({
  providedIn: 'root'
})
export class SellerStocksSummaryService extends ItemsService<Stock> {
  protected serviceName = 'SellerStocksSummaryService';

  public constructor(private dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('seller/catalog/v1/stocks/summary');
  }

  public get collection(): SellerStocksSummaryCollection {
    return new SellerStocksSummaryCollection(this.internalData);
  }

  public async getClientData(params: any = {}): Promise<Stock[]> {
    return await this.dataAccessService.getData<Stock[]>('seller/catalog/v1/client/stocks/summary', '', params, false);
  }
}
