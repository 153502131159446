import { Injectable } from '@angular/core';

import { Connection } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { StateService } from '@app/core';

export class ConnectionsInOrganizationsCollection extends DataCollection<
  Connection
> {}

@Injectable({
  providedIn: 'root'
})
export class ConnectionsInOrganizationsService extends ItemsService<
  Connection
> {
  protected serviceName = 'ConnectionsInOrganizationsService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
    super.configure('connection/v1/connections');
  }

  public get collection(): ConnectionsInOrganizationsCollection {
    return new ConnectionsInOrganizationsCollection(this.internalData);
  }

  public async getAvailableInOrg(
    sourceEntityId: string,
    organizationId: string,
    role: string,
    destinationEntityType: string = 'User'
  ): Promise<void> {
    let urlPostfix = `sourceEntity/${sourceEntityId}/destinationEntity/type/${destinationEntityType}/role/${role}/organization/${organizationId}/available`;

    await super.getData(urlPostfix, null, true);
  }
}
