import { Injectable } from '@angular/core';

import { Logger } from '@app/shared';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class UserPhotoCollection extends DataScalar<any> {}

@Injectable({
  providedIn: 'root'
})
export class UserPhotoService extends ItemService<any> {
  protected serviceName = 'UserPhotoService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('identity/v1/users/profile/photo');
  }

  public get collection(): UserPhotoCollection {
    return new UserPhotoCollection(this.internalItem);
  }
}
