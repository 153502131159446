import { Identity } from './identity';
import { Location } from './location';
import { Image } from './image';
import { Offers } from './transaction';

export class Stock extends Identity {
  status: string = StockStatus.Available;
  primaryExternalUniqueId: string = '';
  imagesName: string[] = [];
  removedImagesCode: string[] = [];
  description: string = '';
  images: Image[] = [];
  featuredImageName: string = '';

  // optional
  location?: Location;
  features?: StockFeatures;
  isSelected: boolean = false;
  organizationId?: string;
  ownerId?: string;
  viewCount?: number = 0;
  offerCount?: number = 0;
  reserveMet?: boolean;
  isGettingOffers?: boolean;

  tenderId? : string;
  isPrivate? : boolean;
  isAuction? : boolean;
  isMember? : boolean;
  winningTender? : number = 0;

  constructor() {
    super();
  }
}

export class StockFeatures {
  year: number = 0;
  make: string = '';
  model: string = '';
  badgeVariant: string = '';
  series: string = '';
  vinNumber: string = '';
  bodyType: string = '';
  transmission: string = '';
  engineNumber: string = '';
  engineSize: number = 0;
  fuelType: string = '';
  tyreDepth: string = '';
  spareKey: string = '';
  serviceHistory: string = '';
  seats: number = 0;
  doors: number = 0;
  color: string = '';
  odometer: number = 0;
  noOfCylinders: number = 0;
  dealerStockNumber: string = '';
  registrationNumber: string = '';
  registrationExpiryDate: string = '';
  price: number = 0;
  defaultReservePrice: number = 0;
  notes: StockNote[] = [];
  metaData: any = {};
  featuredImageSequences: any[] = [];

  // optional
  offerAmount?: number;
  daysValidFor?: number;
  offer?: Offers;
  offers?: Offers[] = [];

  constructor() {}
}

export class StockNote {
  note: string;
}

export enum StockStatus {
  Deleted = '-1',
  Available = '0',
  OnHold = '1',
  Sold = '2',
  Hidden = '3',
  Processing = '4',
  InTender = '5',
  Withdrawn = '6'
}

export const StockStatusText = {
  [StockStatus.Deleted]: 'Deleted',
  [StockStatus.Available]: 'Available',
  [StockStatus.OnHold]: 'In Tender',
  [StockStatus.Sold]: 'Sold',
  [StockStatus.Hidden]: 'Hidden',
  [StockStatus.Processing]: 'Processing',
  [StockStatus.InTender]: 'Pre Tender',
  [StockStatus.Withdrawn]: 'Withdrawn'
};

export const StockStatusAuctionText = {
  [StockStatus.Deleted]: 'Deleted',
  [StockStatus.Available]: 'Available',
  [StockStatus.OnHold]: 'In Auction',
  [StockStatus.Sold]: 'Sold',
  [StockStatus.Hidden]: 'Hidden',
  [StockStatus.Processing]: 'Processing',
  [StockStatus.InTender]: 'Pre Auction',
  [StockStatus.Withdrawn]: 'Withdrawn'
};
