import { Injectable } from '@angular/core';

import { Stock, StockFeatures } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class SellerStocksCollection extends DataCollection<Stock> { }

import { HttpHeaders } from '@angular/common/http';
import { TransactionsCountService } from './transactions-count.service';

@Injectable({
  providedIn: 'root'
})
export class SellerStocksService extends ItemsService<Stock> {
  protected serviceName = 'SellerStocksService';
  public stock: any;

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private transactionsCountService: TransactionsCountService
  ) {
    super(dataAccessService, logger);
    super.configure('seller/catalog/v2/stocks');
  }

  public get collection(): SellerStocksCollection {
    return new SellerStocksCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    const staticFilters = [
      'status$Available$1',
      'status$InTender$1',
      'status$OnHold$1',
      'status$Hidden$1'
    ];

    let localUrlPostfix = 'organization';

    params.filters =
      params.filters && params.filters instanceof Array
        ? params.filters.some((x: string) => x.indexOf('status') > -1)
          ? params.filters
          : params.filters.concat(staticFilters)
        : staticFilters;

    await super
      .getData(localUrlPostfix, params, forceGet, options)
      .then(async () => {
        const collection = this.collection;

        let items = collection.items;

        if (items.length > 0) {
          // stock ids
          const stockIds = items.map((item: Stock) => {
            return item.id;
          });

          // get view count
          await this.transactionsCountService
            .getBatchViewsCount(stockIds)
            .then(() => {
              // replace view count
              const viewCounts = this.transactionsCountService.collection.item as any;
              items = items.map((item: Stock) => {
                const count = viewCounts.find(vcount => vcount.id == item.id)
                item.viewCount = count ? count.count as number : 0
                return item;
              });

              // change data
              const data = {
                items: items,
                pageNumber: collection.pageNumber,
                totalPages: collection.totalPages,
                totalCount: collection.totalCount,
                hasPreviousPage: collection.hasPreviousPage,
                hasNextPage: collection.hasNextPage
              };

              this.init(data);
            });
        }
      });
  }

  public async save(item: Stock): Promise<void> {
    // convert types
    item.features = this.convertTypes<StockFeatures>(
      item.features,
      StockFeatures
    );

    // save and go to server and get new data
    await super.saveItem(item);
  }
}
