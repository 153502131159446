import { Injectable } from '@angular/core';

import {
  Notification,
  NotificationHistorySummary,
  TransactionTypes,
} from '@app/model';

import { Logger } from '@app/shared';
import { ItemService, DataScalar } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

import { config, StateService } from '@app/core';
import { HttpHeaders } from '@angular/common/http';

export class NotificationCollection extends DataScalar<Notification> { }

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ItemService<Notification> {
  protected serviceName = 'NotificationService';

  isGetAllProcessing = false;
  getAllResolve: any;
  getAllReject: any;
  isGetAllReady = new Promise((resolve, reject) => {
    this.getAllResolve = resolve;
    this.getAllReject = reject;
  });

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
    super.configure('notification/v1/history');
  }

  public get collection(): NotificationCollection {
    return new NotificationCollection(this.internalItem);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    await super.getData(urlPostfix, params, forceGet, options);
    if (!this.collection.item) return;

    const notifications = this.collection.item.notificationHistorySummary.map(
      ({ payload, ...history }: NotificationHistorySummary) => {
        if (payload && payload.Message) {
          const { Title, Content, Url, ThumbnailUrl } = payload.Message;
          history.title = Title;
          history.content = Content;
          history.url = Url;
          history.thumbnailUrl = ThumbnailUrl;
          history.metaData = payload.MetaData;
        }
        return history;
      }
    );

    this.init({
      id: this.collection.item.id,
      notificationHistorySummary: notifications,
      allNotifications: notifications,
      unReadNotifications: notifications.filter(
        (history: NotificationHistorySummary) => !history.isRead
      ),
      continuationToken: this.collection.item.continuationToken
    });
  }

  public async getAll(
    channel: string,
    forceGet: boolean = false
  ): Promise<void> {
    if (!forceGet && this.isGetAllProcessing) {
      await this.isGetAllReady;
      return;
    }

    try {
      this.isGetAllProcessing = true;
      await this.getData(`channels/${channel}`, null, forceGet);
      this.getAllResolve();
    } catch (error) {
      this.getAllReject(error);
      throw new Error(error);
    } finally {
      this.isGetAllProcessing = false;
    }
  }

  public async getNotifications(
    channel: string,
    forceGet: boolean = false
  ): Promise<void> {
    this.state.setInitialDataState('Loading Notifications...');

    await this.getAll(channel, forceGet);
    this.state.setInitialDataState(`Notifications ${config.loadedDataIcon}`);
    this.state.setNotification(this.collection.item);

    // stop loading
    this.state.setIsNotificationsLoaded(true);
  }

  public async markAsRead(channel: string, code: string): Promise<void> {
    await super.saveItem(null, `channels/${channel}/code/${code}/markAsRead`, true, false, false, false);
  }

  public getDetailsUrl(notification: NotificationHistorySummary): string {
    let url: string = '';
    const metaData = notification.metaData;
    const tenantCode = `/${this.state.tenantCode}`;

    if (!metaData)
      return url;

    const eventId = metaData.EventId;
    const entityId = metaData.EntityId;
    const target = metaData.Target;
    const action = metaData.Action;

    const isBuyer = target === 'Buyer';
    const isSeller = target === 'Seller';

    const isStock = eventId === config.emptyGuid;
    const isTenderDetails = !isStock && eventId === entityId;
    const isAuction = action == "Auction" || action == 'AuctionCounterOffer' || action == 'AuctionCounterOfferResponse';
    const isTender = action == "Tender" || ((action == "AcceptOffer" || action == "RejectOffer" || action == "Start") && eventId != null && eventId != undefined && eventId != "");
    const isSold =
      action == TransactionTypes.Buy ||
      action == TransactionTypes.AcceptOffer;

    // query
    const queryParams = isSold ? `isSold=${isSold}` : '';

    // urls
    const sellerStockDetailsUrl = `${tenantCode}/sell/stock/details/${entityId}?${queryParams}`;
    const sellerTenderDetailsUrl = `${tenantCode}/sell/tender/edit/${eventId}?${queryParams}`;
    const sellerTenderStockDetailsUrl = `${tenantCode}/sell/tender/edit/${eventId}/stock/${entityId}?${queryParams}`;
    const sellerAuctionDetailsUrl = `${tenantCode}/sell/auction/edit/${eventId}?${queryParams}`;

    const buyerStockDetailsUrl = `${tenantCode}/buy/car/details/${entityId}?${queryParams}`;
    const buyerTenderDetailsUrl = `${tenantCode}/buy/tender/details/${eventId}?${queryParams}`;
    const buyerTenderStockDetailsUrl = `${tenantCode}/buy/tender/details/${eventId}/stock/${entityId}?${queryParams}`;
    const buyerAuctionDetailsUrl = `${tenantCode}/buy/auction/details/${eventId}?${queryParams}`;
    const buyerAuctionStockDetailUrl = `${tenantCode}/buy/auction/details/${eventId}/stock/${entityId}?${queryParams}`;

    if (isStock) {
      if (isBuyer)
        url = buyerStockDetailsUrl;
      else if (isSeller)
        url = sellerStockDetailsUrl;
    }
    else if (isAuction) {
      if (isBuyer) {
        if (isTenderDetails)
          url = buyerAuctionDetailsUrl;
        else
          url = buyerAuctionStockDetailUrl;
      }
      else if (isSeller)
        url = sellerAuctionDetailsUrl;
    }
    else if (isTender) {
      if (isBuyer) {
        if (isTenderDetails)
          url = buyerTenderDetailsUrl;
        else
          url = buyerTenderStockDetailsUrl;
      }
      else if (isSeller) {
        if (isTenderDetails)
          url = sellerTenderDetailsUrl;
        else
          url = sellerTenderStockDetailsUrl;
      }
    }

    return url;
  }
}
