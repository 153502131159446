import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-records',
  templateUrl: './dynamic-records.component.html'
})
export class DynamicRecordsComponent {
  @Input() pageNumber: number = 1;
  @Input() pageSize: number = 10;
  @Input() totalCount: number = 10;
  @Input() totalShowingCount: number = 0;
  @Input() isRecordsVisible: boolean = false;
  @Input() isAggregatedListing: boolean = false;

  public get firstRecord(): number {
    let firstRecord = ((this.pageNumber - 1) * this.newPageSize) + 1;
    return this.totalCount < firstRecord ? this.totalCount : firstRecord;
  }

  public get lastRecord(): number {
    let lastRecord = this.pageNumber * this.newPageSize;
    return this.totalCount < lastRecord ? this.totalCount : lastRecord;
  }

  public get newPageSize(): number {
    return this.totalCount < this.pageSize ? this.totalCount : this.pageSize;
  }

  public get show(): string {
    return this.isAggregatedListing ? `${this.totalShowingCount} of ${this.totalCount}` : `${this.firstRecord} - ${this.lastRecord} of ${this.totalCount}`;
  }
}