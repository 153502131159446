import { Identity } from './identity';

export class EmailNotification extends Identity {

  templateName: string = '';
  templateParameters: any = {};
  emailTargets: EmailTarget[] = [];
  subject: string = '';
  attachments: EmailAttachment[] = [];
}

export enum EmailToTypes
{
    To  = 0,
    Cc  = 1,
    Bcc = 2
}

export class EmailAttachment {
  url: string = '';
  name: string = '';
  type: string = '';
}

export class EmailTarget {
  toAddress: string = '';
  ToName: string = '';
  toType: EmailToTypes;
}
