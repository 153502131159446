import { SharedService } from '@app/shared';
import { TranslateService } from '@app/translate';
import { DisposeService, PublicTenantV2Service } from '@app/data';

import { Router, ActivatedRoute } from '@angular/router';

import { BaseComponent } from './base.component';

import {
  AfterViewInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  EventEmitter,
  ViewChild
} from '@angular/core';

import {
  DynamicDataEditorComponent,
  DynamicPaginationComponent,
  DynamicFilterComponent,
  DynamicSortComponent,
  DynamicRecordsComponent
} from '@app/dynamics';

import { StateService } from '@app/core';
import { InsightService } from '@app/insight';

export abstract class BaseListComponent extends BaseComponent
  implements AfterViewInit {
  @ViewChildren(DynamicDataEditorComponent) dataEditorsComponent: QueryList<
    DynamicDataEditorComponent
  >;

  @ViewChild(DynamicPaginationComponent, { read: false, static: false })
  paginationComponent: DynamicPaginationComponent;

  @ViewChild(DynamicRecordsComponent, { read: false, static: false })
  recordsComponent: DynamicRecordsComponent;

  @ViewChild(DynamicFilterComponent, { read: false, static: false })
  filterComponent: DynamicFilterComponent;

  @ViewChild(DynamicSortComponent, { read: false, static: false })
  sortComponent: DynamicSortComponent;

  // items
  public items: any[] = [];
  public isLoadingItemsVisible: boolean = false;

  // sort
  public keySort: string = '';
  public isAscSort: boolean = true;

  // paging
  public pageSize: number = 10;
  public pagesToShow: number = 10;
  public totalPages: number = 0;
  public totalCount: number = 0;
  public hasPreviousPage: boolean = false;
  public hasNextPage: boolean = false;

  // attitudes
  public selectedItem: any;

  // check boxes
  public masterSelected: boolean = false;
  public checkedList: string[] = [];

  public constructor(
    shared: SharedService,
    translate: TranslateService,
    publicTenantService: PublicTenantV2Service,
    router: Router,
    activatedRoute: ActivatedRoute,
    state: StateService,
    disposeService: DisposeService,
    insightService: InsightService,
    public changeDetectorRef: ChangeDetectorRef,
    public itemsService: any,
    public formFilterData?: any,
    public formSortData?: any
  ) {
    super(
      shared,
      translate,
      publicTenantService,
      router,
      activatedRoute,
      state,
      disposeService,
      insightService
    );
  }

  public async ngOnInit(setItems: boolean = true): Promise<void> {

    // init data
    this.pagesToShow = this.state.isMobileResolution ? 5 : this.pagesToShow;

    // sort
    if (this.formSortData) {
      this.itemsService.sortBy = this.formSortData.sort.value;
      this.itemsService.ascendingSort = this.isAscSort;
    }

    // set params
    this.setParams();

    if (setItems) {
      await this.setItems();
    }
    await super.ngOnInit();
  }

  public ngAfterViewInit(): void {
    this.state.on(this.dataEditorsComponent.changes, () => {
      this._setDataEditorParameters();
    });

    this._setFilterParameters();
  }

  public ngOnDestroy(): void {
    this.resetPageParams();
  }

  public resetPageParams(): void {
    this.itemsService.params = null;
    this.itemsService.pageNumber = 1;
  }

  public async setItems(
    urlPostfix: string = '',
    params?: any,
    forceGet: boolean = false,
    exceptField: string = '',
    exceptFieldValue: string = ''
  ): Promise<void> {
    const items = await this.getItems(urlPostfix, params, forceGet);

    if (items) {
      // items
      this.items = items
        ? items.filter((item: any) => {
          return item[exceptField] !== exceptFieldValue;
        })
        : [];
    }
  }

  public async getItems(
    urlPostfix: string = '',
    params?: any,
    forceGet: boolean = false
  ): Promise<any> {
    this.items = [];

    // start loading
    this.changeLoadingItemsVisible(true);

    // sort
    const sortParam = this.itemsService.sortBy
      ? {
        sortBy: this.itemsService.sortBy,
        ascendingSort: this.itemsService.ascendingSort
      }
      : {};

    // pagination
    const pagingParams = {
      pageNumber: this.itemsService.pageNumber,
      pageSize: this.pageSize
    };

    this.itemsService.params = {
      ...params,
      ...this.itemsService.params,
      ...sortParam,
      ...pagingParams
    };

    // validate if the filter is valid
    if (!Array.isArray(this.itemsService.params.filters)) {
      this.itemsService.params.filters = [];
    }

    // change route
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: this.itemsService.params,
      replaceUrl: true
    });

    // get items
    await this.itemsService
      .getData(`${urlPostfix}`, this.itemsService.params, forceGet);
    return this.getCollocationItems();
  }

  public getCollocationItems(): any {
    const collection = this.itemsService.collection;

    // pagination
    this.itemsService.pageNumber = collection.pageNumber;
    this.totalPages = collection.totalPages;
    this.totalCount = collection.totalCount;
    this.hasPreviousPage = collection.hasPreviousPage;
    this.hasNextPage = collection.hasNextPage;

    // stop loading
    this.changeLoadingItemsVisible(false);

    return collection.items;
  }

  public changeLoadingItemsVisible(isLoadingItemsVisible: boolean) {
    this.isLoadingItemsVisible = isLoadingItemsVisible;

    if (this.filterComponent) {
      this.filterComponent.isLoadingItemsVisible = this.isLoadingItemsVisible;
      this._setEvent(this.filterComponent.search, 'search');
    }

    this._setSortParameters();
    this._setPaginationParameters();
    this._setRecordsParameters();
  }

  public setParams(): void {
    // query
    this.state.on(this.activatedRoute.queryParams, (queryParam: any) => {
      if (Object.keys(queryParam).length > 0) {
        this.itemsService.sortBy = queryParam.sortBy;
        this.itemsService.ascendingSort = queryParam.ascendingSort;
        this.itemsService.params = queryParam;
        this.itemsService.pageNumber = queryParam.pageNumber;
        this.pageSize = queryParam.pageSize;
      }
    });
  }

  public setSelectedItem(item: any): void {
    this.selectedItem = item;
    this._setDataEditorParameters();
  }

  public selectedItemClass(
    id: string,
    isDisabledItem: boolean = false
  ): string {
    return id === this.selectedItemId && !isDisabledItem ? 'active' : '';
  }

  // post
  public async saveCommand(
    item: any,
    urlPostfix: string = '',
    isPut: boolean = false,
    isReturnNewResponse: boolean = true
  ): Promise<void> {
    return await this.itemsService.saveItem(
      item,
      urlPostfix,
      isPut,
      isReturnNewResponse
    );
  }

  // delete
  public async deleteCommand(
    item: any,
    params: any = {},
    isNeedGoBack: boolean = true
  ): Promise<void> {
    if (isNeedGoBack) {
      await this.itemsService
        .deleteItem(item.id, params)
        .then(() => this.goBack());
    } else {
      await this.itemsService.deleteItem(item.id, params);
    }
  }

  // pagination
  public goPage(obj: any, pageNumber: number) {
    obj.itemsService.pageNumber = pageNumber;
    obj.setItems('', null, true);
  }

  public goPreviousPage(obj: any,) {
    obj.itemsService.pageNumber--;
    obj.setItems('', null, true);
  }

  public goNextPage(obj: any,) {
    obj.itemsService.pageNumber++;
    obj.setItems('', null, true);
  }

  // sort
  public sort(obj: any, sortBy: string) {
    obj.itemsService.sortBy = sortBy;
    obj.setItems('', null, true);
  }

  public changeAscOrDesc(obj: any, isAsc: boolean) {
    obj.itemsService.ascendingSort = isAsc;
    obj.setItems('', null, true);
  }

  // filter
  public search(obj: any, data: any) {
    let filters = {};
    obj.itemsService.pageNumber = 1;

    Object.keys(data).forEach((key: any) => {
      const value = data[key];

      if (value instanceof Object) {
        let filtersArray = [];
        const innerKeys = Object.keys(value);

        innerKeys.forEach((innerKey: any) => {
          const innerValue = value[innerKey];
          innerKey = innerKey === 'keyword' ? '' : innerKey;

          if (innerValue instanceof Array) {
            innerValue.forEach((item: any) => {
              filtersArray = obj._addItemInFilterArray(
                filtersArray,
                innerKey,
                item
              );
            });
          } else {
            filtersArray = obj._addItemInFilterArray(
              filtersArray,
              innerKey,
              innerValue
            );
          }
        });

        filters = Object.assign(filters, { [key]: filtersArray });
      } else {
        if (value && value !== 'all') {
          filters[`${key}`] = value;
        }
      }
    });

    // set params
    obj.itemsService.params = filters;

    obj.setItems('', null, true);
  }

  // check boxes
  public checkUncheckedAll(masterSelected: boolean, items: any[] = null): void {
    items = items ? items : this.items;

    this.masterSelected = masterSelected;

    for (let i = 0; i < items.length; i++) {
      items[i].isSelected = this.masterSelected;
    }

    this.getCheckedItemList();
  }

  public onCheckedChange(): void {
    this.masterSelected = this.items.every((item: any) => {
      return item.isSelected == true;
    });

    this.getCheckedItemList();
  }

  public getCheckedItemList(): void {
    this.checkedList = [];

    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].isSelected) {
        this.checkedList.push(this.items[i].id);
      }
    }
  }

  public getCheckedClass(id: string): boolean {
    return this.checkedList.indexOf(id) > -1;
  }

  public showOtherLinks(data: any): boolean {
    return this.selectedItem && data === this.selectedItem;
  }

  // getters
  public get isEmpty(): boolean {
    return this.items.length === 0 && !this.isLoadingItemsVisible;
  }

  public get selectedItemId(): string {
    if (this.selectedItem && this.selectedItem.id) {
      return this.selectedItem.id;
    }
    return null;
  }

  // privates
  private _addItemInFilterArray(
    filtersArray: any[],
    key: any,
    value: any
  ): any[] {
    if (value && value !== 'all') {
      key = key ? `${key}$` : '';
      filtersArray.push(`${key}${value}`);
    }

    return filtersArray;
  }

  private _setDataEditorParameters() {
    if (this.dataEditorsComponent) {
      this.dataEditorsComponent.toArray().forEach(item => {
        // inputs
        item.selectedItem = this.selectedItem;
      });

      if (!this.changeDetectorRef['destroyed']) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  private _setPaginationParameters() {
    if (this.paginationComponent) {
      // inputs
      this.paginationComponent.pageNumber = this.itemsService.pageNumber;
      this.paginationComponent.pageSize = this.pageSize;
      this.paginationComponent.pagesToShow = this.pagesToShow;
      this.paginationComponent.totalPages = this.totalPages;
      this.paginationComponent.hasPreviousPage = this.hasPreviousPage;
      this.paginationComponent.hasNextPage = this.hasNextPage;
      this.paginationComponent.isPagingVisible = !this.isLoadingItemsVisible;

      // outputs
      this._setEvent(this.paginationComponent.goPage, 'goPage');
      this._setEvent(this.paginationComponent.goPreviousPage, 'goPreviousPage');
      this._setEvent(this.paginationComponent.goNextPage, 'goNextPage');

      if (!this.changeDetectorRef['destroyed']) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  // records
  private _setRecordsParameters() {
    if (this.recordsComponent) {
      // inputs
      this.recordsComponent.pageNumber = this.itemsService.pageNumber;
      this.recordsComponent.pageSize = this.pageSize;
      this.recordsComponent.totalCount = this.totalCount;
      this.recordsComponent.isRecordsVisible = !this.isLoadingItemsVisible;

      if (!this.changeDetectorRef['destroyed']) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  private _setFilterParameters() {
    if (this.filterComponent && this.formFilterData) {
      // inputs
      this.filterComponent.data$.next(this.itemsService.params);
      this.filterComponent.filters$.next(this.formFilterData.filters);
      this.filterComponent.isLoadingItemsVisible = this.isLoadingItemsVisible;

      // outputs
      this._setEvent(this.filterComponent.search, 'search');

      if (!this.changeDetectorRef['destroyed']) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  private _setSortParameters() {
    if (this.sortComponent && this.formSortData) {
      // inputs

      this.sortComponent.keySort = this.itemsService.sortBy;
      this.sortComponent.isAscSort = this.itemsService.ascendingSort === 'true' || this.itemsService.ascendingSort === true;
      this.sortComponent.isSortVisible = !this.isLoadingItemsVisible;
      this.sortComponent.sort$.next(this.formSortData.sort);

      // outputs
      this._setEvent(this.sortComponent.sort, 'sort');
      this._setEvent(this.sortComponent.ascOrDesc, 'ascOrDesc');

      if (!this.changeDetectorRef['destroyed']) {
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  private commandMap = {
    'goPage': this.goPage,
    'goPreviousPage': this.goPreviousPage,
    'goNextPage': this.goNextPage,
    'search': this.search,
    'sort': this.sort,
    'ascOrDesc': this.changeAscOrDesc
  };

  private _setEvent(emitter: EventEmitter<any>, name: string): void {
    if (this.hasEvent(emitter)) {
      this.state.on(emitter, (event: any) => {
        const command = this.commandMap[name];
        if (command) {
          event = command(this, event);
        }
      });
    }
  }
}
