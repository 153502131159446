import { DataAccessService } from './data-access.service';
import { Dict, deepCopy, Logger } from '@app/shared';
import { Identity } from '@app/model';
import { BehaviorSubject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

// item
export class DataScalar<T extends Identity> {
  protected _item: T;

  public get item(): T {
    return this._item;
  }

  public constructor(item: T) {
    this._item = item;
  }

  public init(data: T): void {
    this._item = data;
  }

  public getItemById(id: any): T | undefined {
    if (String(this.item.id) === String(id)) {
      return this.item;
    }
    return undefined;
  }
}

export abstract class ItemService<T extends Identity> {
  public static $inject: string[] = ['dataAccessService', 'logger'];

  protected _item: T | undefined = undefined;

  protected get internalItem(): T {
    if (!this.isExistInternalItem) {
      console.warn(
        `Attempt to use ${this.serviceName} before it has been initialized`
      );
    }
    return this._item;
  }

  protected uri: string;
  protected isFullUrl: boolean;

  protected constructor(
    protected dataAccess: DataAccessService,
    protected logger: Logger
  ) { }

  protected abstract serviceName: string;

  public get collection(): DataScalar<T> {
    return new DataScalar<T>(this.internalItem);
  }

  public init(data: T): void {
    this._item = data;
  }

  public dispose(): void {
    this.init(undefined);
  }

  protected configure(uri: string, isFullUrl: boolean = false): void {
    this.uri = uri;
    this.isFullUrl = isFullUrl;
  }

  public get isExistInternalItem(): boolean {
    return this._item !== undefined;
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    if (this._item && !forceGet)
      return;
    try {
      const response = await this.dataAccess.getData<T>(
        this.uri,
        urlPostfix,
        params,
        this.isFullUrl,
        options
      );
      this.init(response);
    } catch (error) {
      console.error(`getAndValidateData ${JSON.stringify(this.uri)} failed`);
      console.error('' + error);
      throw error;
    }

  }

  public async getDataById(
    id: string,
    urlPostfix: string = '',
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    if (this._item && !forceGet) {
      this.init(this.collection.getItemById(id));
    } else {
      try {
        const response = await this.dataAccess.getDataById<T>(
          id,
          this.uri,
          urlPostfix,
          this.isFullUrl,
          options
        );
        this.init(response);
      } catch (error) {
        console.error(`getAndValidateData ${JSON.stringify(this.uri)} failed`);
        console.error('' + error);
        throw error;
      }
    }
  }

  public async deleteItem(
    id: string | null,
    params: any = {},
    showLoading: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    await this.dataAccess.deleteData(
      id,
      this.uri,
      params,
      this.isFullUrl,
      showLoading,
      options
    );

    this.logger.success('Deleted successfully!');
  }

  public async saveItem(
    item: T,
    urlPostfix: string = '',
    isPut: boolean = false,
    showLogger: boolean = true,
    showLoading: boolean = true,
    isNeedReloadItems: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    if (isPut || (item != null && item.id)) {
      await this.dataAccess.putData<T>(
        item,
        this.uri,
        urlPostfix,
        this.isFullUrl,
        options
      );

      if (this._item != null && isNeedReloadItems) {
        Object.assign(this._item, item);
      }

      if (showLogger) {
        this.logger.success('Updated successfully!');
      }
    } else {
      const response = <any>(
        await this.dataAccess.postData<T>(
          item,
          this.uri,
          urlPostfix,
          this.isFullUrl,
          showLoading,
          options
        )
      );

      if (item != null && this._item !== undefined) {
        item.id = response.scalarValue;
        this.init(item);
      }

      if (showLogger) {
        this.logger.success('Created successfully!');
      }
    }
  }
}

// items
export class DataCollection<T extends Identity> {
  protected _data: any;

  public get items(): T[] {
    return 'items' in this._data ? (this._data.items as T[]) : this._data;
  }

  public get pageNumber(): number {
    const pageNumber = this._data.pageNumber;
    return (pageNumber && pageNumber !== 0 ? pageNumber : 1) as number;
  }

  public get totalPages(): number {
    return this._data.totalPages as number;
  }

  public get totalCount(): number {
    return this._data.totalCount as number;
  }

  public get hasPreviousPage(): boolean {
    return this._data.hasPreviousPage as boolean;
  }

  public get hasNextPage(): boolean {
    return this._data.hasNextPage as boolean;
  }

  public constructor(data: any) {
    this._data = data;
  }

  public init(data: any): void {
    this._data = data;
  }

  public dict(): Dict<T> {
    return Dict.fromArray(this.items, item => '' + item.id);
  }

  public getItemById(id: any): T | undefined {
    for (const item of this.items) {
      if (String(item.id) === String(id)) {
        return <T | undefined>item;
      }
    }
    return undefined;
  }

  public copyItemById(id: string): T | undefined {
    const item = this.getItemById(id);
    if (item === undefined) {
      return undefined;
    }
    return <T | undefined>deepCopy(item);
  }

  public updateItem(newItem: T, oldItem?: T): void {
    oldItem = oldItem ? oldItem : this.getItemById(newItem.id);

    const items = this.items;
    const index = items.indexOf(oldItem);

    if (index > -1) {
      items[index] = newItem;
    }
  }

  public deleteItem(item: T, checkWithId: boolean = false): void {
    const items = this.items;

    const index = checkWithId
      ? items.findIndex(x => x.id === item.id)
      : items.indexOf(item);

    items.splice(index, 1);
  }
}

export abstract class ItemsService<T extends Identity> {
  public static $inject: string[] = ['dataAccessService', 'logger'];

  protected _data: any | undefined = undefined;

  public pageNumber: number = 1;
  public params: any = {};

  public scalarValue: string = '';
  public respnse: string = '';

  protected get internalData(): any {
    if (!this.isExistInternalData) {
      throw new Error(
        `Attempt to use ${this.serviceName} before it has been initialized`
      );
    }
    return this._data;
  }

  protected uri: string;
  protected isFullUrl: boolean;

  protected constructor(
    protected dataAccess: DataAccessService,
    protected logger: Logger
  ) { }

  protected abstract serviceName: string;

  public get collection(): DataCollection<T> {
    return new DataCollection<T>(this.internalData);
  }

  public init(data: any): void {
    this._data = data;
  }

  public dispose(): void {
    this.init(undefined);
    this.params = null;
    this.pageNumber = 1;
  }

  protected configure(uri: string, isFullUrl: boolean = false): void {
    this.uri = uri;
    this.isFullUrl = isFullUrl;
  }

  public get isExistInternalData(): boolean {
    return this._data !== undefined;
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    if (this._data && !forceGet)
      return;

    try {
      const response = await this.dataAccess.getData<any>(
        this.uri,
        urlPostfix,
        params,
        this.isFullUrl,
        options
      );
      this.init(response);
    } catch (error) {
      console.error(`getAndValidateData ${JSON.stringify(this.uri)} failed`, error);
      throw error;
    }
  }

  public async getDataById(
    id: string,
    urlPostfix: string = '',
    forceGet: boolean = false,
    options?: HttpHeaders,
    params: any = {}
  ): Promise<T | undefined> {
    if (this._data && !forceGet) {
      return <T>this.collection.getItemById(id);
    } else {
      try {
        const data = await this.dataAccess.getDataById<T>(
          id,
          this.uri,
          urlPostfix,
          this.isFullUrl,
          options,
          params
        );
  
        return <T>data;
      } catch (error) {
        throw new Error(`getAndValidateData ${JSON.stringify(this.uri)} failed: ${error}`);
      }
    }
  }

  public async deleteItem(
    id: string | null,
    params: any = {},
    isRemoveItem: boolean = true,
    showLogger: boolean = true,
    showLoading: boolean = true,
    isNeedReloadItems: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    await this.dataAccess.deleteData(
      id,
      this.uri,
      params,
      this.isFullUrl,
      showLoading,
      options
    );

    // remove item
    if (this._data != null && id && isRemoveItem && !isNeedReloadItems) {
      const foundItem = await this.getDataById(id);
      if (foundItem != undefined) {
        this.collection.deleteItem(foundItem);
        this._data.totalCount = this.collection.totalCount - 1;
      }
    }
    // reload items
    else if (isNeedReloadItems) {
      this.init(undefined);
    }

    if (showLogger) {
      this.logger.success('Deleted successfully!');
    }
  }

  public async saveItem(
    item: T,
    urlPostfix: string = '',
    isPut: boolean = false,
    isReturnNewResponse: boolean = true,
    showLogger: boolean = true,
    showLoading: boolean = true,
    isNeedReloadItems: boolean = false,
    options?: HttpHeaders
  ): Promise<void> {
    if (isPut || (item != null && item.id)) {
      const id = item.id;

      await this.dataAccess
        .putData<T>(item, this.uri, urlPostfix, this.isFullUrl, options)
        .then(async () => {
          // update item
          if (
            this._data != null &&
            id &&
            isReturnNewResponse &&
            !isNeedReloadItems
          ) {
            const foundItem = await this.getDataById(id);

            if (foundItem != undefined) {
              // update item
              this.collection.updateItem(item, foundItem);
            }
          }
          // reload items
          else if (isNeedReloadItems) {
            this.init(undefined);
          }

          if (showLogger) {
            this.logger.success('Updated successfully!');
          }

          // add scalarValue
          this.scalarValue = id;
        });
    } else {
      await this.dataAccess
        .postData<T>(
          item,
          this.uri,
          urlPostfix,
          this.isFullUrl,
          showLoading,
          options
        )
        .then((response: any) => {
          // add new item
          if (
            item != null &&
            this._data != null &&
            isReturnNewResponse &&
            !isNeedReloadItems
          ) {
            item.id = response.scalarValue;

            const items = this.internalData.items;
            items.splice(0, 0, item);

            this._data.totalCount = this.collection.totalCount + 1;
          }
          // reload items
          else if (isNeedReloadItems) {
            this.init(undefined);
          }

          if (showLogger) {
            this.logger.success('Created successfully!');
          }

          // add responses
          this.scalarValue = response && response.scalarValue ? response.scalarValue : '';
          this.respnse = response ? response : '';
        });
    }
  }

  public async login(
    item: T,
    urlPostfix: string = '',
    options?: HttpHeaders
  ): Promise<T> {
    try {
      const response = await this.dataAccess.postData<T>(
        item,
        this.uri,
        urlPostfix,
        this.isFullUrl,
        true,
        options
      );
  
      return <T>response;
    } catch (error) {
      throw new Error(`login ${JSON.stringify(this.uri)} failed: ${error}`);
    }
  }

  public async uploadFiles(
    fileOrFiles: any,
    percentDone$: BehaviorSubject<number>,
    options?: HttpHeaders
  ): Promise<any> {
    return await this.dataAccess.uploadFiles(
      this.uri,
      this.isFullUrl,
      fileOrFiles,
      percentDone$,
      options
    );
  }

  public convertTypes<T>(item: Partial<T>, constructor: new () => T): T {
    const instance = new constructor();

    return Object.keys(instance).reduce((newObject, key) => {
      switch (typeof instance[key]) {
        case 'number':
          newObject[key] = Number(item[key]);
          break;

        default:
          newObject[key] = item[key];
          break;
      }

      return newObject;
    }, {}) as T;
  }

  public get isExistCollection(): boolean {
    return this._data != null;
  }

  public updateClientItem(
    newItem: T,
    oldItem?: T,
    showLogger: boolean = false
  ): void {
    if (this.isExistCollection) {
      this.collection.updateItem(newItem, oldItem);

      if (showLogger) {
        this.logger.success('Updated successfully!');
      }
    }
  }

  public deleteClientItem(
    item: T,
    showLogger: boolean = false,
    checkWithId: boolean = false
  ): void {
    if (this.isExistCollection) {
      this.collection.deleteItem(item, checkWithId);

      if (showLogger) {
        this.logger.success('Deleted successfully!');
      }
    }
  }
}
