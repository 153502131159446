import { Injectable } from '@angular/core';

import { Connection } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';
import { StateService } from '@app/core';

export class ConnectionsInOrganizationsManageCollection extends DataCollection<Connection> {}

@Injectable({
  providedIn: 'root'
})
export class ConnectionsInOrganizationsManageService extends ItemsService<Connection> {
  protected serviceName = 'ConnectionsInOrganizationsManageService';

  public constructor(
    dataAccessService: DataAccessService,
    logger: Logger,
    private state: StateService
  ) {
    super(dataAccessService, logger);
  }

  public get collection(): ConnectionsInOrganizationsManageCollection {
    return new ConnectionsInOrganizationsManageCollection(this.internalData);
  }
}
