import { Injectable } from '@angular/core';

import { StateService } from '@app/core';

import { BuyerConnectionsService } from './services/buyer-connections.service';
import { BuyerStocksService } from './services/buyer-stocks.service';
import { BuyerTendersService } from './services/buyer-tenders.service';
import { BuyerStockTendersService } from './services/buyer-stock-tenders.service';
import { SellerStockTendersService } from './services/seller-stock-tenders.service';
import { ChatsService } from './services/chats.service';
import { UsersRoleConnectionService } from './services/connection-users-role.service';
import { OrganizationsService } from './services/organizations.service';
import { SellerStocksService } from './services/seller-stocks.service';
import { SellerTendersService } from './services/seller-tenders.service';
import { ProfileService } from './services/profile.service';
import { SupervisorUsersService } from './services/supervisor-users.service';
import { UsersService } from './services/users.service';
import { UsersSessionsService } from './services/users-sessions.service';
import { StockMakeService } from './services/stock-make.service';
import { StockModelService } from './services/stock-model.service';
import { StockBadgeService } from './services/stock-badge.service';
import { StockSeriesService } from './services/stock-series.service';
import { PublicRegisterService } from './services/public-register.service';
import { ConnectionService } from './services/connection.service';
import { PublicLoginService } from './services/public-login.service';
import { NotificationService } from './services/notification.service';
import { TransactionsOffersService } from './services/transactions-offers.service';
import { PublicTenantService } from './services/public-tenant.service';
import { PublicTenantStatesService } from './services/public-tenant-states.service';
import { TenantHubChatService } from './services/tenant-hub-chat.service';
import { TenantHubNotificationService } from './services/tenant-hub-notification.service';
import { TransactionsService } from './services/transactions.service';
import { UserPhotoService } from './services/user-photo.service';
import { WatchListService } from './services/watch-list.service';
import { ConnectionsInOrganizationsService } from './services/connections-in-organizations.service';
import { ConnectionsInOrganizationsManageService } from './services/connections-in-organizations-manage.service';
import { BuyerStocksOffersMadeService } from './services/buyer-stocks-offers-made.service';
import { SellerStocksOffersReceivedService } from './services/seller-stocks-offers-received.service';
import { SellerStocksUserService } from './services/seller-stocks-user.service';
import { SellerConnectionsService } from './services/seller-connections.service';
import { SellerStocksManageService } from './services/seller-stocks-manage.service';
import { SellerTendersStocksService } from './services/seller-tenders-stocks.service';
import { BuyerStocksUserService } from './services/buyer-stocks-user.service';
import { SellerStocksCSVService } from './services/integration-stocks-csv.service';
import { AdminTendersService } from './services/admin-tender.service';
import { TenderTransactionsService } from './services/tender-transactions.service';
import { OrganizationStocksService } from './services/organization-stocks.service';
import { TenderDashboardService } from './services/tender-dashboard.service';
import { TenderStocksMetReserveService } from './services/tenders-stocks-met-reserve.service';
import { TenderSellerParticipantsService } from './services/tender-seller-participants.service';
import { TenderStocksWithoutOffersService } from './services/tenders-stocks-without-offers.service';
import { BuyerStocksPurchasedService } from './services/buyer-stocks-purchased.service';
import { BuyerStocksSummaryService } from './services/buyer-stocks-summary.service';
import { SellerStocksSummaryService } from './services/seller-stocks-summary.service';
import { SellerStocksSoldService } from './services/seller-stocks-sold.service';
import { SellerPendingActionsService } from './services/seller-pending-actions.service';
import { BuyerPendingActionsService } from './services/buyer-pending-actions.service';
import { AppraisalService } from './services/appraisal.service';
import { InstantValService } from './services/instant-val.service';
import { Car2CashService } from './services/car2cash.service';
import { UserPreferenceService } from './services/user-preference.service';
import { BuyerStockTendersClientService } from './services/buyer-stock-tenders-client.service';
import { PaymentService } from './services/payment.service';
import { DeleteUserAccountService } from './services/delete-user-account.service';
import { PublicTenantV2Service } from './services/public-tenant-v2.service';
import { PublicRegisterIncompleteService } from './services/public-register-Incomplete.service';
import { FirebaseService } from './firebase.service';

declare let cordova: any;

@Injectable({
  providedIn: 'root'
})
export class DisposeService {
  constructor(
    private state: StateService,
    private publicRegisterService: PublicRegisterService,
    private publicRegisterIncompleteService: PublicRegisterIncompleteService,
    private buyerConnectionsService: BuyerConnectionsService,
    private buyerStocksService: BuyerStocksService,
    private buyerStocksUserService: BuyerStocksUserService,
    private buyerTendersService: BuyerTendersService,
    private buyerStockTendersService: BuyerStockTendersService,
    private buyerStockTendersClientService: BuyerStockTendersClientService,
    private sellerStockTendersService: SellerStockTendersService,
    private chatsService: ChatsService,
    private usersRoleConnectionService: UsersRoleConnectionService,
    private connectionService: ConnectionService,
    private connectionsInOrganizationsService: ConnectionsInOrganizationsService,
    private connectionsInOrganizationsManageService: ConnectionsInOrganizationsManageService,
    private publicLoginService: PublicLoginService,
    private notificationService: NotificationService,
    private organizationsService: OrganizationsService,
    private organizationStocksService: OrganizationStocksService,
    private profileService: ProfileService,
    private sellerConnectionsService: SellerConnectionsService,
    private tenderSellerParticipantsService: TenderSellerParticipantsService,
    private sellerStocksService: SellerStocksService,
    private sellerStocksCsvService: SellerStocksCSVService,
    private sellerStocksManageService: SellerStocksManageService,
    private sellerTendersStocksService: SellerTendersStocksService,
    private publicTenantStatesService: PublicTenantStatesService,
    private sellerTendersService: SellerTendersService,
    private buyerStocksPurchasedService: BuyerStocksPurchasedService,
    private buyerStocksSummaryService: BuyerStocksSummaryService,
    private sellerStocksSummaryService: SellerStocksSummaryService,
    private sellerStocksSoldService: SellerStocksSoldService,
    private publicTenantService: PublicTenantService,
    private publicTenantV2Service: PublicTenantV2Service,
    private tenantHubChatService: TenantHubChatService,
    private tenderDashboardService: TenderDashboardService,
    private tenantHubNotificationService: TenantHubNotificationService,
    private buyerStocksOffersMadeService: BuyerStocksOffersMadeService,
    private sellerStocksOffersReceivedService: SellerStocksOffersReceivedService,
    private sellerPendingActionsService: SellerPendingActionsService,
    private buyerPendingActionsService: BuyerPendingActionsService,
    private appraisalService: AppraisalService,
    private userPreferenceService: UserPreferenceService,
    private instantValService: InstantValService,
    private car2CashService: Car2CashService,
    private transactionsOffersService: TransactionsOffersService,
    private tendersStocksWithoutOffersService: TenderStocksWithoutOffersService,
    private transactionsService: TransactionsService,
    private userPhotoService: UserPhotoService,
    private sellerStocksUserService: SellerStocksUserService,
    private supervisorUsersService: SupervisorUsersService,
    private usersService: UsersService,
    private usersSessionsService: UsersSessionsService,
    private stockMakeService: StockMakeService,
    private stockModelService: StockModelService,
    private stockBadgeService: StockBadgeService,
    private stockSeriesService: StockSeriesService,
    private watchListService: WatchListService,
    private adminTendersService: AdminTendersService,
    private tenderTransactionsService: TenderTransactionsService,
    private tenderStocksMetReserveService: TenderStocksMetReserveService,
    private paymentService: PaymentService,
    private deleteUserAccountService: DeleteUserAccountService,
    private firebaseService: FirebaseService
  ) { }

  public async logout(redirectToLogin = true) {
    await this.firebaseService.logout();
    this.state.stopNotificationHubConnection();

    // check if it is mobile platform
    if (this.state.isMobileResolution) {
      this.removeMobileNotificationBadges();
    }

    this.dispose();

    if (redirectToLogin) {
      this.state.navigateWithTenantCode('login');
    }
  }

  public jailBreakLogout() {
    this.dispose();
    this.removeMobileNotificationBadges();
    this.state.setTenantInfo(null);
    this.state.navigateWithTenantCode('error/403');
  }

  public dispose() {
    this.removeStates();
    this.disposeServices();
  }

  public removeStates() {
    this.state.setToken(null);
    this.state.setArchiveTender(null);
    this.state.setOrganizationId(null);
    this.state.setMenuToggled(null);
    this.state.setCurrentUserId(null);
    this.state.setProfile(null);
  }

  public removeMobileNotificationBadges() {
    if ((typeof (cordova) !== 'undefined' && cordova)) {
      cordova.plugins.notification.badge.clear();
    }
  }

  public removeRelatedServiceStates() {
    this.state.setIsLoadingButtonVisible(false);
    this.state.setIsProfileLoaded(false);
    this.state.setIsOrganizationsLoaded(false);
    this.state.setIsConnections4SellerLoaded(false);
    this.state.setIsConnections4BuyerLoaded(false);
    this.state.setIsNotificationsLoaded(false);
    this.state.setIsWatchListLoaded(false);
  }

  public disposeServices() {
    this.removeRelatedServiceStates();

    const services = [
      this.buyerConnectionsService,
      this.publicRegisterService,
      this.publicRegisterIncompleteService,
      this.buyerStocksService,
      this.buyerTendersService,
      this.buyerStockTendersService,
      this.buyerStockTendersClientService,
      this.sellerStockTendersService,
      this.buyerStocksUserService,
      this.publicTenantStatesService,
      this.chatsService,
      this.usersRoleConnectionService,
      this.connectionService,
      this.publicLoginService,
      this.notificationService,
      this.connectionsInOrganizationsService,
      this.connectionsInOrganizationsManageService,
      this.organizationsService,
      this.organizationStocksService,
      this.profileService,
      this.sellerConnectionsService,
      this.tenderSellerParticipantsService,
      this.sellerStocksService,
      this.sellerStocksCsvService,
      this.sellerStocksManageService,
      this.sellerTendersStocksService,
      this.sellerTendersService,
      this.sellerStocksSoldService,
      this.buyerStocksPurchasedService,
      this.buyerStocksSummaryService,
      this.sellerStocksSummaryService,
      this.buyerStocksOffersMadeService,
      this.sellerStocksOffersReceivedService,
      this.sellerPendingActionsService,
      this.buyerPendingActionsService,
      this.appraisalService,
      this.userPreferenceService,
      this.instantValService,
      this.car2CashService,
      this.transactionsOffersService,
      this.tendersStocksWithoutOffersService,
      this.publicTenantService,
      this.publicTenantV2Service,
      this.tenantHubChatService,
      this.tenderDashboardService,
      this.tenantHubNotificationService,
      this.transactionsService,
      this.userPhotoService,
      this.sellerStocksUserService,
      this.supervisorUsersService,
      this.usersService,
      this.usersSessionsService,
      this.stockMakeService,
      this.stockModelService,
      this.stockBadgeService,
      this.stockSeriesService,
      this.watchListService,
      this.adminTendersService,
      this.tenderTransactionsService,
      this.tenderStocksMetReserveService,
      this.paymentService,
      this.deleteUserAccountService
    ];

    services.forEach(service => service.dispose());
  }
}
