import { Identity } from './identity';
import { User } from './user';
import { Stock } from './stock';

export class Connection extends Identity {
  sourceEntityId: string = '';
  destinationEntityId: string = '';
  sourceEntityType: string = '';
  destinationEntityType: string = '';
  isConnected?: boolean = false;
  features?: User | Stock;

  constructor() {
    super();
  }
}

export enum ConnectionEntityType {
  User  = 0,
  Stock = 1
}

export const ConnectionEntityTypeText = {
  [ConnectionEntityType.User]: 'User',
  [ConnectionEntityType.Stock]: 'Stock'
};
