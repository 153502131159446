import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { StateService } from '@app/core';

@Pipe({
  name: 'tenantCurrency'
})
export class TenantCurrencyPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
    private state: StateService
  ) {}

  transform(value: number | string): string {
    return this.currencyPipe.transform(
      value,
      null,
      this.state.tenantInfo.defaultCurrency
    );
  }
}
