import { Identity } from './identity';

export class Notification extends Identity {
  notificationHistorySummary: NotificationHistorySummary[];
  continuationToken: string;
  allNotifications?: NotificationHistorySummary[];
  unReadNotifications?: NotificationHistorySummary[];

  constructor() {
    super();
  }
}

export class NotificationHistorySummary {
  code: string;
  creationDateTime: string;
  channel?: NotificationChannels;
  payload?: NotificationPayload;
  isRead?: boolean = false;
  title?: string;
  content?: string;
  url?: string;
  thumbnailUrl?: string;
  metaData: any;

  constructor() {}
}

export class NotificationPayload {
  Message: NotificationMessage;
  MetaData: NotificationMetaData;

  constructor() {}
}

export class NotificationMessage {
  Title: string;
  Content: string;
  ThumbnailUrl: string;
  Url: string;

  constructor() {}
}

export class NotificationMetaData {
  EntityId: string;
  EventId: string;
  EntityOrganizationId: string;
  Action: string;
  Target: string;

  constructor() {}
}

export enum NotificationChannels {
  Sms = 'Sms',
  Email = 'Email',
  MobilePush = 'MobilePush',
  WebNotification = 'WebNotification'
}

export enum NotificationStatuses {
  All = 'All',
  Read = 'Read',
  Unread = 'Unread'
}

export enum NotificationActions {
  Create,
  ScheduleStart,
  Start,
  View,
  Offer,
  Buy,
  Sell,
  Accept,
  Reject,
  Invite,
  Counter,
  End,
  Complete,
  HouseKeeping,
  Finish
}

export const NotificationActionsText = {
  [NotificationActions.Create]: 'Create',
  [NotificationActions.ScheduleStart]: 'ScheduleStart',
  [NotificationActions.Start]: 'Start',
  [NotificationActions.View]: 'View',
  [NotificationActions.Offer]: 'Offer',
  [NotificationActions.Buy]: 'Buy',
  [NotificationActions.Sell]: 'Sell',
  [NotificationActions.Accept]: 'Accept',
  [NotificationActions.Reject]: 'Reject',
  [NotificationActions.Invite]: 'Invite',
  [NotificationActions.Counter]: 'Counter',
  [NotificationActions.End]: 'End',
  [NotificationActions.Complete]: 'Complete',
  [NotificationActions.HouseKeeping]: 'HouseKeeping',
  [NotificationActions.Finish]: 'Finish'
};

// push notification
export class PushNotification {
  actions?: any[];
  badge?: string;
  body?: string;
  icon?: string;
  image?: string;
  tag?: string;
  data?: string;
  renotify?: boolean;
  silent?: boolean;
  sound?: string;
  noScreen?: boolean;
  sticky?: boolean;
  lang?: 'auto' | 'ltr' | 'rtl';
  vibrate?: number[];
}

export declare type PushNotificationPermission =
  | 'denied'
  | 'granted'
  | 'default';
