import { Identity } from "./identity";

export class InviceMonthlySummary  extends Identity {
  Month: string;
  amountDue: number = 0;
  totalAmount: number = 0;
  amountPaid: number = 0;
  constructor() {
    super();

  }
}

export class InvoiceMonthlySummaryResponse
{
    totalAmount : number = 0
    totalOweAmount : number = 0
    totalOverDueAmount : number = 0
    totalCount : number = 0
    items?: InviceMonthlySummary[] = [];
      constructor() {}
}
