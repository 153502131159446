import { Injectable } from '@angular/core';

import { User } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class UsersSessionsCollection extends DataCollection<User> {}

@Injectable({
  providedIn: 'root'
})
export class UsersSessionsService extends ItemsService<User> {
  protected serviceName = 'UsersSessionsService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('supervisor/identity/v1/users/sessions');
  }

  public get collection(): UsersSessionsCollection {
    return new UsersSessionsCollection(this.internalData);
  }
}
