import { Injectable } from '@angular/core';

import { Tender } from '@app/model';
import { Logger } from '@app/shared';
import { DataCollection, ItemsService } from '../data-service.service';
import { DataAccessService } from '../data-access.service';

export class AdminTendersCollection extends DataCollection<Tender> {}

import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminTendersService extends ItemsService<Tender> {
  protected serviceName = 'AdminTendersService';

  public constructor(dataAccessService: DataAccessService, logger: Logger) {
    super(dataAccessService, logger);
    super.configure('supervisor/tender/v2/tenders');
  }

  public get collection(): AdminTendersCollection {
    return new AdminTendersCollection(this.internalData);
  }

  public async getData(
    urlPostfix: string = '',
    params: any = {},
    forceGet: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    const staticFilters = ['type$Public$0'];

    params.filters =
      params.filters && params.filters instanceof Array
        ? params.filters.some((x: string) => x.indexOf('type') > -1)
          ? params.filters
          : staticFilters.concat(params.filters)
        : staticFilters;

    await super.getData(urlPostfix, params, forceGet, options);
  }

  public async saveItem(
    item: Tender,
    urlPostfix: string = '',
    isPut: boolean = false,
    isReturnNewResponse: boolean = true,
    showLogger: boolean = true,
    showLoading: boolean = true,
    isNeedReloadItems: boolean = true,
    options?: HttpHeaders
  ): Promise<void> {
    // save and go to server and get new data
    let localUrlPostfix = 'updatebyadmin';
    await super.saveItem(
      item,
      localUrlPostfix,
      isPut,
      isReturnNewResponse,
      showLogger,
      showLoading,
      isNeedReloadItems,
      options
    );
  }
}
