import { Identity } from './identity';

export class File extends Identity {
  code?: string;
  url: string;
  thumbnailUrls: FileThumbnail[];
  hasAnyThumbnail: boolean;

  constructor() {
    super();
  }
}

export class FileThumbnail {
  sizeCodeValue: string;
  url: string;

  constructor() {}
}
