import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-refresh-icon',
  templateUrl: './refresh-icon.component.html'
})
export class RefreshIconComponent {
  public refresh() {
    window.location.reload();
  }
}
