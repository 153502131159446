import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-show-more-list',
  templateUrl: './show-more-list.component.html'
})
export class ShowMoreListComponent {
  @Input() isLoading: boolean = false;
  @Input() className: string = '';

  @Output() showMore: EventEmitter<any> = new EventEmitter();

  public onShowMore() {
    if (this.isLoading) return;
    this.showMore.emit();
  }
}
